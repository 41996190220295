<template>
  <div>
    Bookers overview
  </div>
</template>

<script>
export default {
  name: "bookers-overview",
  data() {
    return{

    }
  },
  mounted() {
 
  }
}
</script>
<style lang="scss" scoped>

</style>
