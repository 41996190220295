<template>
  <div class="settings">
    <div class="settings-header">
      <div class="title">Manage users</div>
      <div class="sub-title">Manage all your users from here</div>
    </div>
    <div id="employees-select">
      <template v-if="pickers.length !== undefined">
        <v-select v-model="currentUserId" @option:selected="changePacker"
        :options="pickers.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(function(el) {

          var o = Object.assign({}, el);
          
          if(o.pickerId === currentUserId)
          {
            currentUserId = o.firstName + ' ' + o.lastName;
          }

          o.fullName = o.firstName + ' ' + o.lastName;
          return o;
          })" 
        :reduce="pickers => pickers.fullName" label="fullName" placeholder="Select a user" dense />
      </template>
    </div>
    <div class="settings-body">
      <div class="field">
        <label>Firstname</label>
        <input v-model="selectedUserObject[0].firstName" type="text">
      </div>
      <div class="field">
        <label>Lastname</label>
        <input v-model="selectedUserObject[0].lastName" type="text">
      </div>
      <div class="field">
        <label>Email</label>
        <input v-model="selectedUserObject[0].email" type="text">
      </div>
      <div class="field" > <!-- Only admin can adjust password of users -->
        <label>Password</label>
        <input v-model="selectedUserObject[0].password" type="text">
      </div>
      {{  currentUserRole }}
      <div class="field" v-if="user.role == 2">
        <label>Role</label>
        <div class="manage">
          <div @click="roleDropdown()" class="manage-btn exclude_manage_btn">
            <span>{{ Object.keys(roles).find(key => roles[key] === currentUserRole) }}</span>
            <i class='bx bxs-edit-alt'></i>
          </div>
          <div class="dropdown-role" v-click-outside="{ exclude: ['exclude_manage_btn'], handler: hideRoleDropdown() }">
            <div @click="(() => {
              currentUserRole = 1; 
              hideRoleDropdown();
            })" :checked="selectedUserObject[0].role === 1" :class="{'role-input-wrap': true, 'active': currentUserRole == 1}">
              <div class="input-title">User</div>
            </div>
            <div @click="currentUserRole = 4" :checked="selectedUserObject[0].role=== 4" :class="{'role-input-wrap': true, 'active': currentUserRole == 4}">
              <div class="input-title">Printer</div>
            </div>
            <div @click="currentUserRole = 3" :checked="selectedUserObject[0].role === 3" :class="{'role-input-wrap': true, 'active': currentUserRole == 3}">
              <div class="input-title">Manager</div>
            </div>
            <div @click="currentUserRole = 2" :checked="selectedUserObject[0].role === 2" :class="{'role-input-wrap': true, 'active': currentUserRole == 2}">
              <div class="input-title">Admin</div>
            </div>
          </div>
        </div>
      </div>
      <div class="field">
        <label>PicqerID</label>
        <input v-model="selectedUserObject[0].pickerId" type="text">
      </div>
      <div class="field">
        <label>User since</label>
        <div class="no-input">{{selectedUserObject[0].createdDate}}</div>
      </div>
    </div>
    <div class="settings-footer">
      <div class="btn" @click="saveUser">Save user</div>
    </div>
  </div>
</template>
  
<script>
import { ref, onMounted, reactive} from "vue";
import Page from '@/components/Page.vue'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import moment from 'moment';
import VueMultiselect from 'vue-multiselect'

export default {
  name: "Settings Manage User",
  components: { Page, VueMultiselect },
  data() {
    return {
      loading: false,
      pickers: [],
      currentUserId: null,
      errorMessage: "",
      showError: false,
      selectedUserObject: [{firstName: "", lastName: "", email: "", pickerId: "", createdDate:"", password: "", id: "", role: 1}],
      currentUserRole: 1,
      roles: {"User": 1, "Printer": 4, "Manager": 3, "Admin": 2}
    }
  },
  setup() {
    const user = ref({});

    onMounted(async () => { 
      user.value = cookies.get('token');
      user.value.createdDate = moment(user.value.createdDate).format('YYYY-MM-DD HH:mm:ss').toString();
    })

    async function saveProfile(){
      user.value = cookies.get('token');
      const updatedUser = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + user.value['pickerId'], {
        method: 'PUT', 
        headers: {"Authorization": user ? `Bearer ${user.value.token}` : ""},
        body: JSON.stringify(user.value)
      })

      if(updatedUser.ok)
      {
        // const userNew = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + user.value.pickerId, {
        // method: 'GET', 
        // headers: {"Authorization": user ? `Bearer ${user.value.token}` : ""}

        // })

        // // Show alert (Succesfully updated profile!)
        // cookies.set('token', user._value);
      }


      if(updatedUser.email != null)
      {
        const user = user._value;
      }
    }

    return {
     user, saveProfile
    }
  },
  methods: {
    roleDropdown(){
      var dropdown = document.querySelectorAll(".settings-body .dropdown-role");

      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
    },
    hideRoleDropdown(){
      var dropdown = document.querySelectorAll(".settings-body .dropdown-role");

      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
    },
    async saveUser() { 
      const user = cookies.get('token');
      
      this.selectedUserObject[0].role = this.currentUserRole;
      const updatedUser = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + this.selectedUserObject[0].pickerId, {
        method: 'PUT', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""},
        body: JSON.stringify(this.selectedUserObject[0])
      })

      // if(updatedUser.ok)
      // {
      //   const userNew = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + this.selectedUserObject[0].pickerId, {
      //   method: 'GET', 
      //   headers: {"Authorization": user ? `Bearer ${user.token}` : ""}

      //   })
      // }
    },

    async changePacker(event){
      this.currentUserRole = this.selectedUserObject[0]["role"];
      cookies.set("currentUserId", event.pickerId);
      Object.assign(this.selectedUserObject, this.pickers.filter((picker) => picker.pickerId === event.pickerId));
      this.selectedUserObject[0]["createdDate"] = moment(this.selectedUserObject[0]["createdDate"]).format('YYYY-MM-DD HH:mm:ss').toString();
    },

    async users(){
       this.loading = true
      try {
        const user = cookies.get('token');
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user.token}` : ""}})
         
        this.pickers = await res.json()
        Object.assign(this.selectedUserObject, this.pickers.filter((picker) => picker.pickerId === this.currentUserId));
      
        this.currentUserRole = this.selectedUserObject[0]["role"];
        this.loading = false
      } catch (error) {
        this.errorMessage = error
        this.showError = true
        this.loading = false
      }
    }
  },
  async created() {
    this.currentUserId = cookies.get("currentUserId") ?? String.empty;
    await this.users();

    // if(this.currentUserId !== String.empty)
    // {
    //   Object.assign(this.selectedUserObject, this.pickers.filter((picker) => picker.pickerId === this.currentUserId));
    // }
  }
}
</script>