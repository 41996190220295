<template>
  <MobileNavbar />
  <nav v-if="user">
    <div class="navbar-left">
      <div @click="showMobileMenu" class="mobile-nav-btn exclude_mobile_nav_btn">
        <ul>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
      <div id="button" @click="shopDropdown" class="button exclude_navbar_shop_btn">
        <p>Dartshopper</p>
        <i class="bx bxs-chevron-down arrow"></i>
      </div>
      <div v-click-outside="{ exclude: ['exclude_navbar_shop_btn'], handler: hideShopDropdown }" class="dropdown"> <!--  -->
        <div class="shop-card">
          <div class="title">
            Dartshopper
          </div>
          <div class="subtitle">
            Current webshop
          </div>
        </div>
        <div class="shop-settings">
          <router-link @click="hideShopDropdown" to="/">Dartshopper<i class='bx bxs-home' ></i></router-link>
          <!-- <router-link @click="hideShopDropdown" to="/sales/overview" id="test">Lightexpert<i class='bx bxs-home' ></i></router-link> -->
        </div>
      </div>
    </div>

    <div class="navbar-right">
      <!-- <div class="theme-switch-wrapper">
        <label @click="themeChange" class="theme-switch" for="checkbox">
          <input type="checkbox" id="checkbox" @change="toggleMode" v-model="checked">
          <transition name="fade" mode="out-in">
            <i v-if="checked === true" class='bx bxs-sun'></i>
            <i v-else class='bx bxs-moon'></i>
          </transition>
        </label>
      </div> -->

      <div class="navbar-user">
        <div id="button" @click="userDropdown" class="button exclude_navbar_user_btn">
          <p>{{ user.firstName }}</p>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <div v-click-outside="{ exclude: ['exclude_navbar_user_btn'], handler: hideUserDropdown }" class="dropdown"> <!--  -->
          <div class="user-card">
            <div class="title">
              Hi, {{ user.firstName }}&nbsp;{{ user.lastName }} <!-- user.email to use user.email -->
            </div>
            <div class="subtitle">
              Overview of your settings
            </div>
          </div>
          <div class="user-settings">
            <router-link @click="hideUserDropdown" to="/settings/profile">Profile<i class="bx bxs-chevron-right arrow"></i></router-link>
            <!-- <router-link @click="hideUserDropdown" to="/" id="test">Account settings<i class="bx bxs-chevron-right arrow"></i></router-link> -->
            <router-link @click="handleClick" to="/login" id="test">Sign out<i class="bx "></i></router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref, onBeforeUpdate, onMounted, watchEffect } from "vue";
import MobileNavbar from '@/components/MobileNavbar.vue'
import useLogout from '../composeables/useLogout'
import getUser from '../composeables/getUser'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  components: { MobileNavbar },
  data() {
    return {
      checked: false,
      value1: false
    }
  },
  created: function() {
    const currentTheme = localStorage.getItem("theme")
      ? localStorage.getItem("theme")
      : null;

    if (currentTheme) {
      document.documentElement.setAttribute("data-theme", currentTheme);

      if (currentTheme === "dark") {
        this.checked = true;
      }
    }
  },

  methods: {
    hideUserDropdown(){
      var arrow = document.querySelectorAll(".navbar-user .arrow");
      var dropdown = document.querySelectorAll(".navbar-user .dropdown");
      var button = document.querySelectorAll(".navbar-user .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    hideShopDropdown(){
      var arrow = document.querySelectorAll(".navbar-left .arrow");
      var dropdown = document.querySelectorAll(".navbar-left .dropdown");
      var button = document.querySelectorAll(".navbar-left .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    themeChange(){
      var theme = document.querySelectorAll(".theme-switch-wrapper .theme-switch");

      if (theme.length > 0) {
        theme[0].classList.toggle("active");
      }
    },
    toggleMode(event) {
      if (this.checked) {
        document.documentElement.setAttribute("data-theme", "dark");
        localStorage.setItem("theme", "dark");
      } else {
        document.documentElement.setAttribute("data-theme", "light");
        localStorage.setItem("theme", "light");
      }
    },
    shopDropdown(){
      var arrow = document.querySelectorAll(".navbar-left .arrow");
      var dropdown = document.querySelectorAll(".navbar-left .dropdown");
      var button = document.querySelectorAll(".navbar-left .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    userDropdown(){
      var arrow = document.querySelectorAll(".navbar-user .arrow");
      var dropdown = document.querySelectorAll(".navbar-user .dropdown");
      var button = document.querySelectorAll(".navbar-user .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    showMobileMenu(){
      var mobileNav = document.querySelectorAll(".mobile-nav");

      if (mobileNav.length > 0) {
        mobileNav[0].classList.toggle("active");
      }
    }
  },
  setup() {
    const { logout, error } = useLogout()
    const user = ref({});

    onMounted(() => {
      user.value = cookies.get('token');
    })

    const handleClick = async () => {
      await logout()
      if(!error.value) {
      }
    }

    return { handleClick, user }
  }
}
</script>

<style lang="scss" scoped>
nav{
  position: fixed;
  height: 6.0rem;
  border-bottom: 1px solid;
  border-color: var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #444;
  transition: background-color .2s ease, color 2s ease;
  background-color: var(--bg-white);
  padding: 1.6rem 3.2rem;
  transition: all 0.3s ease;
  z-index: 2;
  .navbar-left{
    .mobile-nav-btn{
      display: none;
    }
    .button{
      font-family: "Neutrif-bold";
      color: #0B212D;
      font-size: 16px;
      background: white;
      height: 40px;
      padding: 0 1.6rem;
      border-radius: $border-radius;
      cursor: pointer;
      transition: all 0.3s ease;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      border: 1px solid #D7DCE1;
      background-color: #F5F6F8;
      &:hover{
        border: 1px solid #D7DCE1;
        background-color: #F5F6F8;
      }
      p{
        display: inline;
        margin-right: 0.8rem;
      }
      .arrow{
        font-size: 20px;
        transition: transform 0.3s ease;
      }
      .open{
        transform: rotate(180deg);
      }
    }
    .dropdown{
      position: absolute;
      font-size: 16px;
      background: white;
      margin-top: 1.6rem;
      border-radius: $border-radius;
      display: block;
      width: 32.0rem;
      transform: scale(0.01);
      opacity: 0;
      transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
      transform-origin: left top;
      overflow: hidden;
      z-index: 1;
      .shop-card{
        width: 32.0rem;
        background-color: #2E303F;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 3.2rem;
        .title{
          color: white;
          font-family: "Neutrif-bold";
        }
        .subtitle{
          font-size: 16px;
          color: #9397A1;
        }
      }
      .shop-settings{
        padding: 3.2rem 1.6rem;
        a{
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 16px;
          cursor: pointer;
          position: relative;
          color: #9397A1;
          text-decoration: none;
          transition: color 0.2s ease, background-color 0.2s ease, padding-left 0.2s ease;
          overflow: hidden;
          margin-bottom: 0.8rem;
          border-radius: $border-radius;
          padding: 0 1.6rem;
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
          &:last-child{
            margin-bottom: 0;
          }
          &:hover{
            background-color: #F5F6F8;
            color: #0B212D;
          }
        }
        .router-link-exact-active{
          color: #0B212D;
          background-color: #F5F6F8;
        }
      }
    }
    .dropdown.open{
      transform: scale(1);
      opacity: 1;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    }
  }
  .navbar-right{
    display: flex;
    align-items: center;
    .theme-switch-wrapper {
      display: flex;
      align-items: center;
      .theme-switch.active{
        background-color: red;
      }
      .theme-switch {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        width: 40px;
        position: relative;
        margin-right: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #F5F6F8;
        border-radius: $border-radius;
        padding: 0 1.6rem;
        cursor: pointer;
        i{
          transition: all 0.3s ease;
          font-size: 20px;
        }
        input{
          display: none;
        }
      }
    }
    .navbar-user{
      position: relative;
      .button{
        font-family: "Neutrif-bold";
        color: #0B212D;
        font-size: 18px;
        background: white;
        height: 40px;
        padding: 0 1.6rem;
        border-radius: $border-radius;
        cursor: pointer;
        transition: all 0.3s ease;
        border: 1px solid transparent;
        display: flex;
        align-items: center;
        border: 1px solid #D7DCE1;
        background-color: #F5F6F8;
        &:hover{
          border: 1px solid #D7DCE1;
          background-color: #F5F6F8;
        }
        p{
          display: inline;
          margin-right: 0.8rem;
        }
        .arrow{
          font-size: 20px;
          transition: transform 0.3s ease;
        }
        .open{
          transform: rotate(180deg);
        }
      }
      .dropdown{
        position: absolute;
        font-size: 18px;
        background: white;
        margin-top: 1.6rem;
        border-radius: $border-radius;
        display: block;
        width: 32.0rem;
        transform: scale(0.01);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
        transform-origin: right top;
        overflow: hidden;
        z-index: 1;
        right: 0;
        .user-card{
          width: 32.0rem;
          background-color: #2E303F;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: 3.2rem;
          .title{
            font-family: "Neutrif-bold";
            color: white;
          }
          .subtitle{
            font-size: 16px;
            color: #9397A1;
          }
        }
        .user-settings{
          padding: 3.2rem;
          a{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            cursor: pointer;
            position: relative;
            color: #9397A1;
            text-decoration: none;
            transition: color 0.2s ease, background-color 0.2s ease, padding-left 0.2s ease;
            overflow: hidden;
            padding-bottom: 1.6rem;
            &:last-child{
              padding-bottom: 0;
            }
            &:hover{
              color: #0B212D;
            }
          }
        }
      }
      .dropdown.open{
        transform: scale(1);
        opacity: 1;
        box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s
}
.fade-enter,
.fade-leave-to {
  opacity: 0
}

@media only screen and (max-width: 767px) {  
  nav{
    width: 100%;
    left: unset;
    padding-left: 0.0rem;
    padding-right: 1.6rem;
    .navbar-left{
      .mobile-nav-btn{
        display: block;
        width: 56px;
        height: 60px;
        ul{
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          height: 100%;
          width: 100%;
          border-right: 1px solid var(--border-color);
          li{
            list-style: none;
            position: absolute;
            width: 18px;
            height: 3px;
            background: #0B212D;
            opacity: 1;
            &:nth-of-type(1){
              margin-bottom: 14px;
            }
            &:nth-of-type(3){
              margin-bottom: -14px;
            }
          }
        }
      }
      #button{
        display: none;
      }
      .dropdown{
        display: none;
      }
    }
    .navbar-right{
      .navbar-user{
        .button{
          border: 1px solid #D7DCE1;
          background-color: #F5F6F8;
        }
      }
    }
  }
}
</style>