<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist__title picklist-urgent__title">
      <span>Urgent</span>
      <span> Picklists</span>
       <span>&#128680;</span>
      </h2>
    <h4 class="picklist__label">Beginning: {{ state.PicklistsleftBeginning }}</h4>

  </div>
  <div class="picklist__logos-wrapper">
    <div class="picklist__logos">
      <LogoAmazon />
    </div>
    <div class="picklist__logos">
      <LogoBol />
    </div>
  </div>
  <div class="picklist-urgent__main picklist__main">
    <div class="picklist__counter">
      <OdometerComponent :initialValue="state.openUrgent" />

      <!-- <animated-counter :number="state.openUrgent"></animated-counter> -->
    </div>
    <div class="picklist-urgent__chart picklist__chart" :class="DynamicColor">
      <client-only>
        <LineChart :chartData="urgentData" :options="urgentOptions" ref="lineUrgentRef"
          @chart:render="urgentHandleChartRender" />
      </client-only>
      <div class="picklist__chart__status">
        <IconStatus />
        <p class="picklist__chart__percentage">{{ state.percentageChange }}%</p>
      </div>
    </div>
  </div>
</template> 

<script>
import { onMounted, reactive, watch, ref, computed } from "vue";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import IconStatus from '@/components/svg/IconStatus.vue';
import LogoAmazon from '@/components/svg/LogoAmazon.vue';
import LogoBol from '@/components/svg/LogoBol.vue';
import { remToPx } from './utils';
import OdometerComponent from './OdometerComponent.vue';


export default {
  components: { LineChart, IconStatus, LogoBol, LogoAmazon, OdometerComponent },
  props: {
    count: Number,
    history: Array,
  },
  setup(props) {
    const { count, history } = props;

    const state = reactive({
      openUrgent: 0,
      percentageChange: null,
      PicklistsleftBeginning: 0,
      historyArray: [...props.history],
      lineWidth: 0,
      dashLength: 0,
      gapLength: 0,
      borderWidth: 0,
      borderColor: "#ff551f",
      gradientColor: "orange",
    })

    const lineUrgentRef = ref(null);
    Chart.register(...registerables);

    const getResponsiveValues = () => {
      if (window.innerWidth <= 1023) {
        state.lineWidth = remToPx(0.1);
        state.dashLength = remToPx(0.5);
        state.gapLength = remToPx(0.3);
        state.borderWidth = remToPx(0.1);
      } else {
        state.lineWidth = remToPx(0.15)
        state.dashLength = remToPx(0.6);
        state.gapLength = remToPx(0.4);
        state.borderWidth = remToPx(0.15);
      }
    };

    const urgentHandleChartRender = (chart) => {
      if (window.innerWidth <= 1025) {
        // If the width is less than or equal to 1023px, set the font size to 4rem
        chart.canvas.parentNode.style.height = '4rem';
      } else {
        // For wider screens, set the height as originally intended
        chart.canvas.parentNode.style.height = '7rem';
      }
      lineUrgentRef.value.update();
    }


    const urgentOptions = computed(() => ({
      interaction: {
        intersect: false,
        mode: "index",
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        horizontalLine: {
          draw: true,
          lineWidth: state.lineWidth,
          dashLength: state.dashLength,
          gapLength: state.gapLength,
          yValueOption: 'firstData',
          lineColor: '#8F8F8F',
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      maintainAspectRatio: false,
    }));

    const urgentData = computed(() => ({
      labels: state.historyArray.map(x => moment(x.date).add(1, 'minute').startOf('minute').format('YYYY-MM-DD HH:mm:ss').toString()),
      showTooltips: false,
      datasets: [
        {
          label: "Picklists completed",
          gradientType: state.gradientColor,
          data: state.historyArray.map(x => x.count),
          borderColor: state.borderColor,
          borderWidth: state.borderWidth,
          pointRadius: 0,
          fill: true,
          tension: 0.4,
        }
      ],
    }))
    const DynamicColor = computed(() => {
      if (state.percentageChange < 0) {
        state.gradientColor = "orange";
        state.borderColor = "#ff551f";
        return "increase";
      } else {
        state.gradientColor = "green";
        state.borderColor = "#18B432";
        return "decrease";
      }
    });
    onMounted(async () => {
      // Update PicklistsleftToComplete when data1 changes
      if (count) {
        state.openUrgent = count;
      }

      if (state.historyArray) {
        state.percentageChange = parseInt(100 - (100 / state.historyArray[0].count * state.historyArray[state.historyArray.length - 1].count)).toFixed(0)
        state.PicklistsleftBeginning = state.historyArray[0].count
      }
      getResponsiveValues()

    });

    const historyWatcher = computed(() => props.history);
    watch(historyWatcher, (newValue) => {
      state.historyArray = [...newValue];
      state.PicklistsleftBeginning = newValue[0].count
      state.percentageChange = parseInt(100 - (100 / state.historyArray[0].count * state.historyArray[state.historyArray.length - 1].count)).toFixed(0)

      if (lineUrgentRef.value) {
        // Now you can safely use lineUrgentRef
        lineUrgentRef.value.update();
      } else {
        console.log('lineUrgentRef is not initialized yet');
      }
    });

    // Use computed to watch the count prop
    const countWatcher = computed(() => props.count);
    watch(countWatcher, (newValue) => {
      state.openUrgent = newValue;
    });

    return {
      state,
      lineUrgentRef,
      urgentOptions,
      urgentData,
      DynamicColor,
      count,
      history,
      urgentHandleChartRender,
      getResponsiveValues
    };
  },
};

</script>
<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-urgent {
        &__chart {
          margin-bottom: 0.7rem;
        }

        & .picklist__title-wrapper {
          gap: 0.8rem;
        }

        &__title {
          flex: 2;

          & span {
            font-size: 1.8rem;
          }
        }
      }

      &__logos-wrapper {
        display: flex;
        gap: 1rem;
        position: absolute;
        top: 6rem;
      }

      &__logos {
        width: 5rem;
        height: 2.4rem;
        padding: 0.5rem 0 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #F9F9F9;
        background: var(--color-white);
        box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.10);


        & svg {
          display: block;
          height: 100%;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-urgent {
          &__title {
            & span {
              font-size: 1rem;
            }
          }
        }

        &__logos-wrapper {

          gap: 0.5rem;

          top: 3.7rem;
        }

        &__logos {
          width: 3.3rem;
          height: 1.6rem;
          padding: 0.3rem 0 0.3rem 0;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          border: 1px solid #F9F9F9;
          background: var(--color-white);
          box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.10);


          & svg {
            display: block;
            height: 100%;
          }
        }
        &__logos-wrapper{
          display: none;
        }
      }
    }
  }
}
</style>
