import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import moment from 'moment';

export const getOrdersPerDay = async (startDate, endDate, perDate, month) => {
    const user = cookies.get('token');
    let formattedStartdate = startDate;
    let formattedEnddate = endDate;

    if(perDate == 'month') {
        formattedStartdate = moment(month).startOf('month').format('YYYY-MM-DD hh:mm').toString();
        formattedEnddate = moment(month).endOf('month').format('YYYY-MM-DD hh:mm').toString();
    }

    const res = await fetch(process.env.VUE_APP_ROOT_API + `/api/mysql/GetOrdersPerDay?startDate=${formattedStartdate}&endDate=${formattedEnddate}&perDate=${perDate}`, {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""}
    });
    const result = await res.json();

    return JSON.parse(JSON.stringify(result));
}