<template>
  <div class="working-tabs" v-bind:data-selected-title="selectedTitle" v:bind:data-default-title="defaultTitle">
    <ul class="tabs__header">
      <li v-for="(title, index) in tabTitles" :key="index" @click.stop="this.$emit('click', title);  selectedTitle = title;" :class="{ selected: title == selectedTitle }">
        <!-- <img class="tab-working-img" :src="require(`@/assets/img/working/${tabIcons[index]}`)" /> -->
        <span class="tab-working-title">{{ title }}</span>
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from 'vue'

export default {
  setup (props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title));
    const tabIcons = ref(["clipboard-picking.svg", "big-cart.svg"])
    const tabDefaultTitles = ref(slots.default().flatMap((tab) => tab.props.selected === 'true' ? [tab.props.title] : []));

    const defaultTitle = ref(tabDefaultTitles.value[0])
    let selectedTitle = "";
    let selectedIcon = "";

    if(defaultTitle !== null || defaultTitle !== '')
    {
      selectedTitle = defaultTitle;
    }
    else
    {
      selectedTitle = ref(tabTitles.value[0])
    }

    if(selectedTitle === 'Picking')
    {
      selectedIcon = ref(tabIcons.value[0])
    }
    else if(selectedTitle === 'Packing')
    {
      selectedIcon = ref(tabIcons.value[1])
    }


    provide("selectedTitle", selectedTitle)
    provide("defaultTitle", defaultTitle)
    return{
      selectedTitle,
      defaultTitle,
      tabDefaultTitles,
      selectedIcon,
      tabTitles,
      tabIcons
    }
  }
}
</script>

<style lang="scss" scoped>

.working-tabs{
    border-radius: 6px 6px 0px 0px;
    background-color: white;
    border: 1px solid #D7DCE1;
}

.tab-working-title {
    display: block;
}

.tab-working-img {
    display: block;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 100%;
    height: 35px;
}

.tabs__header{
  list-style: none;
  padding: 0;
  display: flex;
  border-bottom: 1px solid #D7DCE1;
}
.tabs__header li{
  text-align: center;
  padding: 1.6rem 54px 0.8rem 54px;
  color: #9397A1;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid transparent;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #3AAFE3;
    bottom: -2.25px;
    left: 0;
    opacity: 0;
    transition: 0.3s all ease-in-out;
  }
}
.tabs__header li.selected{
  color: #3AAFE3;
  &:after{
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .tabs{
    .tabs__header{
      overflow-x: scroll;
      overflow-y: hidden;
      li{
        white-space: nowrap;
      }
    }
  }
}
</style>
