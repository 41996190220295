<template>
  <Page>
  <Loading v-model:active="loading"
                :can-cancel="false"/>
  <div width="100%" style="display: flex; justify-content: center;">
    <!-- Alert for no clocked hours. Temporarily disabled -->
    <div v-if="noClockedHours" style="display: none; border-radius: 0.6rem; border: 1px solid #D0342C;
    background-color: #FFF; margin-top: 0.8rem;padding: 15px; 
    padding-left: 30px; padding-right: 30px; border-radius: 6px 6px 0px 0px; position: fixed; z-index: 1; bottom: 0px;">
        <!-- <div style="text-align: center; padding-top: 5px"><i style="font-size: 30px;" class="bx bxs-chevron-down arrow"></i></div> -->
        <span style="color: #9397A1"><strong style="color: #2E303F">Targets are not accurate</strong><br>The worker hasn't clocked. Worked minutes have been rounded up to an hour.</span>
    </div>
  </div>
    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Packers</div>
        <div class="subtitle">All information about the packers of Dartshopper</div>
      </div>
      
    </div>

    <TabsWrapper>
      <Tab title="Dashboard">

    <div class="employee-date-picker" v-if="pickers.length > 0" style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
        <div class="cards second">
          <div class="card card-1">
            <div id="employees-select">
              <v-select v-model="currentPackerId" @option:selected="changePacker"
              :options="pickers.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(function(el) {

                var o = Object.assign({}, el);
                
                if(o.pickerId === currentPackerId)
                {
                  currentPackerId = o.firstName + ' ' + o.lastName;
                }

                o.fullName = o.firstName + ' ' + o.lastName;
                return o;
                })" 
              :reduce="pickers => pickers.fullName" label="fullName" placeholder="Select a packer" dense />
            </div>
          </div>
          <div class="card card-2"  v-show="showGraph">
            <div class="datepicker">
              <!-- <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
                <p>Pick your date</p>
                <i class="bx bxs-chevron-down arrow"></i>
              </div> -->
              <!-- <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown">  -->
                <!-- <div class="datepicker-card">
                  <div class="title">
                    Pick your date
                  </div>
                  <div class="subtitle">
                    
                  </div>
                </div> -->
                <div class="datepicker-presets">
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Today'}" @click="changeDatePreset">Today</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Yesterday'}" @click="changeDatePreset">Yesterday</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '1 month'}" @click="changeDatePreset">1 month</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '3 months'}" @click="changeDatePreset">3 months</a>
                </div>
                <div :class="{ 'datepicker-customSelector': true, 'active': datePreset === ''}">
                  <Datepicker 
                    v-model="date" 
                    @update:modelValue="handleDate"
                    range 
                    weekNumbers 
                    :enableTimePicker="false" 
                    locale="nl" 
                    placeholder="Custom..." 
                    textInput 
                    cancelText="cancel" 
                    selectText="apply" 
                    :maxDate="new Date()"
                    :minDate="new Date(2022, 5, 13)"
                    position="right"
                    autoPosition
                  />
                </div>
              </div>
            <!-- </div> -->
          </div>
        </div> 
    </div>
    <div v-if="!loading" style="margin-bottom: 7em">
    <div v-if="dateIsSingleDay && showGraph">
      <div  class="cards fourth">
        <div class="card card-1">
          <div class="per-hour-graph-wrapper">
            <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between;">
            <div style=" height: 200;">
              <div class="card-title">Total packed orders</div>
              <div style="font-size: 28px;">
                <b v-if="!showTargetPerHour">
                  {{picklistsCountCompleted}}
                </b>
                <b v-else>
                  {{ currentSelectedHour.TotalPacked }}
                </b>
              </div>
              <div style="position: relative; top: -20px; right: 0px;">
              </div>
            </div>
            <div>
              <div style="text-align: right; font-size: 20px; font-weight: bold;"></div>
              <div style="font-size: 18px; color: #9397A1;">

                <div class="card-title" >Target percentage</div>
                <div style="font-size: 28px;">
                  <b v-if="showTargetPerHour">
                    <span :class="{ 'color-red': currentSelectedTarget < 90, 'color-orange': currentSelectedTarget < 100 && currentSelectedTarget >= 90, 'color-green': currentSelectedTarget >= 100}">{{ parseFloat(currentSelectedTarget).toFixed(0) + "%" ?? "0"   }}</span>
                  </b>
                  <b v-else>
                    <span :class="{ 'color-red': targetOfTheDay < 90, 'color-orange': targetOfTheDay < 100 && targetOfTheDay >= 90, 'color-green': targetOfTheDay >= 100}">{{ parseFloat(targetOfTheDay).toFixed(0) + "%" ?? "0"   }}</span>
                  </b>
                </div>
              </div>
            </div>
            </div>
          </div>
          <LineChart style="height: 250px; " v-bind="lineChartProps" ref="lineRef" />
          <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; padding: 5px">
             <div>
              <div style="
font-style: normal;
font-weight: 400;
font-size: 18px;
color: #D0D0D0;">
                <div v-if="showTargetPerHour">{{ chartStartTime[2] ?? ""}}</div>
                <div v-else>
                  <!-- {{ chartStartTime[0] }}&nbsp; -->
                  <span style="">00:00</span></div>
                </div>
              <!-- <div v-else style="text-align: right; ">-</div> -->
            </div>
            <div>
              <div style="text-align: right;
font-style: normal;
font-weight: 400;
font-size: 18px;
color: #D0D0D0;">{{ chartEndTime ?? "" }}</div>
              <!-- <div v-else style="text-align: right; ">-</div> -->
            </div>
            </div>
        </div>
      </div>
      <!-- {{ minutesWorkedPerHour }}
      {{ targetPerHour }}
      {{ currentSelectedHour }} -->
      <div class="cards third">
        <div class="card card-1 first-card">
          <div class="card-title">Big carts orders packed</div>
          <div class="card-data">
            {{ showTargetPerHour ? currentSelectedHour.Splitted.More?.Groot ?? "0" : !showSplittedGeneral ? "0" : SplittedGeneral.More?.Groot ?? "0"}}
            <img src="@/assets/img/big-cart.svg" alt="big-cart">
          </div>
        </div>
        <div class="card card-2">
          <div class="card-title">Small carts orders packed</div>
          <div class="card-data">
            {{ showTargetPerHour ? currentSelectedHour.Splitted.More?.Klein ?? "0" : !showSplittedGeneral ? "0" : SplittedGeneral.More?.Klein ?? "0"}}
            <img src="@/assets/img/small-cart.svg" alt="small-cart">
          </div>
        </div>
        <div class="card card-1">
          <div class="card-title">Singles big orders packed</div>
          <div class="card-data">
            {{ showTargetPerHour ? currentSelectedHour.Splitted.Singles?.Groot ?? "0" : !showSplittedGeneral ? "0" : SplittedGeneral.Singles?.Groot ?? "0" }}
            <img src="@/assets/img/singles-big.svg" alt="singles-big">
          </div>
        </div>
        <div class="card card-2 last-card">
          <div class="card-title">Singles small orders packed</div>
          <div class="card-data">
            {{ showTargetPerHour ? currentSelectedHour.Splitted.Singles?.Klein ?? "0" : !showSplittedGeneral ? "0" : SplittedGeneral.Singles?.Klein ?? "0"}}
            <img src="@/assets/img/singles-small.svg" alt="singles-small">
          </div>
        </div>
      </div>
  </div>
    <div v-else-if="!dateIsSingleDay && showGraph">
    <div class="table-container">
       <table class="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Target</th>
            <th>Total</th>
            <th>Splitted</th>
            <th>Hours</th>
            <th>Insights</th>
          </tr>
        </thead>
        <tbody>
          
            <tr v-for="(picklist, i) in picklistsCountPerDay" :key="i">
              <td scope="row">{{ new Date(picklist.Time).toLocaleDateString('en-NL', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })  }}</td>  
              <td > <span :class="{ 
                'color-red': picklist.TotalPacked !== 0 ?? parseFloat(picklist.targetPercentage) < 90, 
                'color-orange': parseFloat(picklist.targetPercentage) < 100 && parseFloat(picklist.targetPercentage) >= 90, 
                'color-green': parseFloat(picklist.targetPercentage) >= 100}">{{ parseFloat(picklist.targetPercentage) ? parseFloat(picklist.targetPercentage).toFixed(0) + "%" : "-" }}</span></td>
              <td> {{ picklist.TotalPacked }}</td>
              <td>
                <div class="td-wrapper">
                  <div class="table-row-splitted">
                    <div class="table-row-split"><img src="@/assets/img/big-cart.svg" alt="big-cart">{{ picklist.Splitted?.More?.Groot ?? "0"}}</div>
                    <div class="table-row-split"><img src="@/assets/img/small-cart.svg" alt="small-cart">{{ picklist.Splitted?.More?.Klein ?? "0"}}</div>
                    <div class="table-row-split"><img src="@/assets/img/singles-big.svg" alt="singles-big">{{ picklist.Splitted?.Singles?.Groot ?? "0"}}</div>
                    <div class="table-row-split"><img src="@/assets/img/singles-small.svg" alt="singles-small">{{ picklist.Splitted?.Singles?.Klein ?? "0"}}</div>
                  </div>
                </div>
              </td>
              <td>
                <div>{{ parseInt(picklist.minutesWorked / 60)}}h</div>
              </td>
              <td>
                <a :class="{'greyed-out': picklist.TotalPacked === 0}" @click="showInfoOnDay(picklist.Time)">Show info
                <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6 5.26301C6 5.45165 5.92797 5.64027 5.78422 5.7841L1.25832 10.31C0.970413 10.5979 0.503627 10.5979 0.21584 10.31C-0.0719468 10.0222 -0.0719468 9.55547 0.21584 9.26754L4.22061 5.26301L0.21598 1.25845C-0.0718073 0.970549 -0.0718073 0.503902 0.21598 0.216139C0.503767 -0.0719042 0.970553 -0.0719042 1.25846 0.216139L5.78436 4.74192C5.92814 4.88582 6 5.07444 6 5.26301Z" fill="#3AAFE3"/>
  </svg>
  </a>
  </td> 
          </tr>
        </tbody>
        </table>
        <div style="text-align: right; padding: 30px;" v-if="picklistsCountPerDay.length > 0 || page !== 1">
          {{ picklistsCountPerDayAmount }} items • {{ page + 1 }} from {{ Math.ceil(picklistsCountPerDayAmount / amountPagination) }}
          <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(false, null)" :disabled="page === 0">Prev</button>
          <div v-for="index in Math.ceil(picklistsCountPerDayAmount / amountPagination)" :key="index" style="display:inline-block; ">
            <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(false, index - 1)">{{index}}</button>
          </div>
          <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(true, null)" :disabled="page + 1 === Math.ceil(picklistsCountPerDayAmount / amountPagination)">Next</button>
          <!-- <select style="padding-top: 5px;margin-left: 5px; padding: 4px; padding-left: 8px; padding-right: 8px;" @change="changeAmountPagination">
            <option v-if="picklistsCountPerDayAmount >= 5">5</option>
            <option selected>10</option>
            <option v-if="picklistsCountPerDayAmount >= 15">15</option>
            <option v-if="picklistsCountPerDayAmount >= 30">30</option>
          </select> -->
        </div>
        </div>
    </div>
    </div>
      </Tab>
      <Tab title="Active worker overview">
        <WorkersOverview />
      </Tab>
    </TabsWrapper>
  </Page>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import Datepicker from '@vuepic/vue-datepicker';

import Page from '@/components/Page.vue'
import TabsWrapper from '@/components/TabsWrapper.vue' 
import Tab from '@/components/Tab.vue'
import getUser from '../composeables/getUser'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import '@vuepic/vue-datepicker/dist/main.css';

import { LineChart, useLineChart } from 'vue-chart-3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Page, Datepicker, TabsWrapper, Tab, LineChart, Datepicker, Loading},
  name: "packers",
  data(){
    return{
      gradient: "",
    }
  },
  setup() {
    const user = ref({});
    const router = useRouter()
    const route = useRoute();

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
    
    Chart.register(...registerables);
    Chart.register(annotationPlugin);

    const showGraph = ref(false);
    const noClockedHours = ref(false);
    const targetOfTheDay = ref(0);
    const showSplittedGeneral = ref(true);
    const workingHours = ref([]);
    const chartStartTime = ref(["01/01/1970", "January 1st, 1970", ""]);
    const chartEndTime = ref("23:59");
    const loadingChart = ref(true);
    const showTargetPerHour = ref(false);
    const targetPerHour = ref([]);
    const packagePerHour = ref([]);
    const currentSelectedTarget = ref(0);
    const currentSelectedHour = ref({Time: "N.A.", EndTime: "N.A.", Splitted: { Singles: {Klein: "0", Groot: "0"}, More: {Klein: "0", Groot: "0"}}});
    const lineRef = ref(null);
    const date = ref([]);
    const loading = ref(true);
    const dateIsSingleDay = ref(true);
    const error = ref(null);
    const picklistsCount = ref(null);
    const picklistsCountPerDay = ref([]);
    const picklistsCountCompleted = ref(0);
    const datePreset = ref(null);
    const datePresetRow = ref(null);
    const Splitted = ref({Singles: {Klein: "0", Groot: "0"}, More: {Klein: "0", Groot: "0"}}); 
    const SplittedGeneral = ref({Singles: {Klein: "0", Groot: "0"}, More: {Klein: "0", Groot: "0"}}); 
    const RowSplitted = ref({Singles: [{Klein: "0", Groot: "0"}], More: [{Klein: "0", Groot: "0"}]}); 
    const RowSplittedList = ref([]);
    const bgColor = ref('rgba(58, 175, 227, 0.3)');
    const borderColor = ref('rgba(58, 175, 227, 1)');
    const pickerOrPacker = ref("&packer=false&picker=true");
    const pickerOrPackerRow = ref("&packer=false&picker=true");
    const page = ref(0);
    const picklistsCountPerDayAmount = ref(0);
    const amountPagination = ref(10);
    const minutesWorkedPerHour = ref(Array(24).fill(0));

    const setDateInCookie = (date) => {
      cookies.set("date", JSON.stringify(date));
    }

    // For demo purposes assign range from the current date
    onMounted( async () => {
      user.value = cookies.get('token');
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];
      
      if("startDate" in route.query)
      {
        startDate = route.query.startDate;
      }

      if("endDate" in route.query)
      {
        endDate = route.query.endDate;
      }

      if("datePreset" in route.query)
      {
        date = setDatePreset(route.query.datePreset);
      }

      if("datePresetRow" in route.query)
      {
        cookies.set('datePresetRow', route.query.datePresetRow);
      }

      if(cookies.get("datePreset") === null || cookies.get("datePreset") === undefined)
      {
        cookies.set("datePreset", "Today");
      }

      if(cookies.get("datePresetRow") === null || cookies.get("datePresetRow") === undefined)
      {
        cookies.set("datePresetRow", "Today");
      }

      date.value = date;
      datePreset.value = cookies.get("datePreset");
      setDateInCookie(date.value);
      
      if(!dateIsSingleDay.value)
      {
        if(cookies.get("page") === null || cookies.get("page") === undefined)
        {
          page.value = 0;
        }
      }

      if(cookies.get("amountPagination") === null || cookies.get("amountPagination") === undefined)
      {
        amountPagination.value = 10;
      }

      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        currentPackerId: route.query.currentPackerId
      }

      dateIsSingleDay.value = checkIfDateIsGreaterThenSingleDay(date.value);

      if("currentPackerId" in route.query)
      {
        cookies.set('currentPackerId', route.query.currentPackerId);
        await updateQueryParameters(query);
        showGraph.value = true;
      }
      else
      {
        // Bug fix for switching between pickers and packers
        cookies.set("datePreset", "Today");
        datePreset.value = cookies.get("datePreset");
        
        if(parseInt(cookies.get("currentPackerId")))
        {
          query.currentPackerId = cookies.get('currentPackerId');
          query.datePreset = cookies.get('datePreset');

          await updateQueryParameters(query);
          showGraph.value = true;
        }
        else
        {
          showGraph.value = false;
        }
      }

      loading.value = false;
    })

    const checkIfDateIsGreaterThenSingleDay = (date) => {

      const startDate = date[0] !== null ? moment(date[0]) : new Date();
      const endDate = moment(date[1] ?? date[0]);
      const differenceInDays = Math.abs(moment.duration(startDate.diff(endDate)).asDays());

      picklistsCountPerDayAmount.value = Math.floor(differenceInDays);

      return differenceInDays <= 1 ? true : false
    }

    const changeAmountPagination = async (event) => {
      amountPagination.value = parseInt(event.target.value);

      await changePagination(true, page.value);
    }

    const calculatePackerTarget = (splittedArray, minutes) => {
      // Baseline time for Packers made by Danny
      // These could (probably will) be interchanged for times that could be changed
      // in the dashboard progressively when tempo speeds up
      let amount = 0;

      let SinglesSmallPerMinute = 60 / 110;
      let SinglesBigPerMinute = 60 / 68;
      let MoreSmallPerMinute = 60 / 57;
      let MoreBigPerMinute = 60 / 23;

      if(splittedArray.Singles.Klein !== undefined)
      {
        amount += (splittedArray.Singles.Klein * SinglesSmallPerMinute);
      }

      if(splittedArray.Singles.Groot !== undefined)
      {
        amount += (splittedArray.Singles.Groot * SinglesBigPerMinute)
      }

      if(splittedArray.More.Klein !== undefined)
      {
        amount += (splittedArray.More.Klein * MoreSmallPerMinute);
      }

      if(splittedArray.More.Groot !== undefined)
      {
        amount += (splittedArray.More.Groot * MoreBigPerMinute);
      }

      return ((amount - minutes) / minutes) * 100 + 100;
    }

    const setDatePreset = (text) => {
      let startDate = new Date();
      let endDate = new Date(new Date().setDate(startDate.getDate() + 0));

      if(text === "Today")
      {
        endDate = moment(startDate).endOf('day');
      }
      if(text === "Yesterday")
      {
        startDate = new Date(new Date().setDate(startDate.getDate() - 1));
        startDate = moment(startDate).startOf('day');
        endDate = moment(startDate).endOf('day');
      }
      else if(text === "1 month")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 28))).startOf('day');
      }
      else if(text === "3 months")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 90))).startOf('day');
      }
      
      cookies.set("datePreset", text)
      datePreset.value = cookies.get("datePreset");

      return [startDate, endDate];
    }

    const changeDatePreset = async () => {
      let date = setDatePreset(event.target.innerText);
      dateIsSingleDay.value = checkIfDateIsGreaterThenSingleDay(date);
      setDateInCookie(date);

      if(!dateIsSingleDay.value)
      {
        cookies.set("page", 1);
        page.value = 1;
      }

      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        currentPackerId: cookies.get("currentPackerId")
      }

      await updateQueryParameters(query);

      hideDatepickerDropdown();
    }

    const changePacker = async (event) => {
      cookies.set("currentPackerId", event.pickerId);
      const array = JSON.parse(cookies.get("date"));

      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(array[0]).startOf('day').format('YYYY-MM-DD').toString(),
        endDate: moment(array[1]).endOf('day').format('YYYY-MM-DD').toString(),
        currentPackerId: event.pickerId
      }

      await updateQueryParameters(query);
      targetPerHour.value = [];
      showGraph.value = true;
    }

    // Updates query parameters, make sure this happens after all cookies have been altered.
    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;
      if(dateIsSingleDay.value)
      {
        await picklistsTotalCompletedToday();
        await UpdateHours();
        await fetchDataAfter();

        setTimeout(() => {
          loading.value = false;
        }, 750);
      }
      else
      {
        await fetchDataAfter();
        setTimeout(() => {
          loading.value = false;
        }, 1500);
      }


    }

    const UpdateHours = async () =>
    {
      let worked = Array(24).fill(0);
      workingHours.value = await checkForWorkingHours(route.query.currentPackerId);

      workingHours.value.forEach(entry => {
        const hours = moment(entry.date).hours();
        let secondsRoundedUpToTheHour = Math.abs(moment.duration(moment(entry.date).diff(moment(entry.date).add(1, 'hour').startOf('hour'))).asSeconds()); 
        if(entry.current_work === "Packing" && !entry.breakStart && entry.type !== 3)
        {
          // Check if seconds are higher then the end of the hour
          if(entry.difference_in_seconds > secondsRoundedUpToTheHour)
          {
            const totalSecondsDivided = entry.difference_in_seconds / 3600;
            
            // Add remaining seconds to the following hours
            for(let i = 0; i < totalSecondsDivided; i++)
            {
              let seconds = 0;
              const remainingSecondsInHour = totalSecondsDivided - i;
              
              if(i === 0)
              {
                seconds = secondsRoundedUpToTheHour;
              }
              else if(remainingSecondsInHour < 1)
              {
                seconds = 3600 - secondsRoundedUpToTheHour;
              }
              else
              {
                seconds = 3600;
              }

              worked[hours + i] += Math.abs(seconds);
            }
          }
          else // Seconds fit in the hour itself
          {
              worked[hours] += Math.abs(entry.difference_in_seconds);
          }
        }
        else if(entry.breakStart) // 2. If 'on a break' make sure to remove it from worked seconds
        {
          if(entry.difference_in_seconds > secondsRoundedUpToTheHour)
          {
            const totalSecondsDivided = entry.difference_in_seconds / 3600;
            
            // Remove remaining seconds to the following hours
            for(let i = 0; i < totalSecondsDivided; i++)
            {
              const seconds = totalSecondsDivided * 60 * 60;
              worked[hours + i] -= Math.abs(seconds);
            }
          }
          else
          {
            worked[hours] -= Math.abs(entry.difference_in_seconds);
          }
        }
        // worked[]
      });

      minutesWorkedPerHour.value = worked;
      
      if(JSON.parse(JSON.stringify(workingHours.value)).length === 0)
      {
        noClockedHours.value = true;
      }
    }

    async function picklistsTotalCompletedToday() {
      //this.loading = true
      try {   
        const { user } = getUser();
        const array = JSON.parse(cookies.get("date"));
        await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/completed?userid=" + cookies.get("currentPackerId") + "&startdate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + pickerOrPacker.value, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
        .then(res => res.json())
        .then(result => {
          picklistsCountCompleted.value = result;
          picklistsCountCompleted.update();
        })
        //this.loading = false
      } catch (error) {
        //this.errorMessage = error
        //this.showError = true
        //this.loading = false
      }
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }

    }

    const changePagination = async (minusPlus, index) => {
      loading.value = true;
      showGraph.value = false;
      // Can be globally adjused to let users choose how many results they want
      let limit = 10;


      if(index !== null)
      {
        page.value = index;
      }
      else
      {
        page.value = minusPlus ? page.value + 1 : page.value - 1;
      }

      if(page.value >= 0)
      {
        cookies.set("page", page.value);
        
        const string = "&skip=" + parseInt(page.value + 1);
      
        fetchperDayGraph(string)
          .then(res => res.json())
          .then(result => {
            picklistsCountPerDay.value = result;

            result.forEach((item, index) => {

              let minutes = calculateHours(item.Splitted);
              let targetPercentage = calculatePackerTarget(item.Splitted, minutes);
              result[index].minutesWorked = minutes;
              result[index].targetPercentage = targetPercentage;
            })

            picklistsCountPerDay.value.update();
          })
          .catch(err => {
            error.value = err;
            if (err.json) {
              return err.json.then(json => {
                error.value.message = json.message;
              })
            }
          })
          
        setTimeout(() => {
          showGraph.value = true;
          loading.value = false;
        }, 1500);
      }

    }

    const calculateHours = (item) => {
        let startArray = [];
        let endArray = [];

        // Working hours needs to be implemented here

        // Calculate earliest startingtime
        startArray.push(item["Singles"].startTime);
        startArray.push(item["More"].startTime);

        // Calculate earliest ending time
        endArray.push(item["Singles"].endTime);
        endArray.push(item["More"].endTime);

        let startime = moment(startArray.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())[0]);
        let endtime = moment(endArray.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0]);

        let difference = moment.duration(startime.diff(endtime)).asMinutes();
        return Math.abs(difference);
    }

    const handleDate = (modelData) => {
      date.value = modelData;
      if(date.value[1] === null || date.value[1] === undefined)
      {
        date.value[1] = date.value[0]; 
      }
      
      setDateInCookie(date.value);
      datePreset.value = "";

      dateIsSingleDay.value = checkIfDateIsGreaterThenSingleDay(date.value);

      const query = {
        datePreset: "",
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        currentPackerId: cookies.get("currentPackerId")
      }
      updateQueryParameters(query);
      
      hideDatepickerDropdown();
    }

    const showInfoOnDay = async (date) => {
      handleDate([moment(date).format('YYYY-MM-DD').toString(), moment(date).format('YYYY-MM-DD').toString()]);

      const query = {
        datePreset: "",
        startDate: moment(date).startOf('day').format('YYYY-MM-DD').toString(),
        endDate: moment(date).endOf('day').format('YYYY-MM-DD').toString(),
        currentPackerId: cookies.get("currentPackerId")
      }

      await updateQueryParameters(query);
    }

    async function fetchSplittedPicklistCount()
    {
      const { user } = getUser();
      const array = JSON.parse(cookies.get("date"));
      
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/completed/splitted?userid=" + cookies.get("currentPackerId") 
      + "&startdate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString()+ "&enddate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + pickerOrPacker.value, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
    }

    async function fetchperDayGraph(limitskipString)
    {
      const { user } = getUser();
      const array = JSON.parse(cookies.get("date"));
      
      if(limitskipString === "")
      {
        limitskipString = "&skip=1";
      }

      return await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/completed/splitted/row?userid=" + cookies.get("currentPackerId") 
      + "&startdate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString()+ "&enddate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + pickerOrPackerRow.value + limitskipString, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
    }

    const targetCalculation = (result) => {
        const resultSplitted = result.map(x => x.Splitted);
        let SinglesKlein = 0;
        let SinglesGroot = 0;
        let MoreKlein = 0;
        let MoreGroot = 0;

        let targets = Array(24).fill(0);
        resultSplitted.forEach((entry, index) => {
          // Has to be packing
          // Make sure to count the difference in seconds extra if the shift hasn't ended
          
          // Three alerts
          // 1. No hours
          // 2. Some hours
          // 3. All hours

          if(Object.keys(entry.Singles).length > 0 || Object.keys(entry.More).length > 0)
          {
            const minutes = Math.floor(minutesWorkedPerHour.value[index] === 0 ? 60 : minutesWorkedPerHour.value[index] / 60);
            const calculateTarget = calculatePackerTarget(resultSplitted[index], minutes);

            SinglesKlein += entry.Singles.Klein ?? 0;
            SinglesGroot += entry.Singles.Groot ?? 0;
            MoreKlein += entry.More.Klein ?? 0;
            MoreGroot += entry.More.Groot ?? 0;
            targets[index] = calculateTarget;
          }
        });

        SplittedGeneral.value = {Singles: {Klein: SinglesKlein, Groot: SinglesGroot}, More: {Klein: MoreKlein, Groot: MoreGroot}};
        targetPerHour.value = targets;
    }

    const calculateTargetOfTheDay = () => {
      let workedMinutes = JSON.parse(JSON.stringify(minutesWorkedPerHour.value)).filter(x => x !== 0 );
      const sum = workedMinutes.reduce((a, b) => a + b, 0) / 60;
      let targets = calculatePackerTarget(JSON.parse(JSON.stringify(SplittedGeneral.value)), sum);

      targetOfTheDay.value = parseFloat(targets);
    }

    async function fetchDataAfter() {

      if(dateIsSingleDay.value)
      {
        fetchSplittedPicklistCount()
        .then(res => res.json())
        .then(result => {
          Splitted.value = result;
          targetCalculation(result);

          calculateTargetOfTheDay();
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            })
          }
        })
      }
      else if(!dateIsSingleDay.value)
      {
       page.value = 0;
       fetchperDayGraph("")
        .then(res => res.json())
        .then(result => {
          picklistsCountPerDay.value = result;

          result.forEach((item, index) => {
            let minutes = calculateHours(item.Splitted);
            let targetPercentage = item.TotalPacked !== 0 ? calculatePackerTarget(item.Splitted, minutes) : 0;

            result[index].minutesWorked = minutes;
            result[index].targetPercentage = targetPercentage;
          })
          
          picklistsCountPerDay.value.update();
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            })
          }
        })
      }
    }

    function setGradient() {
      const canvas = lineRef.value.canvasRef;
      bgColor.value = canvas.getContext('2d').createLinearGradient(0, 300, 0, 0);
      bgColor.value.addColorStop(0.0, 'rgba(58, 175, 227, 0.5)');
      bgColor.value.addColorStop(0.3, 'rgba(58, 175, 227, 0.6)');
      bgColor.value.addColorStop(1, 'rgba(46, 48, 63, 0.6)');

      borderColor.value = canvas.getContext('2d').createLinearGradient(450, 0, 0, 0);
      borderColor.value.addColorStop(0, 'rgba(46, 48, 63, 1)');
      borderColor.value.addColorStop(1, 'rgba(46, 48, 63, 1)');
    }

    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#2e303f';
        tooltipEl.style.boxShadow = "0 0 16px rgba(0, 0, 0, 0.15)";
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.color = '#FFF';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .3s ease';
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.display = 'none'; //tooltipEl.style.display = 'flex';
        tooltipEl.style.justifyContent = 'center';

        const table = document.createElement('table');
        table.classList.add("table-linechart");
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');
        tableHead.style.fontSize = '12px';

        for (let index = 0; index < titleLines.length; index++) {
          const title = titleLines[index];

          var dt = new Date();
          dt.setHours( parseInt(title.split(':')[0]) + 1 );

          const tr = document.createElement('tr');
          tr.style.borderWidth = 0;

          const th = document.createElement('th');
          th.style.borderWidth = 0;
          
          const text = document.createTextNode(title  + " - "  + dt.getHours() + ":00" );

          

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        }

        const tableBody = document.createElement('tbody');
        tableBody.style.fontSize = '12px';

        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement('span');
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = '2px';
          span.style.marginRight = '10px';
          span.style.height = '10px';
          span.style.width = '10px';
          span.style.display = 'inline-block';

          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;

          const td = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    const options = ref({
      source: date,
      animation: {
        // onComplete: function(context) {
        //   if (context.initial) {
        //     setGradient();
        //   } else {

        //   }
        // }
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          ticks: {
            autoSkip: false,
            beginAtZero: true,
            stepSize: 1,
            display: false
          },
          grid: {
            color: 'red',
            display: false,
          },
          gridLines: {
            color: 'rgba(51,51,51,1)', 
            display: true,
            drawBorder: false,
          },
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            },
            display: false
          },
        },
        y: {
          ticks: {
            beginAtZero: true
          },
          grid: {
            color: 'rgba(51,51,51,0.1)',
            display: false,
            drawBorder: false,
          },
          beginAtZero: true,
          ticks: {
            color: '#333',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            },
            display: false
          }
        }
      },
      plugins: [annotationPlugin],
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        },
         annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 100,
              yMax: 100,
              borderColor: 'rgba(0, 0, 0, 0.1)',
              borderDash: [10, 15],
              borderWidth: 2,
              z: -1
            }
          }
        }
      },
      maintainAspectRatio: false
    })

    const testData = computed(() => ({
      labels: ["0:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00",
    "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"],
      showTooltips: false,
      datasets: [
        {
          data: targetPerHour.value,
          borderColor: borderColor.value,
          backgroundColor: bgColor.value,
          // borderColor: 'rgb(10, 187, 137)',
          // backgroundColor: 'rgba(10, 187, 137, 0.3)',
          pointBorderColor: 'rgba(0, 0, 0, 0)',
          pointBackgroundColor: 'rgba(0, 0, 0, 0)',
          pointHoverBackgroundColor: 'rgba(58, 175, 227, 1)',
          pointHoverBorderColor: 'rgba(58, 175, 227, 1)',
          fill: true,
          lineTension: 0.1,
          pointRadius: 2,
          borderWidth: 2
        },
      ],
    }));

  const perHourTarget = async (index) => {
    let starttime = moment(route.query.startDate).startOf('day').add(index, 'hours').format('YYYY-MM-DD HH:mm:ss').toString();
    const target_copy = JSON.parse(JSON.stringify(Splitted.value))
    currentSelectedHour.value = target_copy.find(element => element.Time === starttime);
    currentSelectedTarget.value = targetPerHour.value[index];
  }

  const checkForWorkingHours = async (id) => {
    try {
      const array = JSON.parse(cookies.get("date"));
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/hours?pickerId=" + id + "&startdate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { 
      // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
      });
      
      const result = await res.json();

      return result;
    } catch (error) {

      return error;
    } 
  }

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: testData,
      options,
      plugins: [annotationPlugin],
      plugins: [{ afterDraw: chart => {
            showTargetPerHour.value = false;

            if (chart.tooltip?._active?.length) {

                // Add line border
                let x = chart.tooltip._active[0].element.x;
                let yAxis = chart.scales.y;
                let ctx = chart.ctx;
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(x, yAxis.top);
                ctx.lineTo(x, yAxis.bottom);
                ctx.lineWidth = 2;
                ctx.setLineDash([10, 15]);
                ctx.strokeStyle = '#D0D0D0';
                ctx.stroke();
                ctx.restore();
                
                chartStartTime.value[2] = moment(route.query.startDate).startOf('day').add(parseInt(chart.tooltip?.title), 'hours').format('HH:mm').toString();
                chartEndTime.value = moment(route.query.startDate).startOf('day').add(parseInt(chart.tooltip?.title) + 1, 'hours').format('HH:mm').toString();
              
                // if(chart.tooltip?.dataPoints[0]?.raw !== 0)
                // {
                  // console.log(chart.tooltip?.title);
                  // Search for specific hour and make sure to change packed orders count & target to specific
                  perHourTarget(parseInt(chart.tooltip?.title));
                  showTargetPerHour.value = true;
                // }

                showSplittedGeneral.value = false;
            }
            else
            {
              chartStartTime.value = [moment(route.query.startDate).startOf('day').format('L').toString(), moment(route.query.startDate).startOf('day').format('LL').toString(), moment(route.query.startDate).startOf('day').format('HH:mm').toString()];
              chartEndTime.value = moment(route.query.startDate).endOf('day').format('HH:mm').toString();
              showSplittedGeneral.value = true;
              showTargetPerHour.value = false;
            }
        }}]
    }, () => {
        setGradient();});

    return { bgColor, noClockedHours, showGraph, SplittedGeneral, pickerOrPackerRow, showSplittedGeneral, targetOfTheDay, workingHours, minutesWorkedPerHour, packagePerHour, chartStartTime, chartEndTime, loadingChart, showTargetPerHour, showInfoOnDay, perHourTarget, 
    changeAmountPagination, amountPagination, targetPerHour, picklistsCountPerDayAmount, changePagination, page, pickerOrPacker, datePresetRow, workingHours, RowSplitted, RowSplittedList, Splitted, picklistsCountPerDay, dateIsSingleDay, datePreset, testData, options, loading, error, picklistsCount, picklistsCountCompleted, 
    lineChartProps, lineChartRef, lineRef, date, changeDatePreset, handleDate, picklistsTotalCompletedToday, changePacker, hideDatepickerDropdown, currentSelectedHour, currentSelectedTarget};
  },
  data() {
    return{
      currentPackerId: "",
      picklistsCount: 0,
      targetPerHour: 0,
      pickers: [],
      errorMessage: "",
      showError: false
    }
  },
  methods: {
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    async users(){
      try {
        const { user } = getUser();
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
        this.pickers = await res.json()
      } catch (error) {
        this.errorMessage = error
        this.showError = true
      }
    }
    // hideDatepickerDropdown(){
    //   var arrow = document.querySelectorAll(".datepicker .arrow");
    //   var dropdown = document.querySelectorAll(".datepicker .dropdown");
    //   var button = document.querySelectorAll(".datepicker .button");

    //   if (arrow.length > 0) {
    //     arrow[0].classList.remove("open");
    //   }
    //   if (dropdown.length > 0) {
    //     dropdown[0].classList.remove("open");
    //   }
    //   if (button.length > 0) {
    //     button[0].classList.remove("active");
    //   }

    //   picklistsTotalCompletedToday();
    //   fetchDataAfter();
    // },
    // datepickerDropdown(){
    //   var arrow = document.querySelectorAll(".datepicker .arrow");
    //   var dropdown = document.querySelectorAll(".datepicker .dropdown");
    //   var button = document.querySelectorAll(".datepicker .button");

    //   if (arrow.length > 0) {
    //     arrow[0].classList.toggle("open");
    //   }
    //   if (dropdown.length > 0) {
    //     dropdown[0].classList.toggle("open");
    //   }
    //   if (button.length > 0) {
    //     button[0].classList.toggle("active");
    //   }
    // },
  },
  async created() {
    if(cookies.get("currentPackerId") !== null && cookies.get('currentPackerId') !== undefined)
    {
      this.currentPackerId = cookies.get("currentPackerId");
    }
    else
    {
      this.currentPackerId = String.empty;
    }
    this.users()
  }
}
</script>
<style lang="scss" scoped>
.employee-date-picker {
  .cards .card-2 {
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    .datepicker-customSelector {
      width: 100%;
      margin: 0 auto;
      
    }
    .datepicker-customSelector.active {
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    }
    .datepicker-presets {
      display: flex;
      justify-content: between;
      .date-buttons {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
      }
      .date-buttons:hover {
        background-color: rgba(245, 246, 248, 0.55)
      }
      .date-buttons.active {
        background-color: #F5F6F8;
      }
    }
  }
}

.cards.first{
  .card{
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}

.cards.second{
  .card{
    width: calc(50% - 1.6rem);
    margin-right: 3.2rem;
    &:last-child{
      margin-right: 0;
    }
  }
}

.cards.third{
  .card-1 {
    border-radius: 0px;
  }
  .card-2 {
    border-radius: 0px;
  }
  .first-card {
    border-radius: 0px 0px 0px 6px;
  }
  .last-card {
    border-radius: 0px 0px 6px 0px;
  }
  .card{

    margin-bottom: 0;
    margin-right: 0.0rem;
    width: calc(25%);
    height: 13.7rem;
    &:nth-child(1){
      border-right: 0px;
    }
    &:nth-child(2){
      border-right: 0px;
    }
    &:nth-child(3){
      border-right: 0px;
    }
    &:nth-child(4){

    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.cards.fourth{
  .card{
    border-radius: 6px 6px 0px 0px;
    border-bottom: 0px;
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 15px;
    .per-hour-graph-wrapper {
      position: relative;
      margin: auto;
      height: auto;
      width: 100%;
      .card-title{
        margin-top: 0px;
        margin-bottom: 5px;
      }
      margin-bottom: 20px;
    }
  }
}

.table-container{
  overflow-y: hidden;
  background-color: white;
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid #D7DCE1;
  .table{
    width: 100%;
    border-spacing: 0;
  }
  .table-striped{
    thead{
      width: 100%;
      tr{
        width: 100%;
        th{
          border-bottom: 1px solid #D7DCE1;
          width: 50%;
          text-align: left;
          padding: 2.4rem 3.0rem;
          color: #9397A1;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    tbody{
      width: 100%;
      tr{
        width: 100%;
        td, th{
          border-bottom: 1px solid #D7DCE1;
          width: 50%;
          text-align: left;
          padding: 2.4rem 3.0rem;
          font-size: 14px;
          font-weight: 700;
          .td-wrapper{
            display: flex;
            align-items: center;
            i {
              cursor: pointer;
              font-size: 20px;
              transition: all 0.2s;
            }
            .active {
              transform: rotate(-90deg);
              transition: all 0.2s;
            }
            .table-row-splitted{
              display: flex;
              flex-direction: row;
              margin-left: 20px;
              transition: all 0.2s;
              min-width: 300px;
              .table-row-split{
                border: 1px solid #D7DCE1;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                border-radius: 5px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
                img{
                  height: 14px;
                  margin-right: 8px;
                }
              }
            }
          }
          a{
            border: 2px solid #3AAFE3;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #3AAFE3;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                transition: all 0.3s ease;
              }
            }
            &:hover{
              background-color: #3AAFE3;
              color: white;
              svg path{
                fill: white;
              }
            }
          }
          a.greyed-out {
            border: 2px solid #9397A1;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #9397A1;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            pointer-events: none;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                fill: #9397A1;
                transition: all 0.3s ease;
              }
            }
          }
        }
        &:last-child td, &:last-child th {
          border-bottom: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) {

  .cards.second{
    .card{
      width: 100%;
      margin-right: 0.0rem;
    }
  }

  .employee-date-picker {
    .cards .card-2 {
      width: 100%;
      .datepicker-presets {
        .date-buttons { 
          font-size: 14px;
          padding-left: 9px;
          padding-right: 9px;
        }
      }
      .datepicker-customSelector {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .cards.third{
    .card-1 {
      border-radius: 0px 0px 0px 6px;
    }
    .card-2 {
      border-radius: 0px 0px 0px 0px;
    }
    .first-card {
      border-radius: 0px 0px 0px 0px;
    }
    .last-card {
      border-radius: 0px 0px 6px 0px;
    }
    .card{
      &:nth-child(1){
        border-bottom: 0px;
      }
      &:nth-child(2){
        border-right: 1px solid #D7DCE1;
        border-bottom: 0px;
      }
      margin-bottom: 0;
      margin-right: 0.0rem;
      width: calc(50%);
      height: 13.7rem;
    }
  }

  .cards.fourth{
    .card{
    .per-hour-graph-wrapper {
      .card-title{
          margin-top: 15px;
        }
      }
    }
  }
  .page-header{
    flex-direction: column;
    margin-bottom: 1.6rem;
    .title-wrapper{
      margin-bottom: 1.6rem;
      .title{
        font-size: 2.4rem;
      }
    }
  }
  .cards{
    .card{
      padding: 2.4rem;
    }
  }

  .datepicker{
    .dropdown{
      transform-origin: left top;
      left: 0;
    }
  }
}

/* Tommie Styling */
.color-green {
  color: green;
}

.color-orange {
  color: orange;
}

.color-red {
  color: red;
}

</style>
