<template>
  <td class="worker">
    <div ref="openRef" class="worker-wrapper">
      <div v-if="!order.printingInformation.pickerId" class="add-worker" @click="showWorkerDropdown();">
        <span>+</span>
      </div>
      <div v-else class="specific-worker" @click="showWorkerDropdown();"
        v-for="bedrukker in bedrukkers.filter(x => parseInt(x.pickerId) === parseInt(order.printingInformation.pickerId)) ">
        <span :class="{'cas': bedrukker.firstName === 'Cassandra', 'ed': bedrukker.firstName === 'Ed', 'vin': bedrukker.firstName === 'Vinny', }">{{ bedrukker.firstName.charAt(0) + bedrukker.lastName.charAt(0) }}</span>
      </div>
      <div ref="closeRef" class="select-worker" >
        <template v-for="bedrukker in bedrukkers.filter(x => x.role === 4)" :key="bedrukker.pickerId">
          <div class="single-worker" @click="handleWorker(order?.orderInformation?.data.reference, bedrukker, i);">
            <span :class="{'cas': bedrukker.firstName === 'Cassandra', 'ed': bedrukker.firstName === 'Ed', 'vin': bedrukker.firstName === 'Vinny', }">{{ bedrukker.firstName.charAt(0) + bedrukker.lastName.charAt(0) }}</span>
            {{ bedrukker.firstName + " " + bedrukker.lastName}}
          </div>
        </template>
      </div>
    </div>
    <div ref="overlay" class="overlay" @click="closeWithOverlay();"></div>
  </td>
</template>

<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import getUser from '../../composeables/getUser'

const props = defineProps({
  order: Object,
  bedrukkers: Object
})

const openRef = ref(null)
const closeRef = ref(null)
const overlay = ref(null)

function close() {
  closeRef.value.classList.remove("open");
  overlay.value.classList.remove("display");
}

function showWorkerDropdown() {
  openRef.value.querySelector(".select-worker").classList.add("open");
  overlay.value.classList.add("display");
}

function closeOption() {
  closeRef.value.classList.remove("open");
  overlay.value.classList.remove("display");
}

function closeWithOverlay() {
  overlay.value.classList.remove("display");
}

onClickOutside(closeRef, close);

const handleWorker = (orderId, bedrukker, i) => {
  updateOrder(orderId, bedrukker.pickerId, 'worker');
  closeOption();
  props.order.printingInformation.pickerId = bedrukker.pickerId;
}

const updateOrder = async (orderId, data, type) => {
  let postBody = {};
  postBody.orderId = orderId;
  
  if(type === 'worker')
  {
    postBody.pickerId = data;
  }
  else if(type === 'status')
  {
    postBody.status = data;
  }

  const { user } = getUser();
  return await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/create", { 
    method: 'POST', 
    headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""},
    body: JSON.stringify(postBody) 
  })
}

</script>

<style lang="scss" scoped>
.overlay {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  display: none;
  top: 0;
  left: 0;
}
.overlay.display{
  display: block;
}
.worker-wrapper{
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .add-worker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1.0rem 3.84rem;
    cursor: pointer;
    span {
      min-height: 32px;
      max-height: 32px;
      min-width: 32px;
      max-width: 32px;
      background: #D7DCE1;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease-in-out;
      letter-spacing: 1px;
    }
  }
  .specific-worker {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 1.0rem 3.84rem;
    cursor: pointer;
    span {
      min-height: 32px;
      max-height: 32px;
      min-width: 32px;
      max-width: 32px;
      background: #0ABB87;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease-in-out;
      letter-spacing: 1px;
    }
  }
  .select-worker {
    position: absolute;
    transform-origin: center top;
    background: white;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    margin-top: 4.8rem;
    border-radius: $border-radius;
    left: 50%;
    transform: translate(-50%, 0) scale(0.01);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
    z-index: 1;
    padding: 0.8rem;
    .single-worker {
      padding: 0.8rem;
      white-space: nowrap;
      display: flex;
      align-items: center;
      cursor: pointer;
      z-index: 1;
      position: relative;
      &:hover {
        background-color: #D7DCE1;
      }
      &:last-child {
        margin-bottom: 0;
      }
      span {
        min-height: 24px;
        max-height: 24px;
        min-width: 24px;
        max-width: 24px;
        background: #0ABB87;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 6px;
        font-size: 12px;
      }
    }
    &.open {
      transform: translate(-50%, 0) scale(1);
      opacity: 1;
      box-shadow: 3px 3px 30px rgb(0 0 0 / 15%);
    }
    &:before {
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid white;
      left: 50%;
      transform: translate(-50%, 0);
      top: -10px;
    }
  }
}

.worker-wrapper .specific-worker span.ed { background-color: #0ABB87; }
.worker-wrapper .specific-worker span.cas { background-color: #5578EB; }
.worker-wrapper .specific-worker span.vin { background-color: #FD397A; }
.worker-wrapper .select-worker span.ed { background-color: #0ABB87; }
.worker-wrapper .select-worker span.cas { background-color: #5578EB; }
.worker-wrapper .select-worker span.vin { background-color: #FD397A; }

</style>