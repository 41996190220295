// Import router etc
import { createRouter, createWebHistory } from "vue-router";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

// Import views etc
import Login from "@/components/loginPage/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import WorkersOverview from "../views/WorkersOverview.vue";
import Sales from "../views/Sales.vue";
import SalesOverview from "../views/SalesOverview.vue";
import SalesByProduct from "../views/SalesByProduct.vue";
import Orders from "../views/Orders.vue";
import OrdersOverview from "../views/OrdersOverview.vue";
import OrdersRoadmap from "../views/OrdersRoadmap.vue";
import Employees from "../views/Employees.vue";
import EmployeesOverview from "../views/EmployeesOverview.vue";
import EmployeesIndividualReport from "../views/EmployeesIndividualReport.vue";
import Working from "../views/Working.vue";
import Bookers from "../views/Packers.vue";
import BookersOverview from "../views/BookersOverview.vue";
import BookersIndividualReport from "../views/BookersIndividualReport.vue";
import Stock from "../views/Stock.vue";
import Products from "../views/Products.vue";
import Status from "../views/Status.vue";
import Printing from "../views/Printing.vue";
import PrintingOrder from "../views/PrintingOrder.vue";
import DashboardWarehouse from "../views/DashboardWarehouse.vue";
import Settings from "../views/Settings.vue";
import SettingsProfile from "../views/SettingsProfile.vue";
import SettingsTheme from "../views/SettingsTheme.vue";
import SettingsManageUser from "../views/SettingsManageUser.vue";
import SettingsFilter from "../views/SettingsFilter.vue";

// Auth guard
// Make sure to look if the user is logged in with the backend
const requireAuth = (to, from, next) => {
  let user = cookies.get("token");
  console.log("current user in auth guard: ", user);

  if (user === null) {
    next({ name: "Login" });
  }

  if (user.token !== undefined && user !== null){
    next();
  }
  else
  {
    next({ name: 'Login' })
  }
};

const requireAuthAdmin = (to, from, next) => {
  let user = cookies.get("token");

  if (user === null) {
    next({ name: "Login" });
  }

  if (user.token !== undefined && user !== null) {
    if (user.role === 2) {
      next();
    } else {
      next({ name: "Dashboard" });
    }
  } else {
    next({ name: "Login" });
  }
};

// Routes app
// Use this if we wanna lazy load a component --> component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      hideSidebar: true,
      hideNavbar: true,
      hideClockingBtn: true,
    },
  },
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: requireAuth,
  },
  {
    path: "/workers",
    name: "Live workers",
    component: WorkersOverview,
    beforeEnter: requireAuth,
  },
  {
    path: "/sales",
    name: "Sales",
    component: Sales,
    meta: { value: "Sales" },
    beforeEnter: requireAuthAdmin,
    children: [
      {
        path: "overview",
        name: "SalesOverview",
        component: SalesOverview,
        meta: { value: "Overview" },
        beforeEnter: requireAuthAdmin,
      },
      {
        path: "sales-by-product",
        name: "SalesByProduct",
        component: SalesByProduct,
        meta: { value: "Sales by product" },
        beforeEnter: requireAuthAdmin,
      },
    ],
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
    meta: { value: "Orders" },
    beforeEnter: requireAuthAdmin,
    children: [
      {
        path: "overview",
        name: "OrdersOverview",
        component: OrdersOverview,
        meta: { value: "Overview" },
        beforeEnter: requireAuthAdmin,
      },
      {
        path: "order-roadmap",
        name: "OrdersRoadmap",
        component: OrdersRoadmap,
        meta: { value: "Compare" },
        beforeEnter: requireAuthAdmin,
      },
    ],
  },
  {
    path: "/employees",
    name: "Employees",
    component: Employees,
    meta: { value: "Employees" },
    beforeEnter: requireAuthAdmin,
    children: [
      {
        path: "overview",
        name: "EmployeesOverview",
        component: EmployeesOverview,
        meta: { value: "Employees Overview" },
        beforeEnter: requireAuthAdmin,
      },
      {
        path: "individual-report",
        name: "EmployeesIndividualReport",
        component: EmployeesIndividualReport,
        meta: { value: "Employees Individual Report" },
        beforeEnter: requireAuthAdmin,
      },
    ],
  },
  {
    path: "/bookers",
    name: "Bookers",
    component: Bookers,
    meta: { value: "Bookers" },
    beforeEnter: requireAuth,
    children: [
      {
        path: "overview",
        name: "BookersOverview",
        component: BookersOverview,
        meta: { value: "Overview" },
        beforeEnter: requireAuth,
      },
      {
        path: "individual-report",
        name: "BookersIndividualReport",
        component: BookersIndividualReport,
        meta: { value: "Individual report" },
        beforeEnter: requireAuth,
      },
    ],
  },
  {
    path: "/working",
    name: "Working",
    component: Working,
    meta: {
      value: "Working",
      hideSidebar: true,
      hideNavbar: true,
      hideClockingBtn: true,
    },
    beforeEnter: requireAuth,
    children: [
      {
        path: "working?packer=true",
        name: "workingPacker",
        component: Working,
        meta: { value: "Working packer" },
        beforeEnter: requireAuth,
      },
      {
        path: "working?picker=true",
        name: "workingPicker",
        component: Working,
        meta: { value: "Working picker" },
        beforeEnter: requireAuth,
      },
    ],
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
    meta: { value: "Products" },
    beforeEnter: requireAuthAdmin,
  },
  {
    path: "/stock",
    name: "Stock",
    component: Stock,
    meta: { value: "Stock" },
    beforeEnter: requireAuthAdmin,
  },
  {
    path: "/status",
    name: "Status",
    component: Status,
    meta: { value: "Status" },
    beforeEnter: requireAuthAdmin,
  },
  {
    path: "/printing",
    name: "Printing",
    component: Printing,
    meta: { value: "Printing" },
    beforeEnter: requireAuth,
    children: [
      {
        path: "order/:orderId",
        name: "printingOrder",
        component: PrintingOrder,
        meta: { value: "Order overview" },
        beforeEnter: requireAuth,
      },
    ],
  },
  {
    path: "/printing/order/:orderId",
    name: "printingOrder",
    component: PrintingOrder,
    meta: { value: "Order overview" },
    beforeEnter: requireAuth,
  },
  {
    path: "/dashboard-warehouse",
    name: "DashboardWarehouse",
    component: DashboardWarehouse,
    beforeEnter: requireAuth,
    meta: {
      hideSidebar: true,
      hideNavbar: true,
      hideClockingBtn: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { value: "Settings" },
    beforeEnter: requireAuth,
    children: [
      {
        path: "profile",
        name: "SettingsProfile",
        component: SettingsProfile,
        meta: { value: "Settings Profile" },
        beforeEnter: requireAuth,
      },
      {
        path: "theme",
        name: "SettingsTheme",
        component: SettingsTheme,
        meta: { value: "Settings Theme" },
        beforeEnter: requireAuth,
      },
      {
        path: "manage-users",
        name: "SettingsManageUser",
        component: SettingsManageUser,
        meta: { value: "Settings Manage User" },
        beforeEnter: requireAuth,
      },
      {
        path: "filter",
        name: "SettingsFilter",
        component: SettingsFilter,
        meta: { value: "Settings Filter" },
        beforeEnter: requireAuth,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from, next) => {
  document.body.classList.remove("dashboard-home");
  document.body.classList.remove("dashboard-warehouse");
});

export default router;
