<template>
  <div class="warehouse-dashboard" :style="{ height: mainHeight + 'px' }">
    <Header class="header" />
    <section v-if="!initialLoad" class="picklist__container picklist__container--one">
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-goal loader">
        <rect />
      </ContentLoader>
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-open loader">
        <rect />
      </ContentLoader>
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-urgent loader">
        <rect />
      </ContentLoader>
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-closed loader">
        <rect />
      </ContentLoader>
    </section>
    <section v-else-if="initialLoad" class="picklist__container picklist__container--one">
      <article class="picklist picklist-goal">
        <PicklistGoal v-if="Object.keys(status).length !== 0" :data="todaySameDay" />
      </article>
      <article class="picklist picklist-open">
        <PicklistOpen v-if="openCount > 0 && openPicklistHistory.length > 0" :count="openCount"
          :history="openPicklistHistory" />
      </article>
      <article class="picklist picklist-urgent">
        <PicklistUrgent v-if="urgentCount > 0 && urgentPicklistHistory.length > 0" :count="urgentCount"
          :history="urgentPicklistHistory" />
      </article>
      <article class="picklist picklist-closed">
        <PicklistClosed v-if="closedCount > 0 && closedPicklistHistory.length > 0" :count="closedCount"
          :history="closedPicklistHistory" />
      </article>
    </section>


    <section v-if="!initialLoad && !isPhone" class="picklist__container picklist__container--two">
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-compare loader">
        <rect />
      </ContentLoader>
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-status loader">
        <rect />
      </ContentLoader>
      <ContentLoader preserveAspectRatio="none" class="picklist picklist-domain loader">
        <rect />
      </ContentLoader>
    </section>
    <section v-else-if="initialLoad && !isPhone" class="picklist__container picklist__container--two">
      <article class="picklist picklist-compare">
        <PicklistCompare v-if="incomingPickOrd.length > 0" :data="incomingPickOrd" />
      </article>
      <article class="picklist picklist-status">
        <PicklistStatus v-if="Object.keys(status).length !== 0" :data="status" :count="openCount" />
      </article>
      <article class="picklist picklist-domain">
        <PicklistDomain v-if="Object.keys(picklistsPerShopWithoutFlags).length !== 0"
          :data="picklistsPerShopWithoutFlags" />
      </article>
    </section>

    <section v-if="!initialLoad && isPhone" class="picklist__container picklist__container--two">
      <swiper-slide>

        <ContentLoader preserveAspectRatio="none" class="picklist picklist-compare loader">
          <rect />
        </ContentLoader>
      </swiper-slide>
      <!-- <swiper-slide>
        <ContentLoader preserveAspectRatio="none" class="picklist picklist-status" style="padding: 0;">
          <rect/>
        </ContentLoader>
      </swiper-slide> -->
      <!-- <swiper-slide>
        <ContentLoader preserveAspectRatio="none" class="picklist picklist-domain" style="padding: 0;">
          <rect/>
        </ContentLoader>
      </swiper-slide> -->
    </section>
    <swiper :modules="modules" :slides-per-view="1" :space-between="24" :pagination="{ el: '.custom-pagination' }"
      v-else-if="initialLoad && isPhone" class="picklist__container picklist__container--two">
      <swiper-slide class="picklist picklist-compare">
        <PicklistCompare v-if="incomingPickOrd.length > 0" :data="incomingPickOrd" />
      </swiper-slide>
      <swiper-slide class="picklist picklist-status">
        <PicklistStatus v-if="Object.keys(status).length !== 0" :data="status" :count="openCount" />
      </swiper-slide>
      <swiper-slide class="picklist picklist-domain">
        <PicklistDomain v-if="Object.keys(picklistsPerShopWithoutFlags).length !== 0"
          :data="picklistsPerShopWithoutFlags" />
      </swiper-slide>
      <div class="custom-pagination"></div>
    </swiper>

  </div>
</template>

<script>
// globalQueue.js
import { reactive, ref, onMounted, onUnmounted } from "vue";
import getUser from "../composeables/getUser";

import moment from "moment";
import { Chart, registerables } from "chart.js";

import Header from "./Header.vue";
import PicklistGoal from "./PicklistGoal.vue";
import PicklistOpen from "./PicklistOpen.vue";
import PicklistUrgent from "./PicklistUrgent.vue";
import PicklistClosed from "./PicklistClosed.vue";
import PicklistStatus from "./PicklistStatus.vue";
import PicklistDomain from "./PicklistDomain.vue";
import PicklistCompare from "./PicklistCompare.vue";
import GlobeVisualization from "./GlobeVisualization.vue";
import LoadingPlugin from "vue-loading-overlay";
import { ContentLoader } from 'vue-content-loader'

import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';

export default {
  components: {
    Header, PicklistGoal, PicklistOpen, PicklistUrgent, PicklistClosed, PicklistStatus, PicklistDomain, PicklistCompare, GlobeVisualization, ContentLoader, Swiper, SwiperSlide,
  },
  setup() {
    Chart.register(...registerables);

    let intervalId = null;
    const { user } = getUser();

    const status = ref({});
    const statusLoaded = ref(false);
    const closedCount = ref(null);
    const urgentCount = ref(null);
    const openCount = ref(null);
    const picklistsPerShopWithoutFlags = ref({});
    const todaySameDay = ref({});
    const incomingPickOrd = ref([]);
    const openPicklistHistory = ref([]);
    const urgentPicklistHistory = ref([]);
    const closedPicklistHistory = ref([]);
    const initialLoad = ref(false);
    const isPhone = ref(window.innerWidth <= 576)

    const mainHeight = ref(window.innerHeight);

    const updateHeight = () => {
      mainHeight.value = window.innerHeight;
    };

    async function createQueue() { // dont pay attention to this
      let queue = [];
      queue.push(

        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/not/completed?" + moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { headers: { "Authorization": user ? `Bearer ${user._value.token}` : "" } }),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/open/history?" + moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { headers: { "Authorization": user ? `Bearer ${user._value.token}` : "" } }),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/urgent?" + moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { headers: { "Authorization": user ? `Bearer ${user._value.token}` : "" } }),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/urgent/history?" + moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&enddate=" + moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { headers: { "Authorization": user ? `Bearer ${user._value.token}` : "" } }),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/completed?" + moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&endDate=" + moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString()),
        fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getKleinAndGroot"),
        fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getIncomingPicklistsAndOrders"),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/closed/history"),
        fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/open/per-shop-graph-total"),
        fetch(process.env.VUE_APP_ROOT_API + "/api/orders/created/today")
      )

      await Promise.all(queue).then(async (data) => {

        // Open + history
        openCount.value = await data[0].json();
        openPicklistHistory.value = JSON.parse(JSON.stringify(await data[1].json()));

        // Open urgent + history
        urgentCount.value = await data[2].json();
        urgentPicklistHistory.value = await data[3].json();

        // Closed + history
        closedCount.value = await data[4].json();

        // Status Big, Small, Mixed, Singles, with percentage
        status.value = await data[5].json();
        // Incoming picklists + closed picklists graph
        incomingPickOrd.value = await data[6].json();

        closedPicklistHistory.value = await data[7].json();

        // Domain
        picklistsPerShopWithoutFlags.value = await data[8].json();
        todaySameDay.value = await data[9].json();

      });
    }
    function checkScreenWidth() {
      isPhone.value = window.innerWidth <= 576;
    }

    onMounted(async () => {
      window.addEventListener('resize', checkScreenWidth);
      window.addEventListener('resize', updateHeight);
      checkScreenWidth(); // Call initially to set the correct state
      updateHeight()
      initialLoad.value = false;
      await createQueue(); // Load initial data

      // Wait for the first interval (15 seconds) before setting initialLoad to true
      // setTimeout(() => {
      initialLoad.value = true;
      // }, 1000);

      intervalId = setInterval(async () => {
        await createQueue();
      }, 15000);
    })


    onUnmounted(() => {
      window.removeEventListener('resize', checkScreenWidth);
      window.removeEventListener('resize', updateHeight);
      clearInterval(intervalId);
    });

    // ###########################################
    //                 PLUGIN
    // ###########################################

    const gradientBackgroundPlugin = {
      id: 'gradientBackgroundPlugin',
      beforeUpdate: function (chart) {
        const ctx = chart.ctx;

        chart.data.datasets.forEach((dataset) => {
          // Calculate the maximum value of the dataset
          const maxValue = Math.max(...dataset.data);
          // console.log(dataset)
          // Check if maxValue is finite
          if (!isFinite(maxValue)) {
            return; // Skip the dataset if maxValue is not finite
          }

          // Determine the relative height for the gradient
          const gradientHeight = chart.scales.y.getPixelForValue(maxValue);

          // Check if gradientHeight is finite and within canvas bounds
          if (!isFinite(gradientHeight) || gradientHeight < 0 || gradientHeight > chart.height) {
            return; // Skip if gradientHeight is not valid
          }

          let gradient;
          if (dataset.gradientType === 'green') {
            gradient = ctx.createLinearGradient(0, gradientHeight, 0, chart.height);
            gradient.addColorStop(0, 'rgba(29, 215, 60, 1)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
          } else if (dataset.gradientType === 'orange') {
            gradient = ctx.createLinearGradient(0, gradientHeight, 0, chart.height);
            gradient.addColorStop(0, 'rgba(255, 85, 31, 1)');
            gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
          }
          if (gradient) {
            dataset.backgroundColor = gradient;
          }
        });
      }
    };
    Chart.register(gradientBackgroundPlugin);
    // ##################################

    const horizontalLinePlugin = {
      id: 'horizontalLine',
      afterDraw(chart, args, options) {
        if (chart.options.plugins.horizontalLine.draw) {
          const ctx = chart.ctx;
          const yAxis = chart.scales.y;
          const xAxis = chart.scales.x;
          let yValue;

          // Check if yValue should be based on the first data point or a specific value
          if (options.yValueOption === 'firstData') {
            const firstDataPointValue = chart.data.datasets[0].data[0];
            yValue = yAxis.getPixelForValue(firstDataPointValue);
          } else if (typeof options.yValueOption === 'number') {
            yValue = yAxis.getPixelForValue(options.yValueOption);
          } else {
            // If yValueOption is not set or invalid, do not draw the line
            return;
          }

          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([options.dashLength, options.gapLength]);
          ctx.lineWidth = options.lineWidth;
          ctx.strokeStyle = options.lineColor;
          ctx.moveTo(xAxis.left, yValue);
          ctx.lineTo(xAxis.right, yValue);
          ctx.stroke();
          ctx.restore();
        }
      }
    };


    Chart.register(horizontalLinePlugin);



    // const gradientBackgroundPlugin = {
    //   id: 'gradientBackgroundPlugin',
    //   beforeUpdate: function (chart) {
    //     const ctx = chart.ctx;

    //     chart.data.datasets.forEach((dataset) => {
    //       // Calculate the maximum value of the dataset
    //       const maxValue = Math.max(...dataset.data);

    //       // Check if maxValue is finite
    //       if (!isFinite(maxValue)) {
    //         return; // Skip the dataset if maxValue is not finite
    //       }

    //       // Determine the relative height for the gradient
    //       const gradientHeight = chart.scales.y.getPixelForValue(maxValue);

    //       // Check if gradientHeight is finite and within canvas bounds
    //       if (!isFinite(gradientHeight) || gradientHeight < 0 || gradientHeight > chart.height) {
    //         return; // Skip if gradientHeight is not valid
    //       }

    //       let gradient;
    //       if (dataset.gradientType === 'green') {
    //         gradient = ctx.createLinearGradient(0, gradientHeight, 0, chart.height);
    //         gradient.addColorStop(0, 'rgba(29, 215, 60, 1)');
    //         gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
    //       } else if (dataset.gradientType === 'orange') {
    //         gradient = ctx.createLinearGradient(0, gradientHeight, 0, chart.height);
    //         gradient.addColorStop(0, 'rgba(255, 85, 31, 1)');
    //         gradient.addColorStop(1, 'rgba(255, 255, 255, 0.5)');
    //       }
    //       if (gradient) {
    //         dataset.backgroundColor = gradient;
    //       }
    //     });
    //   }
    // };
    // Chart.register(gradientBackgroundPlugin);

    // ###########################################
    // ###########################################


    return {
      status, closedCount, urgentCount, openCount, picklistsPerShopWithoutFlags, todaySameDay, openPicklistHistory, incomingPickOrd, urgentPicklistHistory, closedPicklistHistory,
      initialLoad, isPhone, mainHeight, modules: [Pagination],
    }
  },
  async mounted() {
    document.body.classList.add("dashboard-warehouse");
  },
  mounted() {
    document.documentElement.classList.add('custom-font-size');
    // or for body: document.body.classList.add('custom-font-size');
  },
  beforeDestroy() {
    document.documentElement.classList.remove('custom-font-size');
    // or for body: document.body.classList.remove('custom-font-size');
  },
};


</script>



<style lang="scss">
@keyframes splash {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  to {
    transform: scale(1, 1);
  }
}

// body.dashboard-warehouse {
//   // overflow-y: auto;
// }


.warehouse-dashboard {
  & .swiper {
    overflow: visible !important;

    & .custom-pagination {
      position: absolute;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 1rem;

      & .swiper-pagination-bullet-active {
        background: #1f1f1f;
      }
    }
  }

  background-image: url("~@/assets/img/new/logo-overlay.svg");
  background-color: var(--bg-white);
  width: 100%;
  height: 100vh;
  padding: 4rem;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: -45% 0;

  .picklist__container {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;

    &--one {
      height: calc(23% - 4rem);
      margin-bottom: 4rem;
    }

    &--two {
      height: 70%;
    }

    & .picklist {
      height: 100%;
      width: 100%;
      border-radius: 1rem;
      background: var(--bg-white);
      box-shadow: 0 0.2rem 1.6rem 0 rgba(0, 0, 0, 0.15);
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      &__label {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 2.2rem;
        margin: 0;
      }



      &__title {
        align-self: flex-start;
      }

      &-goal {
        background-color: var(--color-orange);
        background-image: url("~@/assets/img/new/orange-overlay.svg");
        background-repeat: no-repeat;
        background-position: 140%;
        background-size: 75%;
        color: var(--color-white);
      }

      &__title-wrapper {
        width: 100%;
        display: flex;
        gap: 2.6rem;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.4rem;
      }

      &__counter {
        font-size: 8rem;
        font-weight: 700;
        line-height: 8rem;
        height: fit-content;
      }

      &__main {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        gap: 2.4rem;
      }

      &__chart {
        // width: 100%;
        // max-width: 49%;
        display: flex;
        flex-direction: column;
        // margin-bottom: 0.7rem;
        // margin-top: -6rem;
        align-self: flex-end;

        &__percentage {
          font-size: 2rem;
          line-height: 3.4rem;
          // color: var(--color-orange);

          &--green {
            color: var(--color-green);
          }
        }

        &__status {
          display: flex;
          align-self: flex-end;
          gap: 1.2rem;

          & svg {
            display: block;
            width: 2.4rem;
          }

          &--green {
            & svg {
              transform: rotate(180deg);

              & path,
              circle {
                stroke: var(--color-green);
              }
            }
          }
        }
      }

      &-open,
      &-urgent,
      &-closed,
      &-goal {
        padding-bottom: 1.2rem;

        & .picklist__title-wrapper {
          margin-bottom: 0;
        }
      }

      &__sub {
        font-size: 2rem;

        & b {
          font-weight: 700;
        }
      }

      &.loader {
        padding: 0;
        background-image: none;
        background-color: unset;

        & rect {
          x: 0;
          y: 0;
          rx: 0;
          ry: 0;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}


/* For laptops and large desktops (1024px to 3839px width) */
@media (min-width: 1024px) and (max-width: 3839px) {
  .custom-font-size {
    font-size: 44%;
  }
}

/* For HD screens and smaller (up to 1023px width) */
@media (min-width: 1920px) and (aspect-ratio: 16/9) {
  .custom-font-size {
    font-size: 58%;
  }
}

/* For 4K screens (>= 3840px width) */
@media (min-width: 3840px) and (aspect-ratio: 16/9) {
  .custom-font-size {
    font-size: 120%;
  }
}

@media (max-width: 1023px) {
  .custom-font-size {
    font-size: 62.5%;
  }

  h2 {
    font-size: 1.4rem;
  }

  .warehouse-dashboard {
    padding: 2.4rem;

    .picklist__container {
      grid-gap: 2.4rem;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;

      &--one {
        height: calc(22% - 2.4rem);
        margin-bottom: 2.4rem;
      }

      &--two {
        height: 72%;
        grid-template-rows: 1fr 1fr;
      }

      & .picklist {
        padding: 1.6rem;
        padding-bottom: 4rem;

        &-open,
        &-urgent,
        &-closed,
        &-goal {
          padding-bottom: 0.5rem;

          & .picklist__title-wrapper {
            margin-bottom: 0;
          }
        }

        &__label {
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.1rem;
          margin: 0;
        }





        &__title-wrapper {
          width: 100%;
          display: flex;
          gap: 2.6rem;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1.6rem;
        }

        &__counter {
          font-size: 4rem;
          font-weight: 700;
          line-height: normal;
          height: fit-content;
        }

        &__main {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          gap: 1.2rem;
        }

        &__chart {
          display: flex;
          flex-direction: column;
          // margin-bottom: 0.7rem;
          // margin-top: -6rem;
          align-self: flex-end;

          &__percentage {
            font-size: 1.4rem;
            line-height: 2rem;
            // color: var(--color-orange);

            &--green {
              color: var(--color-green);
            }
          }

          &__status {
            display: flex;
            align-self: flex-end;
            gap: 1.2rem;

            & svg {
              display: block;
              width: 1.6rem;
            }

            &--green {
              & svg {
                transform: rotate(180deg);

                & path,
                circle {
                  stroke: var(--color-green);
                }
              }
            }
          }
        }



        &__sub {
          font-size: 1.2rem;

          & b {
            font-weight: 700;
          }
        }

      }
    }
  }
}




// @media only screen and (min-width: 2000px) {
//   .warehouse-dashboard {
//     padding: 5rem;
//   }
// }

// @media only screen and (max-width: 1919px) {}


// @media only screen and (max-width: 1079px) {
//   .warehouse-dashboard {
//     height: 100%;
//     padding: 1.6rem;
//   }
// }

@media (max-width: 576px) {
  .warehouse-dashboard {



    & .header {
      display: none;
    }

    padding: 1.6rem;

    .picklist__container {

      & .picklist {

        &__main {
          width: 100%;
        }


        &-open,
        &-urgent,
        &-closed {
          align-items: center;
          padding: 1.6rem;

          &__chart {
            client-only {
              display: none;
            }

            align-self: center;
            margin: 0;

            &__percentage {
              font-size: 1.2rem;
              line-height: 1.7rem;
            }

            &__status {

              gap: 1.2rem;

              & svg {

                width: 1.2rem;
              }
            }
          }

          & .picklist__title-wrapper {
            width: fit-content;
          }

          &__title {

            & span {
              font-size: 1.4rem;
              font-weight: 400;

              &:last-child {
                font-size: 1rem;
              }

              &:nth-child(2) {
                display: none;
              }
            }

          }

          &__main {
            flex-direction: column;
            align-items: center;
            gap: 0.4rem;
          }
        }

        &__label {
          display: none;
        }

        &__counter {
          font-size: 2.4rem;
        }

        &-goal {
          &__counter {
            font-size: 4rem;
          }

          &__title {
            font-size: 1.6rem;
          }
        }

        &-closed {
          & .picklist__main {

            gap: 2.4rem;
          }
        }


      }


      &--one {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-gap: 1.6rem;
        height: calc(35% - 1.6rem);
        margin-bottom: 1.6rem;

        .picklist-goal {
          grid-area: 1 / 1 / 2 / 4;
          align-items: flex-start;
        }

        .picklist-open {
          grid-area: 2 / 1 / 3 / 2;
        }

        .picklist-urgent {
          grid-area: 2 / 2 / 3 / 3;
        }

        .picklist-closed {
          grid-area: 2 / 3 / 3 / 4;
        }
      }

      &--two {
        display: flex;
        height: 65%;
      }
    }
  }
}




// @media only screen and (max-width: 576px) {
//   .warehouse-dashboard {
//     padding: 1rem;
//   }
// }
</style>
