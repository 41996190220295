import { ref } from 'vue'
import { projectAuth } from '../../firebase/config'

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const error = ref(null)

const login = async (email, password) => {
  error.value = null

  try{
    const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/authenticate", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: email, password: password})
    })
    .then(res => res.json())
    .then(result => {
      cookies.set("token", result)
      error.value = null
      return result
    })
    .catch(err => {
      error.value = err;
      if (err.json) {a
        return err.json.then(json => {
          error.value.message = json.message;
        })
      }
    })
  } catch(err) {
    error.value = 'Incorrect login credentials'
  }
}

const useLogin = () => {
  return { error, login }
}

export default useLogin