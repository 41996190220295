<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist-closed__title picklist__title">
      <span>Closed</span>
      <span> Picklists</span>
    </h2>
    <h4 class="picklist__label">Average: 1580</h4>
  </div>
  <div class="picklist-closed__main picklist__main">
    <div class="picklist__counter">
      <OdometerComponent :initialValue="state.picklistsCountCompleted" />

      <!-- <animated-counter :number="state.picklistsCountCompleted"></animated-counter> -->
      <!-- <span>{{ picklistsCountCompleted }}</span> -->
    </div>
    <div class="picklist__chart picklist-closed__chart">
      <client-only>
      <LineChart :chartData="closedData" :options="closedOptions" ref="lineClosedRef"
        @chart:render="closedHandleChartRender" />
      </client-only>
    </div>
  </div>
</template> 
<script>
import { onMounted, reactive, watch, ref, computed, nextTick } from "vue";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import { remToPx } from './utils';
import OdometerComponent from './OdometerComponent.vue';


export default {
  components: { LineChart , OdometerComponent},
  props: {
    count: Number,
    history: Array,
  },
  setup(props) {
    const { count, history } = props;

    const state = reactive({
      picklistsCountCompleted: 0,
      historyArray: [...props.history],
      lineWidth: 0,
      dashLength: 0,
      gapLength: 0,
      borderWidth: 0,
    })

    const lineClosedRef = ref(null);
    Chart.register(...registerables);

    const getResponsiveValues = () => {
      if (window.innerWidth <= 1023) {
        state.lineWidth = remToPx(0.1);
        state.dashLength = remToPx(0.5);
        state.gapLength = remToPx(0.3);
        state.borderWidth = remToPx(0.1);
      } else {
        state.lineWidth = remToPx(0.15)
        state.dashLength = remToPx(0.6);
        state.gapLength = remToPx(0.4);
        state.borderWidth = remToPx(0.15);
      }
    };

    const calculateMaxYValue = (data) => {
      const maxValue = Math.max(...data);
      const buffer = 2000; // Adjust this value as needed
      return maxValue + buffer;
    };

    const closedHandleChartRender = (chart) => {
      if (window.innerWidth <= 1023) {
        chart.canvas.parentNode.style.height = '7rem';
      } else {
        chart.canvas.parentNode.style.height = '10rem';
      }
      lineClosedRef.value.update();
    }

    const closedOptions = computed(() => ({
      interaction: {
        intersect: false,
        mode: "index",
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
          max: calculateMaxYValue(state.historyArray.map(x => x.count))
        },
      },
      plugins: {
        horizontalLine: {
          draw: true,
          lineWidth: state.lineWidth,
          dashLength: state.dashLength,
          gapLength: state.gapLength,
          yValueOption: 1580,
          lineColor: '#8F8F8F',
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      maintainAspectRatio: false,
    }));

    const closedData = computed(() => ({

      labels: state.historyArray.map(x => moment(x.date).add(1, 'minute').startOf('minute').format('YYYY-MM-DD HH:mm:ss').toString()),
      showTooltips: false,
      datasets: [
        {
          label: "Picklists completed",
          gradientType: "green",
          data: state.historyArray.map(x => x.count),
          borderColor: "#18B432",
          borderWidth: state.borderWidth,
          pointRadius: 0,
          fill: true,
          tension: 0.4,
        }
      ],
    }))

    onMounted(async () => {
      nextTick().then(() => {
        if (lineClosedRef.value) {
          // Now you can safely use lineClosedRef
          lineClosedRef.value.update();
        } else {
          console.log('lineClosedRef is not initialized yet');
        }
      });
      // Update picklistsCountCompleted when data1 changes
      if (count) {
        state.picklistsCountCompleted = count;
      }
      if (lineClosedRef.value) {
        // Now you can safely use lineClosedRef
        lineClosedRef.value.update();
      }
      getResponsiveValues()
    });

    // Use computed to watch the count prop
    const countWatcher = computed(() => props.count);
    watch(countWatcher, (newValue) => {
      state.picklistsCountCompleted = newValue;
    });

    const historyWatcher = computed(() => props.history);
    watch(historyWatcher, (newValue) => {
      state.historyArray = [...newValue];
    });

    return {
      state,
      lineClosedRef,
      closedHandleChartRender,
      getResponsiveValues,
      closedOptions,
      closedData,
      count,
      history,
    };
  },
};

</script>
<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-closed {
        &__chart {
          margin-bottom: 1.9rem;
        }
      }
    }
  }
}
</style>