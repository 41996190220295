<template>
  <div>

    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Sales overview</div>
        <div class="subtitle">Overview of all sales from Dartshopper</div>
      </div>
      <div class="datepicker">
        <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
          <p>Today</p>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown"> <!--  -->
          <div class="datepicker-card">
            <div class="title">
              Today
            </div>
            <div class="subtitle">
              30 Nov. 2021 - 30 Nov. 2021
            </div>
          </div>
          <div class="datepicker-presets">
            <router-link @click="hideDatepickerDropdown" to="/">Today<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Yesterday<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 28 days<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 90 days<i class='bx bx-check'></i></router-link>
          </div>
          <div class="datepicker-customSelector">
            <Datepicker 
              v-model="date" 
              range 
              weekNumbers 
              :enableTimePicker="false" 
              locale="nl" 
              placeholder="Custom..." 
              textInput 
              cancelText="cancel" 
              selectText="apply" 
              :maxDate="new Date()"
              position="right"
            />
          </div>
        </div>
      </div>
    </div>

    <TabsWrapper>
      <Tab title="Dashboard">
        <div class="cards first">
          <div class="card card-1">
            <div class="card-title">Total sales</div>
            <div class="card-data">
              &euro; ...
              <i class='bx bx-money'></i>
            </div>
          </div>
          <div class="card card-2">
            <div class="card-title">Total orders</div>
            <div class="card-data">
              ...
              <i class='bx bx-grid'></i>
            </div>
          </div>
          <div class="card card-3">
            <div class="card-title">Biggest order</div>
            <div class="card-data">
              &euro; ...
              <i class='bx bx-money'></i>
            </div>
          </div>
          <div class="card card-4">
            <div class="card-title">Total picked orders</div>
            <div class="card-data">
              ...
              <i class='bx bxs-hand-up'></i>
            </div>
          </div>
          <div class="card card-5">
            <div class="card-title">Total packed orders</div>
            <div class="card-data">
              ...
              <i class='bx bxs-package'></i>
            </div>
          </div>
          <div class="card card-6">
            <div class="card-title">Orders left to complete</div>
            <div class="card-data">
              ...
              <i class='bx bxs-timer'></i>
            </div>
          </div>
        </div>
      </Tab>
      <Tab title="Sales by country">
        Sales by country lorem ipsum
      </Tab>
      <Tab title="Compare data">
        Compare data lorem ipsum
      </Tab>
      <Tab title="Downloads">
        Downloads lorem ipsum
      </Tab>
    </TabsWrapper>

  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Page from '@/components/Page.vue'
import TabsWrapper from '@/components/TabsWrapper.vue'
import Tab from '@/components/Tab.vue'

export default {
components: { Page, Datepicker, TabsWrapper, Tab },
  name: "sales-overview",
  setup() {
    const date = ref();

    return {
      date,
    }
  },
  data(){
    return{
      contents: null,
    }
  },
  methods: {
    hideDatepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    }
  },
  async mounted(){
    // document.body.classList.add("dashboard-home");
  },
}
</script>
<style lang="scss" scoped>
.cards.first{
  .card{
    width: calc(100% / 3 - (6.4rem / 3));
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
</style>
