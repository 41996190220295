<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist-open__title picklist__title">
      <span>Open</span>
      <span> Picklists</span>

    </h2>
    <h4 class="picklist__label">Beginning: {{ state.PicklistsleftBeginning }}</h4>
  </div>
  <div class="picklist-open__main picklist__main">
    <div class="picklist-open__counter picklist__counter">
      <!-- <animated-counter :number="state.PicklistsleftToComplete"></animated-counter> -->
      <!-- <AnimatedCounter :number="state.PicklistsleftToComplete"/> -->
      <OdometerComponent :initialValue="state.PicklistsleftToComplete" />

    </div>
    <div class="picklist-open__chart picklist__chart" :class="DynamicColor">
      <client-only>
        <LineChart :chartData="openData" :options="openOptions" ref="lineOpenRef" @chart:render="openHandleChartRender" />
      </client-only>

      <div class="picklist__chart__status">
        <IconStatus />
        <p class="picklist__chart__percentage">{{ state.percentageChange }}%</p>
      </div>
    </div>
  </div>
</template> 
<script>
import { onMounted, nextTick, watch, ref, reactive, computed } from "vue";
import moment from "moment";
import { Chart, registerables } from "chart.js";
import { LineChart } from "vue-chart-3";
import IconStatus from '@/components/svg/IconStatus.vue';
import { remToPx } from './utils';
import AnimatedCounter from './AnimatedCounter.vue';
import OdometerComponent from './OdometerComponent.vue';



export default {
  components: { LineChart, IconStatus, AnimatedCounter, OdometerComponent },
  props: {
    count: Number,
    history: Array,
  },
  setup(props) {
    const { count, history } = props;

    const state = reactive({
      PicklistsleftToComplete: 0,
      PicklistsleftBeginning: 0,
      percentageChange: null,
      historyArray: [...props.history],
      lineWidth: 0,
      dashLength: 0,
      gapLength: 0,
      borderWidth: 0,
      borderColor: "#ff551f",
      gradientColor: "orange",
    });

    const lineOpenRef = ref(null);
    Chart.register(...registerables);

    const getResponsiveValues = () => {
      if (window.innerWidth <= 1023) {
        state.lineWidth = remToPx(0.1);
        state.dashLength = remToPx(0.5);
        state.gapLength = remToPx(0.3);
        state.borderWidth = remToPx(0.1);
      } else {
        state.lineWidth = remToPx(0.15)
        state.dashLength = remToPx(0.6);
        state.gapLength = remToPx(0.4);
        state.borderWidth = remToPx(0.15);
      }
    };

    const openHandleChartRender = (chart) => {
      if (window.innerWidth <= 1022) {
        chart.canvas.parentNode.style.height = '4rem';
      } else {
        chart.canvas.parentNode.style.height = '7rem';
      }
      lineOpenRef.value.update();
    };

    const openOptions = computed(() => ({
      interaction: {
        intersect: false,
        mode: "index",
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
          right: 0,
          left: 0,
        },
      },
      scales: {
        x: {
          display: false,
        },
        y: {
          display: false,
        },
      },
      plugins: {
        horizontalLine: {
          draw: true,
          lineWidth: state.lineWidth,
          dashLength: state.dashLength,
          gapLength: state.gapLength,
          yValueOption: 'firstData',
          lineColor: '#8F8F8F',
        },
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
      maintainAspectRatio: false,
    }));

    const openData = computed(() => ({
      labels: state.historyArray.map(x => moment(x.date).add(1, 'minute').startOf('minute').format('YYYY-MM-DD HH:mm:ss').toString()),
      showTooltips: false,
      datasets: [
        {
          label: "Picklists completed",
          gradientType: state.gradientColor,
          data: state.historyArray.map(x => x.count),
          borderColor: state.borderColor,
          borderWidth: state.borderWidth,
          pointRadius: 0,
          fill: true,
          tension: 0.4,
        }
      ],
    }))

    const DynamicColor = computed(() => {
      if (state.percentageChange < 0) {
        state.gradientColor = "orange";
        state.borderColor = "#ff551f";
        return "increase";
      } else {
        state.gradientColor = "green";
        state.borderColor = "#18B432";
        return "decrease";
      }
    });

    onMounted(async () => {

      if (count) {
        state.PicklistsleftToComplete = count;
      }
      if (state.historyArray) {
        state.PicklistsleftBeginning = state.historyArray[0].count
        state.percentageChange = parseInt(100 - (100 / state.historyArray[0].count * state.historyArray[state.historyArray.length - 1].count)).toFixed(0);
      }
      getResponsiveValues()
    });

    const historyWatcher = computed(() => props.history);
    watch(historyWatcher, (newValue) => {
      state.historyArray = [...newValue];
      state.PicklistsleftBeginning = newValue[0].count // Update previous value
      state.percentageChange = parseInt(100 - (100 / state.historyArray[0].count * state.historyArray[state.historyArray.length - 1].count)).toFixed(0);
      if (lineOpenRef.value) {
        // Now you can safely use lineOpenRef
        lineOpenRef.value.update();
      } else {
        console.log('lineOpenRef is not initialized yet');
      }
    });

    // Use computed to watch the count prop
    const countWatcher = computed(() => props.count);
    watch(countWatcher, (newValue) => {
      state.PicklistsleftToComplete = newValue;
    });

    return {
      state,
      lineOpenRef,
      openOptions,
      openData,
      DynamicColor,
      count,
      history,
      openHandleChartRender,
      getResponsiveValues,
    };
  },
};

</script>
<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-open {
        &__chart {
          margin-bottom: 0.7rem;
        }
      }
    }
  }
}

.increase {

  & svg {


    & path,
    circle {
      stroke: var(--color-orange);
    }
  }

  & .picklist__chart__percentage {
    color: var(--color-orange);
  }
}

.decrease {

  & svg {
    transform: rotate(180deg);

    & path,
    circle {
      stroke: var(--color-green);
    }
  }


  & .picklist__chart__percentage {
    color: var(--color-green);
  }
}

@media (min-width: 576px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {}
    }
  }
}
</style>