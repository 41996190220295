<template>
  <div id="sidebar" class="sidebar" :class="{ close: close }"> <!-- v-if="userIsAdmin" -->
    <div class="logo-details">
      <router-link to="/">
        <img src="@/assets/img/logo-lettermark-white.svg" alt="logo-lettermark-white">
      </router-link>
      <i v-on:click="removeOpenTabs" @click="close = !close" :aria-pressed="close ? 'true' : 'false'" class='bx bxs-chevrons-left' ></i>
    </div>
    <ul class="nav-links">
      <li>
        <router-link to="/" tag="div" class="iocn-link">
          <a>
            <i class='bx bx-grid-alt' ></i>
            <span class="link_name">Dashboard</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu blank">
          <li><a class="link_name" href="#">Dashboard</a></li>
        </ul>
      </li>
      <!-- <div class="cat-section">
        <span>Statistics</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div> -->
      <!-- <li>
        <div class="iocn-link" v-bind:class="{ 'active': ['/sales/overview', '/sales/sales-by-product'].includes($route.path) }">
          <a>
            <i class='bx bx-money'></i>
            <span class="link_name">Sales</span>
          </a>
          <i class='bx bxs-chevron-down arrow'></i>
        </div>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu">
          <li><a class="link_name" href="#">Sales</a></li>
          <li><router-link to="/sales/overview" class="submenu-routerlink" exact>Overview</router-link></li>
          <li><router-link to="/sales/sales-by-product" class="submenu-routerlink" exact>Sales by product</router-link></li>
        </ul>
      </li> -->
      <li>
        <!-- '/orders/overview',  <-- add this back to array below to include route -->
        <div class="iocn-link" v-bind:class="{ 'active': ['/orders/order-roadmap'].includes($route.path) }">
          <a>
            <i class='bx bx-grid' ></i>
            <span class="link_name">Orders</span>
          </a>
          <i class='bx bxs-chevron-down arrow'></i>
        </div>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu">
          <li><a class="link_name" href="#">Orders</a></li>
          <!-- <li><router-link to="/orders/overview" class="submenu-routerlink" exact>Overview</router-link></li> -->
          <li><router-link to="/orders/order-roadmap" class="submenu-routerlink" exact>Compare</router-link></li>
        </ul>
      </li>
      <div class="cat-section">
        <span>Reports</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div>      
      <li>
        <div class="iocn-link" v-bind:class="{ 'active': ['/employees/overview', '/employees/individual-report'].includes($route.path) }">
          <a>
            <i class='bx bx-grid' ></i>
            <span class="link_name">Employees</span>
          </a>
          <i class='bx bxs-chevron-down arrow'></i>
        </div>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu">
          <li><a class="link_name" href="#">Employees</a></li>
          <li><router-link to="/employees/overview" class="submenu-routerlink" exact>Overview</router-link></li>
          <!-- <li><router-link to="/employees/individual-report" class="submenu-routerlink" exact>Individual report</router-link></li> -->
        </ul>
      </li>

      <!-- <li>
        <router-link to="/workers" tag="div" class="iocn-link">
          <a>
            <i class='bx bxs-user'></i>
            <span class="link_name">Employees</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu blank">
          <li><a class="link_name" href="#">Live workers</a></li>
        </ul>
      </li> -->

      <div class="cat-section">
        <span>Warehouse</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div>
      <li>
        <router-link to="/status" tag="div" class="iocn-link">
          <a>
            <i class='bx bxs-battery-low'></i>
            <span class="link_name">Status</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu blank">
          <li><a class="link_name" href="#">Status</a></li>
        </ul>
      </li>
      <!-- <li>
        <router-link to="/printing" tag="div" class="iocn-link">
          <a>
            <i class='bx bxs-battery-low'></i>
            <span class="link_name">Printing</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeight }" class="sub-menu blank">
          <li><a class="link_name" href="#">Overview</a></li>
        </ul>
      </li>  -->
    </ul>
    <div class="sidebar-footer">
      <router-link to="/dashboard-warehouse" class=""><i class='bx bx-link-external'></i><span>Dashboard</span></router-link>
    </div>
  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ref, onMounted } from 'vue'

export default {
  data() {
    return{
      close: false,
      maxHeight: null
    }
  },
  computed :{
    computedHeight: function () {
      return this.maxHeight;
    }
  },
  methods: {
    // Remove any open submenus when nav is clicked to be smaller
    removeOpenTabs: function(event) {
      this.maxHeight = null;

      let removeShowmenu = document.querySelectorAll(".iocn-link");
      if (removeShowmenu.length > 0) {
        removeShowmenu[0].classList.remove("showMenu");
      }
    },
    // Cycles through all nav links to make sure only one submenu is open
    activeHeader(to, from){
      var activeHeader = document.querySelectorAll(".iocn-link.active");

      if(activeHeader.length > 0) {
        var test = activeHeader[0].nextElementSibling;
        test.style.maxHeight = test.scrollHeight + "px";
        activeHeader[0].classList.add("showMenu");
      }
    },
    closeOtherSubmenuIfOtherIsOpened(){
      let arrow = document.querySelectorAll(".iocn-link");

      for (var i = 0; i < arrow.length; i++) {
        arrow[i].onclick = function(e) {

          var panel = this.nextElementSibling;
          var coursePanel = document.querySelectorAll(".sub-menu");
          var courseAccordionActive = document.getElementsByClassName(".nav-links li .showMenu");
          var header = document.querySelectorAll(".iocn-link");

          if (panel.style.maxHeight){
            // minifies current submenu if already open
            panel.style.maxHeight = null;
            //removes the 'showMenu' class as toggle didnt work on browsers minus chrome
            this.classList.remove("showMenu");
          } else { //submenu isnt open...
            //goes through the buttons and removes the 'showMenu' css (+ and -)
            for (var ii = 0; ii < courseAccordionActive.length; ii++) {
              courseAccordionActive[ii].classList.remove("showMenu");
            }
            //Goes through and removes 'showMenu' from the css, also minifies any 'submenu' that might be open
            for (var iii = 0; iii < coursePanel.length; iii++) {
              header[iii].classList.remove("showMenu");
              coursePanel[iii].style.maxHeight = null;
            }

            //opens the specified submenu
            panel.style.maxHeight = panel.scrollHeight + "px";
            this.classList.add("showMenu");
          } 
        }
      }
    },
  },
  mounted() {
    this.closeOtherSubmenuIfOtherIsOpened();
  },
  updated() {
    this.activeHeader("", "");
  },
  setup() {
    const userIsAdmin = ref(false);
    const user = ref({});

    onMounted(() => {
      user.value = cookies.get('token');
      
      if(user?._value !== null) {
        if(user?._value?.role === 2) {
          userIsAdmin.value = true;
        }
      }

    })

    return { user, userIsAdmin }
  }
}
</script>

<style lang="scss" scoped>
.sidebar {
  width: 240px;
  min-height: 100%;
  height: 100%;
  background-color: #2E303F;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease;
  padding: 0 1.2rem;
  .logo-details{
    height: 6.0rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    a{
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      img{
        transition: all 0.3s ease;
      }
    }
    i{
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 30px;
      color: #494B59;
      text-align: center;
      &:hover{
        color: #fff;
      }
    }
  }
  .nav-links{
    padding: 30px 0 30px 0;
    overflow: auto;
    height: calc(100% - 196px);
    overflow: auto;
    .cat-section{
      display: flex;
      margin-top: 3.2rem;
      margin-bottom: 1.6rem;
      margin-left: 1.6rem;
      span{
        opacity: 1;
        font-family: "Neutrif-bold";
        text-transform: uppercase;
        color: #494B59;
      }
      i{
        opacity: 0;
        color: #494B59;
        font-size: 20px;
      }
    }
    li{
      position: relative;
      list-style: none;
      transition: all 0.3s ease;
      cursor: pointer;
      margin: 0.0rem 0 1.6rem 0; 
      .router-link-exact-active {
        background: #1F1D2C;
        border-radius: 5px;
        i{
          color: #3AAFE3 !important;
        }
        span{
          color: #fff !important;
        }
      }
      a{
        display: flex;
        align-items: center;
        text-decoration: none;
        .link_name{
          font-size: 16px;
          font-family: "Neutrif-semiBold";
          color: #9397A1;
          transition: all 0.4s ease;
          margin-left: 1.6rem;
        }
      }
      .showMenu{
        i.arrow{
          transform: rotate(-180deg);
        }
      }
      .sub-menu{
        padding: 0px 1.6rem 0px calc(3.2rem + 20px);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        .router-link-exact-active{
          background: unset;
          color: #3AAFE3 !important;
        }
        li{
          margin: 0.0rem 0 0.8rem 0;
        }
        a{
          color: #9397A1;
          font-size: 16px;
          padding: 5px 0;
          white-space: nowrap;
          transition: all 0.3s ease;
          font-family: "Neutrif-semiBold";
          &:hover{
            color: #fff;
          }
        }
        .link_name{
          display: none;
        }
      }
      .sub-menu.blank{
        opacity: 1;
        pointer-events: auto;
        padding: 0px 20px 0px 16px;
        opacity: 0;
        pointer-events: none;
      }
      .iocn-link{
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;
        border-radius: $border-radius;
        padding: 0 1.6rem;
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
        &:hover{
          background: #1F1D2C;
        }
        i{
          width: 20px;
          text-align: center;
          color: #494B59;
          font-size: 20px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
      }
      .iocn-link.active{
        background: #1F1D2C;
        i{
          color: #fff !important;
        }
        a{
          i{
            color: #3AAFE3 !important;
          }
        }
        span{
          color: #fff !important;
        }
      }
      .sub-menu.blank{
        li{
          margin: 0;
        }
      }
    }
  }
  .nav-links::-webkit-scrollbar{
    display: none;
  }
  .sidebar-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 3.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    box-shadow: 0 -10px 10px 0 rgba(31, 29, 44, 0.50);
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: $border-radius;
      text-align: center;
      font-family: "Neutrif-bold";
      height: 4.0rem;
      text-decoration: none;
      background-color: #3AAFE3;
      color: white;
      font-weight: bold;
      border: none;
      z-index: 1;
      transition: all 0.3s ease;
      position: relative;
      outline: none;
      &:after{
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #fff;
        border-radius: 5px;
        transition: all 0.3s ease;
      }
      &:hover{
        color: #0B212D;
        &:after{
          left: 0; 
          width: 100%;
        }
      }
      &:active{
        top: 2px;
      }
      i{
        margin-right: 0.8rem;
        font-size: 20px;
        transition: all 0.3s ease;
      }
      span{
        transition: all 0.3s ease;
      }
    }
  }
}

.sidebar .iocn-link.showMenu {
  background: #1F1D2C;
  .link_name{
    color: #fff;
  }
}

.sidebar.close{
  width: 70px;
  padding: 0;
  .logo-details{
    padding-left: 20px;
    padding-right: 20px;
    a{
      img{
        opacity: 0;
        pointer-events: none;
      }
    }
    i{
      transform: rotate(-180deg);
    }
  }
  .nav-links{
    overflow: visible;
    .cat-section{
      margin-left: 0;
      span{
        opacity: 0;
        width: 0;
      }
      i{
        padding-left: 25px;
        padding-right: 25px;
        opacity: 1;
      }
    }
    li{
      .blank{
        .link_name{
          font-size: 16px !important;
          text-transform: unset !important;
          color: #9397A1 !important;
          margin-left: 0 !important;
          &:hover{
            color: #fff !important;
          }
          &:before{
            display: none !important;
          }
        }
      }
      .sub-menu{
        position: absolute;
        left: 100%;
        top: -10px;
        margin-top: 0;
        padding: 10px 20px;
        border-radius: 0 6px 6px 0;
        opacity: 0;
        display: block;
        pointer-events: none;
        transition: 0s;
        height: auto;
        max-height: unset;
        .link_name{
          font-size: 18px;
          display: block;
          margin-left: 2.4rem;
          color: #494B59;
          text-transform: uppercase;
          &:before{
            content: '';
            transition: all 0.3s ease;
            position: absolute;
            width: 1.6rem;
            height: 2px;
            top: 50%; 
            background-color: #494B59;
            margin-left: -2.4rem;
            opacity: 0.5;
          }
        }
      }
      a{
        .link_name{
          opacity: 0;
          pointer-events: none;
        }
      }
      .iocn-link{
        display: block;
        padding-left: 25px;
        padding-right: 25px;
        border-radius: 0;
        
      }
      i.arrow{
        display: none;
      }
      &:hover .sub-menu{
        background: #1F1D2C;
        top: 0;
        opacity: 1;
        pointer-events: auto;
        transition: all 0.3s ease;
      }
    }
  }
  .sidebar-footer{
    width: 70px;
    padding-left: 16px;
    padding-right: 16px;
    a{
      i{
        margin-right: 0;
        margin-left: 8.0rem;
      }
      span{
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}

@media only screen and (max-width: 767px) {  
  .sidebar{
    display: none;
  }
}
</style>