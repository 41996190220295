<template>
  <Loading v-model:active="loading" :can-cancel="false"/>
  <div class="page-header">
    <div class="title-wrapper">
      <div class="title">Batches / Targets</div>
      <div class="subtitle">All information about the batches & targets of Dartshopper</div>
    </div>
  </div>

  <!-- Date picker and search -->
    <div class="date-picker">
      <div :class="{ 'datepicker-customSelector': true, 'active': datePreset === ''}">
        <Datepicker 
          v-model="date" 
          @update:modelValue="handleDate"
          range 
          weekNumbers
          :week-picker="true"
          :enableTimePicker="false" 
          locale="nl" 
          placeholder="Custom..." 
          cancelText="cancel" 
          selectText="apply" 
          :maxDate="new Date()"
          :minDate="new Date(2023, 3, 6)"
          position="right"
          autoPosition
        />
      </div>
    </div>

  <div v-if="!loading" class="table-container">
    
  </div>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import Datepicker from '@vuepic/vue-datepicker';

import Page from '@/components/Page.vue'
import TabsWrapperWorking from '@/components/TabsWrapperWorking.vue' 
import Tab from '@/components/Tab.vue'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import '@vuepic/vue-datepicker/dist/main.css';

import { LineChart, useLineChart } from 'vue-chart-3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import * as XLSX from 'xlsx'

import { find } from "country-flags-svg/dist/data/countries";

import { getFilteredOutEmployees, getFilteredOutEmployeesIds} from '@/functions/Employee';

export default {
  components: { Page, Datepicker, TabsWrapperWorking, Tab, LineChart, Datepicker, Loading},
  name: "workersOverview",
  data(){
    return{
      gradient: "",
    }
  },
  setup() {
    const user = ref({});
    const datePreset = ref(null);
    const liveWorkers = ref([]);
    const totalOpen = ref({});
    const users = ref([]);
    const workers = ref([]);
    const router = useRouter()
    const route = useRoute();
    const searchWorkerEntry = ref("");

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
    
    Chart.register(...registerables);
    Chart.register(annotationPlugin);
    
    const date = ref([]);
    const startdate = ref("");
    const enddate = ref("");
    const loading = ref(true);
    const error = ref(null);
    const isPacker = ref(false);
    const pickersFilterOut = ref([]);
    const pickersFilterOutIds = ref([]);

    const setDateInCookie = (date) => {
      cookies.set("date", JSON.stringify(date));
    }

    // For demo purposes assign range from the current date
    onMounted( async () => {
      user.value = cookies.get('token');
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];
      
      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      if("startDate" in route.query)
      {
        startDate = route.query.startDate;
      }

      if("endDate" in route.query)
      {
        endDate = route.query.endDate;
      }

      date.value = date;
      setDateInCookie(date.value);
      await getProducts();

      pickersFilterOut.value = await getFilteredOutEmployees();
      pickersFilterOutIds.value = await getFilteredOutEmployeesIds();
      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }
      await updateQueryParameters(query);
    })

    const changeDatePreset = async () => {
      setDateInCookie(date);

      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      const query = {
        startDate: startdate.value,
        endDate: enddate.value,
      }

      await updateQueryParameters(query);

      hideDatepickerDropdown();
    }

    // Updates query parameters, make sure this happens after all cookies have been altered.
    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;
      loading.value = false;
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }

    }

    const handleDate = (modelData) => {
      date.value = modelData;
      
      startdate.value = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value =  moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      

      const query = {
        startDate: startdate.value,
        endDate: enddate.value,
      }
      updateQueryParameters(query);
      
      hideDatepickerDropdown();
    }

    const calculateTarget = (orderData) => {
      const locationsKlein = new Set(["A", "B", "C", "D", "E"]);
      const locationsGroot = new Set(["G", "H", "I", "J", "K", "L", "M", "N"]);

      const locationsFKlein = new Set(["F.01", "F.02", "F.03", "F.04", "F.05", "F.06", "F.07", "F.08", "F.09", "F.10", "F.11", "F.12"]);
      const locationsFGroot = new Set(["F.13", "F.14", "F.15", "F.16", "F.17", "F.18", "F.19", "F.20", "F.21", "F.22"]);

      let actualAmountKleinUniek = 0;
      let actualAmountKleinTotal = 0;
      let actualAmountGrootUniek = 0;
      let actualAmountGrootTotal = 0;

      for (const product of orderData.data.products) {
          if (product.has_parts !== true && product.stocklocation !== null) {
              const stockLocation = product.stocklocation;
              const [letterCode, letterCodeWithDot] = stockLocation.split(".");

              if (stockLocation.startsWith('F')) {
                  if (locationsFKlein.has(stockLocation.substring(0, 4))) {
                      actualAmountKleinUniek += 1;
                      actualAmountKleinTotal += 1 * product.amount;
                  } else if (locationsFGroot.has(stockLocation.substring(0, 4))) {
                      actualAmountGrootUniek += 1;
                      actualAmountGrootTotal += 1 * product.amount;
                  }
              } else if (locationsKlein.has(letterCode) || locationsGroot.has(letterCode)) {
                  const locationsSet = locationsKlein.has(letterCode) ? locationsKlein : locationsGroot;

                  actualAmountKleinUniek += 1;
                  actualAmountKleinTotal += 1 * product.amount;
              } else if (locationsKlein.has(letterCodeWithDot) || locationsGroot.has(letterCodeWithDot)) {
                  const locationsSet = locationsKlein.has(letterCodeWithDot) ? locationsKlein : locationsGroot;

                  actualAmountGrootUniek += 1;
                  actualAmountGrootTotal += 1 * product.amount;
              }
          }
      }

      return [
        actualAmountKleinUniek,
        actualAmountKleinTotal,
        actualAmountGrootUniek,
        actualAmountGrootTotal
      ]
    }

    const getProducts = async () => {
      try {
        const array = JSON.parse(cookies.get("date"));
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getActivePickers?startDate=" + startdate.value + "&endDate=" + enddate.value, { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();
        return result;
      } catch (error) {

        return error;
      } 
    }

    return { liveWorkers, loading, error, date, startdate, enddate, changeDatePreset, handleDate, hideDatepickerDropdown, users, getProducts, totalOpen, searchWorkerEntry, pickersFilterOutIds};
  },
  data() {
    return{
      currentPackerId: "",
      pickers: [],
      errorMessage: "",
      showError: false
    }
  },
  methods: {
    close () {
      var legendPopup = document.querySelectorAll(".legend-popup");
      var legendOverlay = document.querySelectorAll(".legend-overlay");

      legendPopup[0].classList.remove("show");
      legendOverlay[0].classList.remove("show");
    },
    open () {
      var legendPopup = document.querySelectorAll(".legend-popup");
      var legendOverlay = document.querySelectorAll(".legend-overlay");

      legendPopup[0].classList.add("show");
      legendOverlay[0].classList.add("show");
    },
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    hideDatepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
  },
  async created() {
  }
}
</script>
<style lang="scss" scoped>

.date-picker{
    padding: 1.6rem;
    margin-left: 25rem;
    width: calc(25% - 1.6rem);
    border: 1px solid #D7DCE1;
    background: #FFFFFF;
    border-radius: $border-radius;
    .btn-wrapper {
      display: flex;
      a {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        cursor: pointer;
        height: 40px;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
        &:hover {
          background-color: rgba(245, 246, 248, 0.55)
        }
      }
      a.active {
        background-color: #0B212D;
        color: #ffffff;
        border: none;
      }
    }
    .datepicker-customSelector {
      input {
        height: 40px;
      }
    }
  }



  .title {
    font-size: 18px;
    padding: 24px;
    padding-bottom: 0;
    font-family: "Neutrif-bold";
  }
  .packer-ul {
    border-bottom: 1px solid #D7DCE1;
  }
  ul {
    list-style: none;
    padding: 24px;
    padding-top: 12px;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        padding-top: 2px;
      }
      .circle {
        width: 10px;
        height: 10px;
        background: red;
        border-radius: 100%;
        margin-right: 8px;
      }
    }
    li:nth-child(1) .circle { background: #0ABB87; }
    li:nth-child(2) .circle { background: #FFB822; }
    li:nth-child(3) .circle { background: #5578EB; }
    li:nth-child(4) .circle { background: #FF4A4A; }
    li:nth-child(5) .circle { background: #F199FF; }
    li:nth-child(6) .circle { background: #0B212D; }
  }

.date {
  font-weight: 400 !important;
}.export-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  .info-wrapper {
    font-size: 18px;
    font-family: "Neutrif-bold";
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border-radius: $border-radius;
    border: 1px solid #D7DCE1;
    padding: 0 20px;
    font-size: 1.6rem;
    i {
      font-size: 20px;
    }
    span {
      padding-top: 2px;
    }
  }
}

.table-container{
  overflow-y: hidden;
  width: 100%;
  .table-block {
    border-radius: $border-radius;
    background: white;
    border: 1px solid #D7DCE1;
    margin-bottom: 3.2rem;
    .table-header {
      padding: 3.2rem;
      border-bottom: 1px solid #D7DCE1;
      background: #2E303F;
      border-radius: 6px 6px 0 0;
      color: white;
      display: flex;
      justify-content: center;
      .info {
        display: flex;
        padding-top: 4px;
        span {
          margin-right: 1.6rem;
        }
        i {
          margin-right: 0.64rem;
        }
        &:last-child {
          span {
            margin-right: 0;
          }
        }
      }
    }
  }
  .table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .table-striped{
    thead{
      width: 100%;
      tr{
        width: 100%;
        display: flex;
        justify-content: space-between;
        th{
          border-bottom: 1px solid #D7DCE1;
          text-align: left;
          padding: 2.4rem 3.0rem;
          color: #9397A1;
          font-size: 14px;
          font-weight: 400;
        }
        .name {
          min-width: 240px;
          max-width: 240px;
        }
        .packed {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
        }
        .picked {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
        }
        .link {
          min-width: 72px;
          max-width: 72px;
        }
      }
    }
    tbody{
      width: 100%;
      tr{
        width: 100%;
        border-bottom: 1px solid #D7DCE1;
        display: flex;
        justify-content: space-between;
        .name {
          min-width: 240px;
          max-width: 240px;
        }
        .picked {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
          width: 10px;
          display: flex;
          align-items: center;
          .info-wrapper{
            width: 100%; 
            display: flex;
            .info:hover .tooltiptext{
              visibility: visible;
              transform: scale(1);
              opacity: 1;
              top: -150%;
            }
            .info {
              border: 1px solid #D7DCE1;
              color: #9397A1;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 24px;
              white-space: nowrap;
              height: fit-content;
              position: relative;
              .tooltiptext {
                position: absolute;
                top: -75%;
                visibility: hidden;
                width: auto; 
                background-color: #FFFFFF;
                border: 1px solid #D7DCE1;
                padding: 0 1.6rem;
                z-index: 1;
                height: 32px;
                border-radius: 5px;
                display: flex; 
                justify-content: center;
                align-items: center;
                transform: scale(0.4);
                transition: .2s ease;
                opacity: 0;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                &:before {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -20px;
                  width: 0;
                  height: 0;
                  border: 10px solid transparent;
                  border-top-color: #D7DCE1;
                }
                &:after {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -18px;
                  width: 0;
                  height: 0;
                  border: 9px solid transparent;
                  border-top-color: white;
                }
              }
              span {
                color: #0B212D;
                font-size: 20px;
                font-family: "Neutrif-bold";
                position: relative;
                width: 60px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before { 
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 0 0 5px 5px;
                }
              }
              span.bbbu::before { background: #0ABB87; }
              span.bbsu::before { background: #FFB822; }
              span.bbbt::before{ background: #5578EB; }
              span.bbst::before { background: #FF4A4A; }
              span.sbu::before { background: #F199FF; }
              span.sbt::before { background: #0B212D; }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .packed {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
          width: 10px;
          display: flex;
          align-items: center;
          .info-wrapper{
            width: 100%; 
            display: flex;
            .info:hover .tooltiptext{
              visibility: visible;
              transform: scale(1);
              opacity: 1;
              top: -150%;
            }
            .info {
              border: 1px solid #D7DCE1;
              color: #9397A1;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 24px;
              white-space: nowrap;
              height: fit-content;
              position: relative;
              .tooltiptext {
                position: absolute;
                top: -75%;
                visibility: hidden;
                width: auto; 
                background-color: #FFFFFF;
                border: 1px solid #D7DCE1;
                padding: 0 1.6rem;
                z-index: 1;
                height: 32px;
                border-radius: 5px;
                display: flex; 
                justify-content: center;
                align-items: center;
                transform: scale(0.4);
                transition: .2s ease;
                opacity: 0;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                &:before {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -20px;
                  width: 0;
                  height: 0;
                  border: 10px solid transparent;
                  border-top-color: #D7DCE1;
                }
                &:after {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -18px;
                  width: 0;
                  height: 0;
                  border: 9px solid transparent;
                  border-top-color: white;
                }
              }
              span {
                color: #0B212D;
                font-size: 20px;
                font-family: "Neutrif-bold";
                position: relative;
                width: 60px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before { 
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 0 0 5px 5px;
                }
              }
              span.bc::before { background: #0ABB87; }
              span.sc::before { background: #FFB822; }
              span.bs::before{ background: #5578EB; }
              span.ss::before { background: #FF4A4A; }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .link {
          min-width: 72px;
          max-width: 72px;
          i {
            color: #0B212D;
            font-size: 32px;
          }
        }
        td, th{
          text-align: left;
          padding: 2.4rem 3.0rem;
          font-size: 14px;
          font-weight: 700;
          border-right: 1px solid #D7DCE1;
          height: 72px;
          &:last-child {
            border-right: 0;
          }
          a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              transition: all 0.3s ease;
            }
          }
          a.greyed-out {
            border: 2px solid #9397A1;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #9397A1;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            pointer-events: none;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                fill: #9397A1;
                transition: all 0.3s ease;
              }
            }
          }
        }
        &:last-child td, &:last-child th {
          border-bottom: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) { }
</style>
