<template>
  <div v-click-outside="{ exclude: ['exclude_mobile_nav_btn'], handler: hideShopDropdown }" class="mobile-nav">

    <div class="logo-details-mobile">
      <router-link to="/">
        <img src="@/assets/img/logo-lettermark-white.svg" alt="logo-lettermark-white">
      </router-link>
      <i @click="close" class='bx bx-x'></i>
    </div>

    <ul v-if="userIsAdmin" class="nav-links-mobile">
      <li>
        <router-link to="/" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bx-grid-alt' ></i>
            <span class="link_name">Dashboard</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Dashboard</a></li>
        </ul>
      </li>
      <!-- <div class="cat-section">
        <span>Statistics</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div>
      <li>
        <div class="iocn-link-mobile" v-bind:class="{ 'activeMobile': ['/sales/overview', '/sales/sales-by-product'].includes($route.path) }">
          <a>
            <i class='bx bx-money'></i>
            <span class="link_name">Sales</span>
          </a>
          <i class='bx bxs-chevron-down arrow'></i>
        </div>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile">
          <li><a class="link_name" href="#">Sales</a></li>
          <li><router-link to="/sales/overview" class="submenu-routerlink" exact>Overview</router-link></li>
          <li><router-link to="/sales/sales-by-product" class="submenu-routerlink" exact>Sales by product</router-link></li>
        </ul>
      </li> -->
      <li>
        <div class="iocn-link-mobile" v-bind:class="{ 'activeMobile': ['/orders/overview', '/orders/orders'].includes($route.path) }">
          <a>
            <i class='bx bx-grid' ></i>
            <span class="link_name">Orders</span>
          </a>
          <i class='bx bxs-chevron-down arrow'></i>
        </div>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile">
          <li><a class="link_name" href="#">Orders</a></li>
          <li><router-link to="/orders/overview" class="submenu-routerlink" exact>Overview</router-link></li>
          <li><router-link to="/orders/orders" class="submenu-routerlink" exact>Orders</router-link></li>
        </ul>
      </li>
      <div class="cat-section">
        <span>Reports</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div>
      <li>
        <router-link to="/workers" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-user'></i>
            <span class="link_name">Live workers</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Live workers</a></li>
        </ul>
      </li>
      <li>
        <router-link to="/packers" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-user'></i>
            <span class="link_name">Packers</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Packers</a></li>
        </ul>
      </li>
      <li>
        <router-link to="/pickers" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-user'></i>
            <span class="link_name">Pickers</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Pickers</a></li>
        </ul>
      </li>
      <div class="cat-section">
        <span>Warehouse</span>
        <i class='bx bx-dots-horizontal-rounded'></i>
      </div>
      <!-- <li>
        <router-link to="/stock" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-component'></i>
            <span class="link_name">Stock</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Stock</a></li>
        </ul>
      </li> -->
      <li>
        <router-link to="/status" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-battery-low'></i>
            <span class="link_name">Status</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Status</a></li>
        </ul>
      </li>
      <li>
        <router-link to="/printing" tag="div" class="iocn-link-mobile">
          <a>
            <i class='bx bxs-battery-low'></i>
            <span class="link_name">Printing</span>
          </a>
        </router-link>
        <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile blank">
          <li><a class="link_name" href="#">Overview</a></li>
        </ul>
      </li>
    </ul>

    <div v-else class="nav-links-mobile">
      <ul>
        <div class="cat-section">
          <span>Reports</span>
          <i class='bx bx-dots-horizontal-rounded'></i>
        </div>
        <li>
          <div class="iocn-link-mobile" v-bind:class="{ 'activeMobile': ['/pickers/overview', '/pickers/individual-report'].includes($route.path) }">
            <a>
              <i class='bx bxs-hand-up'></i>
              <span class="link_name">Pickers</span>
            </a>
            <i class='bx bxs-chevron-down arrow'></i>
          </div>
          <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile">
            <li><a class="link_name" href="#">Pickers</a></li>
            <li><router-link to="/pickers/overview" class="submenu-routerlink" exact>Overview</router-link></li>
            <li><router-link to="/pickers/individual-report" class="submenu-routerlink" exact>Individual Report</router-link></li>
          </ul>
        </li>
        <li>
          <div class="iocn-link-mobile" v-bind:class="{ 'activeMobile': ['/packers/overview', '/packers/individual-report'].includes($route.path) }">
            <a>
              <i class='bx bxs-package'></i>
              <span class="link_name">Packers</span>
            </a>
            <i class='bx bxs-chevron-down arrow'></i>
          </div>
          <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile">
            <li><a class="link_name" href="#">Packers</a></li>
            <li><router-link to="/packers/overview" class="submenu-routerlink" exact>Overview</router-link></li>
            <li><router-link to="/packers/individual-report" class="submenu-routerlink" exact>Individual Report</router-link></li>
          </ul>
        </li>
        <li>
          <div class="iocn-link-mobile" v-bind:class="{ 'activeMobile': ['/bookers/overview', '/bookers/individual-report'].includes($route.path) }">
            <a>
              <i class='bx bxs-book-add' ></i>
              <span class="link_name">Bookers</span>
            </a>
            <i class='bx bxs-chevron-down arrow'></i>
          </div>
          <ul v-bind:style="{ maxHeight: computedHeightMobile }" class="sub-menu-mobile">
            <li><a class="link_name" href="#">Bookers</a></li>
            <li><router-link to="/bookers/overview" class="submenu-routerlink" exact>Overview</router-link></li>
            <li><router-link to="/bookers/individual-report" class="submenu-routerlink" exact>Individual Report</router-link></li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="nav-footer-mobile">
      <router-link to="/dashboard-warehouse" class=""><i class='bx bx-link-external'></i><span>Dashboard</span></router-link>
    </div>

  </div>
</template>

<script>
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ref, onMounted } from 'vue'

export default {
  data(){
    return{
      maxHeight: null
    }
  },
  watch: {
    '$route' () {
      var dropdown = document.querySelectorAll(".mobile-nav");

      if (dropdown.length > 0) {
        dropdown[0].classList.remove("active");
      }
    }
  },
  computed: {
    computedHeightMobile: function () {
      return this.maxHeight;
    }
  },
  methods: {
    removeOpenTabs: function(event) {
      this.maxHeight = null;

      let removeShowmenu = document.querySelectorAll(".iocn-link-mobile");
      if (removeShowmenu.length > 0) {
        removeShowmenu[0].classList.remove("showMenu-mobile");
      }
    },
    activeHeaderMobile(to, from){
      var activeHeader = document.querySelectorAll(".iocn-link-mobile.activeMobile");

      if(activeHeader.length > 0) {
        var test = activeHeader[0].nextElementSibling;
        test.style.maxHeight = test.scrollHeight + "px";
        activeHeader[0].classList.add("showMenu-mobile");
      }
    },
    closeOtherSubmenuIfOtherIsOpenedMobile(){
      let arrow = document.querySelectorAll(".iocn-link-mobile");

      for (var i = 0; i < arrow.length; i++) {
        arrow[i].onclick = function(e) {

          var panel = this.nextElementSibling;
          var coursePanel = document.querySelectorAll(".sub-menu-mobile");
          var courseAccordionActive = document.getElementsByClassName(".nav-links-mobile li .showMenu-mobile");
          var header = document.querySelectorAll(".iocn-link-mobile");

          if (panel.style.maxHeight){
            panel.style.maxHeight = null;
            this.classList.remove("showMenu-mobile");
          } else { 
            for (var ii = 0; ii < courseAccordionActive.length; ii++) {
              courseAccordionActive[ii].classList.remove("showMenu-mobile");
            }
            for (var iii = 0; iii < coursePanel.length; iii++) {
              header[iii].classList.remove("showMenu-mobile");
              coursePanel[iii].style.maxHeight = null;
            }
            panel.style.maxHeight = panel.scrollHeight + "px";
            this.classList.add("showMenu-mobile");
          } 
        }
      }
    },
    hideShopDropdown(){
      var dropdown = document.querySelectorAll(".mobile-nav");

      if (dropdown.length > 0) {
        dropdown[0].classList.remove("active");
      }
    },
    close(){
      var mobileNav = document.querySelectorAll(".mobile-nav");

      if (mobileNav.length > 0) {
        mobileNav[0].classList.toggle("active");
      }
    }
  },
  mounted() {
    this.closeOtherSubmenuIfOtherIsOpenedMobile();
  },
  updated() {
    this.activeHeaderMobile("", "");
  },
  setup() {
    const userIsAdmin = ref(false);
    const user = ref({});

    onMounted(() => {
      user.value = cookies.get('token');
      
      if(user?._value !== null) {
        if(user?._value?.role === 2) {
          userIsAdmin.value = true;
        }
      }
    })

    return { user, userIsAdmin }
  }
}
</script>

<style lang="scss" scoped>
.mobile-nav{
  display: none;
  position: fixed;
  height: 100%;
  z-index: 3;
  width: 240px;
  min-height: 100%;
  height: 100%;
  background-color: #2E303F;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease-in-out;
  padding: 0 0.75rem;
  left: -240px;
  opacity: 0;
  .logo-details-mobile{
    height: 3.75rem;
    padding-right: 1rem;
    padding-left: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    a{
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      img{
        transition: all 0.3s ease;
      }
    }
    i{
      cursor: pointer;
      transition: all 0.3s ease;
      font-size: 30px;
      color: white;
      text-align: center;
    }
  }
  .nav-links-mobile{
    padding: 16px 0 16px 0;
    height: auto;
    max-height: calc(100% - 132px);
    overflow: auto;
    .cat-section{
      display: flex;
      margin-top: 2rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
      span{
        opacity: 1;
        font-family: "Neutrif-bold";
        text-transform: uppercase;
        color: #494B59;
      }
      i{
        opacity: 0;
        color: #494B59;
        font-size: 20px;
      }
    }
    li{
      position: relative;
      list-style: none;
      transition: all 0.3s ease;
      cursor: pointer;
      margin: 0rem 0 1rem 0; 
      .router-link-exact-active {
        background: #1F1D2C;
        border-radius: 5px;
        i{
          color: #3AAFE3 !important;
        }
        span{
          color: #fff !important;
        }
      }
      a{
        display: flex;
        align-items: center;
        text-decoration: none;
        .link_name{
          font-size: 16px;
          font-family: "Neutrif-semiBold";
          color: #9397A1;
          transition: all 0.4s ease;
          margin-left: 1rem;
        }
      }
      .showMenu-mobile{
        i.arrow{
          transform: rotate(-180deg);
        }
      }
      .sub-menu-mobile{
        padding: 0px 1rem 0px calc(2rem + 20px);
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out;
        .router-link-exact-active{
          background: unset;
          color: #3AAFE3 !important;
        }
        li{
          margin: 0rem 0 0.5rem 0;
        }
        a{
          color: #9397A1;
          font-size: 16px;
          padding: 5px 0;
          white-space: nowrap;
          transition: all 0.3s ease;
          font-family: "Neutrif-semiBold";
          &:hover{
            color: #fff;
          }
        }
        .link_name{
          display: none;
        }
      }
      .sub-menu-mobile.blank{
        opacity: 1;
        pointer-events: auto;
        padding: 0px 20px 0px 16px;
        opacity: 0;
        pointer-events: none;
      }
      .iocn-link-mobile{
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s ease;
        border-radius: $border-radius;
        padding: 0 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:hover{
          background: #1F1D2C;
        }
        i{
          width: 20px;
          text-align: center;
          color: #494B59;
          font-size: 20px;
          cursor: pointer;
          transition: all 0.3s ease;
        }
      }
      .iocn-link-mobile.activeMobile{
        background: #1F1D2C;
        i{
          color: #fff !important;
        }
        a{
          i{
            color: #3AAFE3 !important;
          }
        }
        span{
          color: #fff !important;
        }
      }
      .sub-menu-mobile.blank{
        li{
          margin: 0;
        }
      }
    }
  }
  .nav-links-mobile::-webkit-scrollbar{
    display: none;
  }
  .nav-footer-mobile{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    padding: 1rem;
    box-shadow: 0 -10px 10px 0 rgba(31, 29, 44, 0.50);
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: $border-radius;
      text-align: center;
      font-family: "Neutrif-bold";
      height: 2.5rem;
      text-decoration: none;
      background-color: #3AAFE3;
      color: white;
      font-weight: bold;
      border: none;
      z-index: 1;
      transition: all 0.3s ease;
      position: relative;
      outline: none;
      &:after{
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #fff;
        border-radius: 5px;
        transition: all 0.3s ease;
      }
      &:hover{
        color: #0B212D;
        &:after{
          left: 0; 
          width: 100%;
        }
      }
      &:active{
        top: 2px;
      }
      i{
        margin-right: 0.5rem;
        font-size: 20px;
        transition: all 0.3s ease;
      }
      span{
        transition: all 0.3s ease;
      }
    }
  }
}

.mobile-nav .iocn-link-mobile.showMenu-mobile {
  background: #1F1D2C;
  .link_name{
    color: #fff;
  }
}

.mobile-nav.active{
  left: 0;
  opacity: 1;
}

@media only screen and (max-width: 767px) {  
  .mobile-nav{
    display: block;
  }
}
</style>