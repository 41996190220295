<template>
  <div class="settings">
    <div class="settings-header">
      <div class="title">Theme</div>
      <div class="sub-title">Brand & darkmode</div>
    </div>
    <div class="settings-body">
      Mode
    </div>
  </div>
</template>
  
<script>
  import Page from '@/components/Page.vue'
  
  import VueMultiselect from 'vue-multiselect'
  
  export default {
    name: "Settings Theme",
    components: { Page, VueMultiselect },
    data() {
      return {
  
      }
    },
  }
  </script>