<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M7.42 16.58l9.17-9.17m0 0H9.73m6.86 0v6.86" stroke-linecap="round" stroke="#ff551f" stroke-width="1.5" />
    <circle cx="12" cy="12" r="11.25" fill="none" stroke="#ff551f" stroke-width="1.5" />
  </svg>
</template>
<script>
export default {
  name: 'IconStatus',
}
</script>