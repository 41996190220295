<template>
  <Page class="aaa">
    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Dartshopper Dashboard</div>
        <div class="subtitle">Welcome back to your dashboard</div>
      </div>
      <!-- Turned off untill we will continue working on this
        <div class="datepicker">
        <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
          <p>Today</p>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown">
          <div class="datepicker-card">
            <div class="title">
              Today
            </div> 
            <div class="subtitle">
              30 Nov. 2021 - 30 Nov. 2021
            </div>
          </div>
          <div class="datepicker-presets">
            <router-link @click="hideDatepickerDropdown" to="/">Today<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Yesterday<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 28 days<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 90 days<i class='bx bx-check'></i></router-link>
          </div>
          <div class="datepicker-customSelector">
            <Datepicker 
              v-model="date" 
              range 
              weekNumbers 
              :enableTimePicker="false" 
              locale="nl" 
              placeholder="Custom..." 
              textInput 
              cancelText="cancel" 
              selectText="apply" 
              :maxDate="new Date()"
              position="right"
            />
          </div>
        </div>
      </div> -->
    </div>

    <div class="cards first">
      <!-- <div class="card card-1">
        <TotalSales />
      </div>
      <div class="card card-2">
        <PicklistTotalToday />
      </div>
      <div class="card card-3">
        <HighestTotalPrice />
      </div> -->
      <div class="card card-4">
        <PicklistUrgentOpen />
      </div>
      <div class="card card-5">
        <PicklistTotalCompletedToday />
      </div>
      <div class="card card-6">
        <PicklistLeftToComplete />
      </div>
    </div>

    <div class="cards third">
      <div class="card card-1">
        <PicklistByShop />
      </div>
      <div class="card card-2">
        <PicklistByCountry />
      </div>
    </div>

    <div class="cards fourth">
      <div class="card card-1">
        <PicklistSalesByDomain />
      </div>
      <!-- <div class="card card-2">
      <PicklistByCountry />
      </div> -->
    </div>

  </Page>
</template>

<script>
import { ref, onMounted, onBeforeMount } from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import PicklistTotalToday from '@/components/dashboard/PicklistTotalToday.vue'
import PicklistUrgentOpen from '@/components/dashboard/PicklistUrgentOpen.vue'
import PicklistLeftToComplete from '@/components/dashboard/PicklistLeftToComplete.vue'
import PicklistTotalCompletedToday from '@/components/dashboard/PicklistTotalCompletedToday.vue'
import HighestTotalPrice from '@/components/dashboard/HighestTotalPrice.vue'
import TotalSales from '@/components/dashboard/TotalSales.vue'
import PicklistByShop from '@/components/dashboard/PicklistByShop.vue'
import PicklistByCountry from '@/components/dashboard/PicklistByCountry.vue'
import PicklistSalesByDomain from '@/components/dashboard/PicklistSalesByDomain.vue'
import Page from '@/components/Page.vue'
import getUser from '../composeables/getUser'
import { watch } from 'vue'
import { useRouter } from 'vue-router'
import { Chart, registerables } from 'chart.js';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export default {
  name: "dashboard",
  comments: false,
  components: { Page, PicklistTotalToday, PicklistTotalCompletedToday, PicklistByShop, PicklistByCountry, HighestTotalPrice, TotalSales, Datepicker, PicklistUrgentOpen, PicklistLeftToComplete, PicklistSalesByDomain },

  setup() {
    Chart.register(...registerables);

    const user = ref({});
    const router = useRouter()
    const date = ref();

    onBeforeMount(() => {
      user.value = cookies.get('token');
      // if(user.value.role === 1)
      // {
      //   router.push({path: '/working'});
      // }
    })

    // For demo purposes assign range from the current date
    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 0));
      date.value = [startDate, endDate];
    })
    // user
    watch(user, () => {
      if (!user.value) {
        router.push({ name: 'Login' })
      }
    })

    return {
      date, user
    }
  },
  data() {
    return {
      contents: null,
    }
  },
  methods: {
    hideDatepickerDropdown() {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown() {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    }
  },
  async mounted() {
    document.body.classList.add("dashboard-home");
    // const element = document.querySelector('.aaa');

    // if (element) {
    //   // Apply the style to the html element
    //   document.documentElement.style.fontSize = '100%';
    // }
  },
}
</script>

<style lang="scss">
.cards.first {
  .card {
    width: calc(100% / 3 - (6.4rem / 3));

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.cards.second {
  .card {
    width: calc(100% / 2 - (3.2rem / 2));

    &:nth-child(2) {
      margin-right: 0;
    }
  }
}

.cards.third {
  .card {
    width: calc(100% / 2 - (3.2rem / 2));
    height: 44.8rem;

    &:nth-child(2) {
      margin-right: 0;
    }
  }
}

.cards.fourth {
  .card {
    width: 100%;
    height: 32.0rem;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .cards.first {
    .card {
      width: 100%;
      margin-right: 0;
    }
  }

  .cards.second {
    .card {
      margin-right: 0;
      width: 100%;
    }
  }

  .cards.third {
    .card {
      margin-right: 0;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .cards.first {
    .card {
      margin-right: 1.6rem;
      width: calc(50% - 0.8rem);

      &:nth-child(2n) {
        margin-right: 0;
      }

      &:nth-child(3) {
        margin-right: 1.6rem;
      }
    }
  }

  .cards.second {
    .card {
      margin-right: 1.6rem;
      width: calc(50% - 0.8rem);

      &:nth-child(3) {
        margin-right: 1.6rem;
      }
    }
  }

  .cards.third {
    .card {
      margin-right: 1.6rem;
      width: calc(50% - 0.8rem);
    }
  }
}

@media only screen and (max-width: 600px) {
  .page-header {
    flex-direction: column;
    margin-bottom: 1.6rem;

    .title-wrapper {
      margin-bottom: 1.6rem;

      .title {
        font-size: 2.4rem;
      }
    }

    .datepicker {
      .dropdown {
        transform-origin: left top;
        left: 0;
      }
    }
  }

  .cards {
    .card {
      padding: 2.4rem;
    }
  }

  .cards.first {
    .card {
      width: 100%;
      margin-right: 0;
    }
  }

  .cards.second {
    .card {
      margin-right: 0;
      width: 100%;
    }
  }

  .cards.third {
    .card {
      margin-right: 0;
      width: 100%;
    }
  }
}
</style>