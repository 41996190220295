<template>
  <div style="margin-top: 1.6rem;">What <b>type</b> of data range would you like to compare?</div>
  <div class="block-1">
    <a :class="{ 'active': DayWeekOrMonth === 'Day'}" @click="changeDayWeekOrMonth">Day</a>
    <a :class="{ 'active': DayWeekOrMonth === 'Week'}" @click="changeDayWeekOrMonth">Week</a>
    <a :class="{ 'active': DayWeekOrMonth === 'Month'}" @click="changeDayWeekOrMonth">Month</a>
  </div>
  <div v-if="DayWeekOrMonth !== ''">
    <div style="margin-top: 1.6rem;">
      Choose the 
      <b>{{ DayWeekOrMonth === 'Month' ? 'months' : DayWeekOrMonth === 'Week' ? 'weeks' : 'days' }}</b> 
      you would like to compare
    </div>
    <div class="block-2">
      <div v-for="(date, index) in dateCompare" :key="index" style="margin-right: 0.8rem;">
        <div class="comparison" :style="{'border-color': date.color}">{{ date.custom }}</div>
      </div>
      <div class="datepicker-wrapper">
          <Datepicker 
                v-model="date" 
                @update:modelValue="handleDate"
                :disable-month-year-select="DayWeekOrMonth == 'Day'"
                :range="false"
                :max-range="DayWeekOrMonth !== 'Month' ? 0 : 7"
                weekNumbers 
                :enableTimePicker="false" 
                locale="nl"
                :month-picker="DayWeekOrMonth == 'Month'"
                :week-picker="DayWeekOrMonth == 'Week'"
                placeholder="Add date" 
                textInput 
                cancelText="cancel" 
                selectText="apply" 
                :maxDate="new Date()"
                :minDate="new Date(2023, 3, 6)"
                position="right"
                autoPosition
              />
            </div>
    </div>
    <!-- <template v-if="Object.keys(ordersPerDay).length !== 0"> 
      <div v-for="(order, index) in ordersPerDay" :key="index" style="display: flex; justify-content: space-between;">
          <template v-if="DayWeekOrMonth === 'Month'">
            <i class='bx bx-calendar'></i>
            <span>{{ new Date(order.updatedDay).toLocaleDateString('en-NL', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }}</span>
            <i class='bx bxs-user'></i>
            <span>{{ order.CountPerDay }} orders</span>
          </template>
      </div>
    </template> -->
  </div>
  <template v-if="DayWeekOrMonth !== '' && dateCompare.length > 0">
    <div v-if="DayWeekOrMonth == 'Week' | DayWeekOrMonth == 'Day'">
      <LineChart v-bind="lineChartProps" ref="lineRef" />
    </div>
    <div v-else>
      <BarChart v-bind="barChartProps" ref="barChartRef" />
    </div>
  </template>
  <div>
  <!-- <template  v-if="DayWeekOrMonth !== '' && Object.keys(ordersPerDay).length !== 0"> 
    <div v-for="(order, index) in ordersPerDay" :key="index">
      <div  style="display: flex; justify-content: space-between;">
        <div>
          <i class='bx bx-calendar'></i>
          <span>{{ new Date(order.updatedDay).toLocaleDateString('en-NL', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }) }}</span>
        </div>
        <div>
          <i class='bx bxs-user'></i>
          <span>{{ order.CountPerDay }} orders</span>
        </div>
      </div>
    </div>
  </template> -->
  </div>
</template>

<script>

import { useRouter, useRoute } from 'vue-router'
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import moment from 'moment';

import { LineChart, useLineChart, BarChart, useBarChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import { getOrdersPerDay } from '@/functions/Orders';
import { getPicklistsAndOrdersPerHour } from '@/functions/Warehouse';

import Datepicker from '@vuepic/vue-datepicker';
import Loading from "vue-loading-overlay";
export default {
  components: { LineChart, Datepicker, Loading, BarChart },
  name: "orders-roadmap",
  data() {
    return{

    }
  },
  setup() {
    Chart.register(...registerables);

    const lineRef = ref(null);
    
    const router = useRouter()
    const route = useRoute();

    const datePreset = ref(null);
    const DayWeekOrMonth = ref('Day');
    const date = ref([]);
    const startdate = ref("");
    const enddate = ref("");
    const loading = ref(true);
    const ordersPerDay = ref([]);
    const dateCompare = ref([]);
    const perHourPerDay = ref([]);

    onMounted(async () => {
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];

      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      if("startDate" in route.query) {
        startDate = route.query.startDate;
      }
      else {
        startDate = startdate.value;
      }

      if("endDate" in route.query) {
        endDate = route.query.endDate;
      }
      else {
        endDate = enddate.value;
      }

      if("datePreset" in route.query)
      {
        date = setDatePreset(route.query.datePreset);
      }
      
      ordersPerDay.value = await getOrdersPerDay(startDate, endDate);
    })

    const setDatePreset = (text) => {
      let startDate = new Date();
      let endDate = new Date(new Date().setDate(startDate.getDate() + 0));

      // if(text === "Today")
      // {
      //   endDate = moment(startDate).endOf('day');
      // }
      // if(text === "Yesterday")
      // {
      //   startDate = new Date(new Date().setDate(startDate.getDate() - 1));
      //   startDate = moment(startDate).startOf('day');
      //   endDate = moment(startDate).endOf('day');
      // }
      if(text === "Last week")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 7))).startOf('day');
      }
      else if(text === "Last month")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 28))).startOf('day');
      }
      // else if(text === "Last 3 months")
      // {
      //   startDate = moment(new Date(new Date().setDate(startDate.getDate() - 90))).startOf('day');
      // }
      
      datePreset.value = text;

      return [startDate, endDate];
    }

    const changeDatePreset = async () => {
      let date = setDatePreset(event.target.innerText);

      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      const query = {
        startDate: startdate.value,
        endDate: enddate.value,
      }

      await updateQueryParameters(query);

      hideDatepickerDropdown();
    }

    const changeDayWeekOrMonth = async (event) => {

      // Making date not null so it doesn't become undefined
      date.value = [startdate.value, enddate.value];

      if(DayWeekOrMonth.value !== event.target.innerText) {
        dateCompare.value = [];
        date.value = [];

        DayWeekOrMonth.value = event.target.innerText;

        startdate.value = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        enddate.value =  moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

        hideDatepickerDropdown();
      }
      
    }

    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;

      // If day, get per hour, same as in the warehouse-dashboard
      console.log(DayWeekOrMonth.value);
      if(DayWeekOrMonth.value === 'Day') // Fill per hour day graph if only 1 day
      {
        console.log('dayy');
        return perHourPerDay.value = await getPicklistsAndOrdersPerHour(query.startDate);
      } else { // Else show per month bar chart or week chart
        return ordersPerDay.value = await getOrdersPerDay(query.startDate, query.endDate, DayWeekOrMonth.value.toLowerCase(), query.month);
      }
    }

    const handleDate = async (modelData) => {
      
      let data = {};
      let start = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      let end = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      let identifierDate = "";

      let dateToConvert = moment(DayWeekOrMonth.value == 'Week' ? modelData[0] : DayWeekOrMonth.value === 'Month' ? moment().month(modelData.month) : modelData, "YYYY-MM-DD");
      let lowerCasedTypeDate = DayWeekOrMonth.value.toLowerCase();


      // Calculate week number here or month
      identifierDate = lowerCasedTypeDate == 'week' ? moment(modelData[0]).week() : lowerCasedTypeDate == 'month' ? dateToConvert : dateToConvert.format('D');

      date.value = modelData;

      if(lowerCasedTypeDate == 'day') {
        start = moment(modelData).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment(modelData).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        identifierDate = new Date(modelData).toLocaleDateString('en-NL', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
      }
      else if(lowerCasedTypeDate == 'week') {
        identifierDate = 'Week ' + identifierDate;
        start = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      }
      else if(lowerCasedTypeDate == 'month') {
        start = moment(modelData[0]).startOf('month').format('YYYY-MM-DD HH:mm:ss').toString();
        end = moment(modelData[0]).endOf('month').format('YYYY-MM-DD HH:mm:ss').toString();
        identifierDate = new Date(modelData).toLocaleDateString('en-NL', { year: "numeric", month: "long"})
      }

      let queryDateString = DayWeekOrMonth.value.toLowerCase();
      startdate.value = start;
      enddate.value = end;

      if(DayWeekOrMonth.value == 'Month') {
        identifierDate = new Date(moment().month(modelData.month)).toLocaleDateString('en-NL', { year: "numeric", month: "long"})
      }
      
      const query = {
        startDate: start,
        endDate: end,
        perDate: queryDateString,
        month: identifierDate
      }
      
      if(!dateCompare.value.find(x => x.custom == identifierDate)) {
        let orders = await updateQueryParameters(query);
        loading.value = false;

        if(DayWeekOrMonth.value == 'Month') {
          data = {
            type: DayWeekOrMonth,
            custom: identifierDate,
            date: modelData,
            data: orders.map(x => x.CountPerDay),
            color: GenerateRandomColor()
          }
          console.log(data);
        } else if(DayWeekOrMonth.value == 'Week') {
          data = {
              type: DayWeekOrMonth,
              custom: identifierDate,
              date: modelData,
              data: orders.map(x => x.CountPerDay),
              color: GenerateRandomColor()
          }
        } else if(DayWeekOrMonth.value == 'Day') {
          data = {
              type: DayWeekOrMonth,
              custom: identifierDate,
              date: modelData,
              data: orders.map(x => x.OrdersPerHour),
              color: GenerateRandomColor()
          }
        }
        dateCompare.value.push(data)
      }

      hideDatepickerDropdown();
    }

    const hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const options = DayWeekOrMonth.value === 'Day' ?
    ref({
      interaction: {
        intersect: false,
        mode: 'index',
      },
      layout: {
        padding: {
          bottom: 16,
          top: 32,
          right: 0,
          left: 0
        }
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(82,82,82,0.25)', 
            display: true,
          },
          gridLines: {
            color: 'rgba(82,82,82,1)', 
            display: true,
          },
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            },
          },
        },
        y: {
          grid: {
            color: 'rgba(82,82,82,0.25)',
          },
          beginAtZero: true,
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: '#FFFFFF',
          backgroundColor: "#929BA0",
          borderRadius: 6,
          offset: 10,
          align: 'end',
          anchor: 'end',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 5,
            top: 5,
            right: 10,
            left: 10
          }
        }
      },
      maintainAspectRatio: false,
    })
    : ref({
      interaction: {
        intersect: false,
        mode: 'index',
      },
      layout: {
        padding: {
          bottom: 16,
          top: 32,
          right: 0,
          left: 0
        }
      },
      scales: {
        x: {
          grid: {
            color: 'rgba(82,82,82,0.25)', 
            display: true,
          },
          gridLines: {
            color: 'rgba(82,82,82,1)', 
            display: true,
          },
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            },
          },
        },
        y: {
          grid: {
            color: 'rgba(82,82,82,0.25)',
          },
          beginAtZero: true,
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          color: '#FFFFFF',
          backgroundColor: "#929BA0",
          borderRadius: 6,
          offset: 10,
          align: 'end',
          anchor: 'end',
          font: {
            weight: 'bold'
          },
          padding: {
            bottom: 5,
            top: 5,
            right: 10,
            left: 10
          }
        }
      },
      maintainAspectRatio: false,
    })

    const barData = computed(() => ({
      labels: ['Months'],
      showTooltips: false,
      datasets: dateCompare.value.map((x, index) => ({
        label: dateCompare.value[index].custom,
        barPercentage: 0.5,
          minBarLength: 2,
          backgroundColor: x.color,
          data: x.data
      }))        
      
    }));

    // Per Week
    const perWeekData = computed(() => ({
      labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      showTooltips: false,
      datasets: dateCompare.value.map((x => ({
          label: x.custom,
          data: JSON.parse(JSON.stringify(x.data)),
          // data: [5, 10, 15, 20, 10, 5, 1, 6, 2, 9, 10, 3, 6, 1, 6, 2, 7, 8, 2, 9, 21, 22, 56, 12],
          borderColor: x.color, 
          pointBackgroundColor: "#FFFFFF",
          fill: true,
          lineTension: 0.2,
          hoverBackgroundColor: '#FFFFFF',
        })))
    }));

    // Per hour, per day
    const perHourPerDayData = computed(() => ({
      labels: DayWeekOrMonth.value === 'Day' ? hours : days,
      showTooltips: false,
      datasets: dateCompare.value.map((x => ({
          label: x.custom,
          data: JSON.parse(JSON.stringify(x.data)),
          // data: [5, 10, 15, 20, 10, 5, 1, 6, 2, 9, 10, 3, 6, 1, 6, 2, 7, 8, 2, 9, 21, 22, 56, 12],
          borderColor: x.color, 
          pointBackgroundColor: "#FFFFFF",
          fill: true,
          lineTension: 0.2,
          hoverBackgroundColor: '#FFFFFF',
        })))
    }));

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: perHourPerDayData,
      options
    });

    const { barChartProps, barChartRef, update } = useBarChart({
      chartData: barData
    });

    const GenerateRandomColor = () => {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      return "#" + randomColor;
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }

    }

    return {date, startdate, enddate, loading, ordersPerDay, handleDate, changeDatePreset, datePreset, setDatePreset,
    lineChartProps, lineChartRef, lineRef, GenerateRandomColor, DayWeekOrMonth, changeDayWeekOrMonth, dateCompare,
    barChartProps, barChartRef, perHourPerDay }
  }
}
</script>
<style lang="scss" scoped>

.datepicker-wrapper {
  display: flex; 
  justify-content: space-between; 
  align-items: center;
}

.block-1 {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-evenly;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-top: 1.6rem;
}

.block-2 {
  background-color: #FFFFFF;
  display: flex;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  margin-top: 1.6rem;
}

.block-1 a {
  background-color: #D7DCE1;
  padding: 0.8rem;
  border-radius: 6px;
  border: 1px solid #D7DCE1;
  width: 100%;
  text-align: center;
  margin-right: 0.8rem;
}

.block-1 .active {
  border: 1px solid #0ABB87;
}

.block-2 .comparison {
  background-color: #D7DCE1;
  padding: 0.8rem;
  border-radius: 6px;
  border: 1px solid #D7DCE1;
  width: 100%;
  text-align: center;
  margin-right: 3.2rem; 
}

.employee-date-picker {
  display: flex;
  margin-bottom: 3.2rem;
  .employee-picker {
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    border: 1px solid #D7DCE1;
    background: #FFFFFF;
    margin-right: 3.2rem;
    border-radius: $border-radius;
    .btn-wrapper {
      display: flex;
      a {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        cursor: pointer;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        padding-top: 2px;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
        &:hover {
          background-color: rgba(245, 246, 248, 0.55);
        }
      }
      a.active {
        background-color: #0B212D;
        color: #ffffff;
        border: none;
      }
    }
    input {
      height: 40px;
      border-radius: $border-radius;
      border: 1px solid #D7DCE1;
      width: 100%;
      padding-left: 1.6rem;
      padding-top: 2px;
    }
  }
  .date-picker{
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    border: 1px solid #D7DCE1;
    background: #FFFFFF;
    border-radius: $border-radius;
    .btn-wrapper {
      display: flex;
      a {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        cursor: pointer;
        height: 40px;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
        &:hover {
          background-color: rgba(245, 246, 248, 0.55)
        }
      }
      a.active {
        background-color: #0B212D;
        color: #ffffff;
        border: none;
      }
    }
    .datepicker-customSelector {
      input {
        height: 40px;
      }
    }
  }
}
</style>
