<template>
  <div id="content">
    <div class="container">
      <div class="breadcrumb">
        <router-link to="/">

          <i class='bx bxs-home' ></i>

          <span>
            <i></i>
          </span> 

        </router-link>
        <router-link v-for="item in items" :key="item.path" :to="item.path">
          {{ item.meta.value }}
          <span>
            <i></i>
          </span>
        </router-link>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return{
      items:[],
    }
  },
  computed: {
    active: function (){
      if (this.items.length === 0){
        return true;
      }
      return false;
    }
  },
  watch: {
    $route(){
      this.getRoute();
    }
  },
  methods: {
    getRoute(){
      this.items = this.$route.matched;
    },
    removeLinkSecondChild(){
      var secondlink = document.querySelector(".breadcrumb a:nth-child(2)");
      var thirdLink = document.querySelector(".breadcrumb a:nth-child(3)");

      if(typeof(thirdLink) != 'undefined' && thirdLink != null){
        secondlink.classList.add("disable");
      }
    }
  },
  mounted(){
    this.removeLinkSecondChild();
  },
  created(){
    this.getRoute();
  }
}
</script>

<style lang="scss" scoped>
.dashboard-home{
  .breadcrumb{
    a{
      span{
        transition: all 1s ease;
        opacity: 0 !important;
      }
    }
  }
}
#content{
  .container{
    padding: 9.6rem 3.2rem;
    max-width: 105.6rem;
    margin: 0 auto;
    .breadcrumb{
      margin-bottom: 0.8rem;
      i{
        font-size: 20px;
      }
      a{
        transition: all 0.3s ease;
        color: #9397A1;
        &:hover{
          color: #0B212D;
        }
        span{
          position: relative;
          margin-right: calc(1.2rem + 20px);
          margin-left: 1.2rem;
          width: fit-content;
          opacity: 1; 
          transition: all 1s ease;
          i{
            width: 20px;
            position: absolute;
            border: 1px solid #D7DCE1;
            transform: rotate(115deg);
            top: 10px;
          }
        }
        &:last-child span{
          display: none;
        }
      }
      .router-link-exact-active{
        color: #0B212D;
        &:hover{
          text-decoration: underline;
        }
      }
      .disable{
        pointer-events: none;
      }
    }
  }
}
.sidebar ~ div #content{
  position: relative;
  width: calc(100% - 240px);
  height: 100vh;
  padding-top: 6.0rem;
  left: 240px;
  transition: all 0.3s ease;
}
.sidebar.close ~ div #content{
  left: 70px;
  width: calc(100% - 70px);
}

@media only screen and (max-width: 767px) {  
  #content{
    .container{
      padding: 0.0rem 1.6rem;
      padding-top: 3.2rem;
    }
  }
  .sidebar ~ div #content{
    width: 100%;
    left: unset;
  }
}
</style>