<template>
  <vue-final-modal style="height: 100%; overflow-y: scroll;" v-model="showModal" :esc-to-close="true" v-slot="{ close }" :click-to-close="true">
    <div class="pdf-modal-styling">

      <div class="modal-styling-wrapper">

        <i @click="close" class='bx bx-x close'></i>

        <div class="pdf-wrapper">
          <pdf v-if="showModal" :src="fullString" v-for="i in numPages" :key="i" :id="i" :page="i" :resize="false" :scale.sync="scale" style="width:100%; height: auto;">
            <template slot="loading">
              loading content here...
            </template>
          </pdf>

        </div>

        <div class="buttons">
          <div>
            <a class="ui active item">
              {{page}} / {{ numPages ? numPages : '∞' }}
            </a>
          </div>
          <div>
            <template v-if="base64">
              <a :download="currentIdorder_product + '.pdf'" :title="fullString" :href="fullString"><i class='bx bxs-download'></i></a>
            </template>
          </div>
        </div>

      </div>

    </div>
  </vue-final-modal>

  <vue-final-modal style="height: 100%;" v-model="showComments" :esc-to-close="true" v-slot="{ close }" :click-to-close="true">
    <div class="modal-styling">
      <div class="modal-header">
        <div class="name">
          <span>{{( user?.firstName + " " + user?.lastName)}}</span>
          <i @click="close" class='bx bx-x'></i>
        </div>
        <textarea aria-invalid="true" v-model="comment" placeholder="Write something here..."></textarea>
        <button class="place" @click="sendComment()" type="button" >Place comment</button>
      </div>
      <div class="modal-body">
        <div class="comment" v-for="comment in comments">
          <div class="avatar">
            EV
          </div>
          <div class="content">
            <div class="name">
              {{ comment.fullName === "undefined undefined" ? "Unknown user" : comment.fullName }}
              <span class="date time">{{ new Date(comment.created_at).toLocaleTimeString('en-NL', { hour: '2-digit', minute: '2-digit' }) }}</span>
              <span class="date">{{ new Date(comment.created_at).toLocaleDateString('en-NL', { year: 'numeric', month: 'short', day: 'numeric' }) }}</span>
            </div>
            <p class="description">{{ comment.description }}</p>
          </div>
        </div>
        <a :href='"https://dartshopper.picqer.com/orders/" + orderIdComments' target="_blank">Go to picqer</a>
      </div>
    </div>
  </vue-final-modal>
  
  <Page>
    <Loading v-model:active="loading" :can-cancel="false"/>
    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Printing order overview</div>
        <div class="subtitle">An order overview for personalised orders of Dartshopper</div>
      </div>
    </div>
    <div class="printing">

      <div class="printing-header">
        <div class="btn-wrapper">
          <div @click="goBack" class="back">
            <i class='bx bxs-arrow-from-right'></i>
            Go back
          </div>
          <div @click="openComments(order.data?.idorder)" class="button comments">
            <i class='bx bxs-comment-detail'></i>
            Comments {{ order.comment_count }}
          </div>
        </div>
        <div class="options">
          <div class="worker-wrapper">
            <div class="add-worker exclude_add_worker" v-if="!loading" @click="showWorkerDropdown">
              {{ printing.pickerId === "0" || printing.pickerId === 0 ? "No worker" : bedrukkers.filter(x => String(x.pickerId) === String(printing?.pickerId))[0].firstName + " " + bedrukkers.filter(x => String(x.pickerId) === String(printing?.pickerId))[0].lastName }}
              <i class='bx bxs-chevron-down arrow'></i>
            </div>
            <div class="select-worker" v-click-outside="{ exclude: ['exclude_add_worker'], handler: hideWorkerDropdown }"> 
              <template v-for="bedrukker in bedrukkers.filter(x => x.role === 4)" :key="bedrukker.pickerId">
                <div class="single-worker" @click="handleWorker(order?.orderInformation?.data.reference, bedrukker, i); hideWorkerDropdown();">
                  <span :class="{'cas': bedrukker.firstName === 'Cassandra', 'ed': bedrukker.firstName === 'Ed', 'vin': bedrukker.firstName === 'Vinny', }">{{ bedrukker.firstName.charAt(0) + bedrukker.lastName.charAt(0) }}</span>
                  {{ bedrukker.firstName + " " + bedrukker.lastName}}
                </div>
              </template>
            </div>
          </div>
          <div class="status-wrapper">
            <div @click="showStatusDropdown" v-if="!loading" class="status-button-main exclude_status_button_main" 
                :style="{'background-color': 
                currentStatus === 1 ? '#E8697D' : 
                currentStatus === 2 ? '#FFB545' :
                currentStatus === 3 ? '#339ECD' :
                currentStatus === 4 ? '#33D391' : '#E8697D'}">
                {{ statusArray[currentStatus] === 'None' ? 'To do' :statusArray[currentStatus] }}
                <i class='bx bxs-chevron-down arrow'></i>
            </div>
            <div class="select-status" v-click-outside="{ exclude: ['exclude_status_button_main'], handler: hideStatusDropdown }">
              <div class="status-button" @click="handleStatus(1); hideStatusDropdown();" style="background-color: #E8697D;">To do</div>
              <div class="status-button" @click="handleStatus(2); hideStatusDropdown();" style="background-color: #FFB545;">In progress</div>
              <div class="status-button" @click="handleStatus(3); hideStatusDropdown();" style="background-color: #339ECD;">Prepared</div>
              <div class="status-button" @click="handleStatus(4); hideStatusDropdown();" style="background-color: #33D391;">Done</div>
            </div>
          </div>
        </div>
      </div>

      <div class="cards">
        
        <div v-if="!loading" class="card first">
          <div class="order-information">
            <span class="order-title" style="">Order nr.</span>
            <span class="order-info">{{ $route.params.orderId }}</span>
          </div>
          <div class="order-information">
            <span class="order-title">Shop</span>
            <span class="order-info">
              <div v-for="(tag, i) in Object.keys(order.data.tags).map(key => order.data.tags[key]).filter(tag=> tag?.title.toLowerCase().includes('dartshopper'))" :key="i">
                {{ tag?.title }}
              </div>
            </span>
          </div>
          <div class="order-information">
            <span class="order-title">Date of order</span>
            <span class="order-info">{{ new Date(order.event).toLocaleTimeString('en-NL', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })  }}</span>
          </div> 
          <div class="order-information">
            <span class="order-title">Customer</span>
            <span class="order-info">{{ order.data.picklists[0]?.deliveryname }}</span>
          </div>
        </div>
        <h3>Product list</h3>
        <div class="card second">
          <div class="table-container">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th class="type">Type</th>
                  <th>View</th>
                  <th>Download</th>
                  <th>Recreated</th>
                  <th>Finished</th>
                </tr>
              </thead>
              <tbody v-if="!loading && order.data.products.filter((product) => {
                  if (product?.remarks === null) {
                    return false; // skip
                  }
                  return true;
                  }).filter(order => order?.remarks.includes('colorlab:')).length > 0"  v-for="(orderProducts, i) in order.data.products.filter((product) => {
                  if (product?.remarks === null) {
                    return false; // skip
                  }
                  return true;
                  }).filter(order => order?.remarks.includes('colorlab:'))" :key="i">
                  <tr>
                    <td class="type" @click="getColorByColorlabId(orderProducts, orderProducts?.idorder_product);">
                      <div>
                        {{ orderProducts?.name }} <b>({{ orderProducts?.amount }}x)</b>
                      </div>
                    </td>
                    <td class="view" @click="getFileByColorlabId(orderProducts?.remarks.split('colorlab:')[1], orderProducts?.idorder_product, false);">
                      <i class='bx bxs-show' ></i>
                    </td>
                    <td class="download" @click="getFileByColorlabId(orderProducts?.remarks.split('colorlab:')[1], orderProducts?.idorder_product, true);">
                      <i class='bx bxs-download'></i>
                    </td>
                    <td class="recreated">
                      <a v-if="printingProducts.filter(x => String(x.idorderProduct) === String(orderProducts?.idorder_product)).length > 0 && printingProducts.filter(x => String(x.idorderProduct) === String(orderProducts?.idorder_product))[0].pdfLocation !== undefined" :href="'https://vps.getsales.nl/pdf/' + printingProducts.filter(x => String(x.idorderProduct) === String(orderProducts?.idorder_product))[0].pdfLocation + '.pdf'" target="_blank">
                        <i class='bx bxs-file-pdf'></i>
                      </a>
                      <a class="greyed-out" v-else>
                        <i class='bx bxs-file-pdf'></i>
                      </a>
                    </td>
                    <td class="finished">
                      <div :class="{'animated': printingProducts.filter(x => String(x.idorderProduct) === String(orderProducts?.idorder_product) && x.completed).length > 0}" style="cursor: pointer" @click="checkProduct(printing, orderProducts?.idorder_product)">
                        <div class="box">
                          <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.4319 5.09226L24.4311 5.09308L10.9064 18.6178C10.3975 19.1267 9.57142 19.1281 9.06134 18.6177C9.06124 18.6177 9.06115 18.6176 9.06105 18.6175C9.06088 18.6173 9.0607 18.6171 9.06052 18.6169L1.38286 10.939C1.38258 10.9387 1.3823 10.9384 1.38202 10.9381C0.871468 10.4279 0.874125 9.60245 1.3812 9.09419L1.38203 9.09336L3.248 7.22739L3.24883 7.22656C3.75783 6.71875 4.58323 6.71756 5.09306 7.22737C5.09307 7.22738 5.09308 7.22738 5.09308 7.22739L9.98415 12.1182L20.7203 1.38203L20.722 1.38038C21.2311 0.87366 22.0547 0.872478 22.5646 1.3812L22.5655 1.38208L24.4303 3.24718L24.4319 5.09226ZM24.4319 5.09226C24.9393 4.58372 24.9419 3.7586 24.4312 3.24805L24.4319 5.09226Z" fill="#33D391" stroke="white" stroke-width="2"/>
                          </svg>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <Transition name="scale">
                  <tr v-show="productListToggle.find(x => String(x?.idorder_product) === String(orderProducts?.idorder_product) && x?.toggled)">
                    <td class="color-information">
                      <div id="pdfvuer" class="pdfviewer">
                        <h3>General information</h3>
                        <div class="seperator">{{ activeProductName }}</div>
                        <div class="color-container" v-if="Object.keys(colors).length !== 0 && Object.keys(currentProduct).length !== 0">
                          <div class="color-header">
                            <div>Name</div>
                            <div>Color</div>
                            <div>Value</div>
                            <div>Font details</div>
                          </div>
                       
                            <template v-for="(color, i) in productListToggle.filter(x => String(x?.idorder_product) === String(orderProducts?.idorder_product) && x?.toggled)[0]?.colors" :key="i">
                              <div class="color-body">
                                <template class="color-body-item" v-if="color.type === 'text' || color.type === 'background'">
                                  <template v-for="colorItem in color?.colors.filter(x => x.default)">
                                    <div class="name">{{ color.name?.en }}</div>
                                    <div class="color" >
                                      <div :style="{backgroundColor: colorItem?.color}"></div>
                                      <span>{{ colorItem?.color }}</span>
                                    </div>
                                    <div class="value" v-if="color?.value">{{ color.value }}</div>
                                    <div v-else class="value">-</div>
                                    <div class="font-details" v-if="color?.fontsize && color?.defaultFont?.name">
                                      {{ color?.fontsize }} 
                                      {{ color?.defaultFont.name }}
                                    </div>
                                    <div v-else class="value">-</div>
                                  </template>
                                </template>
                              </div>
                            </template>
                        </div>

                        <h3>Upload</h3>
                        <div v-if="Object.keys(currentProduct).length !== 0" v-bind="getRootProps()" class="dragdrop" :style="{'background-color': isDragActive ? 'gray' : ''}">
                          <input v-bind="getInputProps()" />
                          <div class="dragdrop-content">
                            <i class='bx bxs-file-pdf' ></i>
                            <div class="dragdrop-title">Upload your file here</div>
                          </div>
                        </div>

                      </div>
                    </td>
                  </tr>
                  </Transition>
              </tbody>
            </table>
          </div>
        </div>

        <!-- <div class="card card-1" style="height: 100%; display: flex; justify-content: center;">

          <div v-if="Object.keys(currentProduct).length === 0" style="text-align: center;">
            <p style="font-size: 21px;">Please select a product out of the product list by clicking on the 
              <b><svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 13.998c-.092.065-2 2.083-2 3.5 0 1.494.949 2.448 2 2.5.906.044 2-.891 2-2.5 0-1.5-1.908-3.435-2-3.5zm-16.586-1c0 .534.208 1.036.586 1.414l5.586 5.586c.378.378.88.586 1.414.586s1.036-.208 1.414-.586l7-7-.707-.707L11 4.584 8.707 2.291 7.293 3.705l2.293 2.293L4 11.584c-.378.378-.586.88-.586 1.414zM11 7.412l5.586 5.586L11 18.584h.001l-.001 1v-1l-5.586-5.586L11 7.412z"/></svg>
              </b> icon.</p>
          </div>


          <div v-if="Object.keys(currentProduct).length !== 0" v-bind="getRootProps()" class="dragdrop" :style="{'background-color': isDragActive ? 'gray' : ''}">
            <input v-bind="getInputProps()" />
            <div>
              <div class="dragdrop-title">Drag & drop the reproduced ColorDRAW pdf</div>
              <div class="dragdrop-title-alternative">Or browse file from device</div>
            </div>
          </div>

        </div> -->

      </div>
    </div>
  </Page>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, mounted, computed, watchEffect, onUnmounted } from "vue";

import Page from '@/components/Page.vue'
import getUser from '../composeables/getUser'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import '@vuepic/vue-datepicker/dist/main.css';
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';
import { notify } from "@kyvg/vue3-notification";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { useDropzone } from "vue3-dropzone";
import pdf from 'pdfvuer'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'

export default {
  components: { 
    Page, Loading, pdf,
    VueFinalModal, ModalsContainer},
  name: "printingOrder",
  data(){
    return{
      currentIdorder_product: "",
    }
  },
  setup() {
    const user = ref({});
    const router = useRouter()
    const currentStatus = ref(1);

    const currentWorker = ref("");
    const order = ref({event: "", data: { products: Array(1).fill({"remarks": null}), picklists: [], tags: []}});
    const printing = ref({pickerId: "0", status: 1});
    const printingProducts = ref([{completed: false, pdfLocation: "", orderId: "", idorderProduct: "", updatedAt: ""}]);
    const pdfUrl = ref("https://vps.getsales.nl/html/Order_Reproduced_Colordraw.pdf");
    const base = ref("");
    const loading = ref(true);
    const statusArray = ref([
      "None",
      "To do",
      "In progress",
      "Prepared",
      "Done"
    ]);
    const selectedProduct = ref("");
    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })

    // For demo purposes assign range from the current date
    onMounted( async () => {
      cookies.set('idorder_product', "")
      user.value = cookies.get('token');
      loading.value = true;
      const res = await getOrder();

      let currentOrder = await res.json();
      order.value = currentOrder.order;
      let products = currentOrder.printingProducts;

      products.forEach(item => { // Adds open/close feature to every product
        item.open = false;
      });  


      if(Object.keys(products).length === 0)
      {
        let array = [];
        let productArray = order.value.data.products.filter((product) => {
                  if (product?.remarks === null) {
                    return false; // skip
                  }
                  return true;
                  }).filter(order => order?.remarks.includes('colorlab:'));

        for (let index = 0; index < productArray.length; index++) {
          const element = array[index];

          array.push({completed: false, idorderProduct: productArray[index].idorder_product})
        }
        printingProducts.value = array;
      }
      else
      {
        printingProducts.value = products;
      }

      if(currentOrder.printing !== null)
      {
        printing.value = currentOrder.printing;
        currentStatus.value = currentOrder.printing.status ?? 1;
        currentWorker.value = currentOrder.printing.pickerId ?? 0;
      }

      loading.value = false;
    });

    const checkProduct = async (printing, idorderProduct) => {
      const { user } = getUser();
      let printingIdString = "";
      
      if(printing !== null && printing !== undefined)
      {
        printingIdString = "&printingId=" + printing.printingId + "&orderId=" + router.currentRoute._value.params.orderId;
      }
      else
      {
        printingIdString = "&orderId=" + router.currentRoute._value.params.orderId;
      }
      
      await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/complete?idorderProduct=" + idorderProduct + printingIdString, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
      
      const index = printingProducts.value.findIndex(x => String(x.idorderProduct) === String(idorderProduct));

      printingProducts.value[index].completed = !printingProducts.value[index].completed;
    }

    const uploadFile = async (pdf) => {
        let formData = new FormData();

        formData.append('pdf', pdf);
        if(cookies.get('idorder_product') !== "null" && cookies.get('idorder_product') !== null && cookies.get('idorder_product') !== "")
        {
          let order = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/pdf/create?orderId=" 
          + router.currentRoute._value.params.orderId 
          + "&idorderProduct=" + cookies.get('idorder_product'), {
              method: 'POST',
              headers: {
                  "Authorization": user ? `Bearer ${user._value.token}` : "",
              },
              body: formData
          }).then(x => x.json());

          printingProducts.value.push(order);
        }
        else
        {
          notify({
            title: `Uploading fail`,
            text: `The uploading of a PDF is only available when you are viewing a product.`,
            type: 'error',
            duration: 5000,
          });
        }
    }

    const onDrop = async (acceptFiles, rejectReasons, test) => {

      if(acceptFiles.length > 0)
      {
        const pdf = test.target.files[0];
        await uploadFile(pdf);
      }
    }

    const { getRootProps, getInputProps, ...rest } = useDropzone({ onDrop });

    const handleWorker = (orderId, bedrukker, i) => {
      updateOrder(bedrukker.pickerId,  'worker');
      printing.value.pickerId = bedrukker.pickerId;
    }

    const updateOrder = async (data, type) => {
      let postBody = {};
      postBody.orderId = router.currentRoute._value.params.orderId;
      
      if(type === 'worker')
      {
        postBody.pickerId = data;
      }
      else if(type === 'status')
      {
        postBody.status = data;
      }

      const { user } = getUser();
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/create", { 
        method: 'POST', 
        headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""},
        body: JSON.stringify(postBody) 
      })
   
    }

    const handleStatus = (statusNumber) => {
      currentStatus.value = statusNumber;
      
      updateOrder(statusNumber, 'status');
    }

    const goBack = () => {
      router.back();
    }

    async function getOrder()
    {
      const { user } = getUser();
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order?orderId=" + router.currentRoute._value.params.orderId, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
    }

    return { loading, printingProducts, checkProduct, uploadFile, selectedProduct, getRootProps, statusArray, pdfUrl, getInputProps, ...rest, goBack, order, base, 
    handleStatus, handleWorker, currentWorker, currentStatus, printing, user};
  },
  data() {
    return{
      bedrukkers: [],
      errorMessage: "",
      showError: false,
      loading: false,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      base64: "",
      errors: [],
      scale: 2,
      showModal: false,
      showComments: false,
      comments: [],
      comment: "",
      newPdf: false,
      orderIdComments: 0,
      colors: {
        colors: [],
        views: [{
          elements: [
            { colors: [] }
          ]
       }]
      },
      currentProduct: {},
      activeProductName: "",
      productListToggle: []
    }
  },
  computed: {
    formattedZoom () {
        return Number.parseInt(this.scale * 100);
    },
    fullString() {
      return "data:application/pdf;base64," + this.base64
    }
  },
  mounted () {
    
  },
  watch: {
    show: function (s) {
      if(s) {
        this.getPdf();
      }
    }
  },
  methods: {
    showWorkerDropdown(){
      var arrow = document.querySelectorAll(".worker-wrapper .arrow");
      var dropdown = document.querySelectorAll(".worker-wrapper .select-worker");
      var button = document.querySelectorAll(".worker-wrapper .add-worker");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    hideWorkerDropdown(){
      var arrow = document.querySelectorAll(".worker-wrapper .arrow");
      var dropdown = document.querySelectorAll(".worker-wrapper .select-worker");
      var button = document.querySelectorAll(".worker-wrapper .add-worker");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    showStatusDropdown(){
      var arrow = document.querySelectorAll(".status-wrapper .arrow");
      var dropdown = document.querySelectorAll(".status-wrapper .select-status");
      var button = document.querySelectorAll(".status-wrapper .status-button-main");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    hideStatusDropdown(){
      var arrow = document.querySelectorAll(".status-wrapper .arrow");
      var dropdown = document.querySelectorAll(".status-wrapper .select-status");
      var button = document.querySelectorAll(".status-wrapper .status-button-main");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },

    dynamicImage(color)
    {
      color = JSON.parse(JSON.stringify(color));
      return 'https://console.colorlab.io/api/media/' + color.value['_id'] + '/' + color.value["token"] + '?style=preview'
    },
    async getFileByColorlabId (colorlabId, idorder_product, download) {
      this.newPdf = false;
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/file?exportTrue=true&colorlabId=" + colorlabId.replace(/\s/g, ''));
      const data = await res.text();

      cookies.set('idorder_product', idorder_product);
      this.currentIdorder_product = idorder_product;
      this.base64 = data;
      this.newPdf = true;
      this.getPdf();

      if(download !== null && download === true)
      {
        var a = document.createElement("a");
        a.href = `data:application/pdf;base64, ${this.base64}`
        a.download = `${idorder_product}.pdf`;
        a.click();
      }
      else
      {
        this.openModalExample();
      }
    },
    async getColorByColorlabId (products, idorder_product ) {
        
        let productIndex = this.productListToggle.findIndex(x => String(x.idorder_product) === String(idorder_product));
        if(productIndex !== -1) 
        {
          this.productListToggle[productIndex].toggled = !this.productListToggle[productIndex].toggled; 
        }
        else
        {
          this.currentProduct = JSON.parse(JSON.stringify(products));
          this.currentIdorder_product = idorder_product;
          const colorlabId = products?.remarks.split('colorlab:')[1];
          const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/file?exportTrue=false&colorlabId=" + colorlabId.replace(/\s/g, ''));
          const data = await res.json();
          
          cookies.set('idorder_product', idorder_product);
          this.colors = data.views[0].elements;
          this.activeProductName = data.name;
          this.productListToggle.push({idorder_product: idorder_product, toggled: true, colors: data.views[0].elements});
        }
    },
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    async users(){
      try {
        const { user } = getUser();
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
        this.bedrukkers = await res.json()
      } catch (error) {
        this.errorMessage = error
        this.showError = true
      }
    },
    async openComments(orderId) {
      this.loading = true;
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/comments?orderId=" + orderId);
      const data = await res.json();

      this.comments = data.filter(x => x.type === "comment");
      let users = JSON.parse(JSON.stringify(this.bedrukkers));
      this.comments = this.comments.map(function(el) {
      let user = users.find((x) => parseInt(x.pickerId) === el.iduser); 
                        var o = Object.assign({}, el);
                        o.fullName = user?.firstName + ' ' + user?.lastName;
                        return o;
                      })
      this.orderIdComments = orderId;
      this.loading = false;
      this.showComments = true;
    },
    async sendComment() {
      this.loading = true;
      let postBody = {};
      postBody.note = this.comment;

      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/comments/create?orderId=" + this.orderIdComments, {
        method: "POST", 
        body: JSON.stringify(postBody)
      });
      const data = await res.json();

      this.loading = false;

      this.openComments(this.orderIdComments);
      this.showComments = true;
    },
    openModalExample() {
      this.showModal = true;
    },
    getPdf () {
      var self = this;
      this.pdfData = undefined;
      self.pdfdata = "";
      self.pdfdata = pdf.createLoadingTask("data:application/pdf;base64," + this.base64);
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
      });
    },
  },
  
  async created() {
    this.loading = true;
    this.users()
    this.loading = false;
  }
}
</script>
<style lang="scss" scoped>
.scale-enter-active {
  animation: scale-in 0.3s;
}
.scale-leave-active {
  animation: scale-in 0.3s reverse;
}
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.printing {
  .printing-header {
    display: flex;
    justify-content: space-between;
    .btn-wrapper {
      display: flex;
      align-items: center;
      .back {
        display: flex;
        align-items: center;
        border: 1px solid #D7DCE1;
        background-color: #fff;
        color: #0B212D;
        padding: 0 1.6rem;
        border-radius: $border-radius;
        cursor: pointer;
        height: 4.0rem;
        font-family: "Neutrif-bold";
        transition: all 0.3s ease;
        &:hover{
          i {
            transform: translateX(-4px);
          }
        }
        i {
          transition: all 0.3s ease;
          font-size: 24px;
          margin-right: 4px;
        }
      }
      .comments {
        margin-left: 0.8rem;
        display: flex;
        align-items: center;
        border: 1px solid #D7DCE1;
        background-color: #fff;
        color: #0B212D;
        padding: 0 1.6rem;
        border-radius: $border-radius;
        cursor: pointer;
        height: 4.0rem;
        font-family: "Neutrif-bold";
        transition: all 0.3s ease;
        i {
          font-size: 18px;
          margin-right: 4px;
        }
      }
    }
    .options {
      display: flex;
      .worker-wrapper {
        position: relative;
        .add-worker {
          font-size: 16px; 
          border: 1px solid #D7DCE1;
          background-color: #fff;
          text-align: center; 
          padding: 0 1.6rem;
          height: 4.0rem;
          color: #0B212D;
          font-family: "Neutrif-bold";
          border-radius: $border-radius;
          cursor: pointer;
          display: flex;
          align-items: center;
          i {
            font-size: 20px;
            margin-left: 4px;
            transition: transform 0.3s ease;
          }
          .open{
            transform: rotate(180deg);
          }
        }
        .select-worker{
          position: absolute;
          transform-origin: right top;
          right: 0;
          padding: 1.6rem;
          background: white;
          box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
          margin-top: 0.8rem;
          border-radius: $border-radius;
          transform: scale(0.01);
          opacity: 0;
          transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
          .single-worker {
            padding: 0.8rem;
            white-space: nowrap;
            display: flex;
            align-items: center;
            cursor: pointer;
            z-index: 1;
            position: relative;
            &:hover {
              background-color: #D7DCE1;
            }
            &:last-child {
              margin-bottom: 0;
            }
            span {
              min-height: 24px;
              max-height: 24px;
              min-width: 24px;
              max-width: 24px;
              background: #0ABB87;
              color: white;
              font-weight: bold;
              text-transform: uppercase;
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 6px;
              font-size: 12px;
            }
          }
          &.open {
            transform: scale(1);
            opacity: 1;
            box-shadow: 3px 3px 30px rgb(0 0 0 / 15%);
          }
        }
      }
      .status-wrapper {
        position: relative;
        .status-button-main {
          font-size: 16px; 
          background-color: #fff;
          text-align: center; 
          padding: 0 1.6rem;
          height: 4.0rem;
          color: white;
          font-family: "Neutrif-bold";
          border-radius: $border-radius;
          cursor: pointer;
          display: flex;
          align-items: center;
          margin-left: 1.0rem;
          transition: 0.3s ease-in-out;
          i {
            font-size: 20px;
            margin-left: 4px;
            transition: transform 0.3s ease;
          }
          .open{
            transform: rotate(180deg);
          }
        }
        .select-status{
          position: absolute;
          transform-origin: right top;
          right: 0;
          padding: 1.6rem;
          background: white;
          box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
          margin-top: 0.8rem;
          border-radius: $border-radius;
          transform: scale(0.01);
          opacity: 0;
          transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
          &.open {
            transform: scale(1);
            opacity: 1;
            box-shadow: 3px 3px 30px rgb(0 0 0 / 15%);
          }
          .status-button {
            font-size: 16px;
            width: 140px;
            height: 4.0rem;
            color: white;
            font-family: "Neutrif-bold";
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.8rem;
            cursor: pointer;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.cards {
  margin-top: 1.6rem;
  width: 100%;
  h3 {
    font-size: 18px;
  }
  .card {
    height: auto;
    width: 100%;
    margin-right: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  .card.first {
    .order-information {
      display: flex; 
      flex-direction: column;
      width: calc((100% / 4) - (1.6rem / 3));
      margin-right: 1.6rem;
      &:last-child {
        margin-right: 0;
      }
      .order-title {
        color: #9397A1;
        display: block;
        margin-bottom: 4px;
      }
      .order-info {
        display: block;
      }
    }
  }
  .card.second {
    padding: 0;
    .table-container{
      background-color: white;
      width: 100%;
      border-radius: $border-radius;
      transition: all 0.3s ease;
      .table{
        width: 100%;
        border-spacing: 0;
        tbody{
          &:last-child {
            .color-information {
              border-bottom: 0;
              border-top: 1px solid #D7DCE1;
              .seperator {
                margin-bottom: 1.6rem;
              }
            }
            tr {
              border-bottom: 0;
            }
          }
        }
      }
      .table-striped{
        thead{
          width: 100%;
          display: flex;
          border-bottom: 1px solid #D7DCE1;
          color: #9397A1;
          tr{
            display: flex;
            justify-content: space-between;
            width: 100%;
            th{
              min-width: 100px;
              font-weight: normal;
              padding: 3.0rem 0;
              &:first-child {
                padding-left: 3.0rem;
              }
            }
            th.type {
              width: calc(100% - 400px);
            }
          }
        }
        tbody{
          width: 100%;
          display: flex;
          flex-direction: column;
          tr{
            width: 100%;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #D7DCE1;
            &:last-child{
              border-bottom: 0;
            }
            &:last-child td, &:last-child th {
              border-bottom: none;
            }
            td {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 100px;
              border-right: 1px solid #D7DCE1;
              padding: 1.6rem 0;
              &:hover {
                background: #f2f2f2;
                transition: all 0.3s ease;
              }
              i {
                font-size: 24px;
              }
              &:first-child {
                padding-left: 3.0rem;
              }
              &:last-child {
                border-right: 0;
              }
            }
            td.type {
              justify-content: flex-start;
              width: calc(100% - 400px);
            }
            td.type, td.view, td.download, td.recreated, td.finished {
              cursor: pointer;
            }
            td.recreated{
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: calc(100% + 3.2rem);
                i {
                  color: #0B212D;
                }
              }
              .greyed-out{
                cursor: not-allowed;
                i {
                  color: #D7DCE1;
                }
              }
            }
            td.finished {
              .animated{
                .box{
                  border: 1px solid #33D391;
                  svg {
                    opacity: 1;
                  }
                }
              }
              .box {
                border: 1px solid #D7DCE1;
                background: white;
                width: 20px;
                height: 20px;
                svg{
                  opacity: 0;
                  transition: all 0.3s ease;
                  margin-top: -3px;
                  margin-left: 1px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.color-information {
  width: 100%;
  padding: 3.0rem !important;
  background: #F7F7F7;
  box-shadow: inset 0px 0px 10px rgba(46, 48, 63, 0.1);
  border-bottom: 1px solid #D7DCE1;
  padding-top: 0 !important;
  #pdfvuer {
    width: 100%;
    h3 {
      padding-top: 2.0rem;
      font-size: 18px;
    }
    .color-container {
      background: white;
      border-radius: $border-radius;
      border: 1px solid #D7DCE1;
      .color-header {
        display: flex; 
        justify-content: space-between;
        border-bottom: 1px solid #D7DCE1;
        padding: 3.0rem;
        color: #9397A1;
        > div {
          width: calc((100% / 4) - (1.6rem / 3));
          margin-right: 1.6rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .color-body {
        display: flex;
        justify-content: space-between;
        padding: 3.0rem;
        &:empty {
          display: none;
        }
        .color {
          display: flex;
          align-items: center;
          > div {
            border: 1px solid #9397A1;
            width: 1.6rem; 
            height: 1.6rem;
          };
        }
        > div {
          width: calc((100% / 4) - (1.6rem / 3));
          margin-right: 1.6rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

.modal-styling {
  position: absolute;
  top: 50%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%); 
  max-width: 540px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  background-color: #FFF; 
  border-radius: $border-radius;
  border: 1px solid #D7DCE1; 
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  .modal-header {
    padding: 3.0rem; 
    padding-top: 2.0rem;
    border-bottom: 1px solid #D7DCE1;
    .name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      span {
        font-family: "Neutrif-bold";
        font-size: 18px;
      }
      i {
        font-size: 24px;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease;
        &:hover{
          opacity: 1;
        }
      }
    }
    textarea {
      width: 100%;
      height: 8.0rem;
      margin-bottom: 1.6rem;
      border: 1px solid #D7DCE1;
      border-radius: $border-radius;
      padding: 1.0rem;
      resize: none;
      font-family: "Neutrif-reg";
      color: #0B212D;
      font-size: 14px;
      &::placeholder {
        color: #D7DCE1;
      }
      &:focus-visible {
        outline: none !important;
        border-color: #3AAFE3;
        box-shadow: 0 0 4px #3AAFE3;
      }
    }
    .place {
      display: flex;
      align-items: center;
      background-color: #3AAFE3;
      color: white;
      padding: 0 1.6rem;
      font-size: 14px;
      border-radius: 0.6rem;
      cursor: pointer;
      height: 4.0rem;
      font-family: "Neutrif-bold";
      transition: all 0.3s ease;
      border: 0;
    }
  }
  .modal-body {
    .comment {
      padding: 3.0rem;
      border-bottom: 1px solid #D7DCE1;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: 0;
      }
      .avatar {
        min-height: 40px;
        min-width: 40px;
        background: #0ABB87;
        border-radius: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Neutrif-bold";
        margin-right: 1.6rem;
      }
      .content {
        width: 100%;
        .name {
          font-size: 16px;
          font-family: "Neutrif-bold";
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 12px;
            font-family: "Neutrif-reg";
            margin-left: 0.8rem;
            color: #9397A1;
            margin-top: 1px;
          }
          .time {
            flex: 1;
          }
        }
        .description {

        }
      }
    }
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 3.0rem;
      color: #3AAFE3;
      text-decoration: underline;
    }
  }
}

.dragdrop {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 1.6rem;
  width: fit-content;
  height: auto;
  padding: 0 2.4rem;
  text-align: center;
  border: 1px solid #D7DCE1;
  border-radius: $border-radius;
  background-color: white;
  height: 56px;
  cursor: pointer;
  &:hover{
    background-color: #d7dce141;
  }
  .dragdrop-content {
    display: flex;
    align-items: center;
    i {
      margin-right: 4px;
    }
    .dragdrop-title {
      color: #9397A1;
      text-decoration: underline;
    }
  }
}
.pdf-modal-styling{
  position: absolute;
  top: 50%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%); 
  max-width: 540px;
  width: 100%;
  background-color: #FFF; 
  border-radius: $border-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #D7DCE1; 
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  .close {
    font-size: 24px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s ease;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 99999999999999;
    &:hover{
      opacity: 1;
    }
  }
  .pdf-wrapper {
    position: relative;
    max-height: 60vh;
    overflow-y: auto;
    div {
      padding: 1.6rem;
    }
  }
  .buttons {
    width: 100%;
    border-top: 1px solid #D7DCE1;
    padding: 3.0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      min-width: 40px;
      min-height: 40px;
      border: 1px solid #D7DCE1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;
      i {
        font-size: 24px;
        color: #0B212D;
      }
    }
  }
}

.printing .printing-header .options .worker-wrapper .select-worker .single-worker span.ed { background-color: #0ABB87; }
.printing .printing-header .options .worker-wrapper .select-worker .single-worker span.cas { background-color: #5578EB; }
.printing .printing-header .options .worker-wrapper .select-worker .single-worker span.vin { background-color: #FD397A; }

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) {
  .page-header{
    flex-direction: column;
    margin-bottom: 1.6rem;
    .title-wrapper{
      margin-bottom: 1.6rem;
      .title{
        font-size: 2.4rem;
      }
    }
  }
}
</style>
