import { ref } from 'vue'
  import { projectAuth } from '../firebase/config'

  import { useCookies } from "vue3-cookies";
  const { cookies } = useCookies();

//const user = ref(projectAuth.currentUser)
const user = ref({});

projectAuth.onAuthStateChanged(_user => {
  user.value = cookies.get('token')
})

const getUser = () => {
  return { user }
}

export default getUser