<!-- YourComponent.vue -->
<template>
    <div>
      <input type="file" ref="fileInput" @change="handleFileChange" />
      <button @click="uploadFile">Upload</button>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup(_, { emit }) {
      const fileInput = ref(null);
      const selectedFile = ref(null);
  
      const handleFileChange = () => {
        selectedFile.value = fileInput.value.files[0];
      };
  
      const uploadFile = async () => {
        try {
          const formData = new FormData();
          formData.append('excelFile', selectedFile.value);
  
          const response = await fetch(process.env.VUE_APP_ROOT_API + "/api/clocking/upload", {
            method: 'POST',
            body: formData,
          });
  
          if (response.ok) {
            const responseData = await response.json();
            console.log('File uploaded successfully:', responseData);
          } else {
            console.error('Error uploading file:', response.statusText);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      };
  
      return {
        fileInput,
        handleFileChange,
        uploadFile,
      };
    },
  };
  </script>
  