<template>
  <main class="login">
    <section class="login__container">
      <header class="login__header">
        <div class="login__header--logo">
          <LogoGetsales />
        </div>
        <h1 class="login__header--greeting">Welcome back!</h1>
      </header>
      <transition-group name="fade" tag="div" class="form-container">
        <div class="login__form login__form--login" v-if="showLogin">
          <p class="login__header--intro">Log in to the Getsales Dashboard</p>
          <LoginForm @login="enterDashboard" />
          <footer class="login__footer">
            <p class="login__footer--query">First time here?</p>
            <a href="#" class="login__footer--request-link" @click="toggleForm">Request an account</a>

          </footer>
        </div>

        <div class="login__form login__form--signup" v-else>
          <p class="login__header--intro">Sign up</p>

          <SignupForm @signup="enterDashboard" />
          <footer class="login__footer">
            <p class="login__footer--query">Already registered?</p>
            <a href="#" class="login__footer--request-link" @click="toggleForm">Login instead</a>

          </footer>

        </div>
      </transition-group>


    </section>

    <article class="showcase">
      <div class="showcase__background">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 415.88">
          <defs>
            <linearGradient id="prefix__a" x1="-26.73" y1="308.97" x2="563.53" y2="-120.47"
              gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#f5ded9" stop-opacity="0" />
              <stop offset="1" stop-color="#eabdb1" />
            </linearGradient>
            <linearGradient id="prefix__b" x1="632.97" y1="-21.06" x2="-10.97" y2="252.25" gradientUnits="userSpaceOnUse">
              <stop offset="0" stop-color="#eabdb1" />
              <stop offset="1" stop-color="#f5ded9" stop-opacity="0" />
            </linearGradient>
          </defs>
          <path
            d="M612 0v115.9c-24.55 2.78-50.23.35-74.6-.49-35.77-1.91-52.26 19.51-72.55 49.28-22.32 33.79-41.86 74.97-64.07 109.12-8.74 13.2-17.39 25.28-29.46 35.05-49.51 37.94-138.3 15.97-202.74 21.87l-.22.02c-62 3.97-120.48 45.35-168.36 85.13V0h612z"
            fill="url(#prefix__a)" />
          <path
            d="M612 69.91v23.97c-24.55 2.78-50.23.35-74.6-.49-35.77-1.91-52.26 19.51-72.55 49.28-22.32 33.79-41.86 74.97-64.07 109.12-6.72 10.33-18.38 25.01-29.46 35.05-69.45 62.91-248.65 27.1-371.32-73.47V55.31c25.95-22.38 58.83-42.64 89.76-34.8 59.8 15.16 50.27 119.61 132.14 159.25 41.34 20.02 100.15 20.78 142.31-2.26 41.01-22.4 88.91-79.33 131.57-105.03 7.88-4.75 18.61-10.26 32.19-10.17 14.14.1 20.67 6.19 34.45 9.88 10.6 2.85 26.9 4.65 49.58-2.27z"
            fill="url(#prefix__b)" />
        </svg>
      </div>
      <div class="showcase__images">
        <img :src="imageUrl1" alt="Login Page Image" class="image-one">
        <img :src="imageUrl2" alt="Login Page Image" class="image-two">
        <img :src="imageUrl3" alt="Login Page Image" class="image-three">
      </div>
      <h1 class="showcase__title">
        Warehouse insight in<br>an all-in-one dashboard
      </h1>
    </article>
  </main>
</template>

<script>
import SignupForm from './SignupForm.vue'
import LoginForm from './LoginForm.vue'
import LogoGetsales from '@/components/svg/LogoGetsales.vue';
import image01 from '@/assets/img/loginPageImages/image_01.png';
import image02 from '@/assets/img/loginPageImages/image_02.png';
import image03 from '@/assets/img/loginPageImages/image_03.png';

import { ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  data() {
    return {
      imageUrl1: image01,
      imageUrl2: image02,
      imageUrl3: image03,
      showLogin: true,
    };
  },
  methods: {
    toggleForm() {
      this.showLogin = !this.showLogin;
    },
    // ... other methods ...
  },
  components: { SignupForm, LoginForm, LogoGetsales },
  setup() {
    const showLogin = ref(true)
    const router = useRouter()

    const enterDashboard = () => {
      window.location.assign('/')
      //router.push({ name: 'Dashboard' })
    }

    return { showLogin, enterDashboard }
  },
  async mounted() {
    document.body.classList.add("body-login");
  },
  async unmounted() {
    document.body.classList.remove("body-login");
  }
}
</script>

<style lang="scss" scoped>
.form-container {
  position: relative;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Positioning to prevent overlap */
.login__form {
  position: absolute;
  width: 100%;
}

.login {
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-white);
  display: flex;

  &__header {
    width: 100%;

    &--logo {
      width: 50%;
      margin-bottom: 16.5rem;
    }

    &--greeting {
      color: var(--color-text-black);
      font-size: 4rem;
      margin-bottom: 1.6rem;

    }

    &--intro {
      color: var(--color-text-gray);
      font-size: 1.8rem;
      margin-bottom: 4rem;

    }
  }

  &__footer {
    margin-top: 4rem;
    display: flex;
    gap: 0.3rem;
    font-size: 1.8rem;
    color: var(--color-text-black);

    &--request-link {
      color: var(--color-orange);
    }
  }

  &__container {
    width: 50%;
    padding: 12rem 20rem;

  }

  .showcase {
    width: calc(50% - 2.4rem);
    background: var(--color-orange);
    margin: 2.4rem;
    border-radius: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &__background {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      & svg {
        opacity: 0.3;
      }
    }

    &__images {
      position: relative;
      top: 0;
      left: 0;
      width: 70%;
      height: 50%;

      & img {
        position: absolute;
        transform: translate(-50%, -50%);
      }

      & .image-one {
        width: 100%;
        left: 50%;
        top: 50%;
      }

      & .image-two {
        width: 40%;
        top: 77%;
        left: 12%;
        filter: drop-shadow(8px 12px 12px rgba(0, 0, 0, 0.10));

      }

      & .image-three {
        width: 15%;
        left: 100%;
        top: 40%;
        filter: drop-shadow(8px 12px 12px rgba(0, 0, 0, 0.10));
      }
    }

    &__title {
      color: var(--color-white);
      text-align: center;
      margin-top: 9rem;
      font-size: 3.6rem;
    }
  }
}

@media (max-width: 1440px) {
  .login {
    &__container {
      padding: 7rem 18rem;
    }
  }
}

@media (max-width: 1379px) {
  .login {
    &__container {
      padding: 7rem 9rem;
    }
  }
}

@media (max-width: 1023px) {
  .login {

    &__header {


      &--logo {
        width: 60%;
        margin-bottom: 10rem;
      }

      &--greeting {

        font-size: 2.8rem;
        margin-bottom: 1.2rem;

      }

      &--intro {
        font-size: 1.4rem;
        margin-bottom: 3rem;
      }
    }

    &__footer {
      margin-top: 3rem;
      font-size: 1.4rem;
    }


    &__container {
      width: 50%;
      padding: 8rem 10rem;

    }

    .showcase {
      width: calc(50% - 1.6rem);
      margin: 1.6rem;
      border-radius: 3rem;

      &__images {

        & .image-two {
          top: 68%;
        }
      }

      &__title {
        margin-top: 7rem;
        font-size: 3rem;
      }
    }
  }

}

@media (max-width: 768px) {
  .login {
    &__container {
      padding: 7rem 5rem;
    }
  }
}

@media (max-width: 576px) {
  .login {
    flex-direction: column;

    &__header {


      &--logo {
        width: 100%;
        margin-bottom: 10rem;
      }

      &--greeting {

        font-size: 3.4rem;
        margin-bottom: 1.2rem;

      }

      &--intro {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
    }

    &__footer {
      margin-top: 3rem;
      font-size: 1.6rem;
    }


    &__container {
      width: 100%;
      padding: 10rem 3.6rem;

    }

    .showcase {
      display: none;
      margin: 1.6rem;
      border-radius: 3rem;

      &__images {

        & .image-two {
          top: 68%;
        }
      }

      &__title {
        margin-top: 7rem;
        font-size: 3rem;
      }
    }
  }
}

@media (max-width: 390px) {
  .login {
    flex-direction: column;

    &__header {


      &--logo {
        width: 100%;
        margin-bottom: 8rem;
      }

      &--greeting {

        font-size: 3rem;
        margin-bottom: 1rem;

      }

      &--intro {
        font-size: 1.3rem;
        margin-bottom: 2rem;
      }
    }

    &__footer {
      margin-top: 2rem;
      font-size: 1.3rem;
    }


    &__container {
      width: 100%;
      padding: 8rem 3.2rem;

    }

  }
}
</style>