<template>
  <form @submit.prevent="handleSubmit">
    <input class="login-input" type="email" required placeholder="Email address" v-model="email">
    <input class="login-input" type="password" required placeholder="Password" v-model="password">
    <div class="login-reminder">
      <div class="login-reminder__checkbox">
        <input type="checkbox" id="remember-me" class="login-reminder__checkbox-input" checked>
        <label for="remember-me" class="login-reminder__checkbox-label">Remember me</label>
      </div>
      <div class="login-reminder__forgot-password">
        <a href="/forgot-password" class="login-reminder__forgot-password-link">Forgot your password?</a>
      </div>
    </div>
    <div class="error">{{ error }}</div>
    <button>
      <p>Login</p>
      <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.500001 17L16.5 1M16.5 1L4.52761 1M16.5 1V12.9723" stroke="white" stroke-linecap="round"
          stroke-linejoin="round" />
      </svg>
    </button>

  </form>
</template>

<script>
import { ref } from 'vue'
import useLogin from './useLogin'

export default {
  setup(props, context) {
    //refs
    const email = ref('')
    const password = ref('')

    const { error, login } = useLogin()

    const handleSubmit = async () => {
      await login(email.value, password.value)
      if (!error.value) {
        context.emit('login')
      }
    }

    return { email, password, handleSubmit, error }
  }
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.login-input {
  width: 100%;
  padding: 1.6rem 2.0rem;
  border-radius: 1rem;
  border: none;
  background-color: var(--bg-gray);
  outline: none;
  height: 5.0rem;


  &::placeholder {
    color: var(--color-text-gray);
    font-size: 1.8rem;
  }

  &:not(:placeholder-shown) {
    font-size: 1.8rem;
  }
}



.login-reminder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.8rem;

  &__checkbox {
    display: flex;
    align-items: center;
    height: 100%;

    &-input {
      accent-color: var(--color-orange);
      transform: scale(1.2);
      transform-origin: left;
    }

    &-label {
      margin-left: 0.8rem;
      color: var(--color-text-black);

    }
  }

  &__forgot-password {
    &-link {
      color: var(--color-orange);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}


.error {
  color: #ff3f80;
  font-size: 14px;
  display: none;
}

button {
  height: 5.0rem;
  text-decoration: none;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-size: 1.8rem;
  font-weight: 500;
  border: none;
  border-radius: 1rem;
  padding: 0px 2rem;
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;

  // &:after {
  //   position: absolute;
  //   content: "";
  //   width: 0;
  //   height: 100%;
  //   top: 0;
  //   right: 0;
  //   z-index: -1;
  //   background-color: #0B212D;
  //   border-radius: 5px;
  //   transition: all 0.3s ease;
  // }
  & svg {
    height: 2rem;
    margin-left: 1rem;
  }

  // &:hover {
  //   color: #fff;

  //   &:after {
  //     left: 0;
  //     width: 100%;
  //   }
  // }

  // &:active {
  //   top: 2px;
  // }
}

@media (max-width: 1023px) {
  form {
    gap: 1.8rem;
  }

  .login-input {

    padding: 1.2rem 1.6rem;
    border-radius: 0.8rem;
    height: 4rem;


    &::placeholder {
      font-size: 1.3rem;
    }

    &:not(:placeholder-shown) {
      font-size: 1.3rem;
    }
  }



  .login-reminder {
    font-size: 1.4rem;

    &__checkbox {

      &-input {
        transform: scale(1);
      }

      &-label {
        margin-left: 0.6rem;
      }
    }
  }

  button {
    height: 4rem;
    font-size: 1.4rem;
    border-radius: 0.8rem;

    & svg {
      height: 1.6rem;
      margin-left: 0.8rem;
    }
  }
}

// @media only screen and (max-width: 767px) {
//   form {
//     label {
//       margin: 0.8rem 0 0;
//     }

//     input {
//       height: 4.0rem;
//     }

//     button {
//       height: 4.0rem;
//       font-size: 1.6rem;
//       margin: 1.6rem auto;
//     }
//   }
// }
@media (max-width: 576px) {
  form {
    gap: 2rem;
  }

  .login-input {

    padding: 1.2rem 1.6rem;
    border-radius: 1.2rem;
    height: 5rem;


    &::placeholder {
      font-size: 1.6rem;
    }

    &:not(:placeholder-shown) {
      font-size: 1.6rem;
    }
  }



  .login-reminder {
    font-size: 1.6rem;

  }

  button {
    height: 5rem;
    font-size: 1.6rem;
    border-radius: 1.2rem;

  }
}
@media (max-width: 390px) {
  form {
    gap: 1.8rem;
  }

  .login-input {

    padding: 1.2rem 1.6rem;
    border-radius: 1rem;
    height: 4rem;


    &::placeholder {
      font-size: 1.3rem;
    }

    &:not(:placeholder-shown) {
      font-size: 1.3rem;
    }
  }



  .login-reminder {
    font-size: 1.2rem;

  }

  button {
    height: 4rem;
    font-size: 1.4rem;
    border-radius: 1rem;

  }
}
</style>