<template>
  <div>

    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Orders overview</div>
        <div class="subtitle">Overview of all sales from Dartshopper</div>
      </div>
      <div class="datepicker">
        <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
          <p>Today</p>
          <i class="bx bxs-chevron-down arrow"></i>
        </div>
        <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown">
          <div class="datepicker-card">
            <div class="title">
              Today
            </div>
            <div class="subtitle">
              30 Nov. 2021 - 30 Nov. 2021
            </div>
          </div>
          <div class="datepicker-presets">
            <router-link @click="hideDatepickerDropdown" to="/">Today<i class='bx bx-check'></i></router-link>
            <a @click="[yesterday(), hideDatepickerDropdown()]">Yesterday<i class='bx bx-check'></i></a>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 28 days<i class='bx bx-check'></i></router-link>
            <router-link @click="hideDatepickerDropdown" to="/sales/overview">Last 90 days<i class='bx bx-check'></i></router-link>
          </div>
          <div class="datepicker-customSelector">
            <Datepicker 
              v-model="date" 
              range
              weekNumbers 
              :enableTimePicker="false" 
              locale="nl" 
              placeholder="Custom..." 
              textInput 
              cancelText="cancel" 
              selectText="apply" 
              :maxDate="new Date()"
              position="right"
            />
          </div>
        </div>
      </div>
    </div>

    <TabsWrapper>
      <Tab title="Dashboard">
        <div class="cards first">
          <div class="card card-1">
            <OrderTotalToday />
          </div>
          <div class="card card-2">
            <OrderTotalCompletedToday />
          </div>
          <div class="card card-3">
            <OrderBackorders />
          </div>
        </div> 

        <!-- <div class="cards second">
          <div class="card card-1">
            <OrderTotalCompletedPerHour ref="OrderTotalCompletedPerHour" />
          </div>
        </div> -->
        <div class="cards second">
          <div class="card card-1">
            <OrderTotalCompletedPerHour />
          </div>
        </div>
      </Tab>
      <Tab title="Sales by country">
        Sales by country lorem ipsum
      </Tab>
      <Tab title="Compare data">
        Compare data lorem ipsum
      </Tab>
      <Tab title="Downloads">
        Downloads lorem ipsum
      </Tab>
    </TabsWrapper>

  </div>
</template>

<script>
import OrderTotalToday from '@/components/ordersOverview/OrderTotalToday.vue'
import OrderTotalCompletedToday from '@/components/ordersOverview/OrderTotalCompletedToday.vue'
import OrderTotalCompletedPerHour from '@/components/ordersOverview/OrderTotalCompletedPerHour.vue'
import OrderBackorders from '@/components/ordersOverview/OrderBackorders.vue'

import { ref } from "vue";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Page from '@/components/Page.vue'
import TabsWrapper from '@/components/TabsWrapper.vue' 
import Tab from '@/components/Tab.vue'

export default {
  components: { Page, Datepicker, OrderTotalToday, OrderTotalCompletedToday, OrderTotalCompletedPerHour, OrderBackorders, TabsWrapper, Tab },
  name: "sales-overview",
  setup() {
    const date = ref();

    return {
      date,
    }
  },
  data(){
    return{
      contents: null,
    }
  },
  methods: {
    hideDatepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    yesterday(){
      // this.$refs.OrderTotalCompletedPerHour.yesterday();
    }
  }
}
</script>
<style lang="scss" scoped>
.cards.first{
  .card{
    width: calc(100% / 3 - (6.4rem / 3));
    &:nth-child(3n){
      margin-right: 0;
    }
  }
}
.cards.second{
  .card{
    width: 100%;
    height: auto;
    margin-right: 0;
  }
  .card-data{
    width: 100%;
    position: relative;
    margin: auto;
    height: 32.0rem;
  } 
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { 
  .cards.first{
    .card{
      width: 100%;
      margin-right: 0;
    }
  }
  .cards.second{
    .card{
      margin-right: 0;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 767px) { 
  .cards.first{
    .card{
      margin-right: 1.6rem;
      width: calc(50% - 0.8rem);
      &:nth-child(2n){
        margin-right: 0;
      }
    }
  }
  .cards.second{
    .card{
      margin-right: 0;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .page-header{
    flex-direction: column;
    margin-bottom: 1.6rem;
    .title-wrapper{
      margin-bottom: 1.6rem;
      .title{
        font-size: 2.4rem;
      }
    }
    .datepicker{
      .dropdown{
        transform-origin: left top;
        left: 0;
      }
    }
  }
  .cards{
    .card{
      padding: 2.4rem;
    }
  }
  .cards.first{
    .card{
      width: 100%;
      margin-right: 0;
    }
  }
  .cards.second{
    .card{
      margin-right: 0;
      width: 100%;
    }
  }
}
</style>