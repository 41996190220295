import { ref } from 'vue'
import { projectAuth } from '../firebase/config'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

const error = ref(null)

const logout = async () => {
  error.value = null

  try{
    await projectAuth.signOut()
    cookies.remove("currentUserId");
    cookies.remove("token");
  } catch(err){
    console.log(err.message)
    error.value = err.message
  }
}

const useLogout = () => {
  return { logout, error }
}

export default useLogout