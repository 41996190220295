<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 139.19 41.95">
    <path fill="#f90"
      d="M86.35 32.8c-8.09 5.96-19.81 9.14-29.91 9.14-14.15 0-26.9-5.23-36.53-13.94-.76-.68-.08-1.62.83-1.08 10.4 6.05 23.27 9.69 36.55 9.69 8.96 0 18.82-1.85 27.88-5.7 1.37-.58 2.51.9 1.18 1.89" />
    <path fill="#f90"
      d="M89.71 28.96c-1.03-1.32-6.83-.62-9.44-.32-.79.1-.91-.59-.2-1.09 4.62-3.25 12.21-2.31 13.09-1.22.88 1.1-.23 8.7-4.57 12.33-.67.56-1.3.26-1.01-.48.98-2.44 3.16-7.89 2.13-9.22" />
    <path fill="#221f1f"
      d="M80.46 4.58V1.42c0-.48.36-.8.8-.8h14.16c.45 0 .82.33.82.8v2.71c0 .45-.39 1.05-1.07 1.99L87.83 16.6c2.73-.07 5.6.34 8.08 1.73.56.32.71.78.75 1.23v3.37c0 .46-.51 1-1.04.72-4.36-2.28-10.14-2.53-14.96.02-.49.27-1.01-.27-1.01-.73v-3.21c0-.52 0-1.39.52-2.18l8.5-12.19h-7.4c-.45 0-.82-.32-.82-.79M28.8 24.31h-4.31c-.41-.03-.74-.34-.77-.73V1.47c0-.44.37-.79.83-.79h4.02c.42.02.75.34.78.74v2.89h.08c1.05-2.79 3.02-4.1 5.67-4.1s4.38 1.3 5.59 4.1c1.04-2.79 3.41-4.1 5.95-4.1 1.81 0 3.78.75 4.99 2.42 1.36 1.86 1.08 4.56 1.08 6.93v13.95c0 .44-.38.8-.84.8h-4.3c-.43-.03-.78-.38-.78-.8V11.79c0-.93.08-3.26-.12-4.14-.32-1.48-1.28-1.9-2.53-1.9-1.04 0-2.13.7-2.58 1.81-.44 1.11-.4 2.98-.4 4.24v11.72c0 .44-.37.8-.83.8h-4.3c-.44-.03-.78-.38-.78-.8V11.8c0-2.47.4-6.1-2.66-6.1s-2.97 3.54-2.97 6.1v11.72c0 .44-.37.8-.83.8M108.42.21c6.39 0 9.85 5.49 9.85 12.47s-3.82 12.09-9.85 12.09-9.69-5.49-9.69-12.33S102.19.21 108.42.21m.04 4.51c-3.17 0-3.37 4.33-3.37 7.02s-.04 8.47 3.34 8.47 3.5-4.65 3.5-7.49c0-1.87-.08-4.1-.64-5.86-.48-1.54-1.45-2.14-2.82-2.14M126.56 24.31h-4.29c-.43-.03-.78-.38-.78-.8V1.4c.03-.41.39-.72.82-.72h3.99c.38.02.68.27.77.62v3.38h.08c1.21-3.02 2.9-4.47 5.87-4.47 1.93 0 3.82.7 5.03 2.61 1.13 1.77 1.13 4.74 1.13 6.88v13.92c-.05.39-.41.7-.83.7h-4.32c-.39-.03-.72-.32-.76-.7V11.61c0-2.42.28-5.96-2.7-5.96-1.05 0-2.01.7-2.49 1.77-.61 1.35-.68 2.7-.68 4.19v11.91c0 .44-.38.8-.84.8M69.17 13.75v-.93c-3.11 0-6.4.67-6.4 4.34 0 1.86.96 3.12 2.62 3.12 1.21 0 2.3-.75 2.98-1.96.85-1.49.81-2.89.81-4.57m4.34 10.5c-.28.25-.7.27-1.02.1-1.43-1.19-1.68-1.74-2.47-2.87-2.36 2.41-4.04 3.13-7.1 3.13-3.62 0-6.45-2.24-6.45-6.71 0-3.5 1.9-5.88 4.59-7.04 2.34-1.03 5.6-1.21 8.1-1.5V8.8c0-1.02.08-2.24-.52-3.12-.53-.79-1.53-1.12-2.42-1.12-1.64 0-3.11.84-3.47 2.59-.07.39-.36.77-.75.79l-4.18-.45c-.35-.08-.74-.36-.64-.9C58.15 1.52 62.73 0 66.82 0c2.1 0 4.83.56 6.49 2.14 2.1 1.96 1.9 4.57 1.9 7.41v6.71c0 2.02.84 2.9 1.62 3.99.28.39.34.85-.01 1.15-.88.73-2.44 2.1-3.3 2.86h-.01" />
    <path fill="#221f1f"
      d="M12.69 13.75v-.93c-3.11 0-6.4.67-6.4 4.34 0 1.86.96 3.12 2.62 3.12 1.21 0 2.3-.75 2.98-1.96.85-1.49.81-2.89.81-4.57m4.34 10.5c-.28.25-.7.27-1.02.1-1.43-1.19-1.68-1.74-2.47-2.87-2.36 2.41-4.04 3.13-7.1 3.13C2.83 24.61 0 22.37 0 17.9c0-3.5 1.9-5.88 4.59-7.04 2.34-1.03 5.6-1.21 8.1-1.5V8.8c0-1.02.08-2.24-.52-3.12-.53-.79-1.53-1.12-2.42-1.12-1.64 0-3.11.84-3.47 2.59-.07.39-.36.77-.75.79l-4.18-.45C1 7.41.61 7.13.71 6.59 1.68 1.53 6.26 0 10.35 0c2.1 0 4.83.56 6.49 2.14 2.1 1.96 1.9 4.57 1.9 7.41v6.71c0 2.02.84 2.9 1.62 3.99.28.39.34.85-.01 1.15-.88.73-2.44 2.1-3.3 2.86h-.01" />
  </svg>
</template>
<script>
export default {
  name: 'LogoAmazon',
}
</script>