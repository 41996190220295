<template>
  <vue-final-modal style="height: 100%; overflow-y: scroll;" v-model="showModal" :esc-to-close="true" v-slot="{ close }" :click-to-close="true">
    <div class="pdf-modal-styling">

      <div class="modal-styling-wrapper">

        <i @click="close" class='bx bx-x close'></i>

        <div class="pdf-wrapper">
          <pdf v-if="showModal" :src="fullString" v-for="i in numPages" :key="i" :id="i" :page="i" :resize="false" :scale.sync="scale" style="width:100%; height: auto;">
            <template slot="loading">
              loading content here...
            </template>
          </pdf>
        </div>

        <div class="buttons">
          <div>
            <a class="ui active item">
              {{page}} / {{ numPages ? numPages : '∞' }}
            </a>
          </div>
          <div>
            <template v-if="base64">
              <a :download="productId + '.pdf'" :title="fullString" :href="fullString"><i class='bx bxs-download'></i></a>
            </template>
          </div>
        </div>

      </div>

    </div>
  </vue-final-modal>

  <vue-final-modal style="height: 100%;" v-model="showComments" :esc-to-close="true" v-slot="{ close }" :click-to-close="true">
    <div class="modal-styling-comments">
      <div class="modal-header">
        <div class="name">
          <span>{{( user?.firstName + " " + user?.lastName)}}</span>
          <i @click="close" class='bx bx-x'></i>
        </div>
        <textarea aria-invalid="true" v-model="comment" placeholder="Write something here..."></textarea>
        <button class="place" @click="sendComment()" type="button" >Place comment</button>
      </div>
      <div class="modal-body">
        <div class="comment" v-for="comment in comments">
          <div class="avatar">
            EV
          </div>
          <div class="content">
            <div class="name">
              {{ comment.fullName === "undefined undefined" ? "Unknown user" : comment.fullName }}
              <span class="date time">{{ new Date(comment.created_at).toLocaleTimeString('en-NL', { hour: '2-digit', minute: '2-digit' }) }}</span>
              <span class="date">{{ new Date(comment.created_at).toLocaleDateString('en-NL', { year: 'numeric', month: 'short', day: 'numeric' }) }}</span>
            </div>
            <p class="description">{{ comment.description }}</p>
          </div>
        </div>
        <a :href='"https://dartshopper.picqer.com/orders/" + currentOrderId' target="_blank">Go to picqer</a>
      </div>
    </div>
  </vue-final-modal>

  <vue-final-modal style="height: 100%;" v-model="showFilters" :esc-to-close="true" v-slot="{ close }" :click-to-close="true">
    <div class="modal-styling-filters">
      <div class="modal-header">
        <span>Filter</span>
        <i @click="close" class='bx bx-x'></i>
      </div>
      <div class="modal-body">

        <div class="status">
          <span>Status:</span>
          <VueMultiselect
            v-model="filters.statuses"
            @select="onChangeStatus"
            :options="filterStatusArrayOptions.filter(x => x !== 'None')"
            :multiple="true"
            placeholder="All statuses">
          </VueMultiselect>
        </div>
       
        <div class="workers">
          <span>Workers:</span>
          <VueMultiselect
            v-model="filters.workers"
            @select="onChangeWorkers"
            :options="bedrukkers.filter(x => x.role === 4).sort((a, b) => a.firstName.localeCompare(b.firstName)).map(function(el) {
              var o = Object.assign({}, el);
              o.fullName = o.firstName + ' ' + o.lastName;
              return o;
            })"
            :multiple="true"
            label="fullName"
            placeholder="All workers"
            track-by="fullName">
          </VueMultiselect>
        </div>

        <div class="btn-wrap">
          <button class="save" @click="changeFilters">Save</button>
          <button class="clear" @click="clearFilters">Clear filters</button>
        </div>

      </div>
    </div>
  </vue-final-modal>

  <Page>
    <Loading v-model:active="loading" :can-cancel="false"/>

    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Printing</div>
        <div class="subtitle">An overview to manage the personalised orders of Dartshopper</div>
      </div>
    </div>
    <div v-if="!loading" class="menu">
      <div class="menu-left">
        <input class="menu-search" @input="onChangeSearch" v-on:keyup.enter="changeFilters(true)" placeholder="Search" type="text"/>
        <div class="menu-filter" @click="openFilters">
          <i class='bx bx-filter-alt'></i>
          Filters
        </div>
      </div>
      <div class="menu-right">
        <div v-if="!loading && !nothingFound" class="menu-pagination">
          <div class="pagination-item-group">
            <!-- {{ orders[0]?.totalCount }} items • -->
            <div class="pagination-item prev" @click="changePagination(false, -1)" :disabled="page === 0">
              <i class='bx bx-chevron-left'></i>
            </div>
            <div v-for="index in Math.ceil(orders[0]?.totalCount / 10)" :key="index" style="display:inline-block; ">
              <span v-if="orders[0]?.totalCount > 4 && index === 4">&nbsp;...&nbsp;</span>
              <div v-if="(orders[0]?.totalCount > 3 && index < 4 || index === Math.floor(orders[0]?.totalCount / 10) + 1)" class="pagination-item" @click="changePagination(null, index - 1)">{{index}}</div>
            </div>
            <div class="pagination-item next" @click="changePagination(true, 1)" :disabled="page + 1 === Math.ceil(orders[0]?.totalCount / 10)">
              <i class='bx bx-chevron-right' ></i>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <!-- <div class="employee-date-picker" v-if="bedrukkers.length > 0" style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
      <div class="cards second">
        <div class="card card-1">
          <div id="employees-select">
            
          </div>
        </div>
        <div class="card card-2"  v-show="showGraph">
          <div class="datepicker">
            <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
              <p>Pick your date</p>
              <i class="bx bxs-chevron-down arrow"></i>
            </div>
            <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown">
              <div class="datepicker-card">
                <div class="title">
                  Pick your date
                </div>
                <div class="subtitle">
                  
                </div>
              </div>
              <div class="datepicker-presets">
                <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Today'}" @click="changeDatePreset">Today</a>
                <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Yesterday'}" @click="changeDatePreset">Yesterday</a>
                <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '1 month'}" @click="changeDatePreset">1 month</a>
                <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '3 months'}" @click="changeDatePreset">3 months</a>
              </div>
              <div class="datepicker-customSelector">
                <Datepicker 
                  v-model="date" 
                  @update:modelValue="handleDate"
                  range 
                  weekNumbers 
                  :enableTimePicker="false" 
                  locale="nl" 
                  placeholder="Custom..." 
                  textInput 
                  cancelText="cancel" 
                  selectText="apply" 
                  :maxDate="new Date()"
                  :minDate="new Date(2022, 5, 13)"
                  position="right"
                  autoPosition
                />
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div> -->

    <div v-if="!loading" class="table-container">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="order-nr">Order details</th>
            <th class="worker">Worker</th>
            <th class="status">Status</th>
            <th class="tags">Tags</th>
            <th class="date">Date</th>
            <th class="more-info"></th>
          </tr>
        </thead>
        <template v-if="!loading && !nothingFound">
          <tbody v-for="(order, i) in orders" :key="i"> 
            <tr>
              <td class="order-nr" >
                <div class="order-nr-wrapper">
                  <router-link :to="'/printing/order/' + order?.orderInformation?.data.reference">{{ order?.orderInformation?.data.reference }}</router-link>
                  <div class="comments" @click="openComments(order?.orderInformation?.data.idorder);">
                    <i class='bx bx-message-detail'></i>
                  </div>
                </div>
              </td>
              <!-- <td>{{ order?.orderInformation?.data.products.every((x) => x?.remarks !== null ? x?.remarks.includes('colorlab:') : "Nee"  )}}</td> -->
              <SelectWorker @trigger="close" :order="order" :bedrukkers="bedrukkers"></SelectWorker>

              <!-- <div class="worker-wrapper">
                <div v-if="!order.printingInformation.pickerId" class="add-worker" @click="showWorkerDropdown(i)">
                  <span>+</span>
                </div>
                <div v-else @click="showWorkerDropdown(i)" class="specific-worker"
                  v-for="bedrukker in bedrukkers.filter(x => parseInt(x.pickerId) === parseInt(order.printingInformation.pickerId)) ">
                  <span>{{ bedrukker.firstName.charAt(0) + bedrukker.lastName.charAt(0) }}</span>
                </div>
                <div class="select-worker">
                  <select v-model="order.printingInformation.pickerId" style="color: black;" @change="handleWorker(order?.orderInformation?.data.reference, $event, i);">
                    <option value="0">No worker</option>
                    <option :value="bedrukker.pickerId" v-for="bedrukker in bedrukkers.filter(x => x.role === 4)" :key="bedrukker.pickerId">{{ bedrukker.firstName + " " + bedrukker.lastName}}</option>
                  </select>
                </div>
              </div> -->

              <SelectStatus @trigger="close" :order="order" :bedrukkers="bedrukkers"></SelectStatus>

              <!-- <td class="status" >
                <div class="status-wrapper">

                  <div @click="showStatusDropdown(i)" class="status-button-main exclude_status_button_main" 
                    :style="{'background-color': order.printingInformation.status === 1 ? '#E8697D' : 
                    order.printingInformation.status === 2 ? '#FFB545' :
                    order.printingInformation.status === 3 ? '#339ECD' :
                    order.printingInformation.status === 4 ? '#33D391' : '#E8697D'}">
                    {{ order.printingInformation.status === 0 ? statusArrayOptions[1] : statusArrayOptions[order.printingInformation.status] }}
                  </div>
                  <div class="select-status">
                    <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 1, i); hideStatusDropdown(i);" style="background-color: #E8697D;">To do</div>
                    <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 2, i); hideStatusDropdown(i);" style="background-color: #FFB545;">In progress</div>
                    <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 3, i); hideStatusDropdown(i);" style="background-color: #339ECD;">Prepared</div>
                    <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 4, i); hideStatusDropdown(i);" style="background-color: #33D391;">Done</div>
                  </div>

                </div>
              </td> -->
              <td class="tags">
                <div class="tags-wrapper">
                  <div class="tag" v-for="(tag, i) in order?.orderInformation?.data.tags">
                    <template v-if="tag.title.toLowerCase().includes('dartshopper')">
                      <span class="dot" :style="{'background-color': tag.color}"></span>
                      <span class="title" :style="{'border-color': tag.color}">{{ tag.title.split('.')[1] }}</span>
                    </template>
                  </div>
                </div>
              </td>
              <td class="date">
                <div class="date-wrapper">
                  <div class="created">{{ new Date(order?.orderInformation?.data.created).toLocaleTimeString('en-NL', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })  }}</div>
                  <div class="updated">updated {{ new Date(order?.orderInformation?.data.updated).toLocaleTimeString('en-NL', { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit'})  }}</div>
                </div>
              </td>
              <td class="more-info" @click="showDropdown[i] = !showDropdown[i]">
                <i class='bx bx-chevron-down'></i>
              </td>
              <!-- <td>
                <svg style="cursor: pointer; " @click="showDropdown[i] = !showDropdown[i]" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m13 16.75c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm2.195-5.992 2.746 2.999c.142.154.342.243.552.243s.41-.088.553-.242l2.757-2.999c.132-.144.197-.326.197-.507 0-.684-.841-1.008-1.303-.508l-2.202 2.397-2.194-2.396c-.46-.503-1.303-.175-1.303.507 0 .18.065.362.197.506zm-2.195.992c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm0-5c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/></svg>
              </td>  -->
          </tr>
          <Transition name="scale">
          <tr class="tr-order-information" v-show="showDropdown[i]">
            <td class="order-information">
              <h3>Products</h3>
              <div v-if="!loading && !nothingFound" class="products-table-container">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <!-- <th>Colorlab ID</th> -->
                      <th>Type</th>
                      <th>Customer</th>
                      <th>Quantity</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody v-if="order?.orderInformation?.data?.products.filter((product) => {
                    if (product?.remarks === null) {
                      return false; // skip
                    }
                    return true;
                    }).filter(order => order?.remarks.includes('colorlab:')).length > 0">

                    <tr v-for="(orderProducts, i) in order?.orderInformation?.data?.products.filter((product) => {
                      if (product?.remarks === null) {
                        return false; // skip
                      }
                      return true;
                      }).filter(order => order?.remarks.includes('colorlab:'))" :key="i">

                      <td class="type">
                        {{ orderProducts?.name }}
                      </td>
                      <td class="customer">
                        <span>{{ order?.orderInformation?.data?.picklists[0]?.deliveryname }}</span>
                      </td>
                      <td class="quantity">
                        {{ orderProducts?.amount }}
                      </td>
                      <td class="view" @click="getFileByColorlabId(orderProducts?.remarks.split('colorlab:')[1], orderProducts?.idorder_product)">
                        <i class='bx bxs-show' ></i>
                      </td>
                      <!-- <td>{{ order?.remarks.split('colorlab:')[1] }}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
          </Transition>
        </tbody>
        </template>
        <tbody v-else class="nothing-found">
          <tr>
            <td colspan="100%">
              <i class='bx bx-search'></i>
              <span>No orders have been found. Clear or change your filters.</span>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- <div style="text-align: right; padding: 30px;" v-if="picklistsCountPerDay.length > 0 || page !== 1">
        {{ picklistsCountPerDayAmount }} items • {{ page + 1 }} from {{ Math.ceil(picklistsCountPerDayAmount / amountPagination) }}
        <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(false, null)" :disabled="page === 0">Prev</button>
        <div v-for="index in Math.ceil(picklistsCountPerDayAmount / amountPagination)" :key="index" style="display:inline-block; ">
          <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(false, index - 1)">{{index}}</button>
        </div>
        <button style="margin-left: 5px; padding: 5px; padding-left: 8px; padding-right: 8px;" @click="changePagination(true, null)" :disabled="page + 1 === Math.ceil(picklistsCountPerDayAmount / amountPagination)">Next</button>
        <select style="padding-top: 5px;margin-left: 5px; padding: 4px; padding-left: 8px; padding-right: 8px;" @change="changeAmountPagination">
          <option v-if="picklistsCountPerDayAmount >= 5">5</option>
          <option selected>10</option>
          <option v-if="picklistsCountPerDayAmount >= 15">15</option>
          <option v-if="picklistsCountPerDayAmount >= 30">30</option>
        </select>
      </div> -->
    </div>

    <div v-if="!loading && !nothingFound" class="footer">
      <div class="footer-right">
        <div class="footer-pagination">
          <div class="pagination-item-group">
            <!-- {{ orders[0]?.totalCount }} items • -->
            <div class="pagination-item prev" @click="changePagination(false, -1)" :disabled="page === 0">
              <i class='bx bx-chevron-left'></i>
            </div>
              <div v-for="index in Math.ceil(orders[0]?.totalCount / 10)" :key="index" style="display:inline-block; ">
                <span v-if="orders[0]?.totalCount > 4 && index === 4">&nbsp;...&nbsp;</span>
                <div v-if="(orders[0]?.totalCount > 3 && index < 4 || index === Math.floor(orders[0]?.totalCount / 10) + 1)" class="pagination-item" @click="changePagination(null, index - 1)">{{index}}</div>
              </div>
            <div class="pagination-item next" @click="changePagination(true, 1)" :disabled="page + 1 === Math.ceil(orders[0]?.totalCount / 10)">
              <i class='bx bx-chevron-right' ></i>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </Page>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, computed, watchEffect, onUnmounted, onUpdated, onBeforeMount, onBeforeUpdate } from "vue";
import Datepicker from '@vuepic/vue-datepicker';

import Page from '@/components/Page.vue'
import TabsWrapper from '@/components/TabsWrapper.vue' 
import Tab from '@/components/Tab.vue'
import getUser from '../composeables/getUser'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import SelectWorker from '@/components/printing/SelectWorker.vue'
import SelectStatus from '@/components/printing/SelectStatus.vue'

import { OnClickOutside } from '@vueuse/components'

import '@vuepic/vue-datepicker/dist/main.css';

import { LineChart, useLineChart } from 'vue-chart-3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';
import { notify } from "@kyvg/vue3-notification";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import "vue-multiselect/dist/vue-multiselect.css";

import pdf from 'pdfvuer'
import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import VueMultiselect from 'vue-multiselect'

export default {
  components: { Page, TabsWrapper, Tab, Datepicker, Loading, pdf, 
    VueFinalModal, ModalsContainer, VueMultiselect, SelectWorker, SelectStatus },
  name: "printing",
  data(){
    return{
      gradient: "",
      currentIdorder_product: "",
    }
  },
  setup () {
    const user = ref({});
    const router = useRouter()
    const route = useRoute();
    const filterStatusArrayOptions = ref([
      "None",
      "To do",
      "In progress",
      "Prepared",
      "Done"
    ])
    const statusArrayOptions = ref([
      "None",
      "To do",
      "In progress",
      "Prepared",
      "Done"
    ])

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
    
    Chart.register(...registerables);
    Chart.register(annotationPlugin);
    
    const toggleWorker = ref(Array(10).fill(false));
    const showDropdown = ref(Array(10).fill(false));
    const toggleStatus = ref(Array(10).fill(false));
    const statusArray = ref(Array(10).fill(1));
    const workerArray = ref(Array(10).fill("0"));
    const nothingFound = ref(false);
    const date = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const picklistsCountPerDay = ref([]);
    const datePreset = ref(null);
    const datePresetRow = ref(null);
    const Splitted = ref({Singles: {Klein: "0", Groot: "0"}, More: {Klein: "0", Groot: "0"}}); 
    const RowSplitted = ref({Singles: [{Klein: "0", Groot: "0"}], More: [{Klein: "0", Groot: "0"}]}); 
    const RowSplittedList = ref([]);
    const bgColor = ref('rgba(58, 175, 227, 0.3)');
    const pickerOrPacker = ref("&packer=false&picker=true");
    const pickerOrPackerRow = ref("&packer=false&picker=true");
    const page = ref(0);
    const showFilters = ref(false);
    const amountPagination = ref(10);
    const orders = ref([{totalCount: 0, orderInformation: { data: { products: [], picklists: [], tags: []}}, printingInformation: { status: 0}} ]);
    const filters = ref({statuses: [], workers: [], search: ""})
    const toDoNoMultiple = ref(true);

    const setDateInCookie = (date) => {
      cookies.set("date", JSON.stringify(date));
    }

    const onChangeStatus = (data) => {

      if(filterStatusArrayOptions.value.indexOf(data) === 1)
      {
        toDoNoMultiple.value = false;
      }
      else
      {
        toDoNoMultiple.value = true;
      }

      filters.value.statuses = filterStatusArrayOptions.value.indexOf(data);
    }

    const onChangeWorkers = (data) => {
      filters.value.workers = data.pickerId;
    }
    
    const onChangeSearch = (e) => {
      filters.value.search = e.target.value;
    }

    onBeforeMount( async () => {
      user.value = cookies.get('token');
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];
      
      if("startDate" in route.query)
      {
        startDate = route.query.startDate;
      }

      if("endDate" in route.query)
      {
        endDate = route.query.endDate;
      }

      if("statuses" in route.query)
      {
        let queryArray = route.query.statuses.split(',');
        let statusArrayInText = queryArray.map(x => { return statusArrayOptions.value.filter((y, i) => i === parseInt(x))[0]; } );
        filters.value.statuses = route.query.statuses !== "" ? statusArrayInText : [];; 
      }

      if("workers" in route.query)
      {
        let queryArray = route.query.workers.split(',');
        filters.value.workers = route.query.workers !== "" ? queryArray : [];
      }
      
      loading.value = true;
      fetchDataAfterUpdated();
      loading.value = false;
    })

    const handleStatus = (orderId, statusNumber, i) => {
      statusArray.value[i] = statusNumber;
      
      toggleStatus.value[i] = !toggleStatus.value[i];
      orders.value[i].printingInformation.status = statusNumber;

      updateOrder(orderId, statusNumber, 'status');
    }

    const openFilters = () => {
      showFilters.value = !showFilters.value;
    }

    const handleWorker = (orderId, event, i) => {
      workerArray.value[i] = event.target.value;
      orders.value[i].printingInformation.pickerId = event.target.value;
      toggleWorker.value[i] = !toggleWorker.value[i];

      updateOrder(orderId, event.target.value, 'worker');
    }

    // Updates query parameters, make sure this happens after all cookies have been altered.
    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;
      fetchDataAfterUpdated();
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    }

    const closeAllDropDowns = () => {
      showDropdown.value = Array(10).fill(false);
    }

    const changePagination = async (minusPlus, index) => {
      closeAllDropDowns();
      nothingFound.value = false;
      loading.value = true;
      // Can be globally adjused to let users choose how many results they want
      let limit = 10;
      let skip = 0;

      if(minusPlus === null)
      {
        page.value = index;
      }
      else
      {
        page.value = minusPlus ? page.value + 1 : page.value - 1;
      }
        
      skip = limit * page.value;
      
      if(page.value >= 0)
      {
        cookies.set("page", page.value);
        
        const string = "&limit=" + limit + "&skip=" + skip;
      
        // Fetch
        fetchPersonalizedOrders(string)
        .then(res => res.json())
        .then(result => {
          if(result.length === 0)
          {
            nothingFound.value = true;
            nothingFound.value.update();
          }

          orders.value = result;
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            })
          }
        })
      }

    }

    async function clearFilters() {
      filters.value = {statuses: [], workers: [], search: ""};
      const query = {
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        statuses: String(filters.value.statuses.map((x) => { return statusArrayOptions.value.indexOf(x)})) ?? [],
        workers: String(filters.value.workers.map((x) => { return x.pickerId })) ?? [],
      }

      nothingFound.value = false;
      await updateQueryParameters(query);
      closeAllDropDowns();
    }

    async function changeFilters(filteringThroughSearch) {
      closeAllDropDowns();
      const query = {
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        statuses: String(filters.value.statuses.map((x) => { return statusArrayOptions.value.indexOf(x)})) ?? [],
        workers: String(filters.value.workers.map((x) => { return x.pickerId })) ?? [],
      }

      nothingFound.value = false;
      await updateQueryParameters(query);

      if(filteringThroughSearch === null || filteringThroughSearch === undefined) // Filtering without search
      {
        openFilters();
      }
    }

    async function fetchPersonalizedOrders(limitskipString)
    {
      const { user } = getUser();
      const array = date.value;

      if(limitskipString === "")
      {
        limitskipString = "&skip=0";
      }

      let statusesIds = String(filters.value.statuses.map((x) => { return statusArrayOptions.value.indexOf(x)}));
      let pickerIds = filters.value.workers;
      
      if(filters.value.workers.length > 0)
      {
        if(typeof filters.value.workers[0] === 'object' && filters.value.workers[0] !== null)
        {
          pickerIds = String(filters.value.workers.map((x) => x.pickerId));
        }
      }
      

      return await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/total?startdate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString()+ "&enddate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString() 
      + "&statuses=" + statusesIds + "&workers=" + pickerIds + "&search=" + filters.value.search.toUpperCase() + limitskipString
      , { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
    }

    async function fetchDataAfterUpdated() {
      fetchPersonalizedOrders("")
        .then(res => res.json())
        .then(result => {
          if(result.length === 0)
          {
            nothingFound.value = true;
            nothingFound.value.update();
          }
          orders.value = result;
          orders.value.update();
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            })
          }
        })
    }

    return { orders, bgColor, user, pickerOrPackerRow, changePagination, page, pickerOrPacker, 
    datePresetRow, RowSplitted, RowSplittedList, Splitted, picklistsCountPerDay, nothingFound,
    datePreset, loading, error, date, changeDatePreset, handleSelectedWorker, toDoNoMultiple,
    toggleWorker, toggleStatus, statusArray, workerArray, handleStatus, handleWorker, 
    hideDatepickerDropdown, showDropdown, statusArrayOptions, filterStatusArrayOptions, openFilters, showFilters, 
    filters, clearFilters, changeFilters, onChangeStatus, onChangeWorkers, onChangeSearch, close};
  },
  data() {
    return{
      bedrukkers: [],
      errorMessage: "",
      showError: false,
      loading: false,
      page: 1,
      numPages: 0,
      base64: "",
      newPdf: false,
      scale: 2,
      pdfdata: undefined,
      showModal: false,
      showComments: false,
      comments: [],
      comment: "",
      productId: 0,
      currentOrderId: "",
    }
  },
  computed: {
    formattedZoom () {
        return Number.parseInt(this.scale * 100);
    },
    fullString() {
      return "data:application/pdf;base64," + this.base64
    }
  },
  watch: {
    show: function (s) {
      if(s) {
        this.getPdf();
      }
    }
  },
  methods: {
    showWorkerDropdown(){
      var dropdown = document.querySelectorAll(".worker-wrapper .select-worker");
      var button = document.querySelectorAll(".worker-wrapper .add-worker");

      if (dropdown.length > 0) {
        dropdown[i].classList.toggle("open");
      }
      if (button.length > 0) {
        button[i]?.classList.toggle("active");
      }
    },
    hideWorkerDropdown(){
      var dropdown = document.querySelectorAll(".worker-wrapper select-worker");
      var button = document.querySelectorAll(".worker-wrapper .add-worker");

      if (dropdown.length > 0) {
        dropdown[i].classList.remove("open");
      }
      if (button.length > 0) {
        button[i]?.classList.remove("active");
      }
    },
    showStatusDropdown(i){
      var dropdown = document.querySelectorAll(".status-wrapper .select-status");
      var button = document.querySelectorAll(".status-wrapper .status-button-main");

      if (dropdown.length > 0) {
        dropdown[i].classList.toggle("open");
      }
      if (button.length > 0) {
        button[i]?.classList.toggle("active");
      }
    },
    hideStatusDropdown(i){
      var dropdown = document.querySelectorAll(".status-wrapper .select-status");
      var button = document.querySelectorAll(".status-wrapper .status-button-main");

      if (dropdown.length > 0) {
        dropdown[i].classList.remove("open");
      }
      if (button.length > 0) {
        button[i]?.classList.remove("active");
      }
    },
    async getFileByColorlabId (colorlabId, idorder_product) {
      this.newPdf = false;
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/file?exportTrue=true&colorlabId=" + colorlabId.replace(/\s/g, ''));
      const data = await res.text();

      this.productId = idorder_product;

      this.base64 = data;
      this.newPdf = true;
      this.getPdf();
      this.newPdf = false;
      this.openModalExample()
    },
    async sendComment() {
      this.loading = true;
      let postBody = {};
      postBody.note = this.comment;

      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/comments/create?orderId=" + this.currentOrderId, {
        method: "POST", 
        body: JSON.stringify(postBody)
      });
      const data = await res.json();

      this.loading = false;
      this.openComments(this.currentOrderId);
      this.showComments = true;
    },
    async openComments(orderId) {
      this.currentOrderId = orderId;
      this.loading = true;
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/comments?orderId=" + orderId);
      const data = await res.json();

      this.comments = data.filter(x => x.type === "comment");
      let users = JSON.parse(JSON.stringify(this.bedrukkers));
      this.comments = this.comments.map(function(el) {
      let user = users.find((x) => parseInt(x.pickerId) === el.iduser); 
                        var o = Object.assign({}, el);
                        o.fullName = user?.firstName + ' ' + user?.lastName;
                        return o;
                      })
      this.loading = false;
      this.showComments = true;
    },
    openModalExample() {
      this.showModal = true;
    },
    getPdf () {
      var self = this;
      
      this.pdfData = undefined;
      // try {
        self.pdfdata = pdf.createLoadingTask("data:application/pdf;base64," + this.base64);
      // }
      // catch(e)
      // {
      //   notify({
      //     title: `Colorlab Error`,
      //     text: `File is not in PDF format.`,
      //     type: 'error',
      //     duration: 5000,
      //   });
      // }
      self.pdfdata.then(pdf => {
        self.numPages = pdf.numPages;
      });
    },
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    async users(){
      try {
        const { user } = getUser();
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
        this.bedrukkers = await res.json()
      } catch (error) {
        this.errorMessage = error
        this.showError = true
      }
    }
  },
  async created() {
    this.loading = true;
    await this.users()
    this.loading = false;
  }
}
</script>
<style lang="scss" scoped>
.scale-enter-active {
  animation: scale-in 0.3s;
}
.scale-leave-active {
  animation: scale-in 0.3s reverse;
}
@keyframes scale-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.0rem;
  background-color: white;
  border: 1px solid #D7DCE1;
  border-bottom: 0;
  border-radius: $border-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  .menu-left {
    display: flex;
    width: 100%;
    align-items: center;
    .menu-search {
      height: 40px;
      border: 1px solid #D7DCE1;
      border-radius: $border-radius;
      padding: 6px;
      padding-left: 18px;
      padding-right: 18px;
      max-width: 300px;
      width: 100%;
      &:focus-visible {
        outline: none !important;
        border-color: #3AAFE3;
        box-shadow: 0 0 4px #3aafe3;
      }
      &::placeholder { 
        color: gray;
        opacity: 1; 
      }
    }
    .menu-filter {
      padding: 0 1.6rem;
      cursor: pointer;
      height: 40px;
      border: 1px solid #D7DCE1;
      border-radius: 0.6rem;
      display: flex;
      align-items: center;
      margin-left: 0.8rem;
      i {
        margin-right: 4px;
      }
    }
  }
  .menu-right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .menu-pagination {
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pagination-item-group{
        display: flex;
        align-items: center;
        .pagination-item {
          cursor: pointer;
          padding: 0 10px;
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }
        .prev, .next {
          border: 1px solid #D7DCE1;
          min-height: 40px;
          min-width: 40px;
          max-height: 40px;
          max-width: 40px;
          display: flex; 
          align-items: center;
          justify-content: center;
          border-radius: $border-radius;
          &:hover {
            text-decoration: unset;
          }
          i {
            font-size: 24px;
          }
        }
        .prev {
          margin-right: 10px;
        }
        .next {
          margin-left: 10px;
        }
      }
    }
  }
}

// .employee-date-picker {
//   .cards .card-2 {
//     padding: 1.6rem;
//     width: calc(50% - 1.6rem);
//     .datepicker-customSelector {
//       width: 100%;
//       margin: 0 auto;
//     }
//     .datepicker-presets {
//       display: flex;
//       justify-content: between;
//       .date-buttons {
//         margin-right: 0.8rem;
//         margin-bottom: 1.6rem;
//         border: 1px solid #D7DCE1;
//         background-color: #FFF;
//         border-radius: 6px;
//         font-size: 18px;
//         padding-top: 6px;
//         padding-bottom: 6px;
//         padding-left: 12px;
//         padding-right: 12px;
//         cursor: pointer;
//         &:nth-child(4){
//           margin-right: 0.0rem;
//         }
//       }
//       .date-buttons:hover {
//         background-color: rgba(245, 246, 248, 0.55)
//       }
//       .date-buttons.active {
//         background-color: #F5F6F8;
//       }
//     }
//   }
// }

.tr-order-information {
  display: flex;
  height: 100%;
}

.order-information {
  background: #F7F7F7;
  box-shadow: inset 0px 0px 10px rgb(46 48 63 / 10%);
  padding: 3.0rem;
  padding-top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    padding-top: 2.0rem;
    font-size: 18px;
  }
  .products-table-container{
    background-color: white;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid #D7DCE1;
    .table{
      width: 100%;
      display: block;
      border-spacing: 0;
    }
    .table-striped{
      thead{
        width: 100%;
        display: flex;
        padding: 3.0rem;
        border-bottom: 1px solid #D7DCE1;
        tr{
          width: 100%;
          display: flex;
          justify-content: space-between;
          th {
            width: calc((100% / 4) - (1.6rem / 3));
            margin-right: 1.6rem;
            border: 0;
            padding: 0;
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
      tbody{
        width: 100%;
        display: flex;
        padding: 3.0rem;
        tr{
          width: 100%;
          display: flex;
          justify-content: space-between;
          th, td {
            width: calc((100% / 4) - (1.6rem / 3));
            margin-right: 1.6rem;
            border: 0;
            // padding: 1.6rem;
            &:last-child {
              margin-right: 0;
            }
          }
          td.view {
            i {
              font-size: 24px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.table-container{
  background-color: white;
  width: 100%;
  border: 1px solid #D7DCE1;
  border-top: 0;
  border-bottom: 0;
  .table{
    width: 100%;
    border-spacing: 0;
  }
  .table-striped{
    thead{
      width: 100%;
      tr{
        width: 100%;
        display: flex;
        th.order-nr {
          min-width: 180px;
          max-width: 180px;
        }
        th.worker {
          min-width: 120px;
          max-width: 120px;
        }
        th.status {
          min-width: 120px;
          max-width: 120px;
        }
        th.tags {
          width: 100%;
        }
        th.date {
          min-width: 180px;
          max-width: 180px;
        }
        td.more-info {
          min-width: 54px;
          max-width: 54px;
        }
        th{
          border-bottom: 1px solid #D7DCE1;
          text-align: center;
          padding: 1.6rem 1.6rem;
          color: #9397A1;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    tbody{
    width: 100%;
      tr{
      width: 100%;
      display: flex;
      justify-content: space-between;
        td.order-nr {
          min-width: 180px;
          max-width: 180px;
          padding: 1.0rem 2.56rem;
          display: flex;
          align-items: center;
          justify-content: center;
          .order-nr-wrapper{
            display: flex;
            align-items: center;
            a{
              color: #3AAFE3;
              text-decoration: underline;
              margin-right: 6px;
              width: 90px;
            }
            .comments {
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              i {
                font-size: 20px;
                color: #9397A1;
              }
            }
          }
        }
        td.worker {
          min-width: 120px;
          max-width: 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
        td.status {
          min-width: 120px;
          max-width: 120px;
          position: relative;
        }
        td.tags {
          width: 100%;
          padding: 1.0rem 2.56rem;
          display: flex;
          align-items: center;
          .tags-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .tag {
              display: flex;
              align-items: center;
              border: 1px solid #D7DCE1;
              height: 24px;
              border-radius: $border-radius;
              padding: 0 0.8rem;
              &:empty {
                display: none;
              }
              .dot {
                min-height: 6px;
                max-height: 6px;
                min-width: 6px;
                max-width: 6px;
                border-radius: 100%;
                margin-right: 4px;
              }
              .title {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
              }
            }
          }
        }
        td.date {
          min-width: 180px;
          max-width: 180px;
          padding: 1.0rem 2.56rem;
          .date-wrapper {
          display: flex;
          flex-direction: column;
          .created {
            white-space: nowrap;
          }
          .updated {
            white-space: nowrap;
            font-size: 12px;
            color: #9397A1;
          }
          } 
        }
        td.more-info {
          min-width: 54px;
          max-width: 54px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          i {
            font-size: 24px;
          }
        }
        td, th{
          border-bottom: 1px solid #D7DCE1;
          border-right: 1px solid #D7DCE1;
          text-align: left;
          font-size: 14px;
          &:last-child{
            border-right: 0;
          }
          .td-wrapper{
            display: flex;
            align-items: center;
            i {
              cursor: pointer;
              font-size: 20px;
              transition: all 0.2s;
            }
            .active {
              transform: rotate(-90deg);
              transition: all 0.2s;
            }
            .table-row-splitted{
              display: flex;
              flex-direction: row;
              margin-left: 20px;
              transition: all 0.2s;
              min-width: 300px;
              .table-row-split{
                border: 1px solid #D7DCE1;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                border-radius: 5px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
                img{
                  height: 14px;
                  margin-right: 8px;
                }
              }
            }
          }
        }
        &:last-child td, &:last-child th {
          border-bottom: none;
        }
      }
    }
    tbody.nothing-found {
      padding: 3.0rem;
      display: flex;
      border-bottom: 1px solid #D7DCE1;
      tr {
        width: 100%;
        td{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          i {
            color: #9397A1;
            font-size: 24px;
            margin-right: 10px;
          }
          span {

          }
        }
      }
    }
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3.0rem;
  background-color: white;
  border: 1px solid #D7DCE1;
  border-top: 0;
  border-radius: $border-radius;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  .footer-right {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    .footer-pagination {
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      .pagination-item-group{
        display: flex;
        align-items: center;
        .pagination-item {
          cursor: pointer;
          padding: 0 10px;
          font-weight: bold;
          &:hover {
            text-decoration: underline;
          }
        }
        .prev, .next {
          border: 1px solid #D7DCE1;
          min-height: 40px;
          min-width: 40px;
          max-height: 40px;
          max-width: 40px;
          display: flex; 
          align-items: center;
          justify-content: center;
          border-radius: $border-radius;
          &:hover {
            text-decoration: unset;
          }
          i {
            font-size: 24px;
          }
        }
        .prev {
          margin-right: 10px;
        }
        .next {
          margin-left: 10px;
        }
      }
    }
  }
}

.pdf-modal-styling{
  position: absolute;
  top: 50%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%); 
  max-width: 540px;
  width: 100%;
  background-color: #FFF; 
  border-radius: $border-radius;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #D7DCE1; 
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  .close {
    font-size: 24px;
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s ease;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 99999999999999;
    &:hover{
      opacity: 1;
    }
  }
  .pdf-wrapper {
    position: relative;
    max-height: 60vh;
    overflow-y: auto;
    div {
      padding: 1.6rem;
    }
  }
  .buttons {
    width: 100%;
    border-top: 1px solid #D7DCE1;
    padding: 3.0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      min-width: 40px;
      min-height: 40px;
      border: 1px solid #D7DCE1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: $border-radius;
      i {
        font-size: 24px;
        color: #0B212D;
      }
    }
  }
}

.modal-styling-comments {
  position: absolute;
  top: 50%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%); 
  max-width: 540px;
  width: 100%;
  max-height: 80%;
  overflow-y: auto;
  background-color: #FFF; 
  border-radius: $border-radius;
  border: 1px solid #D7DCE1; 
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  .modal-header {
    padding: 3.0rem; 
    padding-top: 2.0rem;
    border-bottom: 1px solid #D7DCE1;
    .name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.6rem;
      span {
        font-family: "Neutrif-bold";
        font-size: 18px;
      }
      i {
        font-size: 24px;
        cursor: pointer;
        opacity: 0.5;
        transition: all 0.3s ease;
        &:hover{
          opacity: 1;
        }
      }
    }
    textarea {
      width: 100%;
      height: 8.0rem;
      margin-bottom: 1.6rem;
      border: 1px solid #D7DCE1;
      border-radius: $border-radius;
      padding: 1.0rem;
      resize: none;
      font-family: "Neutrif-reg";
      color: #0B212D;
      font-size: 14px;
      &::placeholder {
        color: #D7DCE1;
      }
      &:focus-visible {
        outline: none !important;
        border-color: #3AAFE3;
        box-shadow: 0 0 4px #3AAFE3;
      }
    }
    .place {
      display: flex;
      align-items: center;
      background-color: #3AAFE3;
      color: white;
      padding: 0 1.6rem;
      font-size: 14px;
      border-radius: 0.6rem;
      cursor: pointer;
      height: 4.0rem;
      font-family: "Neutrif-bold";
      transition: all 0.3s ease;
      border: 0;
    }
  }
  .modal-body {
    .comment {
      padding: 3.0rem;
      border-bottom: 1px solid #D7DCE1;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: 0;
      }
      .avatar {
        min-height: 40px;
        min-width: 40px;
        background: #0ABB87;
        border-radius: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Neutrif-bold";
        margin-right: 1.6rem;
      }
      .content {
        width: 100%;
        .name {
          font-size: 16px;
          font-family: "Neutrif-bold";
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            font-size: 12px;
            font-family: "Neutrif-reg";
            margin-left: 0.8rem;
            color: #9397A1;
            margin-top: 1px;
          }
          .time {
            flex: 1;
          }
        }
        .description {

        }
      }
    }
    a {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 3.0rem;
      color: #3AAFE3;
      text-decoration: underline;
    }
  }
}

.modal-styling-filters {
  position: absolute;
  top: 50%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%); 
  max-width: 540px;
  width: 100%;
  max-height: 80%;
  background-color: #FFF; 
  border-radius: $border-radius;
  border: 1px solid #D7DCE1; 
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  width: 100%;
  .modal-header {
    padding: 3.0rem; 
    border-bottom: 1px solid #D7DCE1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: "Neutrif-bold";
      font-size: 18px;
    }
    i {
      font-size: 24px;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.3s ease;
      &:hover{
        opacity: 1;
      }
    }

  }
  .modal-body {
    padding: 3.0rem;
    .status {
      display: flex;
      align-items: center;
      margin-bottom: 1.0rem;
      span{
        width: 100px;
      }
      .multiselect {
        margin-left: 1.6rem;
      }
    }
    .workers {
      display: flex;
      align-items: center;
      margin-bottom: 2.56rem;
      span{
        width: 100px;
      }
      .multiselect {
        margin-left: 1.6rem;
      }
    }
    .btn-wrap {
      display: flex;
      .save {
        display: flex;
        align-items: center;
        background-color: #3AAFE3;
        color: white;
        padding: 0 1.6rem;
        font-size: 14px;
        border-radius: 0.6rem;
        cursor: pointer;
        height: 4.0rem;
        font-family: "Neutrif-bold";
        transition: all 0.3s ease;
        border: 0;
        margin-right: 0.8rem;
      }
      .clear {
        display: flex;
        align-items: center;
        border: 1px solid #D7DCE1;
        background-color: #F5F6F8;
        color: #0B212D;
        padding: 0 1.6rem;
        font-size: 14px;
        border-radius: 0.6rem;
        cursor: pointer;
        height: 4.0rem;
        font-family: "Neutrif-bold";
        transition: all 0.3s ease;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) {
  .page-header{
    flex-direction: column;
    margin-bottom: 1.6rem;
    .title-wrapper{
      margin-bottom: 1.6rem;
      .title{
        font-size: 2.4rem;
      }
    }
  }
}
</style>