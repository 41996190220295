import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

const firebaseConfig = {
  apiKey: "AIzaSyB5qz12bIF4qIru2wTuD3i2sanAjLNMl-0",
  authDomain: "warehouse-dashboard-7d2a7.firebaseapp.com",
  projectId: "warehouse-dashboard-7d2a7",
  storageBucket: "warehouse-dashboard-7d2a7.appspot.com",
  messagingSenderId: "509971025959",
  appId: "1:509971025959:web:c8058d1e9085d482c53e02",
  measurementId: "G-52F7NDMQNR"
};

// init firebase
firebase.initializeApp(firebaseConfig)

const projectAuth = firebase.auth()
const projectFirestore = firebase.firestore()
const timestamp = firebase.firestore.FieldValue.serverTimestamp

export { projectAuth, projectFirestore, timestamp }