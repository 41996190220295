<template>
  <Page>
    <br>
    <b style="font-size: 24px;">OS</b><br>
    <span>Version: <b>{{systemInfo["General"]["version"]}}</b></span><br>
    <span>Current time: <b>{{systemInfo["General"]["timeCurrent"]}}</b></span><br>
    <span>Up since: <b>{{ currentUpTime }}</b></span><br><br>

    <b style="font-size: 24px;">Ram</b><br>
    <span>Total: <b>{{systemInfo["Ram"]["total"]}}</b></span><br>
    <span>Free: <b>{{systemInfo["Ram"]["free"]}}</b></span><br>
    <span>In use: <b>{{systemInfo["Ram"]["used"] }}</b></span><br><br>

    <b style="font-size: 24px;">Storage</b><br>
    <div v-for="disk in systemInfo['Disks']" :key="disk">
        <span>Location: <b>{{disk["fs"]}}</b></span><br>
        <span>Total: <b>{{disk["size"]}}</b></span><br>
        <span>Free: <b>{{disk["available"]}}</b></span><br>
        <span>Used: <b>{{disk["used"] }}</b></span><br><br>
    </div>

  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';
import filesize from 'filesize';

export default {
  components: { Page },
  name: "status",
  setup() {
    const user = ref({});
    const currentUpTime = ref("");
    const systemInfo = ref({
      "General": {
        "version": "",
        "timeCurrent": "",
        "timeUp": ""
      },
      "Ram": {
        "total": "",
        "free": "",
        "used": ""
      },
      "Network": {
        "stats": {}
      },
      "Disks": []
    });
    
    onMounted( async () => {
      const size = filesize.partial({base: 10, standard: "jedec"});
      user.value = cookies.get('token');
      
      let info = await fetchSystemInfo().then(res => res.json()).then(result =>{ systemInfo.value = result; }) ?? systemInfo.value;

      info["General"]["timeCurrent"] = moment(info["General"]["timeCurrent"]).format('YYYY-MM-DD HH:mm:ss').toString()

      info["Ram"]["total"] = size(info["Ram"]["total"]).toString();
      info["Ram"]["free"] = size(info["Ram"]["free"]).toString();
      info["Ram"]["used"] = size(info["Ram"]["used"]).toString();

      info["Disks"].forEach(element => {
        element["size"] = size(element["size"]).toString();
        element["available"] = size(element["available"]).toString();
        element["used"] = size(element["used"]).toString();
      });

      systemInfo.value = info; 

      var dateUpTime = new Date(info["General"]["timeCurrent"]); 
      dateUpTime.setSeconds(dateUpTime.getSeconds() - parseInt(info["General"]["timeUp"]))
      
      currentUpTime.value = moment(dateUpTime).format('YYYY-MM-DD HH:mm:ss').toString();
    })

    async function fetchSystemInfo()
    {
      const user = cookies.get('token');
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/systeminformation/all", { headers: {"Authorization": user ? `Bearer ${user.token}` : ""}})
    }

    return { user, systemInfo, currentUpTime }
  },
  data() {
    return{

    }
  },
  mounted() {
 
  }
}
</script>
<style lang="scss" scoped>

</style>
