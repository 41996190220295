import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import moment from 'moment';

export const getPicklistsAndOrdersPerHour = async (day) => { // Filters all the found hours, sorts them based on hour, for use within a 24-hour based graph
    const user = cookies.get('token');
    let start = moment(day).startOf('day').format('YYYY-MM-DD hh:mm').toString();
    let end = moment(day).endOf('day').format('YYYY-MM-DD hh:mm').toString();

    const res = await fetch(process.env.VUE_APP_ROOT_API + `/api/mysql/getIncomingOrders?startDate=${start}&endDate=${end}`, {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""}
    });

    let result = JSON.parse(JSON.stringify(await res.json()));
    console.log(result);

    let TwentyFourHourGraph = new Array(24).fill({
        OrdersPerHour: 0,
        PicklistsPerHour: 0,
        HourAndDay: ""
    });

    result.forEach((element, index) => {
            let hour = parseInt(element.HourAndDay.split(' ')[1]);
            TwentyFourHourGraph[hour] = element;
            console.log(element);
            console.log(TwentyFourHourGraph[hour]);
            return;
    });
    console.log(TwentyFourHourGraph);
    return TwentyFourHourGraph;
}