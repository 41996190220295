<template>
  <div class="working-container">
    <Loading v-model:active="loading"
                 :can-cancel="false"/>
    <div class="page-header" style="margin-bottom: 2.4rem;">
      <div class="title-wrapper">
        <div class="title">Working</div>
        <div class="subtitle">Clock your hours and view your performance.</div>
      </div>
    </div>

    <!-- Make sure all is loaded first -->
    <!-- Make sure that you load the work switch navigation in after it's known what the current type of working is -->
    <!-- If there are no active hours, make sure the user can use the navigation to switch between types of working -->
    
    <transition name="fade" mode="out-in">
      <div v-if="loading" class="card-wrapper-skeleton">
        <div class="card-title-skeleton"></div>
        <div class="card-data-skeleton">
          <div></div>
          <div></div>
        </div>
      </div>

      <div v-else>
        <div v-if="!loading && currentTypeWorker !== '' || timeline.length === 0" :key="currentTypeWorker"> <!-- The key is there to make sure that currentWorkingType is reactive -->
          <TabsWrapperWorking @click="checkValue">
            <Tab title="Picking" :selected="currentTypeWorker === 'Picking' ? 'true' : 'false'"></Tab>
            <Tab title="Packing" :selected="currentTypeWorker === 'Packing' ? 'true' : 'false'"></Tab>
            <Tab title="Booking" :selected="currentTypeWorker === 'Booking' ? 'true' : 'false'"></Tab>
            <Tab title="Unload" :selected="currentTypeWorker === 'Unload' ? 'true' : 'false'"></Tab>
          </TabsWrapperWorking>
        </div>

        <div class="timeline">
          <div class="timeline-list-wrapper">
            <div class="timeline-list">
              <div class="timeline-item-seperator">
                <div class="timeline-icon">
                  <div class="break-item-wrapper" v-if="timeline.filter(x => x.difference_in_seconds !== 0).length > 0">
                    <div class="break-item-group" v-for="(item, index) in timeline.filter(x => x.difference_in_seconds !== 0).reverse()" v-bind:key="index">
                      <div class="break-item-fix" v-if="item.type === 2">
                        <div v-if="item.type === 2 && item.breakStart" class="break-item-fix" >
                          <div class="bullet"></div>
                          <div class="break-item">
                            <p>Break</p><p>{{ item.date.split(' ')[1].substring(0, item.date.split(' ')[1].length-3)}}</p>
                          </div>
                        </div>
                        <div v-else class="break-item-fix">
                          <div class="bullet"></div>
                          <div class="break-item">
                            <p>End break</p><p>{{ item.date.split(' ')[1].substring(0, item.date.split(' ')[1].length-3)}}</p>
                          </div>
                        </div>
                      </div>
                      <div class="break-item-fix" v-else>
                        <div class="bullet"></div>
                        <div class="break-item">
                          <p>Started {{ item.current_work }}</p><p>{{ item.date.split(' ')[1].substring(0, item.date.split(' ')[1].length-3)}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="timeline-item break-item-wrapper">
                    <div class="break-item-fix" v-if="timeline.length > 0">
                      <div class="bullet"></div>
                      <div class="break-item">
                        <p>Your shift started at</p><p>{{ timeline[0].date.split(' ')[1].substring(0, timeline[0].date.split(' ')[1].length-3) }}</p>
                      </div>
                    </div>
                    <div class="break-item-fix" v-else>
                      <div class="bullet"></div>
                      <div>Start your shift <b>{{ currentTypeWorker.toLowerCase() }}</b></div>
                      <!-- <div style="margin-top: 12px;">
                        <button class="timeline-button" style="" @click="startShift">Start</button>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div v-if="timeline.length > 0" class="timeline-item-seperator-custom">
                <div class="timeline-icon">
                  <div :class="{ 'timeline-circle': timeline.filter(x => x.type === 3).length < 1, 'timeline-circle-completed': timeline.filter(x => x.type === 3).length > 0}">
                    <span v-if="timeline.filter(x => x.type === 3).length > 0" class="timeline-check">
                      eind shift test
                    </span>
                    <span v-else class="timeline-number">2</span>
                  </div>
                  <div class="timeline-item-break">
                  <div class="timeline-header" style="margin-bottom: 8px;">Do you have any breaks today?</div> -->
                    
                    <!-- <div style="display: flex; justify-content: space-around; align-items: center; margin-bottom: 10px;">
                      <div v-if="timeline.filter(x => x.type === 3).length === 0" style="display: flex;">
                          <div style="font-weight; bold; transition: width 2s; display: inline-block; margin-left: 8px;" ><b>{{ totalTimeBreak }}</b></div>
                      </div>
                      <div v-if="timeline.filter(x => x.type === 3).length > 0">
                        <div style="display: inline-block">
                          <div >You took <b>{{ timeline.filter(x => x.type === 2).length === 1 ? `${timeline.filter(x => x.type === 2).length.toString()} break` : `${timeline.filter(x => x.type === 2).length.toString()} breaks` }}</b>  today. <br>With a total break time of <b>{{ totalTimeBreak }}.</b></div>
                        </div>
                      </div>
                      <div v-if="timeline.filter(x => x.type === 2 && x.difference_in_seconds !== 0 || x.switched_current_work).length > 0"> -->
                        <!-- <div v-show="timeline.filter(x => x.type === 3).length > 0" style="font-weight; bold; transition: width 2s; display: inline-block; margin-bottom: 10px;" >Total break time: <b>{{ totalTimeBreak }}</b></div> -->
                        <!-- <div v-for="(item, index) in timeline.filter(x => x.type === 2 && x.difference_in_seconds !== 0 || x.switched_current_work)" v-bind:key="index">
                            <div class="break-item" >
                              <div v-if="item.switched_current_work">
                                Switched to <span style="font-weight: bold">{{ item.current_work }}</span> at <span style="font-weight: bold">{{ item.date.split(' ')[1]}}</span>
                              </div>
                              <div v-else>
                              Took a break at <span style="font-weight: bold;">{{ item.date.split(' ')[1]}}</span> for <span style="font-weight: bold">{{ Math.floor(item.difference_in_seconds) }}</span> seconds 
                              </div>
                              <span style="display: inline;" v-on:click="removeElement(item, index)"><span class="remove-button">X</span></span> -->
                            <!-- </div> -->
                            <!-- </div>
                        </div> -->
                      <!-- </div> -->
                      <!-- <button class="timeline-button" @click="takeBreak">Take a break</button>&nbsp;at&nbsp;&nbsp;<VueTimepicker v-model="breakTime" format="HH:mm:ss" modelValue="breakTime" />&nbsp;&nbsp;for&nbsp;
                      <select v-model="breakTimeDuration">
                        <option>15</option>
                        <option selected>30</option>
                        <option>45</option>
                      </select>&nbsp;minutes.-->
                    <!-- </div> 
                  </div>
                </div>
              </div> -->

            </div>
          </div>
        </div>

        <div v-if="timeline.length > 0">
          <div class="end-wrapper" v-if="timeline.filter(x => x.type === 3).length > 0">
            <div>🎉 Your shift ended at <b>{{ timeline.filter(x => x.type === 3)[0].date.split(' ')[1] }}</b></div>
          </div>
        </div>

        <div class="actions">
          <div class="btn-wrapper break" v-if="timeline.filter(x => x.type === 1).length > 0 && timeline.filter(x => x.type === 3).length < 1">
            <div :class="{'break-button-start': timeline[timeline.length - 1].breakStart, 'break-button-stop': !timeline[timeline.length - 1].breakStart}">
              <button @click="takeBreakNow" >{{ !timeline[timeline.length - 1].breakStart ? "Take break" : "Stop break"}} 
                <svg v-if="!timeline[timeline.length - 1].breakStart" width="20" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="20"  class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
          <div class="btn-wrapper start" v-else-if="timeline.filter(x => x.type === 3).length > 0">
            <button :class="{'shiftstart': timeline.length > 0 }" @click="startShift">
              <span v-if="timeline.filter(x => x.type === 3).length > 0">Start shift again?</span>
            </button>
          </div>
          <div class="btn-wrapper start" v-else>
            <button :class="{'shiftstart': timeline.length > 0 }" @click="startShift">
              <span>Start</span>
            </button>
          </div>
          <div class="btn-wrapper end">
            <button :class="{'shiftended': timeline.filter(x => x.type === 3).length > 0, 'shiftstart': timeline.length === 0, 'enabledBreak': breakNow === 0, 'disabledBreak': breakNow !== 0}" :disabled="breakNow !== 0" @click="endShift">End shift</button>
          </div>
        </div>

      </div>
    </transition>

    
    <!-- <div v-if="timeline.length > 0" class="timeline-progressbar">
      <div v-if="timeline.filter(x => x.type === 1).length > 0">
        <div class="timeline-block">
          <div width="0%" :style="{'max-height': '20px', transition: 'width 2s', 'border-radius': index === 0 ? '6px 0px 0px 6px' : index === timeline.length - 1 ? '0px 6px 6px 0px' : '0px', display: 'inline-block', 
            width: totalTime[0] !== 0 ? (100 / totalTime[0] * item.difference_in_seconds).toString() + '%' : '100%'}" 
            :class="{ 'background-type-1': item.current_work === 'Packing', 'background-type-2': item.type === 2, 'background-type-3': item.current_work === 'Picking'}" v-for="(item, index) in timeline" v-bind:key="index">
            <div v-if="item.type !== 3">
              <div class="img-progressbar" v-if="item.type === 2" >
                <div v-if="!item.breakStart">
                  <Popper arrow hover :content="item.date.split(' ')[1] ?? ''" placement="bottom">
                    <img :src="require(`@/assets/img/working/clock.svg`)" >
                  </Popper> 
                </div>
              </div>
              <div class="img-progressbar" v-else-if="item.current_work === 'Picking'">
                <div v-if="index !== 0">
                  <Popper arrow hover :content="item.date.toString().split(' ')[1] ?? ''" placement="bottom" >
                    <img :src="require(`@/assets/img/working/clipboard-picking.svg`)">
                  </Popper> 
                </div>
                <div v-else>
                  <Popper arrow hover :content="item.date.toString().split(' ')[1] ?? ''" placement="bottom" disabled>
                    <img :src="require(`@/assets/img/working/clipboard-picking.svg`)">
                  </Popper> 
                </div>
              </div>
              <div class="img-progressbar" v-else-if="item.current_work === 'Packing'" >
                <div v-if="index !== 0">
                  <Popper arrow hover :content="item.date.toString().split(' ')[1] ?? ''" placement="bottom">
                    <img :src="require(`@/assets/img/working/big-cart.svg`)" >
                  </Popper> 
                </div>
                <div v-else>
                  <Popper arrow hover :content="item.date.toString().split(' ')[1] ?? ''" placement="bottom" disabled>
                    <img :src="require(`@/assets/img/working/big-cart.svg`)">
                  </Popper> 
                </div>
              </div>
            </div>
          </div> 
        </div>
        <div style="display: flex;justify-content: space-between; color: rgb(105, 108, 117); font-weight: bold;">
          <div>{{ timeline[0].date.split(' ')[1] }}</div>
          <div>{{ timeline.filter(x => x.type === 3).length > 0 ? timeline.length > 1 ? timeline[timeline.length - 1].date.split(' ')[1] : ""  : timestampFormatted}}</div>
        </div>
      </div>
    </div> -->

  </div>
</template>

<script>
import { ref, onMounted, onBeforeMount} from "vue";

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import moment from 'moment'

import VueTimepicker from 'vue3-timepicker'
import 'vue3-timepicker/dist/VueTimepicker.css'

import TabsWrapperWorking from '@/components/TabsWrapperWorking.vue' 
import Tab from '@/components/Tab.vue'

import Popper from "vue3-popper";

import { notify } from "@kyvg/vue3-notification";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Page from '@/components/Page.vue'

export default {
  name: "working",
  components: { VueTimepicker, TabsWrapperWorking, Tab, Popper, Loading },
  data() {
    return {
    }
  },
  setup() {
    const user = ref({});
    const breakTime = ref({'HH': '', 'mm': '', 'ss': ''});
    const timeline = ref([]);
    const breakTimeDuration = ref(30);
    const breakNow = ref(0);
    const breakNowEnd = ref(0);
    const currentBreakTime = ref(0);
    const timestamp = ref('');
    const timestampFormatted = ref('');
    const totalTime = ref(0);
    const totalTimeBreak = ref('');
    const currentBreakTimeInSeconds = ref(0);
    const currentTypeWorker = ref("Picking");
    const loading = ref(false);

    onMounted(async () => { 
      user.value = cookies.get('token');

      timeline.value = await checkForActiveHours();
      
      if(timeline.value.length > 0)
      {
        currentTypeWorker.value = timeline.value[timeline.value.length - 1].current_work;

        // Rendering bug fix for active working type
        await new Promise(r => setTimeout(r))
      }

      loading.value = false

      setInterval(() => {
        currentTime();
      }, 1000)
    })
    
    const checkForActiveHours = async () => {
      try {
        loading.value = true;

        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();

        return result;
      } catch (error) {
        loading.value = false

        return error;
      } 
    }

    const checkValue = async (event) => {
        const oldValue = currentTypeWorker.value;
        
        if(currentTypeWorker.value === event)
        {
          notify({
            title: `Work warning`,
            text: `You can not switch to the same type of work.`,
            type: 'warn',
            duration: 2000,
          });
        }
        else
        {
          currentTypeWorker.value = event;
          
          // Actually switch work if you are in an active shift
          if(timeline.value.length > 0)
          {
            await switchWork(oldValue);
          }
        }
        // else
        // {
        //   currentTypeWorker.value = oldValue;
        //   notify({
        //     title: `Break warning`,
        //     text: `You can not switch to ${event.toLowerCase()} during your break.`,
        //     type: 'warn',
        //     duration: 5000,
        //   });
        // }
    }

    const removeElement = (item, index) => {
      //const breaks = timeline.value.filter(x => x.type === 2);
      const indexOfItem = timeline.value.findIndex(x => x.date === item.date && x.break_time_duration === item.break_time_duration);
  
      timeline.value.splice(indexOfItem, 1);
    }

    const startShift = async () => {
      timeline.value = [];
      loading.value = true;
      const timeNow = moment();

      const timelineItem = {
        "pickerId": user.value.pickerId,
        "date": timeNow.format('YYYY-MM-DD HH:mm:ss').toString(),
        "type": 1,
        "difference_in_seconds": 0,
        "current_work": currentTypeWorker.value,
      }

      breakTime.value = {'HH': timeNow.format("HH"), 'mm': timeNow.format("mm"), 'ss': timeNow.format("ss")}

      const result = await createWorkingHours(timelineItem);

      if(checkStatus(result))
      {
        timeline.value.push(timelineItem);
        getTotalTime();
      }
    }

    // const takeBreak = async () => {

    //   const date = moment().startOf('day');
    //   date.add(parseInt(breakTime.value.HH), 'hours')
    //   date.add(parseInt(breakTime.value.mm), 'minutes')
    //   date.add(parseInt(breakTime.value.ss), 'seconds')

    //   const dateAndBreak = moment(date).add(breakTimeDuration.value, 'minutes');

    //   const duration = breakTimeDuration.value;
    //   const breakTimeInMinutes = moment.duration(moment(dateAndBreak).diff(date)).asSeconds();
    //   const timelineItem = {
    //     "pickerId": user.value.pickerId,
    //     "date": date.format('YYYY-MM-DD HH:mm:ss').toString(),
    //     "type": 2,
    //     "difference_in_seconds": breakTimeInMinutes,
    //     "current_work": currentTypeWorker.value,
    //   }


    //   // Retroactively adjust time for start of shift because of break
    //   // Do this before the push so that you adjust the item before
    //   const differenceInSeconds = moment.duration(date.diff(moment(timeline.value[timeline.value.length - 1].date))).asSeconds();
    //   timeline.value[timeline.value.length - 1].difference_in_seconds = differenceInSeconds;

    //   timeline.value.push(timelineItem);

    //   // Make sure to record time between breaks
    //   const timeBetweenBreaks = {
    //     "pickerId": user.value.pickerId,
    //     "date": dateAndBreak.format('YYYY-MM-DD HH:mm:ss').toString(),
    //     "type": 1,
        
    //     // !! This one has to be 0 because you do not yet know the
    //     // Difference between the upcoming break and the time between it
    //     // So we rectroactively add it just like the 1st shift
    //     "difference_in_seconds": 0,
    //     "current_work": currentTypeWorker.value,
    //   }
      
    //   const result = await createWorkingHours(timeBetweenBreaks);

    //   if(checkStatus(result))
    //   {
    //     timeline.value.push(timeBetweenBreaks);
    //     getTotalTime();
    //   }
    // }

    const takeBreakNow = async () => {
      loading.value = true;
      
      if(!timeline.value[timeline.value.length - 1].breakStart)
      {
        // Make sure to record time between breaks
        const timeBetweenBreaks = {
          "pickerId": user.value.pickerId,
          "date": moment(timestamp.value).format('YYYY-MM-DD HH:mm:ss').toString(),
          "type": 2,
          "breakStart": true,
          
          // !! This one has to be 0 because you do not yet know the
          // Difference between the upcoming break and the time between it
          // So we rectroactively add it just like the 1st shift
          "difference_in_seconds": 0,
          "current_work": currentTypeWorker.value,
        }
        
        const result = await createWorkingHours(timeBetweenBreaks);

        if(checkStatus(result))
        {
          breakNow.value = moment();

          timeline.value.push(timeBetweenBreaks);
          getTotalTime();
        }
      }
      else if(timeline.value[timeline.value.length - 1].breakStart)
      {
        // Add break to list
        const timeBetweenBreaks = {
          "pickerId": user.value.pickerId,
          "date": moment(timestamp.value).format('YYYY-MM-DD HH:mm:ss').toString(),
          "type": 2,
          "breakStart": false,
          
          // !! This one has to be 0 because you do not yet know the
          // Difference between the upcoming break and the time between it
          // So we rectroactively add it just like the 1st shift
          "difference_in_seconds": 0,
          "current_work": currentTypeWorker.value,
        }
        
        const result = await createWorkingHours(timeBetweenBreaks);

        if(checkStatus(result))
        {
          breakNowEnd.value = moment(timestamp.value).format('YYYY-MM-DD HH:mm:ss').toString();

          timeline.value.push(timeBetweenBreaks);
          getTotalTime();

          // Reset values 
          breakNow.value = 0;
          breakNowEnd.value = 0;

          // Continue working
          await switchWork();
        }
      }

    }

    const endShift = async () => {
      loading.value = true;
      
      const timelineItem = {
        "pickerId": user.value.pickerId,
        "date": moment(timestamp.value).format('YYYY-MM-DD HH:mm:ss').toString(),
        "type": 3,
        "current_work": currentTypeWorker.value,
        "switched_current_work": false
      }
      
      const result = await createWorkingHours(timelineItem);

      if(checkStatus(result))
      {
        timeline.value.push(timelineItem);
        getTotalTime();
      }

      loading.value = false;
    }

    const switchWork = async (oldTypeWorkingValue) => {
      loading.value = true;

      let switchedWork = false;
      const switchDate = moment();

      if(currentTypeWorker.value !== timeline.value[timeline.value.length - 1].current_work)
      {
        switchedWork = true;
      }

      const timelineItem = {
        "pickerId": user.value.pickerId,
        "date": switchDate.format('YYYY-MM-DD HH:mm:ss').toString(),
        "type": 1,
        "difference_in_seconds": 0,
        "current_work": currentTypeWorker.value,
        "switched_current_work": switchedWork
      }

      // Check if the person has just ended his break, he's then allowed to entry his next work
      if(timeline.value[timeline.value.length - 1].type === 2 && timeline.value[timeline.value.length - 1].breakStart === false)
      {
        const result = await createWorkingHours(timelineItem);

        if(checkStatus(result))
        {
          timeline.value.push(timelineItem);
          getTotalTime();
        }
      }
      else if(switchedWork)
      {
        const result = await createWorkingHours(timelineItem);

        if(checkStatus(result))
        {
          timeline.value.push(timelineItem);
          getTotalTime();
        }
        else
        {
          currentTypeWorker.value = oldTypeWorkingValue;
        }
      }

      loading.value = false;
    }

    const checkStatus = (result) => {

      if(result.title === 'success')
      {
        notify({
          title: result.title,
          text: result.text,
          type: 'success',
          duration: 5000,
        });
        return true;
      }
      else if(result.title.includes("error"))
      {
        notify({
          title: result.title,
          text: result.text,
          type: 'error',
          duration: 5000,
        });

        return false;
      }

      return false;
    }

    const createWorkingHours = async (item) => {
      try {
        loading.value = true;

        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/create", { 
          method: 'POST', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
          body: JSON.stringify(item)
        });
        
        const result = await res.json();

        loading.value = false

        return result;
      } catch (error) {
        loading.value = false

        return error;
      } 
    }

    const getTotalTime = () => {
      const initialValue = 0;

      // Total break time
      const sumWithBreakInitial = timeline.value.filter(x => x.type === 2).map(x => x.difference_in_seconds).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );

      // This directly influences the progressbar width per bar
      const sumWithInitial = timeline.value.map(x => x.difference_in_seconds).reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        initialValue
      );
      
      totalTime.value = [sumWithInitial, sumWithBreakInitial];

      totalTimeBreak.value = moment(timestamp.value).startOf('day').add(sumWithBreakInitial, 'seconds').format('HH:mm:ss').toString();
    }

    const currentTime = () => {
      const today = moment();
      timestamp.value = today;
      timestampFormatted.value = today.format("HH:mm:ss").toString();

      if(timeline.value.length > 0)
      {
        const differenceInSeconds = moment.duration(moment(today).diff(moment(timeline.value[timeline.value.length - 1].date))).asSeconds();

        if(timeline.value.filter(x => x.type === 3).length === 0)
        {
          if(timeline.value[timeline.value.length - 1].type !== 3)
          {
            timeline.value[timeline.value.length - 1].difference_in_seconds = differenceInSeconds;
          }
        }

        if(differenceInSeconds > 0)
        {
          currentBreakTimeInSeconds.value = differenceInSeconds;
          currentBreakTime.value = moment().startOf('day').add(differenceInSeconds, 'seconds').format("HH:mm:ss").toString();
          
          if(timeline.value[timeline.value.length - 1].breakStart)
          {            
            const liveDifferenceInSeconds = moment.duration(moment(today).diff(moment(timeline.value[timeline.value.length - 1].date))).asSeconds();
            totalTimeBreak.value = moment().startOf('day').add(liveDifferenceInSeconds, 'seconds').format('HH:mm:ss').toString();
          }

          getTotalTime();
        }
      }
    }

    return {
      breakNow, user, takeBreakNow, startShift, endShift, removeElement, getTotalTime, breakTime, breakTimeDuration, timeline, currentTime, timestamp,
      totalTime, totalTimeBreak, breakNowEnd, currentBreakTime, currentBreakTimeInSeconds,
      currentTypeWorker, checkValue, switchWork, timestampFormatted, loading
    }
  },
  methods: {
    
  },

  async created() {
    this.currentUserId = cookies.get("currentUserId") ?? String.empty;
  }
}
</script>

<!-- NOT SCOPED TO ACCESS TABS STYLING -->
<style lang="scss">
.working-container {
  .working-tabs{
    padding: 32px;
    ul {
      border-bottom: none;
      flex-wrap: wrap;
      li {
        width: calc((100% / 2) - (1.6rem / 2));
        height: 40px;
        border-radius: $border-radius;
        display: flex; 
        justify-content: center;
        align-items: center;
        background-color: #F5F6F8;
        border: 1px solid #D7DCE1;
        margin-right: 1.6rem;
        margin-bottom: 1.6rem;
        padding: 0 20px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        &:nth-child(n+3) {
          margin-bottom: 0;
          cursor: not-allowed;
          pointer-events: none;
          opacity: 0.3;
        }
      }
      li.selected {
        color: #0ABB87;
        border-color: #0ABB87;
        box-shadow: 0px 0px 4px 1px rgba(10, 187, 135, 0.5); 
        &:after{
          display: none;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.working-container {  
  max-width: 600px;
  margin: 0 auto;
  min-height: 100vh;
  max-height: 100vh;
  padding-top: 6.4rem;
  padding-bottom: 6.4rem;
  .end-wrapper {
    border-left: 1px solid #D7DCE1;
    border-right: 1px solid #D7DCE1;
    border-radius: 0;
    background: white;
    border-top: 1px solid #D7DCE1;
    padding: 3.2rem;
  }
  .timeline {
    border-left: 1px solid #D7DCE1;
    border-right: 1px solid #D7DCE1;
    border-radius: 0;
    .timeline-list-wrapper {
      background-color: #fff;
      padding: 3.2rem;
      max-height: 40vh;
      overflow: auto;
      .timeline-list {
        position: relative;
        .timeline-item-seperator-custom {
          margin-bottom: 20px;
        }
        .timeline-item-seperator {
          .timeline-icon {
            .timeline-circle {
              margin-right: 8px; 
              background-color: #C8C8C8; 
              border-radius: 50%; 
              width: 45px; 
              height: 45px; 
              text-align: center;
              font-size: 18px;
              .timeline-number {
                color: #2E303F;
                position: relative;
                top: 11.5px;
              }
              .timeline-check {
                position: relative;
              }
            }
            .timeline-item.break-item-wrapper{
              .bullet{
                &:after{
                  display: none;
                }
              }
            }
            .break-item-wrapper{
              .break-item-group{
                margin-bottom: 3.2rem;
                &:first-child {
                  .break-item{
                    font-weight: bold;
                    color: #0B212D;
                  }
                  .bullet {
                    background: #0ABB87;
                    border: none;
                    box-shadow: 0px 0px 6px 2px rgba(10, 187, 135, 0.5);
                  }
                }
                .break-item-fix {
                  display: flex;
                  width: 100%;
                  align-items: center;
                }
              }
              .bullet {
                width: 8px;
                height: 8px;
                border-radius: 8px;
                margin-right: 1.6rem;
                border: 2px solid #D7DCE1;
                position: relative;
                &:after{
                  content: '';
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  top: 15px;
                  width: 3px;
                  background:
                    linear-gradient(#D7DCE1, #D7DCE1) 0 0%/100% 3px,
                    linear-gradient(#D7DCE1, #D7DCE1) 0 25%/100% 3px,
                    linear-gradient(#D7DCE1, #D7DCE1) 0 50%/100% 3px,
                    linear-gradient(#D7DCE1, #D7DCE1) 0 75%/100% 3px,
                    linear-gradient(#D7DCE1, #D7DCE1) 0 100%/100% 3px ;
                  background-repeat: no-repeat;
                  height: 27px;
                }
              }
              .break-item-fix{
                display: flex;
                align-items: center;
                .break-item {
                  font-size: 16px; 
                  color: #D7DCE1;
                  display: flex;
                  justify-content: space-between;
                  width: calc(100% - 46px);
                }
              }
            }
            .timeline-item {
              font-size: 16px;
              b {
                font-weight: bold;
              }
            }
            .timeline-item-break {
              .timeline-break-section {
                display: flex;
                .break-button-start {
                  a {
                    border: 2px solid #0B212D;
                    padding: 6px 12px;
                    padding-right: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-radius: 0.6rem;
                    text-align: center;
                    font-family: "Neutrif-bold";
                    height: 4.0rem;
                    text-decoration: none;
                    background-color: #0B212D;
                    color: white;
                    font-weight: bold;
                    z-index: 1;
                    transition: all 0.3s ease;
                    position: relative;
                    outline: none;
                    cursor: pointer;
                    white-space: nowrap;
                    svg path{
                      fill: white;
                    }
                    svg{
                      margin-left: 4px;
                      transition: all 0.3s ease;
                      path {
                        transition: all 0.3s ease;
                      }
                    }
                  }
                }
                .break-button-stop {
                  a {
                    border: 2px solid #0B212D;
                    padding: 6px 12px;
                    padding-right: 6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-radius: 0.6rem;
                    text-align: center;
                    font-family: "Neutrif-bold";
                    height: 4.0rem;
                    text-decoration: none;
                    color: #0B212D;
                    font-weight: bold;
                    z-index: 1;
                    transition: all 0.3s ease;
                    position: relative;
                    outline: none;
                    cursor: pointer;
                    white-space: nowrap;
                    svg{
                      margin-left: 4px;
                      transition: all 0.3s ease;
                      path {
                        transition: all 0.3s ease;
                      }
                    }
                    &:hover{
                      background-color: #0B212D;
                      color: white;
                      svg path{
                        fill: white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .actions{
    width: 100%;
    padding: 32px;
    border-radius: 0 0 6px 6px;
    background-color: #fff;
    border: 1px solid #D7DCE1;
    display: flex;
    flex-wrap: wrap;
    .btn-wrapper.start{
      button{
        background-color: #0ABB87;
      }
    }
    .btn-wrapper.end{
      button{
        background-color: #CF304A;
      }
    }
    .btn-wrapper.break{
      button {
        background-color: #3AAFE3;
      }
    }
    .btn-wrapper{
      width: calc(50% - 0.8rem);
      margin-right: 1.6rem;
      &:last-child{
        margin-right: 0;
      }
      .break-button-stop {
        border-radius: $border-radius;
      }
      button {
        width: 100%;
        height: 40px;
        border-radius: $border-radius;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5F6F8;
        color: white;
        padding: 0 20px;
        border: none;
        font-size: 16px;
        font-weight: bold;
        svg {
          margin-left: 0.8rem;
        }
      }
    }
    button.shiftended {
      opacity: 0.3;
      pointer-events: none;
    }
    button.disabledBreak {
      opacity: 0.3;
      pointer-events: none;
      background-color: #CF304A;
    }
  }
}




:deep(.popper) {
  background: #f8f8f8;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 6px 6px 3px 6px;
  border: 1px solid #D7DCE1;
  border-radius: 6px;
  color: black;
  font-weight: bold;
  font-size: 13px;
}

:deep(.popper #arrow::before) {
  background: #f8f8f8;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #f8f8f8;
}

/* Tommie styling */
.background-type-1 {
  background-color: #5578EB;
}

.background-type-2 {
  background-color: #2E303F !important;
}

.background-type-3 {
  background-color: #3AAFE3;
}

.remove-button {
  color: red;
  cursor: pointer;
  font-weight: bold;
}

.timeline-block div img {opacity: 1; display: block;}
.timeline-block:hover div { opacity:1 !important; }

.timeline-progressbar {
  margin-top: 3.2rem;
  background-color: white;
  border: 1px solid #D7DCE1;
  border-radius: 6px;

  padding-top: 6.4rem;
  padding-left: 4.8rem;
  padding-bottom: 3.2rem;
  padding-right: 4.8rem;
}

.img-progressbar {
  height: 20px; 
  height: 20px;
  position: relative;
  top: -33px;
  left: -10px;
}

.img-progressbar img {
  display: inline-block;
  width: 20px;
  height: 20px;
}




.timeline-header {
  font-family: "Neutrif-bold";
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) {
  .working-container {
    padding: 3.2rem 1.6rem;
  }
}

@media only screen and (max-width: 600px) {
  .working-container {
    padding: 3.2rem 1.6rem;
    .page-header .title-wrapper .title {
      font-size: 2.4rem;
    }
    .timeline{
      .timeline-list-wrapper{
        padding: 1.6rem;
      }
    }
    .actions {
      padding: 1.6rem;
    }
    .working-tabs {
      padding: 1.6rem;
    }
  } 
}
</style>