<template>
  <div class="tabs">
    <ul class="tabs__header">
      <li v-for="title in tabTitles" :key="title" @click="selectedTitle = title" :class="{ selected: title == selectedTitle }">
        {{ title }}
      </li>
    </ul>
    <slot />
  </div>
</template>

<script>
import { ref, provide } from 'vue'

export default {
  setup (props, { slots }) {
    const tabTitles = ref(slots.default().map((tab) => tab.props.title))
    const selectedTitle = ref(tabTitles.value[0])

    provide("selectedTitle", selectedTitle)
    return{
      selectedTitle,
      tabTitles
    }
  }
}
</script>

<style lang="scss" scoped>
.tabs{
}
.tabs__header{
  margin-bottom: 2.0rem;
  list-style: none;
  padding: 0;
  display: flex;
  border-bottom: 1px solid #D7DCE1;
}
.tabs__header li{
  text-align: center;
  padding: 0 10px 1.6rem 10px;
  margin-right: 10px;
  color: #9397A1;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  border-bottom: 1px solid transparent;
  position: relative;
  &:after{
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: #3AAFE3;
    bottom: -2.25px;
    left: 0;
    opacity: 0;
    transition: 0.3s all ease-in-out;
  }
}
.tabs__header li.selected{
  color: #3AAFE3;;
  &:after{
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .tabs{
    .tabs__header{
      overflow-x: scroll;
      overflow-y: hidden;
      li{
        white-space: nowrap;
      }
    }
  }
}
</style>
