import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { projectAuth } from './firebase/config'
import vSelect from "vue-select";
import Notifications from '@kyvg/vue3-notification'
import 'vue-select/dist/vue-select.css';
import 'pdfvuer/dist/pdfvuer.css'
import AnimatedCounterPlugin from './views/AnimatedCounterPlugin';
import VueCookies from 'vue3-cookies'

let app

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);

    // Use your plugins and components here
    app.use(router);
    app.use(Notifications);
    app.use(AnimatedCounterPlugin); // Ensure this is called after createApp
    app.use(VueCookies, {
      expireTimes: "3520d"
    })
    app.component("v-select", vSelect);

    // Your custom directives would be registered here
    // app.directive("click-outside", clickOutside);
    // app.directive("click-away", clickaway);

    app.mount('#app');
  }
})
