<template>
  <td class="status">
    <div ref="openRef" class="status-wrapper">
      <div class="status-button-main" @click="showStatusDropdown();"
        :style="{'background-color': order.printingInformation.status === 1 ? '#E8697D' : 
        order.printingInformation.status === 2 ? '#FFB545' :
        order.printingInformation.status === 3 ? '#339ECD' :
        order.printingInformation.status === 4 ? '#33D391' : '#E8697D'}">
        {{ order.printingInformation.status === 0 ? statusArrayOptions[1] : statusArrayOptions[order.printingInformation.status] }}
      </div>
      <div ref="closeRef" class="select-status" >
        <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 1);" style="background-color: #E8697D;">To do</div>
        <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 2);" style="background-color: #FFB545;">In progress</div>
        <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 3);" style="background-color: #339ECD;">Prepared</div>
        <div class="status-button" @click="handleStatus(order?.orderInformation?.data.reference, 4);" style="background-color: #33D391;">Done</div>
      </div>
    </div>
    <div ref="overlay" class="overlay" @click="closeWithOverlay();"></div>
  </td>
</template>
  
<script setup>
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import getUser from '../../composeables/getUser'

const props = defineProps({
  order: Object,
  bedrukkers: Object
})

const openRef = ref(null)
const closeRef = ref(null)
const overlay = ref(null)
const statusArrayOptions = ref([
  "None",
  "To do",
  "In progress",
  "Prepared",
  "Done"
])

function close() {
  closeRef.value.classList.remove("open");
  overlay.value.classList.remove("display");
}

function showStatusDropdown() {
  openRef.value.querySelector(".select-status").classList.add("open");
  overlay.value.classList.add("display");
}

function closeOption() {
  closeRef.value.classList.remove("open");
  overlay.value.classList.remove("display");
}

function closeWithOverlay() {
  overlay.value.classList.remove("display");
}

onClickOutside(closeRef, close);


const handleWorker = (orderId, bedrukker, i) => {
  updateOrder(orderId, bedrukker.pickerId, 'worker');
  closeOption();
  props.order.printingInformation.pickerId = bedrukker.pickerId;
}

const handleStatus = (orderId, statusNumber, i) => {
  updateOrder(orderId, statusNumber, 'status');
  closeOption();
  props.order.printingInformation.status = statusNumber;
}

const updateOrder = async (orderId, data, type) => {
  let postBody = {};
  postBody.orderId = orderId;
  
  if(type === 'worker')
  {
    postBody.pickerId = data;
  }
  else if(type === 'status')
  {
    postBody.status = data;
  }

  const { user } = getUser();
  return await fetch(process.env.VUE_APP_ROOT_API + "/api/printing/order/create", { 
    method: 'POST', 
    headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""},
    body: JSON.stringify(postBody) 
  })
}
</script>
  
<style lang="scss" scoped>
.overlay {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
  display: none;
  top: 0;
  left: 0;
}
.overlay.display{
  display: block;
}

.status-wrapper{
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .status-button-main {
    cursor: pointer;
    color: white; 
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    padding: 0 2.56rem;
    white-space: nowrap;
    transition: 0.3s ease-in-out;
  }
  .select-status{
    position: absolute;
    transform-origin: center top;
    padding: 1.6rem;
    background: white;
    box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    margin-top: 4.8rem;
    border-radius: $border-radius;
    left: 50%;
    transform: translate(-50%, 0) scale(0.01);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
    z-index: 1;
    &.open {
      transform: translate(-50%, 0) scale(1);
      opacity: 1;
      box-shadow: 3px 3px 30px rgb(0 0 0 / 15%);
    }
    &:before {
      content: '';
      position: absolute;
      width: 0; 
      height: 0; 
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid white;
      left: 50%;
      transform: translate(-50%, 0);
      top: -10px;
    }
    .status-button {
      font-size: 16px;
      width: 140px;
      height: 4.0rem;
      color: white;
      font-family: "Neutrif-bold";
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.8rem;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>