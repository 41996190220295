<template>
  <Loading v-model:active="loading" :can-cancel="false"/>

  <div @click="open" class="legend">
    <i class='bx bxs-info-circle'></i>
  </div>
  <div class="legend-overlay"></div>
  <div class="legend-popup">
    <div class="legend-header">
      Legend
      <i @click="close" class='bx bx-x'></i>
    </div>
    <div class="title">Packer</div>
    <ul class="packer-ul">
      <li>
        <div class="circle"></div>
        <span>Big cart</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Small cart</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Big singles</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Small singles</span>
      </li>
    </ul>
    <div class="title">Picker</div>
    <ul class="picker-ul">
      <li>
        <div class="circle"></div>
        <span>Big batch big unique</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Big batch small unique</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Big batch big total</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Big batch small total</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Small batch unique</span>
      </li>
      <li>
        <div class="circle"></div>
        <span>Small batch total</span>
      </li>
    </ul>
  </div>

  <div class="page-header">
    <div class="title-wrapper">
      <div class="title">Active {{ isPacker ? 'packers' : isPacker === false ? 'pickers' : 'workers' }}</div>
      <div class="subtitle">All information about the {{ isPacker ? 'packers' : isPacker === false ? 'pickers' : 'workers' }} of Dartshopper</div>
    </div>
  </div>

  <!-- Date picker and search -->
  <div class="employee-date-picker">

    <div class="employee-picker">
      <div class="btn-wrapper">
        <a :class="{ 'active': isPacker === false}" @click="switchTabs(false)">Pickers</a>
        <a :class="{ 'active': isPacker}" @click="switchTabs(true)">Packers</a>
        <!-- <a :class="{ 'active': isPacker === null}" @click="switchTabs(null)">Total</a> -->
      </div>
      <input class="menu-search" v-model="searchWorkerEntry" :placeholder="isPacker ? 'Search for packers' : isPacker === false ? 'Search for pickers' : 'Search for workers'" type="text"/>
    </div>

    <div class="date-picker">
      <div class="btn-wrapper">
        <a :class="{ 'active': datePreset !== '' && datePreset === 'Today'}" @click="changeDatePreset">Today</a>
        <a :class="{ 'active': datePreset !== '' && datePreset === 'Yesterday'}" @click="changeDatePreset">Yesterday</a>
        <a :class="{ 'active': datePreset !== '' && datePreset === '1 month'}" @click="changeDatePreset">1 month</a>
        <a :class="{ 'active': datePreset !== '' && datePreset === '3 months'}" @click="changeDatePreset">3 months</a>
      </div>
      <div :class="{ 'datepicker-customSelector': true, 'active': datePreset === ''}">
        <Datepicker 
          v-model="date" 
          @update:modelValue="handleDate" 
          range
          weekNumbers 
          :enableTimePicker="false" 
          locale="nl" 
          placeholder="Custom..." 
          textInput 
          cancelText="cancel" 
          selectText="apply" 
          :maxDate="new Date()"
          :minDate="new Date(2024, 0, 17)"
          position="right"
          autoPosition
        />
      </div>
    </div>

  </div>

  <div class="export-header">
    <div class="info-wrapper">
      <div class="date">
        From {{ new Date(startdate).toLocaleDateString('en-NL', { month: 'short', day: 'numeric', year: 'numeric', hours: '', minutes: '', seconds: ''})  }} 
        to {{ new Date(enddate).toLocaleDateString('en-NL', { month: 'short', day: 'numeric', year: 'numeric', hours: '', minutes: '', seconds: ''})  }}
      </div>
    </div>
    <button v-if="!loading" @click="exportData">
      <i class="bx bxs-download" data-v-10ae5422=""></i>
      <span>Export</span>
    </button>
  </div>
  <!-- PACKER -->
  <div v-if="!loading && isPacker" class="table-container">
    <template v-if="!loading">
      <!-- MORE THEN 1 DAY -->
      <template v-if="Object.keys(filteredPerDayWorkers).length !== 1"> 
        <div class="table-block" v-for="(workers, day) in filteredPerDayWorkers" :key="day">
          <div class="table-header">
            <div class="info">
              <i class='bx bx-calendar'></i>
              <span>{{ day }}</span>
            </div>
            <div class="info">
              <i class='bx bxs-user'></i>
              <span>{{ workers.length }} workers</span>
            </div>
          </div>
          <table class="table table-striped picker">
            <thead>
              <tr>
                <th class="name">Name</th>
                <th class="packed">Packed</th>
                <th class="link"></th>
              </tr>
            </thead>
            <tbody v-for="(worker, i) in workers" :key="i"> 
              <tr>
                <td class="name">
                  <a :href='"https://dartshopper.picqer.com/config/users/" + worker.iduser'>
                  <span>{{ worker.fullName ? worker.fullName  : worker.closed_by_iduser }}</span></a>
                </td>
                <td class="packed">
                  <div class="info-wrapper">
                    <div class="info">
                      <span class="bc">{{ worker.GrootMixedClosed }}</span>
                      <div class="tooltiptext">Big cart</div>
                    </div>
                    <div class="info">
                      <span class="sc">{{ worker.KleinMoreClosed }}</span>
                      <div class="tooltiptext">Small cart</div>
                    </div>
                    <div class="info">
                      <span class="bs">{{ worker.GrootSinglesClosed }}</span>
                      <div class="tooltiptext">Big singles</div>
                    </div>
                    <div class="info">
                      <span class="ss">{{ worker.KleinSinglesClosed }}</span>
                      <div class="tooltiptext">Small singles</div>
                    </div>
                  </div>
                </td>
                <td class="link">
                  <template v-if="worker.closed_by_iduser !== ''">
                    <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">
                      <i class='bx bx-right-arrow-alt'></i>
                    </router-link>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <!-- 1 DAY -->
      <template v-else> 
        <div class="table-block">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="name">Name</th>
                <th class="packed">Packed</th>
                <th class="link"></th>
              </tr>
            </thead>
            <tbody v-for="(worker, i) in filteredLiveWorkers" :key="i"> 
              <tr>
                <td class="name">
                  <a :href='"https://dartshopper.picqer.com/config/users/" + worker.iduser'>
                  <span>{{ worker.fullName ? worker.fullName  : worker.closed_by_iduser }}</span></a>
                </td>
                <td class="packed">
                  <div class="info-wrapper">
                    <div class="info">
                      <span class="bc">{{ worker.GrootMixedClosed }}</span>
                      <div class="tooltiptext">Big cart</div>
                    </div>
                    <div class="info">
                      <span class="sc">{{ worker.KleinMoreClosed }}</span>
                      <div class="tooltiptext">Small cart</div>
                    </div>
                    <div class="info">
                      <span class="bs">{{ worker.GrootSinglesClosed }}</span>
                      <div class="tooltiptext">Big singles</div>
                    </div>
                    <div class="info">
                      <span class="ss">{{ worker.KleinSinglesClosed }}</span>
                      <div class="tooltiptext">Small singles</div>
                    </div>
                  </div>
                </td>
                <!-- <td class="link">
                  <template v-if="worker.closed_by_iduser !== ''">
                    <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">
                      <i class='bx bx-right-arrow-alt'></i>
                    </router-link>
                  </template>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </template>
  </div>


  <!-- PICKER -->
  <div v-if="!loading && isPacker === false" class="table-container">
    <template v-if="!loading && filteredLiveWorkers.length > 0">
      <!-- MORE THEN 1 DAY -->
      <template v-if="Object.keys(filteredPerDayWorkers).length !== 1">
        <div class="table-block" v-for="(workers, day) in filteredPerDayWorkers" :key="day">
          <div class="table-header">
            <div class="info">
              <i class='bx bx-calendar'></i>
              <span>{{ day }}</span>
            </div>
            <div class="info">
              <i class='bx bxs-user'></i>
              <span>{{ workers.length }} workers</span>
            </div>
          </div>
          <table class="table table-striped picker">
            <thead>
              <tr>
                <th class="name">Name</th>
                <th class="picked">Picked</th>
                <th class="link"></th>
              </tr>
            </thead>
            <tbody v-for="(worker, i) in workers" :key="i"> 
              <tr>
                <td class="name">
                  <a :href='"https://dartshopper.picqer.com/config/users/" + worker.iduser '>
                  <span>{{ worker.fullName ?? worker.iduser }}</span></a>
                </td>
                <td class="picked">
                  <div class="info-wrapper">
                    <div class="info">
                      <span class="bbbu">{{ worker.GrootBatchGrootUniekeproducten   }}</span>
                      <div class="tooltiptext">Big batch big unique</div>
                    </div>
                    <div class="info">
                      <span class="bbsu">{{ worker.GrootBatchKleineUniekeproducten }}</span>
                      <div class="tooltiptext">Big batch small unique</div>
                    </div>
                    <div class="info">
                      <span class="bbbt">{{ worker.GrootBatchGrootTotaalproducten  }}</span>
                      <div class="tooltiptext">Big batch big total</div>
                    </div>
                    <div class="info">
                      <span class="sbu">{{ worker.KleinBatchUniekeproducten }}</span>
                      <div class="tooltiptext">Small batch unique</div>
                    </div>
                    <div class="info">
                      <span class="bbst">{{ worker.GrootBatchKleinTotaalproducten }}</span>
                      <div class="tooltiptext">Big batch small total</div>
                    </div>
                    <div class="info">
                      <span class="sbt">{{  worker.KleinBatchTotaalProducten }}</span>
                      <div class="tooltiptext">Small batch total</div>
                    </div>
                  </div>
                </td>
                <!-- <td class="link">
                  <template v-if="worker.closed_by_iduser !== ''">
                    <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">
                      <i class='bx bx-right-arrow-alt'></i>
                    </router-link>
                  </template>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <!-- 1 DAY -->
      <template v-else>
        <div class="table-block">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="name">Name</th>
                <th class="picked">Picked</th>
                <th class="link"></th>
              </tr>
            </thead>
            <tbody v-for="(worker, i) in filteredLiveWorkers" :key="i"> 
              
              <tr>
                <td class="name">
                  <a :href='"https://dartshopper.picqer.com/config/users/" + worker.iduser'>
                  {{ worker.fullName ?? worker.iduser }}</a>
                </td>
                <td class="picked">
                  <div class="info-wrapper">
                    <div class="info">
                      <span class="bbbu">{{ worker.KleinBatchUniekeproducten }}</span>
                      <div class="tooltiptext">Small batch unique</div>
                    </div>
                    <div class="info">
                      <span class="bbbu">{{ worker.KleinBatchTotaalProducten  }}</span>
                      <div class="tooltiptext">Small batch total</div>
                    </div>
                    <div class="info">
                      <span class="bbsu">{{ worker.GrootBatchKleineUniekeproducten}}</span>
                      <div class="tooltiptext">Big batch small unique</div>
                    </div>
                    <div class="info">
                      <span class="bbbu">{{ worker.GrootBatchGrootUniekeproducten }}</span>
                      <div class="tooltiptext">Big batch big unique</div>
                    </div>
                    <div class="info">
                      <span class="bbst">{{ worker.GrootBatchKleinTotaalproducten }}</span>
                      <div class="tooltiptext">Big batch small total</div>
                    </div>  
                    <div class="info">
                      <span class="bbbt">{{ worker.GrootBatchGrootTotaalproducten  }}</span>
                      <div class="tooltiptext">Big batch big total</div>
                    </div>
                  </div>
                </td>
                <!-- <td class="link">
                  <template v-if="worker.closed_by_iduser !== ''">
                    <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">
                      <i class='bx bx-right-arrow-alt'></i>
                    </router-link>
                  </template>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import Datepicker from '@vuepic/vue-datepicker';

import Page from '@/components/Page.vue'
import TabsWrapperWorking from '@/components/TabsWrapperWorking.vue' 
import Tab from '@/components/Tab.vue'
import UploadClockingHours from '@/components/employees/UploadClockingHours.vue'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import '@vuepic/vue-datepicker/dist/main.css';

import { LineChart, useLineChart } from 'vue-chart-3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import * as XLSX from 'xlsx'

import { find } from "country-flags-svg/dist/data/countries";

import { getFilteredOutEmployees, getFilteredOutEmployeesIds} from '@/functions/Employee';

export default {
  components: { Page, Datepicker, TabsWrapperWorking, Tab, LineChart, Datepicker, Loading, UploadClockingHours},
  name: "workersOverview",
  data(){
    return{
      gradient: "",
    }
  },
  setup() {
    const user = ref({});
    const datePreset = ref(null);
    const liveWorkers = ref([]);
    const totalOpen = ref({});
    const users = ref([]);
    const workers = ref([]);
    const router = useRouter()
    const route = useRoute();
    const searchWorkerEntry = ref("");

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
    
    Chart.register(...registerables);
    Chart.register(annotationPlugin);
    
    const date = ref([]);
    const startdate = ref("");
    const enddate = ref("");
    const loading = ref(true);
    const error = ref(null);
    const isPacker = ref(false);
    const pickersFilterOut = ref([]);
    const pickersFilterOutIds = ref([]);

    const setDateInCookie = (date) => {
      cookies.set("date", JSON.stringify(date));
    }

    // For demo purposes assign range from the current date
    onMounted( async () => {
      user.value = cookies.get('token');
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];
      
      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      if("startDate" in route.query)
      {
        startDate = route.query.startDate;
      }

      if("endDate" in route.query)
      {
        endDate = route.query.endDate;
      }

      if("datePreset" in route.query)
      {
        date = setDatePreset(route.query.datePreset);
      }

      if(cookies.get("datePreset") === null || cookies.get("datePreset") === undefined)
      {
        cookies.set("datePreset", "Today");
      }

      date.value = date;
      datePreset.value = cookies.get("datePreset");
      setDateInCookie(date.value);
      await getUsers();

      pickersFilterOut.value = await getFilteredOutEmployees();
      pickersFilterOutIds.value = await getFilteredOutEmployeesIds();
      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }
      await updateQueryParameters(query);
    })

    const mergeUserValues = (data) => {
      const mergedData = [];

      // Create a map to store merged values based on iduser
      const userMap = new Map();

      // Iterate through the data array
      data.forEach(entry => {
        if(entry !== undefined) {
          const iduser = entry.iduser;

// If the iduser is not in the map, add a new entry
if (!userMap.has(iduser)) {
  userMap.set(iduser, { ...entry });
} else {
  // If the iduser is already in the map, merge/sum the values
  const existingEntry = userMap.get(iduser);

  // Iterate through the properties to merge/sum
  Object.keys(entry).forEach(key => {
    // Skip iduser and full_name
    if (key !== 'iduser' && key !== 'fullName') {
      // Sum the numeric values if they are defined and numeric
      const numericValue = parseFloat(entry[key]);
      if (!isNaN(numericValue)) {
        existingEntry[key] = (existingEntry[key] || 0) + numericValue;
      }
    }
  });

  // Update the map entry
  userMap.set(iduser, existingEntry);
}
        }
      
      });

      // Convert the map values back to an array
      userMap.forEach(value => {
        mergedData.push(value);
      });

      return mergedData;
    }

    const filteredPerDayWorkers = computed(() => {
      let groupedPerDay = {};
      let correctExcelFormat = filteredLiveWorkers.value;

      correctExcelFormat.forEach(item => {
        let day = moment(item.updatedDay).format('YYYY-MM-DD').toString()
        if (groupedPerDay[day]) {
          groupedPerDay[day].push(item);
        } else {
          groupedPerDay[day] = [];
          groupedPerDay[day].push(item);
        }
      });


      const grouperPerDaySorted = Object.keys(groupedPerDay).sort().reverse().reduce(
        (obj, key) => { 
          obj[key] = groupedPerDay[key]; 
          return obj;
        }, 
        {}
      );

      return grouperPerDaySorted
    })

    const filteredLiveWorkers = computed(() => {
        return liveWorkers.value.filter(workerEntry => {
          // Remove users that are in the list of filteredout
          if(!pickersFilterOut.value.find(user => user?.full_name == workerEntry?.fullName))
          {
            // Remove entry with open and 0 closed (Everything that's open for the day)
            if(workerEntry => workerEntry !== totalOpen.value)
            {
              // Dynamically maps fullname to id's in json
              if(searchWorkerEntry.value !== "" && workerEntry?.fullName !== undefined)
              {
                return workerEntry?.fullName.toString().toLowerCase().includes(searchWorkerEntry.value.toLowerCase())
              }

              return workerEntry;
            }
          }
        })
    })

    // Assign a global variable so content can be dynamically changed
    const switchTabs = async (switchValue) => {
      isPacker.value = switchValue; // Make sure to switch first so new data can be loaded
      
      const query = {
        startDate: moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }

      await updateQueryParameters(query);
    }

    const exportData = () => {
        let dataInserted = {};
        //Check if 1 day or more days
        if(!loading && ('updatedDay' in filteredLiveWorkers[0])) {
          // const data = XLSX.utils.json_to_sheet([])
          // const wb = XLSX.utils.book_new()

          const wb = XLSX.utils.book_new()
          let jsonArrayData = [];
          dataInserted = filteredPerDayWorkers.value;

          for (const key of Object.keys(dataInserted)) {
            jsonArrayData.push(dataInserted[key]);
          }

          let packerPickerString = isPacker.value ? "packers" : "pickers";
          let startdate = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
          let enddate = moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

          const ws = XLSX.utils.json_to_sheet(jsonArrayData.flat())
          XLSX.utils.book_append_sheet(wb, ws, 'export')
          XLSX.writeFile(wb, `${packerPickerString}_${startdate}_${enddate}.xlsx`)
        }
        else {
          let dataInserted = filteredLiveWorkers.value;
          const data = XLSX.utils.json_to_sheet(dataInserted)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, data, 'data')
          let packerPickerString = isPacker.value ? "packers" : "pickers";
          let startdate = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
          let enddate = moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

          XLSX.writeFile(wb, `${packerPickerString}_${startdate}_${enddate}.xlsx`)
        }
    }

    const formatWorkerEntryForExcel = (workerEntry) => {
      let workerEntryFormatted = {};

      if(!isPacker.value)
      {
          if(workerEntry["updatedDay"] !== undefined)
          {
            workerEntryFormatted = {
              "updatedDay": moment(workerEntry["updatedDay"]).format('YYYY-MM-DD').toString(),
              "fullName": workerEntry["full_name"],
              "KleinBatchUniekeproducten": workerEntry["KleinBatchUniekeproducten"],
              "KleinBatchTotaalProducten": workerEntry["KleinBatchTotaalProducten"],
              "GrootBatchKleineUniekeproducten": workerEntry["GrootBatchKleineUniekeproducten"],
              "GrootBatchGrootUniekeproducten": workerEntry["GrootBatchGrootUniekeproducten"],
              "GrootBatchKleinTotaalproducten": workerEntry["GrootBatchKleinTotaalproducten"],
              "GrootBatchGrootTotaalproducten": workerEntry["GrootBatchGrootTotaalproducten"],
              "iduser": workerEntry["iduser"]
            }
          }
          else
          {
            workerEntryFormatted = {
              "fullName": workerEntry["full_name"],
              "KleinBatchUniekeproducten": workerEntry["KleinBatchUniekeproducten"],
              "KleinBatchTotaalProducten": workerEntry["KleinBatchTotaalProducten"],
              "GrootBatchKleineUniekeproducten": workerEntry["GrootBatchKleineUniekeproducten"],
              "GrootBatchGrootUniekeproducten": workerEntry["GrootBatchGrootUniekeproducten"],
              "GrootBatchKleinTotaalproducten": workerEntry["GrootBatchKleinTotaalproducten"],
              "GrootBatchGrootTotaalproducten": workerEntry["GrootBatchGrootTotaalproducten"],
              "iduser": workerEntry["iduser"]
            }
          }
      }
      else
      {
          if(workerEntry["updatedDay"] !== undefined)
          {
            workerEntryFormatted = {
              "updatedDay": moment(workerEntry["updatedDay"]).format('YYYY-MM-DD').toString(),
              "fullName": workerEntry["fullName"],
              "GrootMixedOpen": workerEntry["GrootMixedOpen"],
              "GrootMixedClosed": workerEntry["GrootMixedClosed"],
              "KleinMoreOpen": workerEntry["KleinMoreOpen"],
              "KleinMoreClosed": workerEntry["KleinMoreClosed"],
              "GrootSinglesOpen": workerEntry["GrootSinglesOpen"],
              "GrootSinglesClosed": workerEntry["GrootSinglesClosed"],
              "KleinSinglesOpen": workerEntry["KleinSinglesOpen"],
              "KleinSinglesClosed": workerEntry["KleinSinglesClosed"],
              "AllClosed": workerEntry["AllClosed"],
              "iduser": workerEntry["closed_by_iduser"]
            }
          }
          else
          {
            workerEntryFormatted = {
              "fullName": workerEntry["fullName"],
              "GrootMixedOpen": workerEntry["GrootMixedOpen"],
              "GrootMixedClosed": workerEntry["GrootMixedClosed"],
              "KleinMoreOpen": workerEntry["KleinMoreOpen"],
              "KleinMoreClosed": workerEntry["KleinMoreClosed"],
              "GrootSinglesOpen": workerEntry["GrootSinglesOpen"],
              "GrootSinglesClosed": workerEntry["GrootSinglesClosed"],
              "KleinSinglesOpen": workerEntry["KleinSinglesOpen"],
              "KleinSinglesClosed": workerEntry["KleinSinglesClosed"],
              "AllClosed": workerEntry["AllClosed"],
              "iduser": workerEntry["closed_by_iduser"]
            }
          }
      }
      return workerEntryFormatted;
    }

    const getUsers = async () => {
      try {
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();
        users.value = result;
      } catch (error) {

        return error;
      } 
    }

    const setDatePreset = (text) => {
      let startDate = new Date();
      let endDate = new Date(new Date().setDate(startDate.getDate() + 0));

      if(text === "Today")
      {
        endDate = moment(startDate).endOf('day');
      }
      if(text === "Yesterday")
      {
        startDate = new Date(new Date().setDate(startDate.getDate() - 1));
        startDate = moment(startDate).startOf('day');
        endDate = moment(startDate).endOf('day');
      }
      else if(text === "1 month")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 28))).startOf('day');
      }
      else if(text === "3 months")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 90))).startOf('day');
      }
      
      cookies.set("datePreset", text)
      datePreset.value = cookies.get("datePreset");

      return [startDate, endDate];
    }

    const changeDatePreset = async () => {
      let date = setDatePreset(event.target.innerText);
      setDateInCookie(date);

      startdate.value = moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value = moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

      const query = {
        startDate: startdate.value,
        endDate: enddate.value,
      }

      await updateQueryParameters(query);

      hideDatepickerDropdown();
    }

    const parseValuesToInteger = (data) => {
  // Define an array of columns to be parsed to integers
  const columnsToParse = [
    'GrootBatchGrootTotaalproducten',
    'GrootBatchGrootUniekeproducten',
    'GrootBatchKleinTotaalproducten',
    'GrootBatchKleineUniekeproducten',
    'KleinBatchTotaalProducten',
    'KleinBatchUniekeproducten'
  ];

  // Iterate through the array and parse specified columns to integers
  const parsedData = data.map(entry => {
    const parsedEntry = { ...entry };
    columnsToParse.forEach(column => {
      // Parse to integer and set the value to 0 if it's not a valid integer
      parsedEntry[column] = parseInt(parsedEntry[column], 10) || 0;
    });
    return parsedEntry;
  });

  return parsedData;
};

    // Updates query parameters, make sure this happens after all cookies have been altered.
    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;
    
      liveWorkers.value = isPacker.value ? await getActivePackers() : await getActivePickers();
      liveWorkers.value = parseValuesToInteger(liveWorkers.value); // Parses the necessary values to integers
      liveWorkers.value = liveWorkers.value.map((workerEntry) => {
        
        // Either get closed_by_iduser if packer or assigned_to_iduser if picker
        let value = isPacker.value ? parseInt(workerEntry.closed_by_iduser) : parseInt(workerEntry.iduser);

        // Get current picker/packer
        let user = users.value.find(y => y.pickerId == value);

        totalOpen.value = workerEntry;
        // Filter out loose ids
        if(!pickersFilterOutIds.value.includes(value))
        {
          if(user !== undefined)
          {
            workerEntry.fullName = user.firstName + " " + user.lastName;
            const workerEntryFormatted = formatWorkerEntryForExcel(workerEntry);
            return workerEntryFormatted;
          }
          else if(parseInt(value))
          {
            workerEntry.fullName = value;
            const workerEntryFormatted = formatWorkerEntryForExcel(workerEntry);
            return workerEntryFormatted;
          }

          // If not a number or existing user then return nothing
          // This excludes things like NaN
        }
        
      })

      if(!isPacker.value) { // Merge the multiple batches together
        liveWorkers.value = mergeUserValues(liveWorkers.value);
      }

      loading.value = false;
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }

    }

    const handleDate = (modelData) => {
      date.value = modelData;
      
      startdate.value = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
     // change this back when month and 3 months works again enddate.value =  moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      enddate.value =  moment(date.value[0]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
      
      let diffInDays = Math.abs(moment.duration(moment(date.value[0]).diff(moment(date.value[1]))).asDays());

      if(diffInDays <= 1) // Clear if only 1 day
      {
        filteredPerDayWorkers.value = {};
      }

      const query = {
        startDate: startdate.value,
        endDate: enddate.value,
      }
      updateQueryParameters(query);
      
      hideDatepickerDropdown();
    }

    
    const getActivePackers = async () => {
      try {
        const array = JSON.parse(cookies.get("date"));
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getActiveWorkers?startDate=" + startdate.value + "&endDate=" + enddate.value, { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();

        return result;
      } catch (error) {

        return error;
      } 
    }

    const getActivePickers = async () => {
      try {
        const array = JSON.parse(cookies.get("date"));
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getActivePickers?startDate=" + startdate.value + "&endDate=" + enddate.value, { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();
        return result;
      } catch (error) {

        return error;
      } 
    }

    return { liveWorkers, datePreset, loading, error, date, startdate, enddate, changeDatePreset, handleDate, hideDatepickerDropdown, workers, getActivePickers, getActivePackers, getUsers, users, exportData, totalOpen,
      filteredLiveWorkers, searchWorkerEntry, switchTabs, isPacker, filteredPerDayWorkers, pickersFilterOutIds};
  },
  data() {
    return{
      currentPackerId: "",
      pickers: [],
      errorMessage: "",
      showError: false
    }
  },
  methods: {
    close () {
      var legendPopup = document.querySelectorAll(".legend-popup");
      var legendOverlay = document.querySelectorAll(".legend-overlay");

      legendPopup[0].classList.remove("show");
      legendOverlay[0].classList.remove("show");
    },
    open () {
      var legendPopup = document.querySelectorAll(".legend-popup");
      var legendOverlay = document.querySelectorAll(".legend-overlay");

      legendPopup[0].classList.add("show");
      legendOverlay[0].classList.add("show");
    },
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    hideDatepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
  },
  async created() {
  }
}
</script>
<style lang="scss" scoped>
.legend {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  background: #0B212D;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 6px 0 0 6px;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  i{
    font-size: 32px;
  }
}
.legend-overlay.show {
  opacity: 0.7;
  visibility: visible;
}
.legend-overlay {
  opacity: 0;
  visibility: hidden;
  position: fixed; 
  width: 100%;
  height: 100%;
  top: 0; 
  left: 0;
  background: #0B212D;
  opacity: 0.7;
  z-index: 100;
  transition: all 0.3s ease-in-out;
}
.legend-popup.show {
  opacity: 1;
  visibility: visible;
  top: 50%;
}
.legend-popup {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  max-width: 540px;
  width: 100%;
  max-height: 80%;
  background: white;
  z-index: 100;
  top: 150%;
  left: calc(50% + 120px);
  transform: translate(-50%, -50%);
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.3);
  border: 1px solid #D7DCE1;
  border-radius: $border-radius;
  transition: all 0.3s ease-in-out;
  .legend-header {
    padding: 24px; 
    border-bottom: 1px solid #D7DCE1;
    font-size: 18px;
    font-family: "Neutrif-bold";
    display: flex;
    align-items: center;
    justify-content: space-between;
    i {
      font-size: 24px;
      cursor: pointer;
      opacity: 0.5;
      transition: all 0.3s ease;
      &:hover{
        opacity: 1;
      }
    }
  }
  .title {
    font-size: 18px;
    padding: 24px;
    padding-bottom: 0;
    font-family: "Neutrif-bold";
  }
  .packer-ul {
    border-bottom: 1px solid #D7DCE1;
  }
  ul {
    list-style: none;
    padding: 24px;
    padding-top: 12px;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        padding-top: 2px;
      }
      .circle {
        width: 10px;
        height: 10px;
        background: red;
        border-radius: 100%;
        margin-right: 8px;
      }
    }
    li:nth-child(1) .circle { background: #0ABB87; }
    li:nth-child(2) .circle { background: #FFB822; }
    li:nth-child(3) .circle { background: #5578EB; }
    li:nth-child(4) .circle { background: #FF4A4A; }
    li:nth-child(5) .circle { background: #F199FF; }
    li:nth-child(6) .circle { background: #0B212D; }
  }
}

.date {
  font-weight: 400 !important;
}
.employee-date-picker {
  display: flex;
  margin-bottom: 3.2rem;
  .employee-picker {
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    border: 1px solid #D7DCE1;
    background: #FFFFFF;
    margin-right: 3.2rem;
    border-radius: $border-radius;
    .btn-wrapper {
      display: flex;
      a {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        cursor: pointer;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        padding-top: 2px;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
        &:hover {
          background-color: rgba(245, 246, 248, 0.55);
        }
      }
      a.active {
        background-color: #0B212D;
        color: #ffffff;
        border: none;
      }
    }
    input {
      height: 40px;
      border-radius: $border-radius;
      border: 1px solid #D7DCE1;
      width: 100%;
      padding-left: 1.6rem;
      padding-top: 2px;
    }
  }
  .date-picker{
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    border: 1px solid #D7DCE1;
    background: #FFFFFF;
    border-radius: $border-radius;
    .btn-wrapper {
      display: flex;
      a {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        cursor: pointer;
        height: 40px;
        width: 25%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
        &:hover {
          background-color: rgba(245, 246, 248, 0.55)
        }
      }
      a.active {
        background-color: #0B212D;
        color: #ffffff;
        border: none;
      }
    }
    .datepicker-customSelector {
      input {
        height: 40px;
      }
    }
  }
}

.export-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.6rem;
  .info-wrapper {
    font-size: 18px;
    font-family: "Neutrif-bold";
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #FFFFFF;
    border-radius: $border-radius;
    border: 1px solid #D7DCE1;
    padding: 0 20px;
    font-size: 1.6rem;
    i {
      font-size: 20px;
    }
    span {
      padding-top: 2px;
    }
  }
}

.table-container{
  overflow-y: hidden;
  width: 100%;
  .table-block {
    border-radius: $border-radius;
    background: white;
    border: 1px solid #D7DCE1;
    margin-bottom: 3.2rem;
    .table-header {
      padding: 3.2rem;
      border-bottom: 1px solid #D7DCE1;
      background: #2E303F;
      border-radius: 6px 6px 0 0;
      color: white;
      display: flex;
      justify-content: center;
      .info {
        display: flex;
        padding-top: 4px;
        span {
          margin-right: 1.6rem;
        }
        i {
          margin-right: 0.64rem;
        }
        &:last-child {
          span {
            margin-right: 0;
          }
        }
      }
    }
  }
  .table{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
  }
  .table-striped{
    thead{
      width: 100%;
      tr{
        width: 100%;
        display: flex;
        justify-content: space-between;
        th{
          border-bottom: 1px solid #D7DCE1;
          text-align: left;
          padding: 2.4rem 3.0rem;
          color: #9397A1;
          font-size: 14px;
          font-weight: 400;
        }
        .name {
          min-width: 240px;
          max-width: 240px;
        }
        .packed {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
        }
        .picked {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
        }
        .link {
          min-width: 72px;
          max-width: 72px;
        }
      }
    }
    tbody{
      width: 100%;
      tr{
        width: 100%;
        border-bottom: 1px solid #D7DCE1;
        display: flex;
        justify-content: space-between;
        .name {
          min-width: 240px;
          max-width: 240px;
        }
        .picked {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
          width: 10px;
          display: flex;
          align-items: center;
          .info-wrapper{
            width: 100%; 
            display: flex;
            .info:hover .tooltiptext{
              visibility: visible;
              transform: scale(1);
              opacity: 1;
              top: -150%;
            }
            .info {
              border: 1px solid #D7DCE1;
              color: #9397A1;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 24px;
              white-space: nowrap;
              height: fit-content;
              position: relative;
              .tooltiptext {
                position: absolute;
                top: -75%;
                visibility: hidden;
                width: auto; 
                background-color: #FFFFFF;
                border: 1px solid #D7DCE1;
                padding: 0 1.6rem;
                z-index: 1;
                height: 32px;
                border-radius: 5px;
                display: flex; 
                justify-content: center;
                align-items: center;
                transform: scale(0.4);
                transition: .2s ease;
                opacity: 0;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                &:before {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -20px;
                  width: 0;
                  height: 0;
                  border: 10px solid transparent;
                  border-top-color: #D7DCE1;
                }
                &:after {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -18px;
                  width: 0;
                  height: 0;
                  border: 9px solid transparent;
                  border-top-color: white;
                }
              }
              span {
                color: #0B212D;
                font-size: 20px;
                font-family: "Neutrif-bold";
                position: relative;
                width: 60px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before { 
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 0 0 5px 5px;
                }
              }
              span.bbbu::before { background: #0ABB87; }
              span.bbsu::before { background: #FFB822; }
              span.bbbt::before{ background: #5578EB; }
              span.bbst::before { background: #FF4A4A; }
              span.sbu::before { background: #F199FF; }
              span.sbt::before { background: #0B212D; }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .packed {
          min-width: calc(100% - 312px);
          max-width: calc(100% - 312px);
          width: 10px;
          display: flex;
          align-items: center;
          .info-wrapper{
            width: 100%; 
            display: flex;
            .info:hover .tooltiptext{
              visibility: visible;
              transform: scale(1);
              opacity: 1;
              top: -150%;
            }
            .info {
              border: 1px solid #D7DCE1;
              color: #9397A1;
              border-radius: 5px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 24px;
              white-space: nowrap;
              height: fit-content;
              position: relative;
              .tooltiptext {
                position: absolute;
                top: -75%;
                visibility: hidden;
                width: auto; 
                background-color: #FFFFFF;
                border: 1px solid #D7DCE1;
                padding: 0 1.6rem;
                z-index: 1;
                height: 32px;
                border-radius: 5px;
                display: flex; 
                justify-content: center;
                align-items: center;
                transform: scale(0.4);
                transition: .2s ease;
                opacity: 0;
                box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
                &:before {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -20px;
                  width: 0;
                  height: 0;
                  border: 10px solid transparent;
                  border-top-color: #D7DCE1;
                }
                &:after {
                  content: '';
                  display: block;  
                  position: absolute;
                  bottom: -18px;
                  width: 0;
                  height: 0;
                  border: 9px solid transparent;
                  border-top-color: white;
                }
              }
              span {
                color: #0B212D;
                font-size: 20px;
                font-family: "Neutrif-bold";
                position: relative;
                width: 60px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:before { 
                  content: "";
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  height: 4px;
                  border-radius: 0 0 5px 5px;
                }
              }
              span.bc::before { background: #0ABB87; }
              span.sc::before { background: #FFB822; }
              span.bs::before{ background: #5578EB; }
              span.ss::before { background: #FF4A4A; }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .link {
          min-width: 72px;
          max-width: 72px;
          i {
            color: #0B212D;
            font-size: 32px;
          }
        }
        td, th{
          text-align: left;
          padding: 2.4rem 3.0rem;
          font-size: 14px;
          font-weight: 700;
          border-right: 1px solid #D7DCE1;
          height: 72px;
          &:last-child {
            border-right: 0;
          }
          a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
              transition: all 0.3s ease;
            }
          }
          a.greyed-out {
            border: 2px solid #9397A1;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #9397A1;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            pointer-events: none;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                fill: #9397A1;
                transition: all 0.3s ease;
              }
            }
          }
        }
        &:last-child td, &:last-child th {
          border-bottom: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) { }
</style>
