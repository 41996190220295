<template>
  <form @submit.prevent="handleSubmit">
    <input class="signup-input" type="text" required placeholder="Display name" v-model="displayName">
    <input class="signup-input" type="email" required placeholder="Email" v-model="email">
    <input class="signup-input" type="password" required placeholder="Password" v-model="password">
    <div class="error">{{ error }}</div>

    <button>
      <p>Sign up</p>
    </button>
  </form>
</template>

<script>
import { ref } from 'vue'
import useSignup from './useSignup'

export default {
  setup(props, context) {
    const { error, signup } = useSignup()

    //refs
    const displayName = ref('')
    const email = ref('')
    const password = ref('')

    const handleSubmit = async () => {
      await signup(email.value, password.value, displayName.value)
      if (!error.value) {
        context.emit('signup')
      }
    }

    return { displayName, email, password, handleSubmit, error }
  }
}
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.signup-input {
  width: 100%;
  padding: 1.6rem 2.0rem;
  border-radius: 1rem;
  border: none;
  background-color: var(--bg-gray);
  outline: none;
  height: 5.0rem;


  &::placeholder {
    color: var(--color-text-gray);
    font-size: 1.8rem;
  }

  &:not(:placeholder-shown) {
    font-size: 1.8rem;
  }
}

button {
  height: 5.0rem;
  text-decoration: none;
  background-color: var(--color-orange);
  color: var(--color-white);
  font-size: 1.8rem;
  font-weight: 500;
  border: none;
  border-radius: 1rem;
  padding: 0px 2rem;
  width: 100%;
  z-index: 1;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.error {
  display: none;
}

@media (max-width: 1023px) {
  form {
    gap: 1.8rem;
  }

  .signup-input {

    padding: 1.2rem 1.6rem;
    border-radius: 0.8rem;
    height: 4rem;


    &::placeholder {
      font-size: 1.3rem;
    }

    &:not(:placeholder-shown) {
      font-size: 1.3rem;
    }
  }

  button {
    height: 4rem;
    font-size: 1.4rem;
    border-radius: 0.8rem;
  }
}

@media (max-width: 576px) {
  form {
    gap: 2rem;
  }

  .signup-input {

    padding: 1.2rem 1.6rem;
    border-radius: 1.2rem;
    height: 5rem;


    &::placeholder {
      font-size: 1.6rem;
    }

    &:not(:placeholder-shown) {
      font-size: 1.6rem;
    }
  }

  button {
    height: 5rem;
    font-size: 1.6rem;
    border-radius: 1.2rem;

  }
}
</style>