<template>
  <!-- <transition name="fade" mode="out-in"> -->
    <div class="tab-content" v-show="title == selectedTitle">
      <slot />
    </div>
  <!-- </transition> -->
</template>

<script>
import { inject } from 'vue'

export default {
  props: ['title'],
  setup(){
    const selectedTitle = inject("selectedTitle")

    return {
      selectedTitle
    }
  }
}
</script>

<style lang="scss" scoped>
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.5s ease;
// }
// .fade-enter-from,
// .fade-leave-to {
//   opacity: 0;
// }
</style>