import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

export const getFilteredOutEmployees = async () => {
    const user = cookies.get('token');
    const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/employeefilteroutget", {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""}
    });
    const result = await res.json();
    return JSON.parse(JSON.stringify(result));
}

export const getFilteredOutEmployeesIds = async () => {
    const user = cookies.get('token');
    const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/employeefilteroutidsget", {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""}
    });
    const result = await res.json();
    return JSON.parse(JSON.stringify(result));
}

export const postFilteredOutEmployees = async (data) => {
    const user = cookies.get('token');
    const response = await fetch(process.env.VUE_APP_ROOT_API + "/api/employeefilterout", {
        method: 'POST', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""},
        body: data
    });

    return response;
}

export const postFilteredOutEmployeesIds = async (data) => {
    const user = cookies.get('token');
    const response = await fetch(process.env.VUE_APP_ROOT_API + "/api/employeefilteroutids", {
        method: 'POST', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""},
        body: data
    });

    return response;
}