<template>
  <div id="globeViz"></div>
</template>

<script>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import Globe from 'globe.gl';
import * as THREE from 'three'

export default {
  name: 'GlobeVisualization',
  setup(props) {
    const myWorld = ref(null);
    const pointColor = '#fff';
    const arcColor = '#8F2100';
    // const europeCoordinates = { lat: 2, lng: 5, altitude: 0 };

    const locations = ref([
      { name: "Netherlands", coordinates: [5.2913, 52.1326], openPicklists: 104 },
      { name: "Germany", coordinates: [10.4515, 51.1657], openPicklists: 488 },
      { name: "France", coordinates: [2.2137, 46.2276], openPicklists: 53 },
      { name: "Spain", coordinates: [-3.7492, 40.4637], openPicklists: 13 },
      { name: "Denmark", coordinates: [9.5018, 56.2639], openPicklists: 12 },
      { name: "Italy", coordinates: [12.5674, 41.8719], openPicklists: 7 },
      { name: "Poland", coordinates: [19.1451, 51.9194], openPicklists: 20 },
      { name: "United Kingdom", coordinates: [-3.4359, 55.3781], openPicklists: 7 },
      { name: "Belgium", coordinates: [4.4699, 50.5039], openPicklists: 33 },
      { name: "Czechia", coordinates: [15.4729, 49.8175], openPicklists: 15 },
      { name: "United States", coordinates: [-95.7129, 37.0902], openPicklists: 100 },
    ]);

    const makeMap = () => {
      // const globeVizElement = document.getElementById("globeViz");
      // const width = globeVizElement.offsetWidth;
      // const height = globeVizElement.offsetHeight;

      nextTick().then(() => {
        const globeVizElement = document.getElementById("globeViz");
        const width = globeVizElement.offsetWidth;
        const height = globeVizElement.offsetHeight;
        const europeCoordinates = { lat: 2, lng: 5, altitude: 0 };



        // const createRippleRings = (location) => {
        //   const numRings = 5; // Number of rings per ripple
        //   const rippleRings = [];
        //   for (let i = 0; i < numRings; i++) {
        //     rippleRings.push({
        //       lat: location.coordinates[1],
        //       lng: location.coordinates[0],
        //       maxR: Math.sqrt(location.openPicklists) * 0.06 + i * 0.01, // Incremental radius
        //       propagationSpeed: 0.1, // Adjust as needed
        //       repeatPeriod: 2000, // Adjust as needed
        //       opacity: (numRings - i) / numRings // Decreasing opacity
        //     });
        //   }
        //   return rippleRings;
        // };

        // const gData = locations.value.flatMap(location => createRippleRings(location));


        const arcsData = locations.value.map((endpt, index) => ({
          startLat: locations.value[0].coordinates[1],
          startLng: locations.value[0].coordinates[0],
          endLat: endpt.coordinates[1],
          endLng: endpt.coordinates[0],
          initialGap: Math.random(),
          isUSA: endpt.name === "United States",
          animationTime: Math.random() * 4000 + 1000 // Random time between 1000ms and 5000ms
        })).filter((_, i) => i > 0);

        fetch("https://raw.githubusercontent.com/vasturiano/globe.gl/master/example/datasets/ne_110m_admin_0_countries.geojson")
          .then(countries => {


            if (myWorld.value) myWorld.value = null;

            myWorld.value = Globe()
              .width(width) // Set width to 100% of the container
              .height(height)
              .backgroundColor("#ffeee9")
              .globeImageUrl("./img/earth-orange.png") // Adjust the path as needed
              .pointResolution(50)
              .arcsData(arcsData)
              .arcDashAnimateTime(d => d.animationTime)
              .arcColor(() => arcColor)
              .arcAltitude((d) => (d.isUSA ? 0.1 : 0.05)) // Higher altitude for arcs connected to the US
              .arcStroke(() => 0.15)
              .arcDashLength(1)
              .arcDashGap(1)
              .arcDashInitialGap(() => Math.random())
              .arcDashAnimateTime(4000)
              .arcsTransitionDuration(0)
              .atmosphereAltitude(0)
              .pointsData(locations.value)
              .pointLat(d => d.coordinates[1])
              .pointLng(d => d.coordinates[0])
              .pointColor(() => pointColor) // Use a bright color for visibility
              .pointResolution(50)
              .pointAltitude(() => 0.001) // Slightly elevated for visibility
              .pointRadius((d) => Math.sqrt(d.openPicklists) * 0.06)



              // .ringsData(gData)
              // .ringColor(() => pointColor) // Adjust color as needed
              // .ringMaxRadius(d => d.maxR)
              // .ringPropagationSpeed(d => d.propagationSpeed)
              // .ringRepeatPeriod(d => d.repeatPeriod)


              .pointOfView(europeCoordinates)
              .onGlobeReady(() => {

                setTimeout(transitionToEurope(myWorld.value), 3000);
                offsetCameraY(-70);



              })
              .lineHoverPrecision(0)
              (document.getElementById("globeViz"));
            (globeVizElement);
          });

        // ... the rest of your code that relies on the dimensions ...
      });

    };
    const offsetCameraY = (offsetY) => {
      if (myWorld.value && myWorld.value.camera()) {
        const globeCamera = myWorld.value.camera();
        const originalMatrix = globeCamera.projectionMatrix.clone();
        globeCamera.projectionMatrix = originalMatrix.multiply(new THREE.Matrix4().makeTranslation(0, offsetY, 0));
      }
    }
    const transitionToEurope = (globe) => {
      const europeCoordinates = { lat: 2, lng: 5, altitude: 0 };

      globe.pointOfView(europeCoordinates, 3000);
    };

    onMounted(() => {
      makeMap();

      let europeCoordinates = { lat: 2, lng: 5, altitude: 0 };
      const handleResize = () => {
        const globeVizElement = document.getElementById("globeViz");
        const baseContaner = document.getElementsByClassName("picklist-closed")
        const width = (baseContaner[0].offsetWidth - 33);
        const height = globeVizElement.offsetHeight;


        if (myWorld.value && myWorld.value.camera()) {
          myWorld.value.camera().aspect = width / height;
          myWorld.value.camera().updateProjectionMatrix();
        }
        if (myWorld.value) {
          myWorld.value.pointOfView(europeCoordinates);
        }
        const canvas = globeVizElement.getElementsByTagName('canvas')[0];
        canvas.style.width = `${width}px`;
        offsetCameraY(-70);
      };


      window.addEventListener('resize', handleResize); // Use the wrapper function for the resize event
    });

    onUnmounted(() => {
      // Remember to remove the same listener you added
      window.removeEventListener('resize', handleResize);
    });



    return {
      myWorld,
      locations,

    };
  }
};
</script>

<style>
#globeViz {
  width: 100%;
  height: 36%;
  border-radius: 1rem;
  overflow: hidden;
}

.scene-container {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 1023px) {
  #globeViz {
    width: 170%;
    height: 100%;
    margin-right: 1.6rem;
  }

}
@media (max-width: 576px) {
  #globeViz{
    width: 100%;
  }
}
</style>