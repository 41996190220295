<template>
  <div>
    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Sales by product</div>
        <div class="subtitle">Coming soon ...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sales-by-product",
  data() {
    return{

    }
  },
  mounted() {
 
  }
}
</script>
<style lang="scss" scoped>

</style>
