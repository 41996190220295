<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="card-wrapper-skeleton">
      <div class="card-title-skeleton"></div>
      <div class="card-data-skeleton">
        <div></div>
        <div></div>
      </div>
    </div>

    <div v-else style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
      <div class="card-title">Total sales</div>
      <div class="card-data">
        {{ totalSales }}

        <span :class="{ 'bg-color-red': totalSalesPercentage < 0, 'bg-color-gray': totalSalesPercentage === 0, 'bg-color-green': totalSalesPercentage > 0,  }">{{ totalSalesPercentage + "%" }}</span>
      </div>
    </div>
  </transition>
</template>

<script>

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ref, onMounted } from "vue";
import moment from 'moment';

export default {
  data(){
    return{
    }
  },
  setup()
  {
    const user = ref({});
    const totalSales = ref(0);
    const totalSalesYesterday = ref(0);
    const totalSalesPercentageValue = ref([]);
    const totalSalesPercentageValueYesterday = ref([]);
    const totalSalesPercentage = ref("");
    const loading = ref(true);
    const showError = ref(null);
    const errorMessage = ref(null);

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',  
      minimumFractionDigits: 0
    });

    const getCurrentTotalSales = async () => {
      try {
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/totalPrice/all", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}});
        
        const result = await res.json();
        totalSales.value = formatter.format(result);
        totalSalesPercentageValue.value = parseInt(result);
        totalSales.value.update();
    
        loading = false
      } catch (error) {
        errorMessage.value = error
        showError.value = true
        loading.value = false
      }
    }

    const getYesterdayTotalSales = async () => {

      let date = moment(new Date()).subtract(1, 'days').startOf('day');
      let date2 = moment(new Date()).subtract(1, 'days');
      let startdate = date.format('YYYY-MM-DD HH:mm:ss').toString();
      let enddate = date2.format('YYYY-MM-DD HH:mm:ss').toString();

      try {
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/totalPrice/all?" + "startdate=" + startdate + "&enddate=" + enddate, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}});
        
        const result = await res.json();
        
        totalSalesYesterday.value = formatter.format(result);
        totalSalesPercentageValueYesterday.value = parseInt(result);
        totalSalesYesterday.value.update();
    
        loading = false
      } catch (error) {
        errorMessage.value = error
        showError.value = true
        loading.value = false
      }
    }

    onMounted(async () => {
        user.value = cookies.get('token');
        loading.value = true

        // Promise.all([getCurrentTotalSales(), getYesterdayTotalSales()]).then(() => {
        //     totalSalesPercentage.value = (100 / totalSalesPercentageValueYesterday.value * (totalSalesPercentageValue.value - totalSalesPercentageValueYesterday.value)).toFixed(0); 
        // });
    });

    return { user, totalSales,totalSalesYesterday, formatter, totalSalesPercentage, loading, showError, errorMessage}
  }
}
</script> 

<style lang="scss" scoped>
.card-data {
  span{
    font-size: 16px;
    padding: 5px 10px;
    border-radius: $border-radius;
  }
  .bg-color-red {
    color: #cf304a;
    background-color: #FFD4DB;
  }
  .bg-color-gray {
    color: #636363;
    background-color: #CECECE;
  }
  .bg-color-green {
    color: #509177;
    background-color: #EBFCF2;
  }
}
.card-wrapper-skeleton{
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.card-title-skeleton{
  width: 100%; 
  height: 24px; 
  animation: pulse-bg 1s infinite;
}
.card-data-skeleton{
  display: flex;
  justify-content: space-between;
  div:nth-child(1){
    height: 24px;
    width: 75%;
    animation: pulse-bg 1s infinite;
  }
  div:nth-child(2){
    height: 24px;
    width: 24px;
    border-radius: 100%;
    animation: pulse-bg 1s infinite;
  }
}

/* Tommie styling */
.bg-color-green {
  background-color: green;
}

.bg-color-gray {
  background-color: #d5d5d5;
}

.bg-color-red {
  background-color: red;
}
</style>