<template>
  <div>
    Bookers indivudual report
  </div>
</template>

<script>
export default {
  name: "bookers-individual-report",
  data() {
    return{

    }
  },
  mounted() {
 
  }
}
</script>
<style lang="scss" scoped>

</style>
