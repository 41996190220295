<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="card-wrapper-skeleton">
      <div class="card-title-skeleton"></div>
      <div class="card-data-skeleton"></div>
    </div>

    <div v-else style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
      <div class="card-title">Today Sales Per Shop</div>
      <div class="card-data">
        <DoughnutChart v-bind="doughnutChartProps" ref="doughNutRef" />
      </div>
    </div>
  </transition>
</template>

<script>

import { DoughnutChart, useDoughnutChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ref, onMounted, computed } from "vue";

export default {
  components: {DoughnutChart},
  data(){
    return{
    }
  },
  setup()
  {
    const user = ref({});
    const perShop = ref([]);
    const perShopLabels = ref([]);
    const loading = ref(true);
    const showError = ref(null);
    const errorMessage = ref(null);
    const doughNutRef = ref(null);

    Chart.register(...registerables);

    onMounted(async () => {
        user.value = cookies.get('token');
        loading.value = true
       
       try {
            const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/domains/totalprice/closed", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
            const result = await res.json();
            console.log(result);
            
            Object.entries(result).forEach((element, index) => {
              if(element[1].totalTotaalprijs !== 0) {
                perShopLabels.value.push(element[1].domain);
                perShop.value.push(element[1].convertedTotalTotaalPrijs !== null ? element[1].convertedTotalTotaalPrijs : element[1].totalTotaalprijs);             
              }
            });

            perShop.value.update();
            perShopLabels.value.update();

            loading = false
          } catch (error) {
            errorMessage.value = error
            showError.value = true
            loading.value = false
          }
        })


    const testData = computed(() => ({
      labels: perShopLabels.value,
      showTooltips: false,
      datasets: [
        {
          backgroundColor: ['#0B212D', '#0ABB87', '#CDCDCD', '#5578EB', '#FFB822', '#FD397A', '#F199FF', '#0AA8FF', '#FF7A00', '#00B828', '#AD00FF', '#FF4A4A', '#FAFF00', '#861E31', '#81A89A'],
          data: perShop.value
        },
      ],
    }));

    const options = ref({
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: '#0B212D',
        font: {
          size: 14,
          weight: 'bold'
        },
        usePointStyle: 'true',
        pointStyle: 'circle'
      }
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.label;
          const value = context.dataset.data[context.dataIndex];
          
          const formattedValue = new Intl.NumberFormat('sfb', { style: 'currency', currency: 'EUR' }).format(value);

          return `${label}: ${formattedValue}`;
        }
      }
    }
  }
})

    const { doughnutChartProps, doughnutChartRef } = useDoughnutChart({
      chartData: testData,
      options,
    });

    return { user, perShop, perShopLabels, doughNutRef, doughnutChartRef, doughnutChartProps, loading, showError, errorMessage}
  }
}
</script> 

<style lang="scss" scoped>
.card-wrapper-skeleton{
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.card-title-skeleton{
  width: 100%; 
  height: 24px; 
  animation: pulse-bg 1s infinite;
}
.card-data-skeleton{
  display: flex;
  justify-content: space-between;
  height: calc(100% - 40px);
  width: 100%;
  animation: pulse-bg 1s infinite;
}
.card-data {
  > div {
    width: 100%;
    height: 360px;
  }
}
</style>