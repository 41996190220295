<template>
  <header class="header">
    <a class="header__logo" href="/">
      <LogoGetsales />
    </a>
    <!-- <div class="header__record">
      <div class="wrap">
        <RecordSvg />
      </div>
    </div> -->
    <div class="header__date">
      <DateTime />
    </div>
  </header>
</template>
<script>
import LogoGetsales from '@/components/svg/LogoGetsales.vue';
import RecordSvg from '@/components/svg/RecordSvg.vue';
import DateTime from './DateTime.vue';

export default {
  components: { LogoGetsales, DateTime, RecordSvg }
}
</script>
<style lang="scss">

@keyframes grow {
  0% {
    transform: scale(1.0) rotate(0deg);
  }
  50% {
    transform: scale(1.1) rotate(-2deg);
  }
  100% {
    transform: scale(1.0) rotate(0deg);
  }
}

.warehouse-dashboard {
  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.2rem;
    height: calc(7% - 3.2rem);
    position: relative;
    &__cake {
      display: flex; 
      flex: 1;
      .cake-wrap {
        color: white;
        width: fit-content;
        background: var(--standard-color);
        padding: 2rem;
        border-radius: 1rem;
        margin-left: 3.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        h1 {
          margin: 0;
          font-size: 2.4rem;
        }
      }
    }
    &__logo {
      width: 27rem;


      & svg {
        display: block;
      }
    }
    &__record {
      position: absolute;
      top: -2rem;
      width: 100%;
      height: calc(100% + 6rem);    
      overflow: hidden;
      pointer-events: none;
      .wrap {
        animation: grow 5s ease-in-out infinite;
        width: 100%;
        height: 100%;;  
        svg {
          width: 100%; height: 100%;
        }
      }
    }
    &__date {
      font-size: 2rem;
      font-weight: 450;
      padding: 0.9rem 1.6rem;
      background-color: #ffe0d6;
      border-radius: 1rem;
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    & .header {



      margin-bottom: 2.4rem;
      height: calc(6% - 2.4rem);

      &__logo {
        width: 17.6rem;

        & svg {
          display: block;
        }
      }

      &__date {
        display: none;
      }
    }
  }
}
</style>