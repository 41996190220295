<template>
  <transition name="fade" mode="out-in" appear>

    <div v-if="loading" class="per-hour-graph-wrapper">
      <div class="card-title">Orders completed per hour</div>
      <LineChart v-bind="lineChartProps" ref="lineRef" />
    </div>

    <div v-else class="card-wrapper-skeleton">
      <div class="card-title-skeleton">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="card-data-skeleton"></div>
    </div>

    <!-- <p v-if="error"></p> -->

  </transition>
</template>

<script> 
import { ref, onMounted, computed, watchEffect } from "vue";
import { defineComponent } from 'vue';

import { LineChart, useLineChart } from 'vue-chart-3';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import getUser from '../../composeables/getUser'

export default defineComponent({
  components: { LineChart },
  data(){
    return{
      gradient: "",
    }
  },
  setup() {
    Chart.register(...registerables);

    const lineRef = ref(null);
  
    const bgColor = ref('transparent');
    const borderColor = ref('transparent');

    const loading = ref(false);
    const error = ref(null);
    const orderCount = ref(null);
    const { user } = getUser();

    async function fetchData() {
      loading.value = false;
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/completed/per-hour-graph-of-today", {
        // method: 'get', 
        // headers: {
        //   'content-type': 'application/json'
        // }
      })
      .then(res => res.json())
      .then(result => {
        orderCount.value = result;
      })
      .catch(err => {
        error.value = err;
        if (err.json) {
          return err.json.then(json => {
            error.value.message = json.message;
          })
        }
      })
      .then(() => {
        loading.value = true;
      })
    }

    async function fetchDataAfter() {
      return await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/completed/per-hour-graph-of-today", {
        method: 'get', 
        headers: {
          'content-type': 'application/json',
          "Authorization": user ? `Bearer ${user._value.token}` : ""
        }
      })
      .then(res => res.json())
      .then(result => {
        orderCount.value = result;
        orderCount.value.update();
      })
      .catch(err => {
        error.value = err;
        if (err.json) {
          return err.json.then(json => {
            error.value.message = json.message;
          })
        }
      })
    }

    function setGradient() {
      const canvas = lineRef.value.canvasRef;
      bgColor.value = canvas.getContext('2d').createLinearGradient(0, 300, 0, 0);
      bgColor.value.addColorStop(0.0, 'rgba(58, 175, 227, 0.5)')
      bgColor.value.addColorStop(0.3, 'rgba(58, 175, 227, 0.6)')
      bgColor.value.addColorStop(1, 'rgba(46, 48, 63, 0.6)');

      borderColor.value = canvas.getContext('2d').createLinearGradient(450, 0, 0, 0);
      borderColor.value.addColorStop(0, 'rgba(46, 48, 63, 1)');
      borderColor.value.addColorStop(1, 'rgba(46, 48, 63, 1)')
    }

    function updateDashboardOnInterval() {
      setTimeout(() => {
        fetchDataAfter();
        updateDashboardOnInterval();
      }, 5000);
    }

    onMounted(() => {
      fetchData();
    })

    watchEffect(() => {
      updateDashboardOnInterval();
    })

    const hours = ["00:00", "01:00", "02:00", "03:00", "04:00", "05:00", "06:00", "07:00", "08:00", "09:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00", "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"];
    
    const getOrCreateTooltip = (chart) => {
      let tooltipEl = chart.canvas.parentNode.querySelector('div');

      if (!tooltipEl) {
        tooltipEl = document.createElement('div');
        tooltipEl.style.background = '#2e303f';
        tooltipEl.style.boxShadow = "0 0 16px rgba(0, 0, 0, 0.15)";
        tooltipEl.style.borderRadius = '5px';
        tooltipEl.style.color = '#FFF';
        tooltipEl.style.opacity = 1;
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.transform = 'translate(-50%, 0)';
        tooltipEl.style.transition = 'all .3s ease';
        tooltipEl.style.width = 'fit-content';
        tooltipEl.style.display = 'flex';
        tooltipEl.style.justifyContent = 'center';

        const table = document.createElement('table');
        table.style.margin = '0px';

        tooltipEl.appendChild(table);
        chart.canvas.parentNode.appendChild(tooltipEl);
      }

      return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
      // Tooltip Element
      const {chart, tooltip} = context;
      const tooltipEl = getOrCreateTooltip(chart);

      // Hide if no tooltip
      if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return;
      }

      // Set Text
      if (tooltip.body) {
        const titleLines = tooltip.title || [];
        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');
        tableHead.style.fontSize = '12px';

        titleLines.forEach(title => {
          const tr = document.createElement('tr');
          tr.style.borderWidth = 0;

          const th = document.createElement('th');
          th.style.borderWidth = 0;
          const text = document.createTextNode(title);

          th.appendChild(text);
          tr.appendChild(th);
          tableHead.appendChild(tr);
        });

        const tableBody = document.createElement('tbody');
        tableBody.style.fontSize = '12px';

        bodyLines.forEach((body, i) => {
          const colors = tooltip.labelColors[i];

          const span = document.createElement('span');
          span.style.background = colors.backgroundColor;
          span.style.borderColor = colors.borderColor;
          span.style.borderWidth = '2px';
          span.style.marginRight = '10px';
          span.style.height = '10px';
          span.style.width = '10px';
          span.style.display = 'inline-block';

          const tr = document.createElement('tr');
          tr.style.backgroundColor = 'inherit';
          tr.style.borderWidth = 0;

          const td = document.createElement('td');
          td.style.borderWidth = 0;

          const text = document.createTextNode(body);

          td.appendChild(span);
          td.appendChild(text);
          tr.appendChild(td);
          tableBody.appendChild(tr);
        });

        const tableRoot = tooltipEl.querySelector('table');

        // Remove old children
        while (tableRoot.firstChild) {
          tableRoot.firstChild.remove();
        }

        // Add new children
        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);
      }

      const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;

      // Display, position, and set styles for font
      tooltipEl.style.opacity = 1;
      tooltipEl.style.left = positionX + tooltip.caretX + 'px';
      tooltipEl.style.top = positionY + tooltip.caretY + 'px';
      tooltipEl.style.font = tooltip.options.bodyFont.string;
      tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
    };

    const options = ref({
      animation: {
        onProgress: function(context) {
          if (context.initial) {
            setGradient();
          } else {
            
          }
        }
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          grid: {
            // color: 'rgba(51,51,51,0.1)', 
            color: 'red',
            display: false,
          },
          gridLines: {
            color: 'rgba(51,51,51,1)', 
            display: true,
          },
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            },
          },
        },
        y: {
          grid: {
            color: 'rgba(51,51,51,0.1)',
          },
          beginAtZero: true,
          ticks: {
            color: '#D0D0D0',
            font: {
              family: 'Neutrif-reg',
              size: 14,
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: externalTooltipHandler
        }
      },
      maintainAspectRatio: false,
    })

    const testData = computed(() => ({
      labels: hours,
      showTooltips: false,
      datasets: [
        {
          label: 'Orders completed',
          data: orderCount.value,
          // data: [5, 10, 15, 20, 10, 5, 1, 6, 2, 9, 10, 3, 6, 1, 6, 2, 7, 8, 2, 9, 21, 22, 56, 12],
          borderColor: borderColor.value,
          backgroundColor: bgColor.value,
          pointBackgroundColor: "#FFFFFF",
          fill: true,
          lineTension: 0.2,
          pointRadius: 4,
          borderWidth: 3   
        },
      ],
    }));

    const { lineChartProps, lineChartRef } = useLineChart({
      chartData: testData,
      options
    });

    return { testData, options, loading, error, orderCount, lineChartProps, lineChartRef, lineRef, setGradient };
  }
});
</script>

<style lang="scss" scoped>
.per-hour-graph-wrapper {
  position: relative;
  margin: auto;
  height: 44.8rem;
  width: 100%;
  .card-title{
    margin-bottom: 20px;
  }
  div:nth-child(2){
    height: auto;
    canvas{
      height: auto;
    }
  }
}
.card-wrapper-skeleton{
  height: 44.8rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.card-title-skeleton{
  display: flex;
  justify-content: space-between;
  height: 24px;
  width: 100%;
  div:nth-child(1){
    height: 100%;
    width: calc((100% / 3) - 50px);
    animation: pulse-bg 1s infinite;
  }
  div:nth-child(2){
    height: 100%;
    width: calc((100% / 3) - 50px);
    animation: pulse-bg 1s infinite;
  }
  div:nth-child(3){
    height: 100%;
    width: calc((100% / 3) - 50px);
    animation: pulse-bg 1s infinite;
  }
}
.card-data-skeleton{
  width: 100%; 
  height: 85%; 
  animation: pulse-bg 1s infinite;
}
</style>