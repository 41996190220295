<template>
  <Page>
    <div class="parent">
      <div class="page-header">
        <div class="title-wrapper">
          <div class="title">Account settings</div>
          <div class="subtitle">Account information and general settings</div>
        </div>
      </div>

      <div class="settings-wrapper">
        <ul class="nav">
          <li>
            <router-link to="/settings/profile" tag="div" class="iocn-link">
              <i class='bx bxs-user'></i>
              <span class="link_name">Profile</span>
            </router-link>
          </li>
          <li>
            <router-link to="/settings/theme" tag="div" class="iocn-link">
              <i class='bx bxs-palette'></i>
              <span class="link_name">Theme</span>
            </router-link>
          </li>
          <li>
            <router-link to="/settings/manage-users" tag="div" class="iocn-link">
              <i class='bx bxs-user-minus'></i>
              <span class="link_name">Manage users</span>
            </router-link>
          </li>
          <li>
            <router-link to="/settings/filter" tag="div" class="iocn-link">
              <i class='bx bxs-filter-alt'></i>
              <span class="link_name">Filter</span>
            </router-link>
          </li>
        </ul>

        <router-view class="child"></router-view>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import VueMultiselect from 'vue-multiselect'

export default {
  name: "settings",
  components: { Page, VueMultiselect },
  data() {
    return {

    }
  },
  setup() {
  }
}
</script>

<style lang="scss">
.settings-wrapper {
  display: flex;
  .nav {
    width: 320px;
    margin-right: 30px;
    list-style: none;
    li {
      border-radius: $border-radius;
      a{
        display: flex;
        width: 100%;
        border-radius: $border-radius;
        padding: 8px 12px;
        color: #0B212D;
        align-items: center;
        i {
          font-size: 18px;
          margin-right: 8px;
          color: #9397A1;
        }
        span {
          padding-top: 4px;
        }
      }
      a.router-link-active {
        background-color: white;
        border: 1px solid #D7DCE1;
        color: #3AAFE3;
        font-family: "Neutrif-bold";
        i {
          color: #3AAFE3;
        }
      }
    }
  }
}

.settings {
  width: calc(100% - 320px);
  height: auto;
  margin-right: 0;
  border: 1px solid #D7DCE1;
  border-radius: $border-radius;
  background-color: white;
  div:last-child{
    border-bottom: 0;
  }
  .settings-header {
    border-bottom: 1px solid #D7DCE1;
    padding: 3.0rem; 
    .title {
      font-size: 18px;
      font-family: "Neutrif-bold";
    }
    .sub-title {
      color: #9397A1;
    }
  }
  #employees-select {
    border-bottom: 1px solid #D7DCE1;
    padding: 3.0rem;
    width: 100%;
  }
  .settings-body {
    border-bottom: 1px solid #D7DCE1;
    padding: 3.0rem;
    display: flex;
    flex-wrap: wrap;
    .field {
      width: calc(50% - 12px);
      display: flex;
      flex-direction: column;
      margin-bottom: 1.6rem;
      margin-right: 24px;
      font-size: 14px;
      position: relative;
      &:nth-child(even) {
        margin-right: 0;
      }
      label {
        color: #9397A1;
        margin-bottom: 4px;
      }
      input{
        height: 40px;
        border: 1px solid #D7DCE1;
        border-radius: $border-radius;
        padding: 0 10px;
        font-size: 14px;
      }
      .no-input{
        height: 40px;
        display: flex; align-items: center;
      }
    }
    .manage {
      cursor: pointer;
      position: relative;
      .manage-btn {
        display: flex;
        align-items: center;
      }
      span {
        margin-right: 4px;
      }
    }
    .dropdown-role.open {
      transform: scale(1);
      opacity: 1;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    }
    .dropdown-role {
      position: absolute;
      left: 0;
      opacity: 0;
      transform: scale(0.01);
      border-radius: $border-radius;
      transition: transform 0.3s ease, opacity 0.3s ease, box-shadow 0.3s ease 0.15s;
      transform-origin: left top;
      z-index: 2; 
      padding: 1.6rem;
      background: white;
      width: 100%;
      .role-input-wrap.active {
        background-color: #3AAFE3;
        color: white;
        border-radius: $border-radius;
        padding: 0 1.6rem;
        font-family: "Neutrif-bold";
      }
      .role-input-wrap {
        display: flex;
        align-items: center;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 32px;
        border: 1px solid #D7DCE1;
        background-color: #F5F6F8;
        border-radius: $border-radius;
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0;
        }
        input {
          height: auto;
        }
      }
    }
  }
  .settings-footer {
    padding: 3.0rem;
    display: flex;
    justify-content: flex-end;
    .loose-ids {
      input {
        width: 100%;
        height: 40px;
        border: 1px solid #D7DCE1;
        border-radius: $border-radius;
        padding: 0 10px;
        font-size: 14px;
      }
      p {
        font-size: 14px;
        margin-top: 1.6rem;
      }
    }
    .btn-wrap {
      display: flex;
      margin-top: 8px;
      .btn {
        margin-right: 8px;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.6rem;
      text-align: center;
      font-family: "Neutrif-bold";
      height: 4.0rem;
      text-decoration: none;
      background-color: #3AAFE3;
      color: white;
      font-weight: bold;
      border: none;
      z-index: 1;
      transition: all 0.3s ease;
      position: relative;
      outline: none;
      padding: 0 1.6rem;
      cursor: pointer;
      &:after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #fff;
        border-radius: 5px;
        transition: all 0.3s ease;
      }
    }
  }
}
</style>