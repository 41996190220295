<template>
  <div class="digits-container">
    <div class="digits"></div>
  </div>
</template>


<script>
export default {
  name: "AnimatedCounter",
  props: {
    number: {
      type: Number,
      required: true,
    },
  },
  watch: {
    number(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.animateNumber();
      }
    },
  },
  mounted() {
    this.animateNumber();
  },
  methods: {
    createDigitElement(value) {
      const digitElem = document.createElement("span");
      digitElem.classList.add("digit");
      digitElem.textContent = value;
      return digitElem;
    },

    animateDigitChange(digitElem, newDigit) {
      const currentDigit = parseInt(digitElem.textContent);
      let step = currentDigit < newDigit ? 1 : -1;

      // Check for the special case where the digit changes directly between 9 and 0.
      if ((currentDigit === 9 && newDigit === 0) || (currentDigit === 0 && newDigit === 9)) {
        step = newDigit === 0 ? -9 : 9; // Jump directly to the new digit.
      }

      const animationName = step === 1 || step === 9 ? 'moveDownAndUp' : 'moveUpAndDown';

      const stepAnimation = () => {
        let digitValue = parseInt(digitElem.textContent);
        if ((step > 0 && digitValue < newDigit) || (step < 0 && digitValue > newDigit)) {
          setTimeout(() => {
            digitValue += step;
            digitElem.textContent = digitValue % 10; // Cycle between 0-9
          }, step === 9 || step === -9 ? 100 : 200); // Faster for direct 9-0 or 0-9 transition.

          digitElem.style.animation = 'none';
          digitElem.offsetHeight; // Trigger reflow
          digitElem.style.animation = `${animationName} ${step === 9 || step === -9 ? 300 : 600}ms ease-out forwards`;

          if (!(step === 9 || step === -9)) {
            setTimeout(stepAnimation, 300);
          }
        }
      };

      stepAnimation();
    },



    animateNumber() {
      const newDigits = this.number.toString().split("");
      const digitsContainer = this.$el.querySelector('.digits');

      while (digitsContainer.children.length < newDigits.length) {
        const digitElem = this.createDigitElement(0);
        digitsContainer.appendChild(digitElem);
      }

      Array.from(digitsContainer.children).forEach((digitElem, index) => {
        const newDigit = index < newDigits.length ? parseInt(newDigits[index]) : 0;
        this.animateDigitChange(digitElem, newDigit);
      });
    },
  },
};
</script>


<style>
.digits-container {
  width: fit-content;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

}

.digits {
  display: flex;
}

@keyframes moveDownAndUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(50%);
    opacity: 0;
  }

  51% {
    transform: translateY(-50%);
    opacity: 0;

  }

  100% {
    transform: translateY(0);
    opacity: 1;

  }
}

@keyframes moveUpAndDown {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-50%);
    opacity: 0;
  }

  51% {
    transform: translateY(50%);
    opacity: 0;

  }

  100% {
    transform: translateY(0);
    opacity: 1;

  }
}
</style> 
