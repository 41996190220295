<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist-status__title picklist__title">Open Picklists Per Domain</h2>
  </div>
  <div class="picklist-domain__wrapper">
    <GlobeVisualization />
    <div class="picklist-domain__countries">
      <div v-for="(value, index) in sortedPicklistsPerShop" :key="index" class="picklist-domain__countries__item">
        <img :src="'/img/flags/' + value.flagUrl" alt="" class="picklist-domain__flag">
        <p>
          <span>{{ value.countryFullname }}</span>
          <span>: </span>
          <span>{{ value.val }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, onUnmounted, watch, ref, computed } from "vue";
import GlobeVisualization from './GlobeVisualization.vue';

export default {
  components: { GlobeVisualization },
  props: {
    data: Array,
  },
  setup(props) {

    const picklistsPerShopFill = ref([]);

    const getFlagEmoji = (countryCode) => {
      const flagMapping = {
        'CZ': 'Czech.png',
        'DK': 'Denmark.png',
        'FR': 'France.png',
        'EU': 'Europe.png',
        'COM': 'Other.png',
        'ES': 'Spain.png',
        'PL': 'Poland.png',
        'DE': 'Germany.png',
        'IT': 'Italy.png',
        'CO': 'UK.png',
        'NL': 'Netherlands.png',
        'BE': 'Belgium.png'
        // Add more country codes and flag emojis as needed
      };
      return flagMapping[countryCode.toUpperCase()] || '🏳️';
    }
    const countryCodeToName = {
      'CZ': 'Czechia',
      'DK': 'Denmark',
      'FR': 'France',
      'EU': 'Europe',
      'COM': 'Global',
      'ES': 'Spain',
      'PL': 'Poland',
      'DE': 'Germany',
      'IT': 'Italy',
      'CO': 'UK',
      'NL': 'Netherlands',
      'BE': 'Belgium'
    };
    const mergeObjectsByKey = (objects, keyToMerge) => {
      if (!Array.isArray(objects)) {
        console.error('mergeObjectsByKey: provided "objects" is not an array', objects);
        return [];
      }

      const resultMap = {};
      objects.forEach(obj => {
        const keyValue = obj[keyToMerge];
        if (keyValue !== undefined) {
          if (!resultMap[keyValue]) {
            resultMap[keyValue] = { ...obj };
          } else {
            resultMap[keyValue].val += obj.val;
          }
        }
      });
      return Object.values(resultMap);
    }


    const sortedPicklistsPerShop = computed(() => {
      return picklistsPerShopFill.value.slice().sort((a, b) => b.val - a.val);
    });

    onMounted(async () => {
      fixDomainObjecToArray(props.data);
    });

    const fixDomainObjecToArray = (newValue) => {
      // Convert Proxy object to an array format
      const dataArray = Object.entries(newValue).map(([key, value]) => {
        // Extract the country code and value
        let countryCode = key.split('.')[1] || key; // Adjust this logic as needed

        // If the country is Belgium, merge it into the Netherlands
        if (countryCode.toUpperCase() === 'BE') {
          countryCode = 'NL'; // Change Belgium to Netherlands
        }

        let countryFullname = countryCodeToName[countryCode.toUpperCase()] || countryCode; // Get full country name
        let flagUrl = getFlagEmoji(countryCode); // Get flag URL
        let val = value;

        return { countryCode, countryFullname, flagUrl, val };
      });

      // Now pass this array to mergeObjectsByKey
      picklistsPerShopFill.value = mergeObjectsByKey(dataArray, 'countryFullname');

    }

    const dataWatcher = computed(() => props.data);

    watch(dataWatcher, (newValue) => {
      if (newValue) {
        fixDomainObjecToArray(newValue);
      }
    }, { deep: true });



    return {
      sortedPicklistsPerShop,
      picklistsPerShopFill
    };
  },
};
</script>
<style lang="scss">
@keyframes splash {
  from {
    opacity: 1;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  to {
    transform: scale(1, 1);
  }
}


.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-domain {
        grid-area: 1 / 4 / 2 / 5;

        &__wrapper {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        &__globe {
          width: 100%;
          border-radius: 1rem;
          margin-top: 2.4rem;
          margin-bottom: 3.2rem;


          // width: 30rem;
          & canvas {
            width: 100%;
          }
        }

        &__countries {
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;

          &__item {
            display: flex;
            margin-top: auto;
            gap: 1rem;
            height: fit-content;
            align-items: center;
            flex-basis: 50%;

            & img {
              height: 100%;
              width: 11%;
            }

            & p {
              font-size: 2.4rem;
              color: var(--color-text-gray);

              & span:first-child {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-domain {
          grid-area: 2 / 3 / 3 / 5;

          &__wrapper {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: 100%;
          }

          &__countries {
            height: 100%;
            flex-grow: 0;

            &__item {
              gap: 0.2rem;

              & img {
                height: 100%;
                width: 11%;
              }

              & p {
                font-size: 1.4rem;



              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-domain {


          &__wrapper {

            flex-direction: column;

          }
        }
      }
    }
  }
}
</style>