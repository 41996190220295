<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist-status__title picklist__title">Open Picklists Status</h2>
  </div>
  <div class="picklist-status__wrapper">


    <div class="picklist-status__circle-progress" ref="SVGcontainer">
      <svg :width="svgSize" :height="svgSize">
        <circle class="bg" cx="50%" cy="50%" :r="adjustedRadius"></circle>
        <circle class="progress" cx="50%" cy="50%" :r="adjustedRadius" :stroke-dasharray="circumference"
          :stroke-dashoffset="dashOffset">
        </circle>
      </svg>
      <div class="picklist-status__circle-progress__title">
        <p>Total Completed</p>
        <h1>{{ animatedPercentage === 0 ? '00%' : animatedPercentage + '%' }}</h1>
      </div>
    </div>
    <div class="picklist-status__progress-wrapper">
      <div class="picklist-status__progress picklist__progress" v-for="row in packages" :key="index">
        <div class="picklist-status__progress--title">
          <h4>{{ row.Title }}</h4>
          <p>
            <b>{{ row.Closed }}</b>
            <span> of </span>
            <b>{{ (Number(row.Open) + Number(row.Closed)) }}</b>
          </p>
        </div>
        <div v-if="row.Percentage >= 15" class="picklist-status__progress__bar picklist__progress__bar">
          <div class="picklist-status__progress__bar--last picklist__progress__bar--last"
            :style="{ width: row.Percentage + '%' }">
            <p>{{ row.Percentage }}%</p>
          </div>
        </div>
        <div v-else class="picklist-status__progress__bar picklist__progress__bar">
          <div class="picklist-status__progress__bar--last picklist__progress__bar--last"
            :style="{ width: row.Percentage + '%' }">
          </div>
          <p :style="{ color: 'var(--color-orange)' }">{{ row.Percentage }}%</p>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import { onMounted, onUnmounted, watch, ref, computed, onBeforeUnmount } from "vue";
import { useProgressAnimation } from './useProgressAnimation';
export default {
  props: {
    data: Object,
    count: Number
  },
  setup(props) {

    const { count, data } = props;
    const status = ref({});
    const PicklistsleftToComplete = ref(0);
    const svgSize = ref(0); // Initial SVG size
    const strokeWidthPercent = ref(12); // Stroke width as a percentage of SVG size
    const SVGcontainer = ref(null);
    // const filledPercentage = ref(0);
    const fullAmount = ref(0)
    const filledPercentage = ref(0)

    const { animatedPercentage, animateProgress } = useProgressAnimation(); // Use the correct composable function
    const animationPlayed = ref(false); // Flag to track if animation has been played
    const updateSvgSize = () => {
      if (SVGcontainer.value) {

        svgSize.value = Math.min(SVGcontainer.value.clientWidth, SVGcontainer.value.clientHeight);
      }
    };

    const adjustedRadius = computed(() => {
      const strokeWidth = (strokeWidthPercent.value / 100) * svgSize.value;
      return (svgSize.value / 2) - (strokeWidth / 2);
    });

    const circumference = computed(() => {
      return 2 * Math.PI * adjustedRadius.value;
    });

    const dashOffset = computed(() => {
      return circumference.value - (circumference.value * (animatedPercentage.value / 100));
    });

    const packages = ref([{
      Title: 'Big Packages',
      Percentage: 0,
      Open: 0,
      Closed: 0,
      PKGAnimatedPercentage: ref(0)
    },
    {
      Title: 'Big Singles',
      Percentage: 0,
      Open: 0,
      Closed: 0,
      PKGAnimatedPercentage: ref(0)
    },
    {
      Title: 'Small Packages',
      Percentage: 0,
      Open: 0,
      Closed: 0,
      PKGAnimatedPercentage: ref(0)
    },
    {
      Title: 'Small Singles',
      Percentage: 0,
      Open: 0,
      Closed: 0,
      PKGAnimatedPercentage: ref(0)
    },
    ]);

    function calculatePercentage(open, closed) {
      const openNum = Number(open);
      const closedNum = Number(closed);
      if (isNaN(openNum) || isNaN(closedNum) || openNum + closedNum === 0) {
        return 0; // or some default value
      }
      return ((closedNum * 100) / (openNum + closedNum)).toFixed(0);
    }

    onMounted(async () => {
      window.addEventListener('resize', updateSvgSize);
      updateSvgSize();

      // Update PicklistsleftToComplete when data1 changes
      if (count) {
        PicklistsleftToComplete.value = count;
      }
      if (data) {
        status.value = data;
        fillStatusVariables(data);
        fullAmount.value = Number(status.value.AllClosed) + Number(PicklistsleftToComplete.value);
        filledPercentage.value = ((Number(status.value.AllClosed) * 100) / fullAmount.value).toFixed(0);
      }


      if (!animationPlayed.value) {
        setTimeout(() => {
          animateProgress(filledPercentage.value);
          animationPlayed.value = true; // Set the flag as true after playing the animation
        }, 2000);
      }

    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateSvgSize);
    });

    const fillStatusVariables = (newStatus) => {
      status.value = newStatus

      const packagesFill = packages.value;
      packagesFill[0].Open = newStatus.GrootMixedOpen;
      packagesFill[0].Closed = newStatus.GrootMixedClosed;
      packagesFill[0].Percentage = calculatePercentage(newStatus.GrootMixedOpen, newStatus.GrootMixedClosed);

      packagesFill[1].Open = newStatus.GrootSinglesOpen;
      packagesFill[1].Closed = newStatus.GrootSinglesClosed;
      packagesFill[1].Percentage = calculatePercentage(newStatus.GrootSinglesOpen, newStatus.GrootSinglesClosed);

      packagesFill[2].Open = newStatus.KleinMoreOpen;
      packagesFill[2].Closed = newStatus.KleinMoreClosed;
      packagesFill[2].Percentage = calculatePercentage(newStatus.KleinMoreOpen, newStatus.KleinMoreClosed);
      
      packagesFill[3].Open = newStatus.KleinSinglesOpen;
      packagesFill[3].Closed = newStatus.KleinSinglesClosed;
      packagesFill[3].Percentage = calculatePercentage(newStatus.KleinSinglesOpen, newStatus.KleinSinglesClosed);
      // Repeat for other package types...
      packages.value = packagesFill; // Update the reactive reference
    }

    const dataWatcher = computed(() => props.data);
    watch(dataWatcher, (newStatus) => {
      if (newStatus) {
        fillStatusVariables(newStatus);
        // Recalculate filledPercentage here after data update
        fullAmount.value = Number(status.value.AllClosed) + Number(PicklistsleftToComplete.value);
        filledPercentage.value = ((Number(status.value.AllClosed) * 100) / fullAmount.value).toFixed(0);
      }
      animatedPercentage.value = filledPercentage.value;
    }, { deep: true });

    const countWatcher = computed(() => props.count);
    watch(countWatcher, (newValue) => {
      PicklistsleftToComplete.value = newValue;
      // Recalculate filledPercentage here after count update
      // fullAmount.value = Number(status.value.AllClosed) + Number(PicklistsleftToComplete.value);
      // filledPercentage.value = ((Number(status.value.AllClosed) * 100) / fullAmount.value).toFixed(0);
    });


    return {
      // other properties and methods
      circumference,
      dashOffset,
      filledPercentage,
      adjustedRadius,
      SVGcontainer,
      svgSize,
      packages,
      PicklistsleftToComplete,
      data,
      count,
      status,
      animatedPercentage
    };

  },
};

</script>
<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-status {
        grid-area: 1 / 3 / 2 / 4;
        display: flex;
        align-items: center;

        &__wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &__title {
          align-self: flex-start;
        }

        &__circle-progress {
          width: 70%;
          margin: 0 0 3rem 0;
          aspect-ratio: 1 / 1;
          position: relative;

          &__title {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;

            & p {
              font-size: 2.3rem;
              margin-bottom: 0.4rem;
              color: var(--color-text-gray);
            }

            & h1 {
              font-size: 4rem;
            }
          }

          & svg {
            transform: rotate(-90deg);

            & .bg {
              fill: none;
              stroke-width: 12%;
              stroke: var(--color-gray);
              width: 100%;
              height: 100%;
            }

            & .progress {
              fill: none;
              stroke-width: 12%;
              stroke: var(--color-green);
              stroke-linecap: round;
              // transition: stroke-dashoffset 1s cubic-bezier(0.42, 0, 0.58, 1);

            }
          }

          // &__big {}
        }
      
        &__progress {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          
          &-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
          }
          
          &__bar {
            width: 100%;
            height: 2.4rem;
            border-radius: 1.2rem;
            position: relative;
            display: flex;
            align-items: center;
            background-color: var(--color-gray);
            
            & p {
              color: var(--color-white);
              font-size: 1.4rem;
              font-weight: 700;
              // line-height: 1.4rem;
              margin: 0 1.2rem 0 1.2rem;
            }
            
            &--last {
              background-color: var(--color-orange);
              display: flex;
              align-items: center;
              justify-content: end;
              height: 100%;
              border-radius: inherit;
            }
          }

          &--title {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1rem;

            & h4 {
              font-size: 2rem;
              font-weight: 400;
              margin-bottom: 0;
            }

            & p {
              font-size: 2rem;
              font-weight: 400;

              & span {
                color: var(--color-text-gray);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-status {
          grid-area: 1 / 3 / 2 / 5;

          &__circle-progress {
            width: 55%;
            margin: 0 2rem 0 0;

            &__title {


              & p {
                font-size: 1.2rem;

              }

              & h1 {
                font-size: 2.4rem;
              }
            }
          }

          &__wrapper {

            flex-direction: row;
            height: 100%;

          }


          &__progress {
            width: 100%;
            display: flex;
            align-items: center;

            &-wrapper {
              height: 100%;
            }

            &__bar {
              background-color: var(--color-gray);
              width: 100%;
              height: 1.2rem;
              border-radius: 1.2rem;

              &--last {
                height: 100%;
                border-radius: inherit;
              }

              & p {
                font-size: 1rem;
              }
            }

            &--title {
              & h4 {
                font-size: 1.4rem;
              }

              & p {
                font-size: 1.4rem;
              }
            }
          }





        }
      }
    }
  }
}

@media (max-width: 576px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-status {
          &__circle-progress {
            height: 60%;
            width: fit-content;
            margin: 0 0 1.6rem 0;
          }

          &__wrapper {
            flex-direction: column;
          }

          &__progress {
            &-wrapper {
              height: 100%;
            }

            &__bar {
              height: 1.6rem;
            }
          }
        }
      }
    }
  }
}
</style>