<template>
    <transition name="fade" mode="out-in">
      <div v-if="loading" class="card-wrapper-skeleton">
        <div class="card-title-skeleton"></div>
        <div class="card-data-skeleton">
          <div></div>
          <div></div>
        </div>
      </div>
   
      <div v-else style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
        <div class="card-title">Picklists left to complete</div>
        <div class="card-data">
          {{ picklistCount }}
          <i class='bx bx-grid'></i>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  
  import { useCookies } from "vue3-cookies";
  const { cookies } = useCookies();
  import { ref, onMounted } from "vue";
  
  export default {
    data(){
      return{
      }
    },
    setup()
    {
      const user = ref({});
      const picklistCount = ref(null);
      const loading = ref(true);
      const showError = ref(null);
      const errorMessage = ref(null);
  
      onMounted(async () => {
          user.value = cookies.get('token');
          loading.value = true
        try {
          const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/not/completed", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}});
          picklistCount.value = await res.json()
          picklistCount.value.update();
          loading = false
        } catch (error) {
          errorMessage.value = error
          showError.value = true
          loading.value = false
        }
      })
  
      return { user, picklistCount, loading, showError, errorMessage}
    }
  }
  </script> 
  
  <style lang="scss" scoped>
  .card-wrapper-skeleton{
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
  }
  .card-title-skeleton{
    width: 100%; 
    height: 24px; 
    animation: pulse-bg 1s infinite;
  }
  .card-data-skeleton{
    display: flex;
    justify-content: space-between;
    div:nth-child(1){
      height: 24px;
      width: 75%;
      animation: pulse-bg 1s infinite;
    }
    div:nth-child(2){
      height: 24px;
      width: 24px;
      border-radius: 100%;
      animation: pulse-bg 1s infinite;
    }
  }
  </style>