<template>
  <Page>
    <div class="parent">
      <router-view class="child"></router-view>
    </div>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import getUser from '../composeables/getUser'
import { watch } from 'vue'
import { useRouter } from 'vue-router'

export default {
  components: { Page },
  name: "Orders",
  setup() {
    const { user } = getUser()
    const router = useRouter()

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
  },
  data() {
    return{

    }
  },
  mounted() {
 
  }
}

</script>