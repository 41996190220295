<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="card-wrapper-skeleton">
      <div class="card-title-skeleton"></div>
      <div class="card-data-skeleton">
        <div></div>
        <div></div>
      </div>
    </div>

    <div v-else style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
      <div class="card-title">Total orders completed</div>
      <div class="card-data">
        {{ orderCountCompleted }}
        <i class='bx bx-grid'></i>
      </div>
    </div>
  </transition>
</template>

<script> 
import getUser from '../../composeables/getUser'

export default {
  data(){
    return{
      orderCountCompleted: 0,
      loading: false,
      user: {}
    }
  },
  async created(){
    this.loading = true
    try {
      const { user } = getUser();
      const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/picklists/completed", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}})
      this.orderCountCompleted = await res.json()
      this.loading = false
    } catch (error) {
      this.errorMessage = error
      this.showError = true
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card-wrapper-skeleton{
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.card-title-skeleton{
  width: 100%; 
  height: 24px; 
  animation: pulse-bg 1s infinite;
}
.card-data-skeleton{
  display: flex;
  justify-content: space-between;
  div:nth-child(1){
    height: 24px;
    width: 75%;
    animation: pulse-bg 1s infinite;
  }
  div:nth-child(2){
    height: 24px;
    width: 24px;
    border-radius: 100%;
    animation: pulse-bg 1s infinite;
  }
}
</style>