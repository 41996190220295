<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.93 22.26">
    <path
      d="M0 0h6.49v5.36c1.27-.49 2.52-.72 3.86-.72 2.63 0 4.76.83 6.38 2.47 1.69 1.62 2.47 3.72 2.52 6.38 0 2.03-.67 3.91-2.03 5.66-1.39 1.8-3.61 2.77-6.61 2.82H0V0zm6.49 16.32c.83.12 1.62.23 2.22.23 1.27 0 2.22-.3 2.94-.9.72-.53 1.09-1.32 1.09-2.33s-.37-1.8-1.02-2.4c-.72-.53-1.62-.83-2.82-.9-.79 0-1.57.12-2.47.42v5.89h.07zm13.59-2.89c0-2.29.79-4.32 2.33-6.08 1.57-1.8 4.09-2.7 7.51-2.7 3.56.07 6.12 1.02 7.63 2.82 1.5 1.73 2.29 3.72 2.22 5.96 0 2.33-.79 4.39-2.4 6.08-1.62 1.8-4.09 2.7-7.46 2.7-3.49-.07-6.08-1.02-7.63-2.89-1.48-1.76-2.26-3.74-2.2-5.89zm6.42 0c0 .97.3 1.73.97 2.33.6.6 1.43.9 2.47.9s1.87-.3 2.47-.9c.6-.6.97-1.39.97-2.33s-.3-1.8-.97-2.4c-.6-.6-1.43-.9-2.47-.9s-1.87.3-2.47.9-.97 1.36-.97 2.4zM40.65 0h6.49v21.89h-6.49V0zm11.49 14.33c1.09 0 1.99.42 2.7 1.13.72.72 1.09 1.69 1.09 2.82s-.37 2.03-1.09 2.77c-.72.79-1.62 1.13-2.7 1.2-1.09 0-1.99-.42-2.63-1.2-.79-.72-1.13-1.69-1.13-2.77s.37-2.1 1.13-2.82c.65-.79 1.55-1.13 2.63-1.13z"
      fill="#002aaf" />
  </svg>
</template>
<script>
export default {
  name: 'LogoBol',
}
</script>