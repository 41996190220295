import { ref } from "vue";

export function useProgressAnimation() {
  const animatedPercentage = ref(0);
  let interval;
  let timeout;

  const easeOutQuad = (t) => 1 - (1 - t) * (1 - t);

  const animateProgress = (targetValue, identifier) => {
    let startValue = animatedPercentage.value;
    let phase = 1;
    const durationPhase1 = 1000; // Duration to reach 100
    const durationPhase2 = 1500; // Duration to return to targetValue
    const phase2Interval = 600000; // Interval for phase 2 to reapply
    const stepTime = 10;
    let currentStep = 0;
    const totalStepsPhase1 = durationPhase1 / stepTime;
    const totalStepsPhase2 = durationPhase2 / stepTime;

    const performAnimation = () => {
      interval = setInterval(() => {
        currentStep++;

        // Update startValue dynamically at the beginning of each phase
        if (currentStep === 0) {
          startValue = animatedPercentage.value;
        }

        let normalizedTime;
        let easedStep;

        if (phase === 1) {
          normalizedTime = currentStep / totalStepsPhase1;
          easedStep = easeOutQuad(normalizedTime);
          animatedPercentage.value = Number(Math.min(startValue + easedStep * (100 - startValue), 100).toFixed(0));

          if (currentStep >= totalStepsPhase1) {
            phase = 2;
            startValue = 100;
            currentStep = 0;
          }
        } else if (phase === 2) {
          normalizedTime = currentStep / totalStepsPhase2;
          easedStep = easeOutQuad(normalizedTime);
          animatedPercentage.value = Number(Math.max(startValue - easedStep * (startValue - targetValue), targetValue).toFixed(0));
  
          if (currentStep >= totalStepsPhase2) {
            clearInterval(interval);
            startValue = animatedPercentage.value; // Ensure startValue is updated here as well
            currentStep = 0;
            phase = 1;
            timeout = setTimeout(performAnimation, phase2Interval);
          }
        }
      }, stepTime);
    };

    // Start the first animation
    performAnimation();
  };

  // Function to stop the ongoing animation loop
  const stopAnimation = () => {
    clearInterval(interval);
    clearTimeout(timeout);
  };

  return {
    animatedPercentage,
    animateProgress,
    stopAnimation, // Call this to clean up when the component is unmounted
  };
}
