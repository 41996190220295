<template>
  <transition name="fade" mode="out-in">
    <div v-if="loading" class="card-wrapper-skeleton">
      <div class="card-title-skeleton"></div>
      <div class="card-data-skeleton">
        <div></div>
        <div></div>
      </div>
    </div>

    <div v-else style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
      <div class="card-title">
        Highest sale 
        <v-select :key="order => order.country" :clearSearchOnSelect="true" :searchable="false" :clearSearchOnBlur="true" @option:selected="changeHighestOrder" :options="highestOrderValuesEuro" :reduce="order => order.country" label="country" dense />
      </div>
      <div>
        

      <div class="card-data"> 
        <div style="display: flex;">
          <div v-if="currentCountry.country !== ''" >
            <img v-bind:src="currentCountry.flagUrl" height="20" width="20" />
            {{ currentCountry.country }}&nbsp;{{ currentCountry.priceNew }}
          </div>
          <div v-else>
            <div v-if="highestOrder.country !== '' && highestOrderValuesEuro[highestOrder] !== undefined">
              <img v-bind:src="highestOrderValuesEuro[highestOrder].flagUrl" height="20" width="20" />
              {{ highestOrderValuesEuro[highestOrder].country }}&nbsp;{{ highestOrderValuesEuro[highestOrder].priceNew }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  </transition>
</template>

<script>

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { ref, onMounted } from "vue";
import moment from 'moment';
import {Cashify} from 'cashify';
import { findFlagUrlByIso2Code } from "country-flags-svg";


export default {
  data(){
    return{
    }
  },
  setup()
  {
    const user = ref({});
    const biggestOrder = ref(0);
    const biggestOrderYesterday = ref(0);
    const loading = ref(true);
    const showError = ref(null);
    const errorMessage = ref(null);
    const biggestOrderDifference = ref(0);
    const highestOrderValuesEuro = ref([]);
    const currentCountry = ref({"country": "", "priceOriginal": "", "flagUrl": "", "priceNew": "", "priceNewNonEuro": ""})
    const highestOrder = ref(0);
    const vSelect = ref(null);

    const formatter = new Intl.NumberFormat('nl-NL', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    });

    const changeHighestOrder = async (event) => {
      currentCountry.value = highestOrderValuesEuro.value.find(x => x.country === event.country); 
    }

    const getHighestTotalPrice = async () => {
      try {
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/totalPrice/highest", { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}});
        
        const result = await res.json();
        biggestOrder.value = result;
        biggestOrder.value.update();
        
        loading = false
      } catch (error) {
        errorMessage.value = error
        showError.value = true
        loading.value = false
      }
    }
    
    const getHighestTotalPriceYesterday = async () => {

      let date = moment(new Date()).subtract(1, 'days').startOf('day');
      let date2 = moment(new Date()).subtract(1, 'days');
      let startdate = date.format('YYYY-MM-DD HH:mm:ss').toString();
      let enddate = date2.format('YYYY-MM-DD HH:mm:ss').toString();

      try {
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/orders/totalPrice/highest?startdate=" + startdate + "&enddate=" + enddate, { headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}});
        
        const result = await res.json();
        biggestOrderYesterday.value = parseInt(result);
        biggestOrderYesterday.value.update();
    
        loading = false
      } catch (error) {
        errorMessage.value = error
        showError.value = true
        loading.value = false
      }
    }
    onMounted(async () => {
        user.value = cookies.get('token');
        loading.value = true;

        Promise.all([getHighestTotalPrice(), getHighestTotalPriceYesterday()]).then(async() => {

            let rates = [];

            try {
              const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/money/rates", { headers: {"Access-Control-Allow-Origin": "*","Authorization": user ? `Bearer ${user._value.token}` : ""}});
              
              rates = JSON.parse(JSON.stringify(await res.json()));

              const cashify = new Cashify({base: 'EUR', rates});
              const countryToCurrency = require( 'country-to-currency' );
              const highestOrderValues = JSON.parse(JSON.stringify(biggestOrder.value));

              highestOrderValues.forEach((element) => {
                  let convertedToEuro = 0;
                  let currency = countryToCurrency[element.country];

                  if(element.country == "SK")
                  {
                    convertedToEuro = element.value / 1 * 0.0331939;
                  }
                  else
                  {

                    convertedToEuro = cashify.convert(element.value, {from: currency, to: 'EUR'});
                  }

                  highestOrderValuesEuro.value.push({"country": element.country, "flagUrl":  findFlagUrlByIso2Code(element.country), "priceOriginal": element.value, "priceNew": convertedToEuro === 0 ? "N/A" : formatter.format(convertedToEuro), "priceNewNonEuro": convertedToEuro})
              });


              let highestOrderValuesNonEuro = highestOrderValuesEuro.value.map(x => x.priceNewNonEuro);
              const index = highestOrderValuesNonEuro.indexOf(Math.max.apply(Math, highestOrderValuesNonEuro));
              highestOrder.value = parseInt(index);
              let currentCountry = highestOrderValuesEuro.value[highestOrder.value]; 

              let updatedArray = highestOrderValuesEuro.value;
              updatedArray.push(currentCountry);
              highestOrderValuesEuro.value = updatedArray;

              currentCountry.value = currentCountry;

              loading.value = false;
            } catch (error) {
              errorMessage.value = error
              showError.value = true
            }


            //biggestOrderDifference.value = biggestOrder.value - biggestOrderYesterday.value;
        });
    })
       

    return { user, biggestOrder, highestOrder, currentCountry, changeHighestOrder, loading, showError, highestOrderValuesEuro, errorMessage, biggestOrderYesterday, biggestOrderDifference}
  }
}
</script> 

<style lang="scss">
.card-3{
  .v-select{
    width: 100%;
    position: absolute;
    .vs__dropdown-toggle {
      border: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.card-title{
  display: flex;
  justify-content: space-between;
  position: relative;
}    
img{
  border-radius: 20px;
  width: 20px;
  height: 20px;
  object-fit: cover;
}
.card-wrapper-skeleton{
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.card-title-skeleton{
  width: 100%; 
  height: 24px; 
  animation: pulse-bg 1s infinite;
}
.card-data-skeleton{
  display: flex;
  justify-content: space-between;
  div:nth-child(1){
    height: 24px;
    width: 75%;
    animation: pulse-bg 1s infinite;
  }
  div:nth-child(2){
    height: 24px;
    width: 24px;
    border-radius: 100%;
    animation: pulse-bg 1s infinite;
  }
}

/* Tommie styling */
.bg-color-green {
  background-color: green;  
}

.bg-color-gray {
  background-color: #d5d5d5;
}

.bg-color-red {
  background-color: red;
}
</style>