<template>
  
  <div class="settings">
    <div class="settings-header">
      <div class="title">Filter users</div>
      <div class="sub-title">Select all the worker id's that should <b>not</b> be included in the Employee Overview.</div>
    </div>
    <!-- <template v-if="devMode"> -->

      <div class="settings-body">
      <VueMultiselect
        v-model="pickersFilterOut"
        @select="saveEmployeeOverview"
        :options="pickers.sort((a, b) => a.firstName.localeCompare(b.firstName)).map(function(el) {

        var o = Object.assign({}, el);

        if(o.pickerId === currentUserId)
        {
          currentUserId = o.firstName + ' ' + o.lastName;
        }

        o.fullName = o.firstName + ' ' + o.lastName;
        return o;
        })" 
        :multiple="true"
        track-by="pickerId"
        :reduce="pickers => pickers.fullName"
        label="fullName"
        placeholder="Select users to filter out" dense>
      </VueMultiselect>
    </div>
    <div class="settings-footer">
      <div class="loose-ids">
        <input v-model="employeeIdFilterOutAdd" type="text" />
        <div class="btn-wrap">
          <button class="btn" @click="addEmployeeFilterOut(true)">Add</button>
          <button class="btn" @click="addEmployeeFilterOut(false)" style="width: 100px">Remove</button>
          <button class="btn" @click="clearIds" style="width: 100px">Clear id's</button>
        </div>
        <p>Select all the <b>loose</b> worker id's that should <b>not</b> be included in the Employee Overview. These id's are <b>not</b> synchronized to the user database, but can still come up in the results because of <b>duplicate</b> emails.</p>
        {{ employeeIdsToFilterout.length > 0 ? employeeIdsToFilterout : "" }}
      </div>
    </div>
    <!-- </template>
    <div v-else>
      You are in development modus, this functionality is only applicable to production.
    </div> -->
  </div>
</template>

<script>
import Page from '@/components/Page.vue'
import { ref, onMounted, reactive, watch} from "vue";
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import { notify } from "@kyvg/vue3-notification";
import VueMultiselect from 'vue-multiselect'
import { getFilteredOutEmployees, postFilteredOutEmployees, getFilteredOutEmployeesIds, postFilteredOutEmployeesIds } from '@/functions/Employee';

export default {
  name: "Settings Filter",
  components: { Page, VueMultiselect },
  data() {
    return {
      pickers: []
    }
  },
  setup() {
    const pickersFilterOut = ref([]);
    const employeeIdsToFilterout = ref([]);
    const employeeIdFilterOutAdd = ref(0);
    const devMode = ref(false);

    onMounted(async () => {
      pickersFilterOut.value = await getFilteredOutEmployees();
      employeeIdsToFilterout.value = await getFilteredOutEmployeesIds();
      // Inside your Vue component or any JavaScript file
      const isDevMode = process.env.NODE_ENV === 'development';
      devMode.value = isDevMode;
    })

    async function addEmployeeFilterOut (addOrRemove) {

      if(isNaN(employeeIdFilterOutAdd.value))
      {
        // Return alert if not a number
        notify({
          title: `${employeeIdFilterOutAdd.value} is not a number.`,
          duration: 6000,
          type: "warn"
        })
        return;
      }
      else
      {
          let arrayToReplace = [];
          console.log(employeeIdsToFilterout.value);
          if(employeeIdsToFilterout.value.length > 0) {
            arrayToReplace = JSON.parse(JSON.stringify(employeeIdsToFilterout.value)).map((each)=>{ return JSON.parse(each) });
          }
          else{
            arrayToReplace = employeeIdsToFilterout.value.map((each)=>{ return JSON.parse(each) });
          }
          
          if(addOrRemove) { // add
            if(arrayToReplace.filter(x => parseInt(x) == parseInt(employeeIdFilterOutAdd.value)).length > 0) {
              notify({
                title: `Id ${employeeIdFilterOutAdd.value} is already in the list.`,
                duration: 6000,
                type: "error"
              })
              return;
            }
            else
            {
              arrayToReplace.push(parseInt(employeeIdFilterOutAdd.value));
            }
          }
          else { // Delete
            if(arrayToReplace.filter(x => parseInt(x) == parseInt(employeeIdFilterOutAdd.value)).length < 1)
            {
              notify({
                title: `Id ${employeeIdFilterOutAdd.value} is not in the list.`,
                duration: 6000,
                type: "error"
              })
              return;
            }
            else
            {
              arrayToReplace = arrayToReplace.filter(x => parseInt(x) != parseInt(employeeIdFilterOutAdd.value));
            }
          }
          
          employeeIdsToFilterout.value = JSON.stringify([...arrayToReplace]);
          saveEmployeeOverview()
      }
    }  

    const saveEmployeeOverview = async () => {
      const usersInDb = JSON.stringify(pickersFilterOut.value); // Users that are in the database
      const LooseIds = JSON.stringify(employeeIdsToFilterout.value); // Loose id's (Not in the database, only in picker/packer data)

      await postFilteredOutEmployees(usersInDb);
      await postFilteredOutEmployeesIds(LooseIds);
    }

    const clearIds = () => {
      employeeIdsToFilterout.value = JSON.stringify([]);
      saveEmployeeOverview();
    }

    return { pickersFilterOut, addEmployeeFilterOut, employeeIdFilterOutAdd, employeeIdsToFilterout, 
      saveEmployeeOverview, clearIds}
  },
  methods: {
    async users(){
       this.loading = true
      try {
        const user = cookies.get('token');
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user.token}` : ""}})
         
        this.pickers = await res.json()
        this.loading = false
      } catch (error) {
        this.errorMessage = error
        this.showError = true
        this.loading = false
      }
    }
  }, 
  async created() {
    this.currentUserId = cookies.get("currentUserId") ?? String.empty;
    this.users()
  }
}
</script>