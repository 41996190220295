<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist-goal__title picklist__title">
      Today’s Incoming Picklists
    </h2>
  </div>
  <div class="picklist__main">
    <div class="picklist__counter picklist-goal__counter">
      <!-- <animated-counter :number="state.todayOrder"></animated-counter> -->
      <!-- <AnimatedCounter :value="state.todayOrder" /> -->
      <OdometerComponent :initialValue="state.todayOrder" />
      <!-- <vue-odometer :value="state.todayOrder"></vue-odometer> -->

    </div>
    <div class="picklist-goal__sub">
      <p class="picklist__sub">
        vs same day last <br>week:
      </p>
      <div class="picklist-goal__status" :class="DynamicIcon">
        <IconStatus />
        <p class="picklist-goal__percentage">{{ state.comparePercentage < 0 ?
          state.comparePercentage.toString().split('-')[1] : state.comparePercentage }}%</p>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, onMounted, reactive, computed } from "vue";
import IconStatus from '@/components/svg/IconStatus.vue';
import AnimatedCounter from './AnimatedCounter.vue';
import OdometerComponent from './OdometerComponent.vue';

export default {
  components: { IconStatus, AnimatedCounter, OdometerComponent },

  props: {
    data: Object
  },

  setup(props) {
    const state = reactive({
      todaySameDay: {},
      todayOrder: 0,
      comparePercentage: 0,
    });

    const DynamicIcon = computed(() => {
      return state.comparePercentage < 0 ? "down" : "up";
    });

    onMounted(() => {
      if (props.data) {
        state.todaySameDay = props.data;

        updateValues(state.todaySameDay);
      }
    });

    watch(() => props.data, (newValue) => {
      if (newValue) {
        state.todaySameDay = newValue;
        updateValues(state.todaySameDay);
      }
    });

    function updateValues(data) {
      if (data.totalOrdersToday && data.totalOrdersLastWeekSameDay) {
        state.todayOrder = data.totalOrdersToday;
        state.comparePercentage = -1 * (100 - ((data.totalOrdersToday * 100) / data.totalOrdersLastWeekSameDay).toFixed(0));
      }
    }
    return { state, DynamicIcon };
  },
};
</script>


<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &-goal {
        &__sub {
          position: relative;
          margin-bottom: 1.2rem;

          & svg {
            display: block;
            width: 2.4rem;

            & path,
            circle {
              stroke: #fff;

            }
          }
        }

        &__status {
          position: absolute;
          bottom: 0;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
          gap: 1.2rem;
          height: fit-content;

          &.down {
            & svg {
              transform: rotate(180deg);
            }
          }
        }

        &__percentage {
          font-size: 2rem;
          line-height: 2.5rem;
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-goal {
          &__sub {
            margin-bottom: 0.7rem;

            & p {
              font-size: 1.4rem;
              line-height: 1.8rem;
              margin-right: 0rem;
            }

            & svg {
              width: 1.6rem;
            }
          }

          &__status {
            gap: 0.6rem;

            &.down {
              & svg {
                transform: rotate(180deg);
              }
            }
          }

          &__percentage {
            font-size: 1.4rem;
            line-height: 2rem;
          }

        }
      }
    }
  }
}

@media (max-width: 576px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {}
    }
  }
}
</style>