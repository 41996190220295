<template>
  <Page>
  <Loading v-model:active="loading"
                :can-cancel="false"/>
    <div class="page-header">
      <div class="title-wrapper">
        <div class="title">Active {{ isPacker ? 'packers' : isPacker === false ? 'pickers' : 'workers' }}</div>
        <div class="subtitle">All information about the {{ isPacker ? 'packers' : isPacker === false ? 'pickers' : 'workers' }} of Dartshopper</div>
      </div>
      
    </div>

    <!-- Date picker and search -->
    <div class="employee-date-picker" style="height: 100%; justify-content: space-between; display: flex; flex-direction: column;">
        <div class="cards second">
          <div class="card card-1">
            <div class="datepicker">
              <div class="datepicker2-presets">
                <a class="date-buttons" :class="{ 'active': isPacker}" @click="switchTabs(true)">Packers</a>
                <a class="date-buttons" :class="{ 'active': isPacker === false}" @click="switchTabs(false)">Pickers</a>
                <a class="date-buttons" :class="{ 'active': isPacker === null}" @click="switchTabs(null)">Total</a>
              </div>
            </div>
            <input class="menu-search" v-model="searchWorkerEntry" :placeholder="isPacker ? 'Search for packers' : isPacker === false ? 'Search for pickers' : 'Search for workers'" type="text"/>
          </div>
          <div class="card card-2">
            <div class="datepicker">
              <!-- <div id="button" @click="datepickerDropdown" class="button exclude_dashboard_datepicker_btn">
                <p>Pick your date</p>
                <i class="bx bxs-chevron-down arrow"></i>
              </div> -->
              <!-- <div v-click-outside="{ exclude: ['exclude_dashboard_datepicker_btn'], handler: hideDatepickerDropdown }" class="dropdown">  -->
                <!-- <div class="datepicker-card">
                  <div class="title">
                    Pick your date
                  </div>
                  <div class="subtitle">
                    
                  </div>
                </div> -->
                <div class="datepicker-presets">
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Today'}" @click="changeDatePreset">Today</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === 'Yesterday'}" @click="changeDatePreset">Yesterday</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '1 month'}" @click="changeDatePreset">1 month</a>
                  <a class="date-buttons" :class="{ 'active': datePreset !== '' && datePreset === '3 months'}" @click="changeDatePreset">3 months</a>
                </div>
                <div :class="{ 'datepicker-customSelector': true, 'active': datePreset === ''}">
                  <Datepicker 
                    v-model="date" 
                    @update:modelValue="handleDate"
                    range 
                    weekNumbers 
                    :enableTimePicker="false" 
                    locale="nl" 
                    placeholder="Custom..." 
                    textInput 
                    cancelText="cancel" 
                    selectText="apply" 
                    :maxDate="new Date()"
                    :minDate="new Date(2022, 5, 13)"
                    position="right"
                    autoPosition
                  />
                </div>
              </div>
            <!-- </div> -->
          </div>
        </div> 
    </div>
    <div class="export">
      <div v-if="!loading">
        <button @click="exportData">Export to excel</button>
      </div>
    </div>
        <div v-if="!loading && isPacker" class="table-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="order-nr">Name</th>
                <th class="worker">Grote kar gemixed</th>
                <th class="status">Grote kar singles</th>
                <th class="tags">Kleine kar gemixed</th>
                <th class="date">Kleine kar singles</th>
                <th class="more-info">Details</th>
              </tr>
            </thead>
            <template v-if="!loading">
              <tbody v-for="(worker, i) in filteredLiveWorkers" :key="i"> 
                <tr>
                  <td class="worker">{{ worker.fullName ?? worker.closed_by_iduser }}</td>
                  <td>{{ worker.GrootMixedClosed }}</td>
                  <td>{{ worker.KleinMoreClosed }}</td>
                  <td>{{ worker.GrootSinglesClosed }}</td>
                  <td>{{ worker.KleinSinglesClosed }}</td>
                  <td>
                    <template v-if="worker.closed_by_iduser !== ''">
                      <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">Show info
                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5.26301C6 5.45165 5.92797 5.64027 5.78422 5.7841L1.25832 10.31C0.970413 10.5979 0.503627 10.5979 0.21584 10.31C-0.0719468 10.0222 -0.0719468 9.55547 0.21584 9.26754L4.22061 5.26301L0.21598 1.25845C-0.0718073 0.970549 -0.0718073 0.503902 0.21598 0.216139C0.503767 -0.0719042 0.970553 -0.0719042 1.25846 0.216139L5.78436 4.74192C5.92814 4.88582 6 5.07444 6 5.26301Z" fill="#3AAFE3"/>
                        </svg>
                      </router-link>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
        <div v-if="!loading && isPacker === false" class="table-container">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="order-nr">Name</th>
                <th class="seconds">Seconds</th>
                <th class="more-info">Details</th>
              </tr>
            </thead>
            <template v-if="!loading">
              <tbody v-for="(worker, i) in filteredLiveWorkers" :key="i"> 
                <tr>
                  <td class="worker">{{ worker.fullName ?? worker.closed_by_iduser }}</td>
                  <td>{{ worker.TimePickerShouldHaveSpentOnAllPicklists }}</td>
                  <td>
                    <template v-if="worker.closed_by_iduser !== ''">
                      <router-link :to="'/packers?currentPackerId=' + worker.closed_by_iduser">Show info
                        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 5.26301C6 5.45165 5.92797 5.64027 5.78422 5.7841L1.25832 10.31C0.970413 10.5979 0.503627 10.5979 0.21584 10.31C-0.0719468 10.0222 -0.0719468 9.55547 0.21584 9.26754L4.22061 5.26301L0.21598 1.25845C-0.0718073 0.970549 -0.0718073 0.503902 0.21598 0.216139C0.503767 -0.0719042 0.970553 -0.0719042 1.25846 0.216139L5.78436 4.74192C5.92814 4.88582 6 5.07444 6 5.26301Z" fill="#3AAFE3"/>
                        </svg>
                      </router-link>
                    </template>
                  </td>
                </tr>
              </tbody>
            </template>
          </table>
        </div>
  </Page>
</template>

<script>
import _ from "lodash"
import { ref, onMounted, computed, watchEffect, onUnmounted } from "vue";
import Datepicker from '@vuepic/vue-datepicker';

import Page from '@/components/Page.vue'
import TabsWrapperWorking from '@/components/TabsWrapperWorking.vue' 
import Tab from '@/components/Tab.vue'
import { watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import '@vuepic/vue-datepicker/dist/main.css';

import { LineChart, useLineChart } from 'vue-chart-3';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart, registerables } from 'chart.js';

import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();

import moment from 'moment';

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import * as XLSX from 'xlsx'

export default {
  components: { Page, Datepicker, TabsWrapperWorking, Tab, LineChart, Datepicker, Loading},
  name: "workersOverview",
  data(){
    return{
      gradient: "",
    }
  },
  setup() {
    const user = ref({});
    const datePreset = ref(null);
    const liveWorkers = ref([]);
    const totalOpen = ref({});
    const users = ref([]);
    const workers = ref([]);
    const router = useRouter()
    const route = useRoute();
    const searchWorkerEntry = ref("");

    watch(user, () => {
      if(!user.value) {
        router.push({ name:'Login' })
      }
    })
    
    Chart.register(...registerables);
    Chart.register(annotationPlugin);
    
    const date = ref([]);
    const loading = ref(true);
    const error = ref(null);
    const isPacker = ref(true);

    const setDateInCookie = (date) => {
      cookies.set("date", JSON.stringify(date));
    }

    // For demo purposes assign range from the current date
    onMounted( async () => {
      user.value = cookies.get('token');
      let startDate = moment().startOf('day');
      let endDate = moment().endOf('day');
      let date = [startDate, endDate];
      
      if("startDate" in route.query)
      {
        startDate = route.query.startDate;
      }

      if("endDate" in route.query)
      {
        endDate = route.query.endDate;
      }

      if("datePreset" in route.query)
      {
        date = setDatePreset(route.query.datePreset);
      }

      if(cookies.get("datePreset") === null || cookies.get("datePreset") === undefined)
      {
        cookies.set("datePreset", "Today");
      }

      date.value = date;
      datePreset.value = cookies.get("datePreset");
      setDateInCookie(date.value);
      await getUsers();

      const query = {
        datePreset: cookies.get("datePreset"),
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }
      await updateQueryParameters(query);
    })

    const filteredLiveWorkers = computed(() => {
        return liveWorkers.value.filter(workerEntry => {
          // Remove entry with open and 0 closed (Everything that's open for the day)
          if(workerEntry => workerEntry !== totalOpen.value)
          {
            if(searchWorkerEntry.value !== "" && workerEntry?.fullName !== undefined)
            { 
              return workerEntry?.fullName.toLowerCase().includes(searchWorkerEntry.value.toLowerCase())
            }

            return workerEntry;
          }
        })
    })

    // Assign a global variable so content can be dynamically changed
    const switchTabs = (switchValue) => {
      isPacker.value = switchValue;
    }

    const exportData = () => {
        const data = XLSX.utils.json_to_sheet(liveWorkers.value)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, data, 'data')
        let packerPickerString = isPacker.value ? "packers" : "pickers";
        let startdate = moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString();
        let enddate = moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString();

        XLSX.writeFile(wb, `${packerPickerString}_${startdate}_${enddate}.xlsx`)
    }

    const getUsers = async () => {
      try {
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();
        users.value = result;
      } catch (error) {

        return error;
      } 
    }

    const setDatePreset = (text) => {
      let startDate = new Date();
      let endDate = new Date(new Date().setDate(startDate.getDate() + 0));

      if(text === "Today")
      {
        endDate = moment(startDate).endOf('day');
      }
      if(text === "Yesterday")
      {
        startDate = new Date(new Date().setDate(startDate.getDate() - 1));
        startDate = moment(startDate).startOf('day');
        endDate = moment(startDate).endOf('day');
      }
      else if(text === "1 month")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 28))).startOf('day');
      }
      else if(text === "3 months")
      {
        startDate = moment(new Date(new Date().setDate(startDate.getDate() - 90))).startOf('day');
      }
      
      cookies.set("datePreset", text)
      datePreset.value = cookies.get("datePreset");

      return [startDate, endDate];
    }

    const changeDatePreset = async () => {
      let date = setDatePreset(event.target.innerText);
      setDateInCookie(date);

      const query = {
        startDate: moment(date[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }

      await updateQueryParameters(query);

      hideDatepickerDropdown();
    }

    // Updates query parameters, make sure this happens after all cookies have been altered.
    const updateQueryParameters = async (query) => {
      if (!_.isEqual(route.query, query)) {
        await router.replace({ path: route.path, query: query }).catch(() => {});
      }

      loading.value = true;
      
     liveWorkers.value = isPacker.value ? await getActivePackers() : await getActivePickers();

     liveWorkers.value.map((x) => {
        let value = isPacker.value ? parseInt(x.closed_by_iduser) : parseInt(x.assigned_to_iduser);
        let user = users.value.find(y => y.pickerId == value);
        totalOpen.value = x;

        if(user !== undefined)
        {
          x.fullName = user.firstName + " " + user.lastName;
        }
        else
        {
          x.fullName = value + "Geen gebr. gevndn/toegwzn."
        }
      })

      loading.value = false;
    }

    const hideDatepickerDropdown = (event) => {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }

    }

    const handleDate = (modelData) => {
      date.value = modelData;
     
      const query = {
        startDate: moment(date.value[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
        endDate: moment(date.value[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(),
      }
      updateQueryParameters(query);
      
      hideDatepickerDropdown();
    }

    const getActivePackers = async () => {
      try {
        const array = JSON.parse(cookies.get("date"));
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getActiveWorkers?startDate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&endDate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();

        return result;
      } catch (error) {

        return error;
      } 
    }

    const getActivePickers = async () => {
      try {
        const array = JSON.parse(cookies.get("date"));
        
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/mysql/getActivePickers?startDate=" + moment(array[0]).startOf('day').format('YYYY-MM-DD HH:mm:ss').toString() + "&endDate=" + moment(array[1]).endOf('day').format('YYYY-MM-DD HH:mm:ss').toString(), { 
        // const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/working/active?pickerId=" + user.value.pickerId, { 
          method: 'GET', 
          headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}, 
        });
        
        const result = await res.json();

        return result;
      } catch (error) {

        return error;
      } 
    }

    return { liveWorkers, datePreset, loading, error, date, changeDatePreset, handleDate, hideDatepickerDropdown, workers, getActivePackers, getActivePickers, getUsers, users, exportData, totalOpen,
      filteredLiveWorkers, searchWorkerEntry, switchTabs, isPacker};
  },
  data() {
    return{
      currentPackerId: "",
      pickers: [],
      errorMessage: "",
      showError: false
    }
  },
  methods: {
    datepickerDropdown () {
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
    hideDatepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.remove("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.remove("open");
      }
      if (button.length > 0) {
        button[0].classList.remove("active");
      }
    },
    datepickerDropdown(){
      var arrow = document.querySelectorAll(".datepicker .arrow");
      var dropdown = document.querySelectorAll(".datepicker .dropdown");
      var button = document.querySelectorAll(".datepicker .button");

      if (arrow.length > 0) {
        arrow[0].classList.toggle("open");
      }
      if (dropdown.length > 0) {
        dropdown[0].classList.toggle("open");
      }
      if (button.length > 0) {
        button[0].classList.toggle("active");
      }
    },
  },
  async created() {
  }
}
</script>
<style lang="scss" scoped>

.datepicker2-presets {
  display: flex;
  justify-content: between;
  .date-buttons {
    margin-right: 0.8rem;
    margin-bottom: 1.6rem;
    border: 1px solid #D7DCE1;
    background-color: #FFF;
    border-radius: 6px;
    font-size: 18px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
    &:nth-child(4){
      margin-right: 0.0rem;
    }
  }
  .date-buttons:hover {
    background-color: rgba(245, 246, 248, 0.55)
  }
  .date-buttons.active {
    background-color: #F5F6F8;
  }
}

.employee-date-picker {
  .cards .card-2 {
    padding: 1.6rem;
    width: calc(50% - 1.6rem);
    .datepicker-customSelector {
      width: 100%;
      margin: 0 auto;
      
    }
    .datepicker-customSelector.active {
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.15);
    }
    .datepicker-presets {
      display: flex;
      justify-content: between;
      .date-buttons {
        margin-right: 0.8rem;
        margin-bottom: 1.6rem;
        border: 1px solid #D7DCE1;
        background-color: #FFF;
        border-radius: 6px;
        font-size: 18px;
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        cursor: pointer;
        &:nth-child(4){
          margin-right: 0.0rem;
        }
      }
      .date-buttons:hover {
        background-color: rgba(245, 246, 248, 0.55)
      }
      .date-buttons.active {
        background-color: #F5F6F8;
      }
    }
  }
}

.cards.first{
  .card{
    width: 100%;
    height: auto;
    margin-right: 0;
  }
}

.cards.second{
  .card-1 {
    justify-content: space-around;
    width: 100%;
    .menu-search {
        height: 40px;
        border: 1px solid #D7DCE1;
        border-radius: $border-radius;
        padding: 8px;
        padding-left: 18px;
        padding-right: 18px;
        width: 100%;
        &:focus-visible {
          outline: none !important;
          border-color: #3AAFE3;
          box-shadow: 0 0 4px #3aafe3;
        }
        &::placeholder { 
          color: gray;
          opacity: 1; 
        }
      }
  }
  .card{
    width: calc(50% - 1.6rem);
    margin-right: 3.2rem;
    &:last-child{
      margin-right: 0;
    }
  }
}

.cards.third{
  .card-1 {
    border-radius: 0px;
  }
  .card-2 {
    border-radius: 0px;
  }
  .first-card {
    border-radius: 0px 0px 0px 6px;
  }
  .last-card {
    border-radius: 0px 0px 6px 0px;
  }
  .card{

    margin-bottom: 0;
    margin-right: 0.0rem;
    width: calc(25%);
    height: 13.7rem;
    &:nth-child(1){
      border-right: 0px;
    }
    &:nth-child(2){
      border-right: 0px;
    }
    &:nth-child(3){
      border-right: 0px;
    }
    &:nth-child(4){

    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.cards.fourth{
  .card{
    border-radius: 6px 6px 0px 0px;
    border-bottom: 0px;
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 15px;
    .per-hour-graph-wrapper {
      position: relative;
      margin: auto;
      height: auto;
      width: 100%;
      .card-title{
        margin-top: 0px;
        margin-bottom: 5px;
      }
      margin-bottom: 20px;
    }
  }
}

.table-container{
  overflow-y: hidden;
  background-color: white;
  width: 100%;
  border-radius: $border-radius;
  border: 1px solid #D7DCE1;
  .table{
    width: 100%;
    border-spacing: 0;
  }
  .table-striped{
    thead{
      width: 100%;
      tr{
        width: 100%;
        th{
          border-bottom: 1px solid #D7DCE1;
          width: 50%;
          text-align: left;
          padding: 2.4rem 3.0rem;
          color: #9397A1;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
    tbody{
      width: 100%;
      tr{
        width: 100%;
        td, th{
          border-bottom: 1px solid #D7DCE1;
          width: 50%;
          text-align: left;
          padding: 2.4rem 3.0rem;
          font-size: 14px;
          font-weight: 700;
          .td-wrapper{
            display: flex;
            align-items: center;
            i {
              cursor: pointer;
              font-size: 20px;
              transition: all 0.2s;
            }
            .active {
              transform: rotate(-90deg);
              transition: all 0.2s;
            }
            .table-row-splitted{
              display: flex;
              flex-direction: row;
              margin-left: 20px;
              transition: all 0.2s;
              min-width: 300px;
              .table-row-split{
                border: 1px solid #D7DCE1;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 10px;
                border-radius: 5px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
                img{
                  height: 14px;
                  margin-right: 8px;
                }
              }
            }
          }
          a{
            border: 2px solid #3AAFE3;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #3AAFE3;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                transition: all 0.3s ease;
              }
            }
            &:hover{
              background-color: #3AAFE3;
              color: white;
              svg path{
                fill: white;
              }
            }
          }
          a.greyed-out {
            border: 2px solid #9397A1;
            padding: 6px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: 0.6rem;
            text-align: center;
            font-family: "Neutrif-bold";
            height: 4.0rem;
            text-decoration: none;
            color: #9397A1;
            font-weight: bold;
            z-index: 1;
            transition: all 0.3s ease;
            position: relative;
            outline: none;
            cursor: pointer;
            white-space: nowrap;
            pointer-events: none;
            svg{
              margin-left: 8px;
              transition: all 0.3s ease;
              path {
                fill: #9397A1;
                transition: all 0.3s ease;
              }
            }
          }
        }
        &:last-child td, &:last-child th {
          border-bottom: none;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) { }

@media only screen and (min-width: 601px) and (max-width: 767px) { }

@media only screen and (max-width: 600px) {

  .cards.second{
    .card{
      width: 100%;
      margin-right: 0.0rem;
    }
  }

  .employee-date-picker {
    .cards .card-2 {
      width: 100%;
      .datepicker-presets {
        .date-buttons { 
          font-size: 14px;
          padding-left: 9px;
          padding-right: 9px;
        }
      }
      .datepicker-customSelector {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .cards.third{
    .card-1 {
      border-radius: 0px 0px 0px 6px;
    }
    .card-2 {
      border-radius: 0px 0px 0px 0px;
    }
    .first-card {
      border-radius: 0px 0px 0px 0px;
    }
    .last-card {
      border-radius: 0px 0px 6px 0px;
    }
    .card{
      &:nth-child(1){
        border-bottom: 0px;
      }
      &:nth-child(2){
        border-right: 1px solid #D7DCE1;
        border-bottom: 0px;
      }
      margin-bottom: 0;
      margin-right: 0.0rem;
      width: calc(50%);
      height: 13.7rem;
    }
  }

  .cards.fourth{
    .card{
    .per-hour-graph-wrapper {
      .card-title{
          margin-top: 15px;
        }
      }
    }
  }
  .page-header{
    flex-direction: column;
    margin-bottom: 1.6rem;
    .title-wrapper{
      margin-bottom: 1.6rem;
      .title{
        font-size: 2.4rem;
      }
    }
  }
  .cards{
    .card{
      padding: 2.4rem;
    }
  }

  .datepicker{
    .dropdown{
      transform-origin: left top;
      left: 0;
    }
  }
}

.export {
  display: flex;
  justify-content: space-between;
}
</style>
