<template>
  <div class="picklist__title-wrapper">
    <h2 class="picklist__title picklist-compare__title">Incoming Picklists vs Closed Picklists</h2>
  </div>
  <div class="picklist__canvas" style="max-width: 100%; position: relative; height: 100%;">
    <LineChart class="line-chart" :chartData="compareData" :options="options" ref="lineCompareRef"
      @chart:render="compareHandleChartRender" />
  </div>
  <div class="picklist-compare__sub">
    <p class="picklist__lable picklist__lable--orange">Incoming Picklists</p>
    <p class="picklist__lable picklist__lable--dashed-incoming">Incoming Picklists (Yesterday)</p>
    <p class="picklist__lable picklist__lable--green">Closed Picklists</p>
    <p class="picklist__lable picklist__lable--dashed-closed">Closed Picklists (Yesterday)</p>
  </div>
</template> 
<script>
import { onMounted, nextTick, watch, ref, computed, reactive } from "vue";
import { LineChart, useLineChart } from "vue-chart-3";
import { Chart } from "chart.js";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { remToPx } from './utils';

export default {
  components: { LineChart },
  props: {
    data: Array,
  },
  setup(props) {
    const state = reactive({
      tickFontSize: 0,
      labelFontSize: 0,
      labelBorderRadius: 0,
      borderWidth: 0,
      pointRadius: 0,
      pointBorderWidth: 0,
    })

    const data = ref([...props.data]);
    Chart.register(ChartDataLabels);

    const incomingOrders = ref([]);
    const lineCompareRef = ref(null);
    const incomingPicklists = ref([]);
    const incomingOrdersYesterday = ref([]);
    const closedPicklistsYesterday = ref([]);
    const hours = ["", "", "", "", "", "", "06:00", "", "08:00", "", "10:00", "", "12:00", "", "14:00", "", "16:00", "", "18:00", "", "20:00", "", "", ""];

    const compareHandleChartRender = (chart) => {
      chart.canvas.parentNode.style.height = "100%";
      lineCompareRef.value.update();
    }

    const getResponsiveValues = () => {
      if (window.innerWidth <= 1023) {
        state.tickFontSize = remToPx(1.1);
        state.labelFontSize = remToPx(1.2);
        state.labelBorderRadius = remToPx(0.6);
        state.borderWidth = remToPx(0.1);
        state.pointRadius = remToPx(0.5);
        state.pointBorderWidth = remToPx(0.1);

      } else {
        state.tickFontSize = remToPx(1.6);
        state.labelFontSize = remToPx(1.6);
        state.labelBorderRadius = remToPx(0.9);
        state.borderWidth = remToPx(0.2);
        state.pointRadius = remToPx(0.7);
        state.pointBorderWidth = remToPx(0.2);
      }
    };
    // ##################################
    // #########    Plugins    ##########
    // ##################################
    const verticalLinePlugin = {
      id: 'verticalLinePlugin',
      afterDatasetDraw: function (chart) {
        if (chart.options.verticalLine) {
          const ctx = chart.ctx;
          const datasets = chart.data.datasets;
          let highestValue = Number.NEGATIVE_INFINITY;
          let highestX = 0;
          let highestY = 0;
          datasets.forEach((dataset, datasetIndex) => {
            dataset.data.forEach((value, index) => {
              if (value !== undefined && !isNaN(value) && value > highestValue) {
                highestValue = value;
                const meta = chart.getDatasetMeta(datasetIndex);
                if (meta && meta.data && meta.data[index]) {
                  highestX = meta.data[index].x;
                  highestY = meta.data[index].y;
                }
              }
            });
          });

          if (highestValue !== Number.NEGATIVE_INFINITY) {
            const bottomY = chart.scales.y.getPixelForValue(0);

            // Create gradient
            const gradient = ctx.createLinearGradient(highestX, highestY, highestX, bottomY);
            gradient.addColorStop(0, 'rgba(264, 264, 264, 0.4)'); // Top color with 100% opacity
            gradient.addColorStop(1, 'rgba(264, 264, 264, 0)'); // Bottom color with 0% opacity

            // Draw line with gradient
            ctx.save();
            ctx.beginPath();
            ctx.moveTo(highestX, highestY);
            ctx.lineTo(highestX, bottomY);
            ctx.lineWidth = remToPx(0.4);
            ctx.strokeStyle = gradient;
            ctx.stroke();
            ctx.restore();
          }
        }
      }
    };
    Chart.register(verticalLinePlugin);
    // ##################################

    const trianglePlugin = {
      id: 'trianglePlugin',
      afterDatasetsDraw: function (chart) {
        const ctx = chart.ctx;

        chart.data.datasets.forEach((dataset, datasetIndex) => {
          // Check if this is the targeted dataset
          if (dataset.trianglePointer === true) { // Replace with the label of your target dataset
            const meta = chart.getDatasetMeta(datasetIndex);
            const datalabelConfig = dataset.datalabels || {};

            meta.data.forEach((element, index) => {
              const dataValue = dataset.data[index];
              if (dataValue > 0) {
                const triangleSize = remToPx(1.4); // Size of the triangle
                const trianglePadding = remToPx(2.7); // Space between the triangle and the data point

                // Determine the position for the triangle
                let triangleX = element.x;
                let triangleY = element.y;

                // Adjust position based on alignment
                const align = datalabelConfig.align || 'right'; // Default alignment
                if (align === 'left') {
                  triangleX -= (triangleSize + trianglePadding);
                } else if (align === 'right') {
                  triangleX += (triangleSize + trianglePadding);
                }

                // Get background color from datalabels configuration
                const backgroundColorFunction = datalabelConfig.backgroundColor;
                let color = '#000'; // Default color
                if (typeof backgroundColorFunction === 'function') {
                  const context = {
                    chart: chart,
                    dataIndex: index,
                    dataset: dataset,
                    datasetIndex: datasetIndex
                  };
                  color = backgroundColorFunction(context);
                }

                // Draw the triangle
                ctx.save();
                ctx.beginPath();
                ctx.moveTo(triangleX, triangleY - triangleSize / 1.5);
                ctx.lineTo(triangleX, triangleY + triangleSize / 1.5);
                if (align === 'left') {
                  ctx.lineTo(triangleX + triangleSize, triangleY);
                } else if (align === 'right') {
                  ctx.lineTo(triangleX - triangleSize, triangleY);
                }
                ctx.closePath();
                ctx.fillStyle = color; // Use the background color
                ctx.fill();
                ctx.restore();
              }
            });
          }
        });
      }
    };
    Chart.register(trianglePlugin);
    // ##################################

    const yAxisLabelPositionPlugin = {
      id: 'yAxisLabelPositionPlugin',
      afterDraw(chart) {
        const yAxis = chart.scales.y;
        if (!yAxis || !chart.options.scales.y.customTick) return;

        const ctx = chart.ctx;
        const customTickOptions = chart.options.scales.y.customTick;

        // Extracting font properties from the customTick options
        const fontSize = customTickOptions.font?.size || 12; // Default to 12 if no font size is set
        const fontFamily = customTickOptions.font?.family || 'Arial'; // Default to Arial if no font family is set
        const fontColor = customTickOptions.font?.color || '#000'; // Default to black if no font color is set

        ctx.fillStyle = fontColor;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';
        ctx.font = `${fontSize}px ${fontFamily}`; // Set the font size, family, and color

        yAxis.ticks.forEach((tick, index) => {
          const y = yAxis.getPixelForTick(index);

          const labelX = yAxis.right + (customTickOptions.shiftX || 0);
          const labelY = y + (customTickOptions.shiftY || 0);

          ctx.fillText(tick.label, labelX, labelY);
        });
      }
    };
    Chart.register(yAxisLabelPositionPlugin);
    // ##################################

    const shadowPlugin = {
      id: 'shadowPlugin',
      afterDatasetsDraw: function (chart) {
        const pointShadowOptions = chart.options.datasets.pointShadow;
        if (pointShadowOptions) {
          const ctx = chart.ctx;

          chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach((element, index) => {
                // Extract point style and border properties
                const pointStyle = dataset.pointBackgroundColor[index];
                const pointBorderColor = dataset.pointBorderColor;
                const pointBorderWidth = dataset.pointBorderWidth;

                // Draw shadow with options from pointShadow
                ctx.save();
                ctx.shadowColor = pointShadowOptions.shadowColor || 'rgba(0, 0, 0, 0.5)';
                ctx.shadowBlur = pointShadowOptions.shadowBlur || remToPx(5);
                ctx.shadowOffsetX = pointShadowOptions.shadowOffsetX || 0;
                ctx.shadowOffsetY = pointShadowOptions.shadowOffsetY || remToPx(0.2);
                ctx.fillStyle = pointStyle;
                ctx.beginPath();
                ctx.arc(element.x, element.y, element.options.radius, 0, 2 * Math.PI);
                ctx.fill();
                ctx.restore();

                // Redraw the point on top without shadow
                ctx.beginPath();
                ctx.fillStyle = pointStyle;
                ctx.arc(element.x, element.y, element.options.radius, 0, 2 * Math.PI);
                ctx.fill();

                // Redraw the border
                if (pointBorderWidth > 0) {
                  ctx.strokeStyle = pointBorderColor;
                  ctx.lineWidth = pointBorderWidth;
                  ctx.stroke();
                }
              });
            }
          });
        }
      }
    };

    Chart.register(shadowPlugin);
    // ##################################
    // ##################################

    // ##################################
    // #######   Chart Options   ########
    // ##################################
    const options = computed(() => ({
      datasets: {
        pointShadow: {
          shadowColor: 'rgba(0, 0, 0, 0.5)',
          shadowBlur: remToPx(0.5),
          shadowOffsetX: 0,
          shadowOffsetY: 0
        }
      },
      verticalLine: true,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      layout: {
        padding: {
          bottom: 0,
          top: remToPx(3),
          right: 0,
          left: 0
        }
      },
      scales: {
        x: {
          display: true,
          width: 0,
          grid: {
            display: false, // This will remove the vertical grid lines
          },
          ticks: {
            font: {
              size: state.tickFontSize,
              color: "#8F8F8F",
              family: 'Neue Haas Grotesk Display Pro'
            },
            maxRotation: 0, // Set the rotation of the ticks to 0
            minRotation: 0,
            color: "#8F8F8F"
          },
        },
        y: {
          display: true,
          grid: {
            drawBorder: false,
            z: 5
          },
          beginAtZero: true,
          customTick: {
            font: {
              size: state.tickFontSize,
              color: "#8F8F8F",
              family: 'Neue Haas Grotesk Display Pro'
            },
            shiftX: -5,
            shiftY: -5,
          },
          ticks: {
            display: false,
            stepSize: 100,
          }
        }
      },
      plugins: {

        yAxisLabelPositionPlugin: true,
        legend: {
          display: false,
        },

        // Include all your desired plugins here
        ChartDataLabels,
      },

      maintainAspectRatio: false,
    }));

    // ##################################
    // ##################################

    // ##################################
    // ########   Chart Data    #########
    // ##################################
    const compareData = computed(() => ({
      labels: hours,
      showTooltips: false,

      datasets: [
        {
          label: 'Completed picklists yesterday',
          data: [null, ...closedPicklistsYesterday.value],
          // data: ["50", "100"],
          borderColor: '#128A26',
          borderWidth: state.borderWidth,
          borderDash: [5, 5],
          fill: false,
          pointBackgroundColor: [null, ...closedPicklistsYesterday.value].map(value => value === Math.max(...closedPicklistsYesterday.value) ? '#FF551F' : 'rgba(0,0,0,0)'),
          pointBorderColor: "#FFFFFF",
          pointRadius: 0,
          pointHoverRadius: 0,
          lineTension: 0.4,
          spanGaps: true,
          datalabels: {
            display: false,
          },
          order: 1
        },
        {
          label: 'Incoming picklists',
          trianglePointer: true,
          gradientType: 'orange',
          data: [null, ...incomingOrders.value],
          // data: ["50", "100"],
          borderColor: '#FF551F',
          borderWidth: state.borderWidth,

          fill: true,
          pointBackgroundColor: [null, ...incomingOrders.value].map(value => value === Math.max(...incomingOrders.value) ? '#FF551F' : 'rgba(0,0,0,0)'),
          pointBorderColor: "#FFFFFF",
          pointRadius: [null, ...incomingOrders.value].map(value => value === Math.max(...incomingOrders.value) ? state.pointRadius : 0),
          pointHoverRadius: [null, ...incomingOrders.value].map(value => value === Math.max(...incomingOrders.value) ? state.pointRadius : 0),
          pointBorderWidth: state.pointBorderWidth,
          hoverBorderWidth: state.pointBorderWidth,
          hoverBorderColor: "#FFFFFF",
          lineTension: 0.4,

          datalabels: {
            color: '#FFFFFF',
            backgroundColor: (context) => {
              const value = context.dataset.data[context.dataIndex];
              const max = Math.max(...context.dataset.data);

              // Check for null, undefined, zero values, and non-max values
              if (value === null || value === undefined || value === 0 || value !== max) {
                return 'transparent';
              }
              return "#FF551F"; // Return red for the peak label
            },
            borderRadius: state.labelBorderRadius,
            offset: remToPx(3.5),
            align: 'right',
            anchor: 'center',
            // textShadowBlur: 20,
            textShadowColor: '#000000',
            formatter: (value, context) => {
              // Check for null, undefined, or zero values
              if (value === null || value === undefined || value === 0) {
                return '';
              }

              // Your existing logic
              var max = Math.max(...context.dataset.data);
              if (value === max) {
                return value;
              } else {
                return ''; // Return an empty string to hide the label
              }
            },

            font: {
              size: state.labelFontSize,
              lineHeight: 1.1,
              weight: 'bold'
            },
            padding: {
              bottom: remToPx(0.4),
              top: remToPx(0.4),
              right: remToPx(1.2),
              left: remToPx(1.2)
            }
          },
          order: 2
        },
        {
          label: 'Incoming picklists yesterday',
          data: [null, ...incomingOrdersYesterday.value],
          // data: ["50", "100"],
          borderColor: 'red',
          borderWidth: state.borderWidth,
          borderDash: [5, 5],
          fill: false,
          pointBackgroundColor: [null, ...incomingOrdersYesterday.value].map(value => value === Math.max(...incomingOrdersYesterday.value) ? '#FF551F' : 'rgba(0,0,0,0)'),
          pointBorderColor: "#FFFFFF",
          pointRadius: 0,
          pointHoverRadius: 0,
          lineTension: 0.4,
          spanGaps: true,
          datalabels: {
            display: false,
          },
          order: 3
        },
        {
          label: 'Picklists completed',
          trianglePointer: true,

          gradientType: 'green',
          data: [null, ...incomingPicklists.value],
          borderColor: '#1dd73c',
          borderWidth: state.borderWidth,
          pointBackgroundColor: [null, ...incomingPicklists.value].map(value => value === Math.max(...incomingPicklists.value) ? '#1dd73c' : 'rgba(0,0,0,0)'),
          pointBorderColor: "#FFFFFF",
          pointRadius: [null, ...incomingPicklists.value].map(value => value === Math.max(...incomingPicklists.value) ? state.pointRadius : 0),
          pointHoverRadius: [null, ...incomingPicklists.value].map(value => value === Math.max(...incomingPicklists.value) ? state.pointRadius : 0),
          pointBorderWidth: state.pointBorderWidth,
          hoverBorderWidth: state.pointBorderWidth,
          hoverBorderColor: "#FFFFFF",
          fill: true,
          lineTension: 0.3,
          datalabels: {
            color: '#FFFFFF',

            backgroundColor: (context) => {
              const value = context.dataset.data[context.dataIndex];
              const max = Math.max(...context.dataset.data);

              // Check for null, undefined, zero values, and non-max values
              if (value === null || value === undefined || value === 0 || value !== max) {
                return 'transparent';
              }
              return "#18B432"; // Return red for the peak label
            },

            borderRadius: state.labelBorderRadius,
            offset: remToPx(3.5),
            align: 'left',
            anchor: 'center',
            // textShadowBlur: 20,
            textShadowColor: '#000000',
            formatter: (value, context) => {
              if (value > 0) {
                // Check if the value is the highest in the dataset
                var max = Math.max(...context.dataset.data);
                if (value === max) {
                  return value;
                } else {
                  return ''; // Return an empty string to hide the label
                }
              }

              return '';

            },
            font: {
              size: state.labelFontSize,
              weight: 'bold'
            },
            padding: {
              bottom: remToPx(0.4),
              top: remToPx(0.4),
              right: remToPx(1.2),
              left: remToPx(1.2)
            }

          },
          order: 4
        },

      ],
    }));
    // ##################################
    // ##################################

    onMounted(async () => {
      await nextTick().then(() => {

        if (data) {
          const { orders, picklists, ordersYesterday, picklistsYesterday } = processIncomingData(data.value);

          closedPicklistsYesterday.value = picklistsYesterday;
          incomingOrders.value = orders;
          incomingPicklists.value = picklists;
          incomingOrdersYesterday.value = ordersYesterday;
        }

        getResponsiveValues()
      });


      if (lineCompareRef.value) {
        lineCompareRef.value.update();
      } else {
        console.log('lineCompareRef is not initialized yet');
      }
    });



    const processIncomingData = (incomingData) => {
      // console.log(incomingData);
      const today = moment().startOf('day');
      let processedData = Array.from({ length: 24 }, () => ({ OrdersPerHour: 0, PicklistsPerHour: 0, OrdersPerHourPreviousDay: 0, PicklistsClosedPreviousDay: 0 }));

      if (incomingData.length > 0) {

        incomingData.forEach((element) => {
          let elementDate = moment(element.HourAndDay);
          if (elementDate.isSame(today, 'day')) {
            let hour = elementDate.hour();
            processedData[hour] = {
              OrdersPerHour: element.OrdersPerHour || 0,
              PicklistsPerHour: element.PicklistsPerHour || 0,
              OrdersPerHourPreviousDay: element.OrdersPerHourPreviousDay || 0,
              PicklistsClosedPreviousDay: element.PicklistsClosedPreviousDay || 0
            };
          }
        });

        return {
          orders: processedData.map(x => x.OrdersPerHour),
          picklists: processedData.map(x => x.PicklistsPerHour),
          ordersYesterday: processedData.map(x => x.OrdersPerHourPreviousDay),
          picklistsYesterday: processedData.map(x => x.PicklistsClosedPreviousDay),
        };
      }

      return {
        orders: Array(24).fill(0),
        picklists: Array(24).fill(0),
        ordersYesterday: Array(24).fill(0),
        picklistsYesterday: Array(24).fill(0)
      };

    };

    const dataWatcher = computed(() => props.data);

    // watch(dataWatcher, (newIncomingPickOrd) => {
    //   const { orders, picklists } = processIncomingData(newIncomingPickOrd);
    //   incomingOrders.value = orders;
    //   incomingPicklists.value = picklists;
    //   // Trigger chart update
    //   if (lineCompareRef.value) {
    //     lineCompareRef.value.update();
    //   }
    // }, { deep: true });

    // watch(dataWatcher, (newIncomingPickOrd) => {
    //   // Process new incoming data
    //   let arrayToFill = new Array(24).fill({
    //     OrdersPerHour: 0,
    //     PicklistsPerHour: 0,
    //     HourAndDay: ""
    //   });

    //   // console.log(newIncomingPickOrd)

    //   newIncomingPickOrd.forEach((element) => {
    //     if (moment(element.HourAndDay).isSame(new Date(), "day")) {
    //       let hour = parseInt(element.HourAndDay.split(" ")[1]);
    //       arrayToFill[hour] = element;
    //     }
    //   });

    //   incomingPicklists.value = arrayToFill.map(x => x.PicklistsPerHour !== null ? x.PicklistsPerHour : 0);
    //   incomingOrders.value = arrayToFill.map(x => x.OrdersPerHour !== null ? x.OrdersPerHour : 0);

    //   incomingOrdersYesterday.value = arrayToFill.map(x => x.OrdersPerHourPreviousDay === undefined ? 0 : x.OrdersPerHourPreviousDay);

    //   // Trigger chart update

    // }, { deep: true });

    watch(dataWatcher, (newIncomingPickOrd) => {
      // Process new incoming data for orders, picklists, and orders from yesterday
      let arrayToFill = new Array(24).fill({
        OrdersPerHour: 0,
        PicklistsPerHour: 0,
        OrdersPerHourPreviousDay: 0,
        HourAndDay: "",
        PicklistsClosedPreviousDay: 0
      });

      newIncomingPickOrd.forEach((element) => {
        if (moment(element.HourAndDay).isSame(new Date(), "day")) {
          let hour = parseInt(element.HourAndDay.split(" ")[1]);
          arrayToFill[hour] = element;
        }
      });

      // Update the orders, picklists, and orders from yesterday
      incomingPicklists.value = arrayToFill.map(x => x.PicklistsPerHour !== null ? x.PicklistsPerHour : 0);
      incomingOrders.value = arrayToFill.map(x => x.OrdersPerHour !== null ? x.OrdersPerHour : 0);
      incomingOrdersYesterday.value = arrayToFill.map(x => x.OrdersPerHourPreviousDay === undefined ? 0 : x.OrdersPerHourPreviousDay);
      closedPicklistsYesterday.value = arrayToFill.map(x => x.PicklistsClosedPreviousDay === null ? 0 : x.PicklistsClosedPreviousDay);

      // Trigger chart update if necessary
      if (lineCompareRef.value) {
        lineCompareRef.value.update();
      }

      // Additional processing if needed

    }, { deep: true });

    watch(dataWatcher, (newValue) => {
      data.value = [...newValue];
    });


    return {
      state,
      getResponsiveValues,
      options,
      compareData,
      incomingOrders,
      incomingOrdersYesterday,
      incomingPicklists,
      closedPicklistsYesterday,
      data,
      compareHandleChartRender,
      lineCompareRef
    };
  },
};

</script>
<style lang="scss">
.warehouse-dashboard {
  .picklist__container {
    & .picklist {
      &__canvas {
        margin-top: 1rem;
      }

      &__lable {
        font-size: 2rem;
        font-weight: 400;
        color: #8F8F8F;
        line-height: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        &:before {
          content: "";
          display: block;
          width: 3rem;
          height: 0.4rem;
        }

        &--green:before {
          background-color: var(--color-green);
        }

        &--orange:before {
          background-color: var(--color-orange);
        }

        &--dashed-closed:before {
          background: linear-gradient(90deg, rgb(166, 241, 166) 20%, rgb(255, 255, 255) 20%, rgb(255, 255, 255) 40%, rgb(166, 241, 166) 40%, rgb(166, 241, 166) 60%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 80%, rgb(166, 241, 166) 80%);
          height: 0.2rem;
        }

        &--dashed-incoming:before {
          background: linear-gradient(90deg, rgb(255, 0, 0) 20%, rgb(255, 255, 255) 20%, rgb(255, 255, 255) 40%, rgb(255, 0, 0) 40%, rgb(255, 0, 0) 60%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 80%, rgb(255, 0, 0) 80%);          height: 0.2rem;
        }
      }

      &-compare {
        &__sub {
          display: flex;
          width: 70%;
          justify-content: space-between;
          row-gap: 1rem;
          column-gap: 2rem;
          margin-top: 2.6rem;
        }

        grid-area: 1 / 1 / 2 / 3;

        & .line-chart {
          height: 100%;
        }

        &__title {
          flex: 2;
        }
      }

    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &__lable {
          font-size: 1.2rem;
          line-height: 1.2rem;
          gap: 0.5rem;

          &:before {
            width: 1.6rem;
            height: 0.2rem;
          }
        }

        &-compare {
          grid-area: 1 / 1 / 3 / 3;

          &__sub {

            width: 100%;
            justify-content: space-between;
            row-gap: 0.4rem;
            column-gap: 1.2rem;
            margin-top: 1.6rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-compare {
          &__sub {
            height: 15%;
          }

          & .line-chart {
            height: 85%;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .warehouse-dashboard {
    .picklist__container {
      & .picklist {
        &-compare {
          &__sub {
            flex-wrap: wrap;

            & .picklist__lable {
              flex-basis: 48%;
            }
          }
        }
      }
    }
  }
}
</style>