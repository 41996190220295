<template>
  {{ formattedDateTime }}
</template>
<script>
import { ref, onMounted } from "vue";

export default {
  name: 'DateTime',
  setup() {
    const formattedDateTime = ref("");
    const updateDateTime = () => {
      const now = new Date();

      // Formatting the date part
      const dateOptionsWeekDay = { weekday: "long" };
      const dateOptionsDay = { day: "numeric" };
      const dateOptionsMonth = { month: "long" };
      const formattedWeekDay = new Intl.DateTimeFormat("en-US", dateOptionsWeekDay).format(now);
      const formattedDay = new Intl.DateTimeFormat("en-US", dateOptionsDay).format(now);
      const formattedMonth = new Intl.DateTimeFormat("en-US", dateOptionsMonth).format(now);

      // Formatting the time part
      const timeOptions = { hour: "numeric", minute: "2-digit", hour12: false }; // 24-hour format
      const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(now);

      // Concatenating the date and time parts in the desired order
      formattedDateTime.value = `${formattedWeekDay} ${formattedDay} ${formattedMonth} ${formattedTime}`;
    };

    onMounted(async () => {

      updateDateTime();
      const interval = setInterval(updateDateTime, 60000); // Update every minute

    });
    return {
      formattedDateTime,
    }
  },

}

</script>