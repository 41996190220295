<template>
  <div class="settings">
    <div class="settings-header">
      <div class="title">Profile</div>
      <div class="sub-title">Change your personal settings here</div>
    </div>
    <div class="settings-body">
      <div class="field">
        <label>Firstname</label>
        <div class="no-input">{{user.firstName}}</div>
      </div>
      <div class="field">
        <label>Lastname</label>
        <div class="no-input">{{user.lastName}}</div>
      </div>
      <div class="field">
        <label>Email</label>
        <input v-model="user.email" type="text">
      </div>
      <div class="field">
        <label>Role</label>
        <div class="no-input">{{user.role === 2 ? "Admin" : user.role === 3 ? "Manager" : user.role === 4 ? "Bedrukker" : "User"}}</div>
      </div>
      <div class="field">
        <label>PicqerID</label>
        <div class="no-input">{{user.pickerId}}</div>
      </div>
      <div class="field">
        <label>User since</label>
        <div class="no-input">{{user.createdDate}}</div>
      </div>
    </div>
    <div class="settings-footer">
      <div class="btn" @click="saveProfile">Save profile</div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive} from "vue";
import Page from '@/components/Page.vue'
import { useCookies } from "vue3-cookies";
const { cookies } = useCookies();
import moment from 'moment';
import VueMultiselect from 'vue-multiselect'

export default {
  name: "Settings Profile",
  components: { Page, VueMultiselect },
  data() {
    return {
      loading: false,
      pickers: [],
      currentUserId: null,
      errorMessage: "",
      showError: false,
      selectedUserObject: [{firstName: "", lastName: "", email: "", pickerId: "", createdDate:"", password: "", id: "", role: 1}],
      currentUserRole: 1,
      roles: {"User": 1, "Printer": 4, "Manager": 3, "Admin": 2}
    }
  },
  setup() {
    const user = ref({});

    onMounted(async () => { 
      user.value = cookies.get('token');
      user.value.createdDate = moment(user._value.createdDate).format('YYYY-MM-DD HH:mm:ss').toString();
    })

    async function saveProfile(){
      const updatedUser = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + user._value.id, {
        method: 'PUT', 
        headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""},
        body: JSON.stringify(user._value)
      })

      if(updatedUser.ok)
      {
        const userNew = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + user._value.id, {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user._value.token}` : ""}

        })

        // Show alert (Succesfully updated profile!)
        cookies.set('token', user._value);
      }


      if(updatedUser.email != null)
      {
        const user = user._value;
      }
    }

    return {
     user, saveProfile
    }
  },
  methods: {
    async saveUser()
    { 
      const user = cookies.get('token');
      this.selectedUserObject[0].role = this.currentUserRole;
      const updatedUser = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + this.selectedUserObject[0].id, {
        method: 'PUT', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""},
        body: JSON.stringify(this.selectedUserObject[0])
      })

      if(updatedUser.ok)
      {
        const userNew = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/" + this.selectedUserObject[0].id, {
        method: 'GET', 
        headers: {"Authorization": user ? `Bearer ${user.token}` : ""}

        })
      }
    },
    async changePacker(event){
      this.currentUserRole = this.selectedUserObject[0]["role"];
      cookies.set("currentUserId", event.pickerId);
      Object.assign(this.selectedUserObject, this.pickers.filter((picker) => picker.pickerId === event.pickerId));
      this.selectedUserObject[0]["createdDate"] = moment(this.selectedUserObject[0]["createdDate"]).format('YYYY-MM-DD HH:mm:ss').toString();
    },

    async users(){
       this.loading = true
      try {
        const user = cookies.get('token');
        const res = await fetch(process.env.VUE_APP_ROOT_API + "/api/users/", { headers: {"Authorization": user ? `Bearer ${user.token}` : ""}})
         
        this.pickers = await res.json()
        this.loading = false
      } catch (error) {
        this.errorMessage = error
        this.showError = true
        this.loading = false
      }
    }
  },

  async created() {
    this.currentUserId = cookies.get("currentUserId") ?? String.empty;
    this.users()

    if(this.currentUserId !== String.empty)
    {
       Object.assign(this.selectedUserObject, this.pickers.filter((picker) => picker.pickerId === this.currentUserId));
    }
  }
}
</script>